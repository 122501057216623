import { Typography,Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react'
import  PropTypes  from 'prop-types';
import AddNotification from './AddNotification'
import NotificationGrid from './NotificationGrid';
import getAccess from '../../../utils/getAccess';

function TabPanel (props){
    const { children , value , index , ...other} = props;
    return (
        <div
         role ="tabpanel"
         hidden = {value !== index}
         id ={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
         >
            { value == index && (
                <Box sx={{p : 3}}>
                    <Typography >{children} </Typography>
                </Box>
            )}

        </div>
    )
}
TabPanel.propTypes = {
    children : PropTypes.node ,
    value : PropTypes.number.isRequired,
    index : PropTypes.number.isRequired

}

function a11yProps(index){
    return {
        id : `simple-tab-${index}`,
        'aria-controls':`simple-tabpanel-${index}`
    }
}
const Notification = () => {
    const access = getAccess();
    const[value , setValue] = useState(0);
    const handleChange = (event , newValue) => {
        setValue(newValue);
    }
  return (
    <Box sx={{ width : '100%'}}>
        <h2> Notification </h2>
        <Box sx={{borderBottom : 1 , borderColor : 'divider'}}>
            <Tabs value={value} onChange={handleChange} sx={{width : '100%'}} aria-label='basic tab example'>
               <Tab label="All Notification" sx={{width : '100%'}} {...a11yProps(0)}/>
               { ( access?.access?.includes('all') || access?.access?.includes('142')) && <Tab label="Create Notification" sx={{width : '100%'}} {...a11yProps(1)}/>}
            </Tabs>
                
        </Box>
            <TabPanel value={value} index={0}>
                <NotificationGrid />
            </TabPanel>
            <TabPanel value={value} index={1}>
            { ( access?.access?.includes('all') || access?.access?.includes('142')) && <AddNotification /> }
            </TabPanel>
    

    </Box>
  )
}

export default Notification