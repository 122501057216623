import * as React from "react";
import Box from "@mui/material/Box";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ModeIcon from '@mui/icons-material/Mode';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LogoutIcon from '@mui/icons-material/Logout';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Stack , Avatar,Typography, Modal, Button, TextField} from "@mui/material";
import getAccess from "../../../utils/getAccess";
import { RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { useTheme } from '@emotion/react';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius : '15px'
};


function CustomToolbar ({rows , columns , ...gridProps}){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'serialNumber') {
            return index+1;
          }else if ( columnId === 'profileWithName'){
            return row[columnId]?.name;
          }
           
          return row[columnId]?.toString().replaceAll("," ," ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'AllStaff.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function TeacherGrid() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const theme = useTheme()

 
  const [ loading , setLoading] = React.useState(true);
  const navigate = useNavigate();
  const access = getAccess();

  // modal  
  const [open , setOpen ] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  const [id , setId] = React.useState("");
  const[file , setFile] = React.useState();
  const [val, setVal] = React.useState(false);
  const [focused , setFocused] =  React.useState(false);
  
  const changeHandler = (e) => {
    setFile(e.target.files[0])
  }
  
  const profileUpdate = async () => {
      if( file ){
        setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("teacher" , id);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_LIVE_URL}/adminSection/updateProfilePhoto`,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status) {
            setLoading(false);
            toast.success("Profile Update Successfully");
            handleClose();
            // setTimeout(() => {
            //   // navigate(`/dashboard/batchDetails/${Id}`);
            // }, 1000);
          } else {
            toast.error(response.data.msg);
            // reset();
            setLoading(false);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
      }else {
        toast.error('Please Select A Profile Photo')

      }
    
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    
    setLoading(true);
    async function fetchStaffs() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/getteacherListByAdmin`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
      setRows(responseJson.data);
      setLoading(false);
    
    }

    fetchStaffs();
  }, []);


  const makeActiveAndInActiveTeacher = async (id) => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/makeActiveAndInActiveTeacher/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    const responseJson = await response.json();
    // console.log(responseJson);
    if( responseJson?.status){
      toast.success(responseJson.msg)
      setTimeout(() => window.location.reload()
      , 1000)
    }else{
      toast.error(responseJson.msg);
    }
    // setLoadingButton(false)
    // toast.success('Deleted')
    

  }

  const logoutUser = async (id) => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/logoutAdmin/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    const responseJson = await response.json();
    // console.log(responseJson);
    if( responseJson?.status){
      toast.success(responseJson.msg)
      setTimeout(() => window.location.reload()
      , 1000)
    }else{
      toast.error(responseJson.msg);
    }
    // setLoadingButton(false)
    // toast.success('Deleted')
    

  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const columns = [
    {
      field: "sNo",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      // editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "profileWithName",
      headerName: "Name",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell : (params) =>  (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={params?.value?.name} src={params?.value?.profilePhoto} sx={{ width: 28, height: 28 }} />
            <Typography variant="subtitle2" noWrap>{params?.value?.name}</Typography>
        </Stack>
      )
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ?? "NA"}</div>
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Phone",
      type: "String",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
        field: "Role",
        headerName: "Role",
        type: "String",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },
      {
        field: "categories",
        headerName: "Categories",
        type: "String",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell : (params) => (
          params?.value?.length > 0 ? <Stack spacing={2} direction='column' >
            { params?.value?.map((item) => (
             <span>{item?.title}</span>
          ))}
          </Stack> : <strong>NA</strong>
   
        )
      },
      {
        field: "demoVideo",
        headerName: "Demo Video",
        type: "String",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
          params.value != "" ? <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <YouTubeIcon fontSize="large" style={{ color: 'red', marginRight: '8px' }} />
          </a> : <span>NA</span>
        )
      },
    
    
      {
        field: "created_at",
        headerName: "Created At",
        type: "String",
        width: 250,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "refreshToken",
      headerName: "isLogin",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params?.value == "" ? "red" : "green" }}>
          {params?.value == "" ? "Not Login" : "Login"}
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find(item => item.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Edit">
            <GridActionsCellItem
            icon={<ModeIcon />}
            label="Edit"
            onClick={() => navigate(`/dashboard/staff/updateStaff/${id}`)}
            color="inherit"
          />
          </Tooltip> : <></>)
          ,
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Update Permission">
                 <GridActionsCellItem
                    icon={<LockPersonIcon sx={{color : 'red'}}/>}
                   label="Update Permission"
                   onClick={() => navigate(`/dashboard/staff/updatePermission/${id}`)}
                  color="inherit"
                 />
          </Tooltip> : <></>),
           ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Change Status">
           <GridActionsCellItem
            icon={ rowData?.isActive ? <ToggleOffIcon style={{ color:'green'}}  /> : <ToggleOnIcon style={{color : 'red'}}/>}
            label="Status"
            onClick={() => { makeActiveAndInActiveTeacher(id) }}
            color="inherit"
          />
        </Tooltip> : <></>),
        
        ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Logout">
        <GridActionsCellItem
         icon={  <LogoutIcon style={{color : 'red'}}/>}
         label="Status"
         onClick={() => { logoutUser(id) }}
         color="inherit"
       />
     </Tooltip> : <></>)
         ,
         ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Profile Update">
        <GridActionsCellItem
         icon={  <InsertEmoticonIcon style={{color : 'red'}}/>}
         label="Update Profile"
         onClick={() => { setId(id) ; setOpen(true) }}
         color="inherit"
       />
     </Tooltip> : <></>)
        ];
      },
    },
  ];

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box mb={2}>
        {/* <h2>All Staff</h2> */}
      </Box>
      <DataGrid
        // autoHeight
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        editMode="row"
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="profile-update"
        aria-describedby="for-profile-update-of-staff"
      >
        <Box sx={style}>
          <Box sx={{ marginBottom : '10px'}}>
             <h3>Upload Profile Photo </h3>
          </Box>
        <TextField 
              name="file"
              onChange={changeHandler}
              id="file"
              error={!file && val}
              helperText='Image Only ( Maximum allowed size 100KB)'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              InputProps={{
                  classes: {
                      input: "CustomTextField"
                  }
              }}
              inputProps={{ accept: "image/*" }}
              type =  'file'
          />
          <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleClose()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => profileUpdate()}>Upload</Button>
          </Stack>
        </Box>

      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
