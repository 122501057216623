import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import getAccess from "../../../utils/getAccess";

// ----------------------------------------------------------------------

function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'categoryDetails') {
            return `${row.categoryDetails.title}`;
          }
          else if ( columnId === 'serialNumber'){
            return index+1
          }
          return row[columnId]?.toString()?.replaceAll(",","");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'UserCTA.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function CTA() {
  const d = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };


  const handleDeleteClick =  (id) => async() => {
    let confirmDelete = window.confirm("Want to Delete CTA?")
    if( confirmDelete){
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteCTA/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if( resJson.status){
      
      toast.success(resJson.msg);
      setRows(rows.filter((row) => row.id !== id));
    }else{
      toast.error(resJson.msg);
    }
    // toast.success(resJson);
   };
    }
  
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  React.useEffect(()=>{
    const token = localStorage.getItem('token');
    async function fetchCall(){
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAllCTA` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`

      }
      });

      const responseJson = await response.json();
       setRows(responseJson.data);

    }
    fetchCall()

  },[])
 
  const rowWithSerialNumber = rows?.map((item , index) => { return { ...item , serialNumber : index+1}})

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
    {
      field: "fullName",
      headerName: "Name",
      // type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
        field: "phoneNumber",
        headerName: "Phone Number",
        // type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "email",
        headerName: "Email",
        // type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "categoryDetails",
        headerName: "Category",
        // type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        renderCell: (params) => (
          <div>{params.value ? params.value?.title : "NA"}</div>
        ),
      },
      
      {
        field: "utmCampaign",
        headerName: "Utm Campaign",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderCell: (params) => (
          <div>{params.value ? params.value : "NA"}</div>
        ),
      },
      {
        field: "utmSource",
        headerName: "Utm Source",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderCell: (params) => (
          <div>{params.value ? params.value : "NA"}</div>
        ),
      },
      {
        field: "utmMedium",
        headerName: "Utm Medium",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderCell: (params) => (
          <div>{params.value ? params.value : "NA"}</div>
        ),
      },
      
      {
        field: "createdAt",
        headerName: "Requested Date",
        // type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          return [
            ( d?.deleteAccess == true || d?.access?.includes('all')) ?  
            <GridActionsCellItem
              icon={<DeleteIcon style={{color : 'red'}}/>}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            /> : <></> 
          ];
        }
      }
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        rows={rowWithSerialNumber}
        columns={columns}
        getRowHeight={() => 'auto'} 
        getEstimatedRowHeight={() => 200} 
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        autoHeight={true}
        
        processRowUpdate={processRowUpdate}
        initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7, 50,100]}
          slots={{
            toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
          }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
     
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
