import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Butn,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
  Box,
  Divider,
  Grid,
  Select,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import AddQuiz from "./AddQuizQuestion";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { set } from "lodash";
// import RHFTextEditorUpdate from '../../../components/hook-form/RHFTextEditorUpdate';
import { yupResolver } from "@hookform/resolvers/yup";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

import LinearProgress from "@mui/material/LinearProgress";

import "./quiz.css";

import EditQuestion from './EditQuestion';
import { Link } from 'react-router-dom';
import { useTheme } from "@emotion/react";
import getAccess from "../../../utils/getAccess";

// ------------------------------------------------

export default function QuizTable() {

  const access = getAccess();
  const theme = useTheme();

  const [formData, setFormData] = useState({
    question_e: "",
    question_h: "",
    option1_e: "",
    option2_e: "",
    option3_e: "",
    option4_e: "",
    option1_h: "",
    option2_h: "",
    option3_h: "",
    option4_h: "",
    difficulty_level: "",
    correctAns: "",
    answer_e: "",
    answer_h: "",
  });

  const handleForm = (e) => {
    //console.log("change");
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdateQuestions = (item) => {
    setFormData({
      ...formData,
      question_e: item?.question_title[0].e,
      question_h: item?.question_title[0].h,
      difficulty_level: item?.que_level[0].e,
      option1_e: item?.option1[0].e,
      option2_e: item?.option2[0].e,
      option3_e: item?.option3[0].e,
      option4_e: item?.option4[0].e,
      option1_h: item?.option1[0].h,
      option2_h: item?.option2[0].h,
      option3_h: item?.option3[0].h,
      option4_h: item?.option4[0].h,
      correctAns: item?.correctOption,
      answer_e: item?.answer[0].e,
      answer_h: item?.answer[0].e,
    });
  };

  const [questionSelected, setQuestionSelected] = useState(false);

  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  //Getting Id and Name Of quiz
  const { quizId, quizTitle } = useParams();

  const [updatedQuestion, setupdatedQuestion] = useState({});

  const handelInput = (e) => {
    const { name, value } = e.target;

    setupdatedQuestion({
      ...updatedQuestion,
      [name]: value,
    });
  };

  const [quizD, setQuizD] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizDetails/${quizId}`,
        {
          // const fullResponse = await fetch(`http://localhost:5000/api/v1/adminPanel/getQuizDetails/${quizId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setQuizD(responseJson.data);

      // addDetails(responseJson.data)
    }

    fetchUsers();
  }, []);
  // //console.log(quizD)

  const [selectedIdOfQuestion, setSelectedIdOfQuestion] = useState("");
  const [selectedIdOfQuestionOnHover, setSelectedIdOfQuestionOnHover] =
    useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/AdminPanel/getQuizQuestions/${quizId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data); //seting data of responce to the users variable
      setSelectedIdOfQuestion(responseJson.data[0]?._id);
      setFormData({
        ...formData,
        question_e: responseJson.data[0]?.question_title[0].e,
        question_h: responseJson.data[0]?.question_title[0].h,
        difficulty_level: responseJson.data[0]?.que_level[0].e,
        option1_e: responseJson.data[0]?.option1[0].e,
        option2_e: responseJson.data[0]?.option2[0].e,
        option3_e: responseJson.data[0]?.option3[0].e,
        option4_e: responseJson.data[0]?.option4[0].e,
        option1_h: responseJson.data[0]?.option1[0].h,
        option2_h: responseJson.data[0]?.option2[0].h,
        option3_h: responseJson.data[0]?.option3[0].h,
        option4_h: responseJson.data[0]?.option4[0].h,
        correctAns: responseJson.data[0]?.correctOption,
        answer_e: responseJson.data[0]?.answer[0].e,
        answer_h: responseJson.data[0]?.answer[0].e,
      });
      setLoading(false);
    }

    fetchUsers();
  }, []);
  // //console.log(users);
  // //console.log("form");
  // //console.log(formData);

  //Delete Question
  const deleteDetails = async (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    await fetch(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteQuizQuestion/${id}`,
      {
        // await fetch(`http://localhost:3001/api/v1/${section}/${req}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      setLoading(false);
      toast.success("Deleted");

      setTimeout(() => window.location.reload(), 1000);
    });
  };

  const [deleteDisplay, setDeleteDisplay] = useState(false);

  //Update
  const handleUpdate = async () => {

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      question_title: [
        {
          e: formData?.question_e,
          h: formData?.question_h,
        },
      ],
      option1: [
        {
          e: formData?.option1_e,
          h: formData?.option1_h,
        },
      ],
      option2: [
        {
          e: formData?.option2_e,
          h: formData?.option2_h,
        },
      ],
      option3: [
        {
          e: formData?.option3_e,
          h: formData?.option3_h,
        },
      ],
      option4: [
        {
          e: formData?.option4_e,
          h: formData?.option4_h,
        },
      ],
      answer: [
        {
          e: formData?.answer_e,
          h: formData?.answer_h,
        },
      ],
      correctOption: formData?.correctAns,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/editQuizQuestion/${selectedIdOfQuestion}`,
      data,
      config
    );
    // //console.log(res.msg)

    setTimeout(() => {
      setLoading(false);
      // window.location.reload()
    }, 600);

    window.location.reload();
  };

  // uploading excel file for bulk question import
  const handleUploadExcel = () => {
    document.getElementById("fileInput").click();
    document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
  }

  const [progress, setProgress] = useState();

  const handleFileUpload = async (event) => {
    const file1 = event.target.files[0];
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: data => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total))
      },
    };
    const formData = new FormData();
    formData.append('file', file1);
    //console.log(file1);
    const response = await axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/uploadQuizQuestion/${quizId}`, formData, config);
    if (response.data.status == true) {
      toast.success("Question File Uploaded Successfully", { autoClose: false });
    }
    else {
      toast.error(response.data.message, { autoClose: false });
    }
    //console.log(response);
    setTimeout(() => window.location.reload(), 3000);

  }

  const [language, setLanguage] = useState(false);

  // function editPerticular(data) {
  //   // const history = ;
  //   const targetUrl = `quiz/editquestion/${quizId}/${selectedIdOfQuestion}/${data}`;
  //   useHistory().push(targetUrl);
  // }

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "70vh" }}
        >
          {" "}
          <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Quiz Questions">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                {quizTitle} Questions
              </Typography>
              <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: theme.palette.primary.mainTheme, '&:hover': { background: 'red' } }} onClick={handleUploadExcel}>Import From Excel</Button>
              <input
                type="file"
                id="fileInput"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </Stack>
            <div className="mainDiv">
              <div className="childDiv" style={{ width: `${progress}%` }}>
                <span>{progress}% </span>
              </div>
            </div>
            <Stack
              direction="row"
              mb={2}
              sx={{
                boxShadow: " 0px 0px 17px #BFBFBF",
                borderRadius: "15px",
                height: "100%",
                background: "#FFFFFF",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  flex: 0.5,
                  height: "100%",
                  bgcolor: theme.palette.primary.mainTheme,
                  borderRadius: "15px 0px 0px 15px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  p={2}
                  sx={{ width: "100%" }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <svg
                      width="32"
                      height="28"
                      viewBox="0 0 32 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 16.8H3.55556V11.2H0V16.8ZM0 28H3.55556V22.4H0V28ZM0 5.6H3.55556V0H0V5.6ZM7.11111 16.8H32V11.2H7.11111V16.8ZM7.11111 28H32V22.4H7.11111V28ZM7.11111 0V5.6H32V0H7.11111Z"
                        fill="white"
                      />
                    </svg>
                    <Typography color="#FFFFFF" sx={{ fontSize: "18px" }}>
                      {users?.length} Questions
                    </Typography>
                  </Stack>
                  {(access?.access?.includes('44') || access?.access.includes('all')) && (<Stack direction="row">
                    <Button
                      p={3}
                      sx={{
                        bgcolor: "#FFFFFF",
                        color: theme.palette.primary.mainTheme,
                        borderRadius: "50px",
                        "&:hover": { background: "#fff1f3" },
                      }}
                      onClick={() => {
                        navigate(
                          `/dashboard/addQuizQuestion/${quizTitle}/${quizId}`
                        );
                        // setAddQuizSelected(true)
                      }}
                    >
                      + Add Question
                    </Button>
                  </Stack>)}

                  <Typography>{deleteDisplay}</Typography>
                </Stack>
                <Stack
                  direction="column"
                  sx={{
                    width: "100%",
                    height: "1000px",
                    borderRight: "2px solid #FB5259",
                    maxHeight: "fit-content",
                    overflowY: "scroll",
                    borderBottomLeftRadius: "15px",
                  }}
                >
                  {users?.length > 0 ? (
                    users?.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedIdOfQuestion(item?._id);
                            handleUpdateQuestions(item);
                            //console.log("update");
                            //console.log(formData);
                          }}
                        >
                          <Stack
                            key={index}
                            p={2}
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            onMouseOver={() => {
                              setDeleteDisplay(true);
                              setSelectedIdOfQuestionOnHover(item?._id);
                            }}
                            onMouseLeave={() => setDeleteDisplay(false)}
                            sx={{
                              width: "100%",
                              minHeight: "100px",
                              bgcolor:
                                selectedIdOfQuestion == item?._id
                                  ? "#fff1f3"
                                  : "white",
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => setQuestionSelected(true)}
                          >
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              sx={{ flex: 1 }}
                            >
                              <Typography
                                p={0.4}
                                sx={{
                                  fontWeight: "700",
                                  color: "white",
                                  background: theme.palette.primary.mainTheme,
                                  borderRadius: "50%",
                                  textAlign: "center",
                                }}
                              >
                                Q-{index + 1}
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (language
                                      ? item?.question_title[0]?.h
                                      : item?.question_title[0]?.e
                                    )
                                      .replace(/<[^>]+>/g, "")
                                      .substring(0, 30) + " ...",
                                }}
                              ></div>
                            </Stack>
                            {item?._id == selectedIdOfQuestionOnHover && (
                              <Tooltip
                                title="Delete"
                                onClick={() => deleteDetails(item?._id)}
                                sx={{
                                  display: deleteDisplay ? "block" : "none",
                                  flex: 0.1,
                                  position: "absolute",
                                  right: "2%",
                                }}
                              >
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </div>
                      );
                    })
                  ) : (
                    <Stack
                      p={2}
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        width: "100%",
                        minHeight: "100px",
                        bgcolor: "white",
                        cursor: "pointer",
                      }}
                      onMouseOver={() => setDeleteDisplay(true)}
                      onMouseLeave={() => setDeleteDisplay(false)}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        No Questions
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  flex: 1,
                  height: "fit-content",
                  bgcolor: "#FFFFFF",
                  borderRadius: "0px 15px 15px 0px",
                }}
              >
                <Stack
                  direction="row"
                  p={1.5}
                  alignItems="center"
                  sx={{
                    width: "100%",
                    borderBottom: 1,
                    borderColor: "lightgray",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      px={2}
                      sx={{
                        color: theme.palette.primary.mainTheme,
                        background: theme.palette.primary.mainThemeLight,
                        borderRadius: "25px",
                        cursor: "pointer",
                      }}
                    >
                      {quizD?.quiz_duration} min
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      px={2}
                      sx={{
                        color: theme.palette.primary.mainTheme,
                        borderRadius: "25px",
                        cursor: "pointer",
                      }}
                    >
                      Score : {users?.length * 10}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      sx={{
                        background: theme.palette.primary.mainThemeLight,
                        borderRadius: "100%",
                        width: "45px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setLanguage(!language)}
                    >
                      {language ? "अ" : "A"}
                    </Typography>
                    {/* <Typography>
                      <EditIcon
                        onClick={() => {
                          setIsEdit(!isEdit);
                          // navigate(`/dashboard/updateQuizQuestions/${selectedIdOfQuestion}`)
                        }}
                        fontSize="large"
                        sx={{
                          color: "#FB5259",
                          background: "#fff1f3",
                          padding: 1,
                          borderRadius: "100%",
                          cursor: "pointer",
                        }}
                      />
                    </Typography> */}
                  </Stack>
                </Stack>
                {users?.length > 0 &&
                  users?.map((item, index) => {
                    return (
                      <>
                        {selectedIdOfQuestion == item?._id && (
                          <>
                            <form sx={{ margin: "10px" }}>
                              {isEdit ? (
                                language ? (
                                  <>
                                    {/* <RHFTextEditor name="question_h" label="Question" /> */}

                                    <TextField
                                      multiline
                                      rows={3}
                                      maxRows={Infinity}
                                      type="text"
                                      name="question_h"
                                      value={formData.question_h.replace(
                                        /<\/?[^>]+(>|$)/g,
                                        ""
                                      )}
                                      label="Question"
                                      onChange={handleForm}
                                      variant="standard"
                                      sx={{
                                        width: "85%",
                                        margin: "10px auto 10px 35px",
                                      }}
                                    />

                                  </>
                                ) : (
                                  <TextField
                                    multiline
                                    rows={3}
                                    maxRows={Infinity}
                                    type="text"
                                    name="question_e"
                                    label="Question"
                                    value={formData.question_e.replace(
                                      /<\/?[^>]+(>|$)/g,
                                      ""
                                    )}
                                    onChange={handleForm}
                                    variant="standard"
                                    sx={{
                                      width: "85%",
                                      margin: "10px auto 10px 35px",
                                    }}
                                  />
                                )
                              ) : (
                                <Typography
                                  p={5}
                                  variant=" h6"
                                  sx={{
                                    textAlign: "start",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "90%",
                                      marginBottom: "10px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: language
                                        ? item?.question_title[0]?.h
                                        : item?.question_title[0]?.e,
                                    }}
                                  ></div>
                                </Typography>
                              )}

                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue={item?.correctOption}
                                  name="radio-buttons-group"
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    px={5}
                                    sx={{ width: "100%" }}
                                  >
                                    <Grid item xs={4} sm={3} md={6}>
                                      <Card
                                        sx={{
                                          height: "100px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {isEdit ? (
                                          <TextField
                                            multiline
                                            rows={3}
                                            maxRows={Infinity}
                                            sx={{
                                              width: "90%",
                                              paddingLeft: "20px",
                                            }}
                                            type="text"
                                            value={
                                              language
                                                ? formData.option1_h
                                                : formData.option1_e
                                            }
                                            name={
                                              language
                                                ? "option1_h"
                                                : "option1_e"
                                            }
                                            onChange={handleForm}
                                            variant="standard"
                                          ></TextField>
                                        ) : (

                                          <Typography variant="body1" style={{ padding: "25px" }}>
                                            {language
                                              ? item?.option1[0]?.h
                                              : item?.option1[0]?.e}
                                          </Typography>
                                        )}
                                      </Card>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={6}>
                                      <Card
                                        sx={{
                                          height: "100px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {isEdit ? (
                                          <TextField
                                            multiline
                                            rows={3}
                                            maxRows={Infinity}
                                            sx={{
                                              width: "90%",
                                              paddingLeft: "20px",
                                            }}
                                            type="text"
                                            value={
                                              language
                                                ? formData.option2_h
                                                : formData.option2_e
                                            }
                                            name={
                                              language
                                                ? "option2_h"
                                                : "option2_e"
                                            }
                                            onChange={handleForm}
                                            variant="standard"
                                          ></TextField>
                                        ) : (
                                          <Typography variant="body1" style={{ padding: "25px" }}>
                                            {language
                                              ? item?.option2[0]?.h
                                              : item?.option2[0]?.e}
                                          </Typography>
                                        )}
                                      </Card>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={6}>
                                      <Card
                                        sx={{
                                          height: "100px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {isEdit ? (
                                          <TextField
                                            multiline
                                            rows={3}
                                            maxRows={Infinity}
                                            sx={{
                                              width: "90%",
                                              paddingLeft: "20px",
                                            }}
                                            type="text"
                                            value={
                                              language
                                                ? formData.option3_h
                                                : formData.option3_e
                                            }
                                            name={
                                              language
                                                ? "option3_h"
                                                : "option3_e"
                                            }
                                            onChange={handleForm}
                                            variant="standard"
                                          ></TextField>
                                        ) : (
                                          <Typography variant="body1" style={{ padding: "25px" }}>
                                            {language
                                              ? item?.option3[0]?.h
                                              : item?.option3[0]?.e}
                                          </Typography>
                                        )}
                                      </Card>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={6}>
                                      <Card
                                        sx={{
                                          height: "100px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                        }}
                                      >
                                        {isEdit ? (
                                          <TextField
                                            multiline
                                            rows={3}
                                            maxRows={Infinity}
                                            sx={{
                                              width: "90%",
                                              paddingLeft: "20px",
                                            }}
                                            type="text"
                                            value={
                                              language
                                                ? formData.option4_h
                                                : formData.option4_e
                                            }
                                            name={
                                              language
                                                ? "option4_h"
                                                : "option4_e"
                                            }
                                            onChange={handleForm}
                                            variant="standard"
                                          ></TextField>
                                        ) : (
                                          <Typography variant="body1" style={{ padding: "25px" }}>
                                            {language
                                              ? item?.option4[0]?.h
                                              : item?.option4[0]?.e}
                                          </Typography>
                                        )}
                                      </Card>
                                    </Grid>
                                  </Grid>
                                </RadioGroup>
                              </FormControl>
                              <Stack
                                direction="row"
                                alignItems="start"
                                p={5}
                                justifyContent="space-between"
                              >
                                {isEdit ? (
                                  <TextField
                                    type="text"
                                    name={language ? "answer_h" : "answer_e"}
                                    value={
                                      language
                                        ? (formData?.answer_h).replace(
                                          /<\/?[^>]+(>|$)/g,
                                          ""
                                        )
                                        : (formData?.answer_e).replace(
                                          /<\/?[^>]+(>|$)/g,
                                          ""
                                        )
                                    }
                                    multiline
                                    maxRows={7}
                                    variant="standard"
                                    sx={{ width: "70%", alignSelf: "center" }}
                                    onChange={handleForm}
                                  />
                                ) : (
                                  <div>
                                    <h4>
                                      {language ? "व्याख्या" : "Explanation"} :{" "}
                                    </h4>
                                    <div
                                      sx={{
                                        fontSize: "20px",
                                        textTransform: "capitalize",
                                        textAlign: "start",
                                        width: "100%",
                                        wordBreak: "break-all",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: language
                                          ? item?.answer[0]?.h
                                          : item?.answer[0]?.e,
                                      }}
                                    >
                                      { }
                                    </div>
                                  </div>
                                )}
                                <br></br>
                                <Stack direction="column">
                                  {isEdit ? (
                                    <>
                                      <Typography
                                        sx={{
                                          fontWeight: "700",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Correct Answer: {" "}
                                        <span style={{ color: "#4CAF50" }}>
                                          {item?.correctOption === "1" ? "A" : item?.correctOption === "2" ? "B" : item?.correctOption === "3" ? "C" : "D"}
                                        </span>
                                      </Typography>

                                      <Select
                                        name="correctAns"
                                        value={formData?.correctAns}
                                        onChange={handleForm}
                                        sx={{ marginBottom: "10px" }}
                                        label="Correct Option"
                                        select
                                      >
                                        <MenuItem value="A">A</MenuItem>
                                        <MenuItem value="B">B</MenuItem>
                                        <MenuItem value="C">C</MenuItem>
                                        <MenuItem value="D">D</MenuItem>
                                      </Select>
                                    </>

                                  ) : (
                                    <Typography
                                      sx={{
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Correct Answer:-{" "}
                                      <span style={{ color: "#4CAF50" }}>
                                        {item?.correctOption}
                                      </span>
                                    </Typography>
                                  )}
                                  {isEdit && (
                                    <Button
                                      sx={{
                                        background: theme.palette.primary.mainTheme,
                                        color: "white",
                                        width: "fit-content",
                                        padding: "5px 30px",
                                        borderRadius: "50px",
                                        fontSize: "15px",
                                      }}
                                      onClick={() => {
                                        handleUpdate();
                                      }}
                                    >
                                      Update
                                    </Button>
                                  )}

                                  <Button
                                    sx={{
                                      background: theme.palette.primary.mainTheme,
                                      color: "white",
                                      width: "fit-content",
                                      padding: "5px 30px",
                                      borderRadius: "50px",
                                      fontSize: "15px",
                                    }}
                                    onClick={() => {
                                      const encodedData = encodeURIComponent(JSON.stringify(item));
                                      // history.push(`/dashboard/quiz/editquestion/${quizId}/${selectedIdOfQuestion}/${quizTitle}/?item=${encodedData}` , {item})
                                      navigate(`/dashboard/quiz/editquestion/${quizId}/${selectedIdOfQuestion}/${quizTitle}/?item=${encodedData}`)
                                    }
                                    }
                                  >
                                    Update
                                  </Button>

                                </Stack>

                              </Stack>

                              <ToastContainer
                                position="bottom-center"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                              />
                            </form>
                          </>
                        )}
                      </>
                    );
                  })}
              </Stack>
            </Stack>
          </Container>
        </Page>
      )}
    </>
  );
}
