import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { reset } from 'numeral';
import { useTheme } from '@emotion/react';
import { toast } from 'react-toastify';
// ----------------------------------------------------------------------

export default function UpdateLectureResource() {
const theme=useTheme()
    const { id , from  } = useParams();
    const navigate = useNavigate();
    const [fetchMessage, setFetchMessage] = useState()
    const [subject, setSubject] = useState()
    const [loading, setLoading] = useState(true)
    const [positive, setPositive] = useState(false);
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [ values , setValues ] = useState();
    const [val, setVal] = useState(false);
    const [msg , setMsg] = useState();
    // const { lectId, batchId } = useParams()
    const RegisterSchema = Yup.object().shape({
    });

    const defaultValues = {
        title: '',
        resurce_type: '',
        // file_url: '',
        is_active: '',
        language: '',
    };
    const [file, setFile] = useState()


    const changeHandler = async (event) => {
        if (!type) { setFile(event.target.files[0]) }
        else {
            setFile(event.target.value)
        }


    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        //console.log(data)
        setLoading(true);
        const formData = new FormData();
        if (!type) { formData.append('file', file) }
        if (type) {
            formData.append('link', file)

        }
        formData.append('batch_id', values.batch_id);
        formData.append('lecture_id', values.lecture_id);
        formData.append('title', values.title);
        formData.append('language', values.language);
         formData.append('is_active', values.is_active);
        formData.append('resurce_type', typeName);
        for( let Pair of formData){
            console.log( Pair[0] + " " + Pair[1]);
        }
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateTheLectureResourceDetails/${id}`, formData, config).then((response) => {
            if( response.data.status == true){
                toast.success("Lecture Resouce updated");
                setLoading(false);
                if( from != 'batch'){
                    window.location.href = `/dashboard/lectureResources/${values?.lecture_id}/${values?.batch_id}`
                }else{
                    window.location.href = `/dashboard/batchDetails/${values?.batch_id}`
                }
                
            }else{
                toast.error(response.data.msg);
                setLoading(false);
            }
            // if( response..)
            


        })
            .catch(e => {
                //console.log(e);
            })

    };
    const onChange2 = (e) => {
        const { name, value } = e.target;
        // console.log( name + " "+ value);
        setValues(prev => ({ ...prev, [name]: value }))
    }

    const [users, usersSet] = useState([]);
    const [type, setType] = useState(false);
    const [typeName, setTypeName] = useState('');
    const [fileSelected, setFileSelected] = useState(false)
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const resSubject = await res.json();
            setSubject(resSubject.data);

            const details = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel//getLectureResourceById/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const detailJson = await details.json();
            checkDetails( detailJson.data);
            // console.log(detailJson)
            setTypeName(detailJson.data.resourceType);
            if( detailJson.data.resourceType == 'link' || detailJson.data.resourceType == 'yt_videos'){
                setType(true);
            }else{
                setType(false)
            }

            setLoading(false)
        }
        
        fetchUsers();
    }, []);
    const checkDetails = (detailJson) => {
        setValues( prev => ({
            ...prev,
            title : detailJson?.title,
            resourceType : detailJson?.resourceType,
            language : detailJson?.language,
            batch_id : detailJson.batch,
            lecture_id : detailJson.lecture,
            is_active: detailJson?.is_active,
        }));
        

    }
    // console.log(values)
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Update Lecture Resource</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title"  value={values?.title} onChange={onChange2}/>
                    <RHFTextField name="resurce_type" label={values?.resourceType} select>
                        <MenuItem onClick={() => {
                            setType(true)
                            setTypeName('yt_videos')
                        }} value='yt_videos' defaultValue="yt_videos">Youtube Link</MenuItem>
                        <MenuItem onClick={() => {
                            setType(true)
                            setTypeName('link')
                        }} value='link'>Link</MenuItem>
                        <MenuItem onClick={() => {
                            setType(false)
                            setTypeName('video')
                        }} value="video">Video</MenuItem>
                        <MenuItem onClick={() => {
                            setType(false)
                            setTypeName('pdf')
                        }} value="pdf">PDF</MenuItem>
                        <MenuItem onClick={() => {
                            setType(false)
                            setTypeName('DPP')
                        }} value="DPP">DPP</MenuItem>
                    </RHFTextField>
                    <Stack direction="row" spacing={3}>
                        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
                            <RHFTextField name="file"
                                onChange={changeHandler}
                                sx={{ width: '100%' }}
                                error={!fileSelected && val}
                                label="Resource"
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                inputProps={{ accept: (typeName == 'pdf' || typeName == 'DPP') ? "application/pdf" : "video/*" }}
                                helperText={type ? typeName == 'link' ? "Links Only" : "Youtube Videos link" : (typeName == 'pdf' || typeName == 'DPP') ? "Pdf Files Only" : "Video Files Only"}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}

                                onClick={() => setPdf(true)}
                                type={pdf || focus ? type ? "url" : "file" : "text"}
                            />
                            {!fileSelected && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                        </Stack>
                        <RHFTextField name="language" label={values?.language == 'en' ? "English" : values?.language == 'hi' ? "Hindi" : "Bilingual"}  value={values?.language} onChange={onChange2} select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                            <MenuItem value="enhi">Bilingual</MenuItem>
                        </RHFTextField>
                        <RHFTextField name="is_active" label={values?.is_active == true ? "Active" : "inActive"} value={values?.is_active} onChange={onChange2} select>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Update
                    </LoadingButton>
                </Stack>
                {/* <Typography variant='h4'>{fetchMessage}</Typography> */}
            </FormProvider>
            <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography>
        </Stack>
    </>
    );
}
