import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

export default function AddStoreFeaturedVideo() {
  const theme = useTheme()

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [restrict, setRestrict] = useState('')
  const [focus, setFocused] = useState(false);
  const [focusForVideo, setFocusedVideo] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [pdfForVideo, setPdfVideo] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [type, setType] = useState('none');
  const [valueId, setValueId] = useState('');
  const [list, setList] = useState([]);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    videoType: Yup.string().required('Video Type required'),
    url: Yup.string().when('videoType', {
      is: 'yt',
      then: Yup.string().required('URL is required for YouTube videos'),
      otherwise: Yup.string(), 
    }),
  
    // url: Yup.string()
    //   .required('Link Banner Required'),

    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),

    // category: Yup.string().required('Category is required'),
    // banner_url: Yup.string().required('Url No. is required'),
    // language: Yup.string().required('Language is required'),

    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    // title: '',
    // BannerType: '',
    // category: '',
    // banner_url: '',
    // language: '',

    // videoType: '',
    // linkWith: '',
    // url: '',
    // isActive: '',
  };
  const [file, setFile] = useState()
  const [videoFile, setVideoFile] = useState()



  const handleChange = (value) => {
    //console.log(`${value}`);
  };
  const changeHandler = async (event) => {
    setFile(event.target.files)
    var fileName = document.getElementById('file').value.toLowerCase();
    if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
    else { setRestrict('JPG Or PDF') }
    //console.log(file)

  };
  const changeHandlerForVideo = async (event) => {
    setVideoFile(event.target.files)
    var fileName = document.getElementById('videoFile').value.toLowerCase();
    if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
    else { setRestrict('JPG Or PDF') }
    //console.log(file)

  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    // //console.log(data)
    // var fileName = document.getElementById('file').value.toLowerCase();

    // if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) {


    // setRestrict('')
    // let myfile = [...file]
    if (file) {
      const formData = new FormData();

      setLoading(true)
      // debugger;
      //console.log(file)
      Object.values(file).forEach(file => {
        formData.append("icon", file);
      })
      if (videoType == 'upload') {
        Object.values(videoFile).forEach(file => {
          formData.append("video", file);
        })
      }
      formData.append('title', data.title);
      // formData.append('category', data.category);
      // formData.append('category', 'Banner');
      formData.append('videoType', data.videoType);
      formData.append('url', data.url);

      formData.append('isActive', data.isActive);
      // formData.append('fileName', file.name);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
      };
      // document.querySelector(".mainDiv").style.display = "block";
      setProgress(0);
      axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addFeatureVideo`, formData, config).then((response) => {
        //console.log(response.data);
        if (response.data.msg === 'New featured video added') {
          toast.success(response.data.msg);
          setLoading(false);
          setPdf(false);
          reset();
          window.location.href = "/dashboard/storeFeaturedVideo";
        }

      })
        .catch(e => {
          //console.log(e);
        })
    }
    // } else {

    //     setRestrict('Please Select pdf or jpg')
    // }
  };

  //get batch or get testSeries
  useEffect(() => {
    if (type !== '' && type !== 'none') {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/${type == "batch" ? "getBatchesDetails" : "getallTestSeriesdetails"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson.data);

      }
      fetchDetails();
    }
    setLoading(false);

  }, [type]);

  const [videoType, setVideoType] = useState('')
  // console.log('yutube', videoType)
  return (<>
    <Stack spacing={3} px={5} >
      <Typography variant='h3'>Add Store Featured Video</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>


          <RHFTextField name="title" label="Title" />
          <Stack direction="row" spacing={3}>
            <RHFTextField name="file"
              onChange={changeHandler}
              id="file"
              label="File"
              error={!file && val}
              helperText='Image Only'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}

              onClick={() => setPdf(true)}
              inputProps={{ accept: "image/*" }}
              type={pdf || focus ? "file" : "text"}

            />
            {/* {videoType == 'upload' && <RHFTextField name="videoFile"
              onChange={changeHandlerForVideo}
              id="videoFile"
              label="Video"
              error={!videoFile && val}
              helperText='Video Only'
              onFocus={() => setFocusedVideo(true)}
              onBlur={() => setFocusedVideo(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}

              onClick={() => setPdfVideo(true)}
              // inputProps={{ accept: "image/*" }}
              type={pdfForVideo || focusForVideo ? "file" : "text"}

            />} */}
            {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
            <RHFTextField name="videoType" label="Video Type" select>
              <MenuItem value='yt' onClick={(e) => setVideoType('yt')}>Youtube</MenuItem>
              <MenuItem value="upload" onClick={(e) => setVideoType('upload')}>Upload</MenuItem>
              {/* <MenuItem value="WEB">Web</MenuItem> */}
            </RHFTextField>
          </Stack>

          {videoType == 'yt' && <RHFTextField name="url" type='url' label="Youtube URL" />}
          {videoType == 'upload' && <RHFTextField name="videoFile"
              onChange={changeHandlerForVideo}
              id="videoFile"
              label="Video"
              error={!videoFile && val}
              helperText='Video Only'
              onFocus={() => setFocusedVideo(true)}
              onBlur={() => setFocusedVideo(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}

              onClick={() => setPdfVideo(true)}
              // inputProps={{ accept: "image/*" }}
              type={pdfForVideo || focusForVideo ? "file" : "text"}

            />}
          <Stack direction="row" spacing={3}>
            {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item, i) => { return <MenuItem key={i} value={item.title}>{item.title}</MenuItem> })}
                        </RHFTextField> */}

            <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>

          {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
              setMsg('Required')
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
    </Stack>
  </>
  );
}

