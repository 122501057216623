import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, TextField, MenuItem, Select, InputLabel, Button, IconButton, Box, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Page from '../../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { MuiChipsInput } from 'mui-chips-input'
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
const AddEbook = () => {

    // const [users, usersSet] = useState([]);
    const animatedComponents = makeAnimated();
    const [selectedCategory, setSelectedCategory] = useState([]);
    const handleChangeCategory = (data) => {
       setSelectedCategory(data);
     }
    
    const [loading, setLoading] = useState(true);

    const[isPaid , setIsPaid]= useState(false);
    const navigate = useNavigate()
    const [selected, setSelected] = useState([]);
    const [uploadSize, setUploadSize] = useState({
        completed: 0,
        totalSize: 0
    })
    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = React.useState(0);
    const [submitted, setSubmitted] = React.useState(false);
    const theme = useTheme();
    const LoginSchema = Yup.object().shape({
        title: Yup.string().required('title is required'),
        isActive: Yup.string().required('Status is required'),
        metaTitle: Yup.string().required('metaTitle is required'),
        language: Yup.string().required('language is required'),
        isPaid: Yup.boolean().required('Ebook Type  is required'),
        regularPrice: Yup.number().when('isPaid', {
            is: true,
            then: Yup.number().positive("Regular Price always postive").moreThan(0 , 'Regular Price is required'),
            otherwise: Yup.number(),
          }),
        salePrice :Yup.number().when('isPaid', {
            is: true,
            then: Yup.number().positive("Sale Price always postive").moreThan(0 , 'Sale Price is required'),
            otherwise: Yup.number(),
          })
        // salePrice: Yup.number().positive().required('Required Sale Price').test('is-less-than-or-equal-to-regularPrice', 'Sale Price must be less than or equal to Regular Price', function (value) {
        //            const { regularPrice } = this.parent;
        //            console.log(regularPrice , value)
        //            return value <= regularPrice;
        //    })
    });

    const defaultValues = {
        title : '',
        description: '',
        keyFeatures: [],
        tags: [],
        metaDesc:'',
        metaTitle:'',
        language: '',
        regularPrice : 0  ,
        salePrice : 0 ,
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    
    const [msg, setMsg] = useState('')
    const [chips , setChips] = useState([]);
    const handleChangeChips = (newChips) => {
        setChips(newChips)
      }
      const [keyFeatures , setKeyFeatures] = useState([]);
    const handleChangeKeyFeatures = (newChips) => {
        setKeyFeatures(newChips)
      }

    const [banner, setBanner] = useState()
    const[pdf , setPdf] = useState(false);
    
    const changeHandler = async (event) => {
        let size = event.target.files[0].size / (1024);
        // console.log(size);
        if( size <= 100){
            setBanner(event.target.files[0])
        }else {
            setVal(true)
            setBanner();
        }
    };
    


    //Add Details'

    const onSubmit = async (data) => {
            setShowProgress(true)
            setLoading(true)

            const formData = new FormData();
            if( banner){
              formData.append('banner', banner);
            }
            formData.append('preview' , preview);
            formData.append('title', data.title);
            for (let i = 0; i < samplePdfs.length; i++) {
                formData.append('demoBooks', samplePdfs[i]);
            }
            if (selectedCategory.length === 1) {
                formData.append('category', selectedCategory[0]?.id);
                formData.append('category', "");
              } else {
                selectedCategory.map((item) => formData.append('category', item?._id))
            }
            chips.map((item) => {
                formData.append("tags", item);
            })

            keyFeatures.map((item) => {
                formData.append("keyFeatures", item);
            })
            if( isPaid == true){
                formData.append('salePrice', data.salePrice);
                formData.append('regularPrice', data.regularPrice); 
            }else{
                formData.append('salePrice', 0);
                formData.append('regularPrice', 0); 
            }
            // formData.append('salePrice', data.salePrice);
            // formData.append('regularPrice', data.regularPrice); 
            formData.append('description', data.description);
            formData.append('metaDesc', data.metaDesc);
            formData.append('metaTitle', data.metaTitle);
            formData.append('language', data.language);
            formData.append('isActive', data.isActive);
            formData.append('isPaid', isPaid);

            let token = localStorage.getItem("token");
            for( let Pair of formData){
                console.log( Pair[0] ,"->", Pair[1])
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                // onUploadProgress: (progressEvent) => {
                //     const { loaded, total } = progressEvent;
                //     let percent = Math.floor((loaded * 100) / total)
                //     setUploadSize(prev => ({
                //         completed: Math.floor((loaded / 1000000)),
                //         totalSize: Math.floor((total / 1000000))
                //     }))
                 
                //     if (percent < 90) {
                //         setProgress(percent)
                //     }

                // },
                // maxBodyLength: Infinity,
                // maxContentLength: Infinity,
            };

            axios.post(`${process.env.REACT_APP_LIVE_URL}/ebook/postEbook`, formData, config).then((response) => {
                if (response.data.status) {
                    setProgress(100)
                    toast.success("Ebook Created")
                    setSubmitted(true)
                    setTimeout(() => {
                        setLoading(false)
                        navigate('/dashboard/ebooks')

                    }, 1000)
                    reset()
                }else{
                    setProgress(100);
                    setLoading(false);
                    setSubmitted(false);
                    setShowProgress(false);
                    toast.error(response.data.msg);
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        

    }
    const[category , setCategory] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchCategory() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProductCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setCategory(responseJson.data);
            setLoading(false)

        }

        fetchCategory();
    }, []);

    const [focus, setFocused] = React.useState(false);
    const [focusFive, setFocusedFive] = React.useState(false);
  const [planner, setPlanner] = React.useState(false);
  const[preview , setPreview ] = useState();
  const changeHandler1 = async (event) => {
    setPreview(event.target.files[0]);
  };

  const[samplePdfs , setSamplePdfs ] = useState();
  const changeHandlerForPdfs = async (event) => {
    setSamplePdfs(event.target.files);
  };
    const [val, setVal] = useState(false)
    return (<>
        <Page title='Batch'>
            <Stack spacing={3} ml={5}>
                <Typography className='page-heading'>Add Ebook </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '80%' }}> 
                        <Stack direction='row' spacing={2}>
                           <RHFTextField name="title" label="Ebook Title" />
                           <RHFTextField name="banner"
                               onChange={changeHandler}
                               id="banner"
                               label="Banner"
                               error={!banner && val}
                               helperText='Image Only ( Maximum allowed size 100KB)'
                               onFocus={() => setFocused(true)}
                               onBlur={() => setFocused(false)}
                               InputProps={{
                                 classes: {
                                   input: "CustomTextField"
                                 }
                               }}
                               onClick={() => setPdf(true)}
                               inputProps={{ accept: "image/*" }}
                               type={pdf || focus ? "file" : "text"}
                             />
                        </Stack>
                        <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                               <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}> Categories</InputLabel>
                               <Stack  sx={{ width : '80%'}}>
                                  <ReactSelect
                                     closeMenuOnSelect={false}
                                     components={animatedComponents}
                                     value={selectedCategory}
                                     onChange={handleChangeCategory}
                                     menuPortalTarget={document.body} 
                                     placeholder='Select Categories For Ebook'
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isMulti
                                    options={category}
                                  />
                              </Stack>
                        </Stack>
                        <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                               <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}> Sample Pdfs</InputLabel>
                               <Stack  sx={{ width : '80%'}}>
                                   <RHFTextField name="files"
                                      onChange={changeHandlerForPdfs}
                                      id="file"
                                      label="File"
                                      error={!samplePdfs && val}
                                      helperText='Select Sample Pdfs'
                                      onFocus={() => setFocused(true)}
                                      onBlur={() => setFocused(false)}
                                      inputProps={{ multiple: true, accept: "application/pdf" }}
                                      InputProps={{
                                          classes: {
                                              input: "CustomTextField"
                                          }
                                      }}
                                      onClick={() => setPdf(true)}
                                      type={pdf || focus ? "file" : "text"}
                                  />
                              </Stack>
                        </Stack>
                        <Stack direction="row" >
                            <RHFTextField
                                name='isPaid'
                                label="Ebook Type"
                                // sx={{ width: isPaid ? '50%' : '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true} onClick={() => setIsPaid(true)}>Paid</MenuItem>
                                <MenuItem value={false} onClick={() => setIsPaid(false)}>Free</MenuItem>

                            </RHFTextField>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                              {/* <Stack direction='row' sx={{width : '50%'}}>
                                 <InputLabel sx={{width : '50%', marginRight:"5px", alignSelf: 'center'}}>Regular Price : </InputLabel> 
                                 <RHFTextField name="regularPrice"  type="text" id="custom-css-outlined-input" />
                               </Stack>
                               <Stack direction='row' sx={{width : '50%'}}>
                                 <InputLabel sx={{width : '50%', marginRight:"5px", alignSelf: 'center'}}>Sale Price : </InputLabel> 
                                 <RHFTextField name="salePrice" type="text" />
                               </Stack> */}
                               { isPaid ?
                                 <>
                                 <Stack direction='row' sx={{width : '50%'}}>
                                 <InputLabel sx={{width : '50%', marginRight:"5px", alignSelf: 'center'}}>Regular Price : </InputLabel> 
                                 <RHFTextField name="regularPrice"  type="text" id="custom-css-outlined-input" />
                                 
                               </Stack>
                               <Stack direction='row' sx={{width : '50%'}}>
                                 <InputLabel sx={{width : '50%', marginRight:"5px", alignSelf: 'center'}}>Sale Price : </InputLabel> 
                                 <RHFTextField name="salePrice" type="text" />
                                 
                               </Stack>
                                   
                                   
                                 </> 
                               : 
                               <>
                               <Stack direction='row' sx={{width : '50%'}}>
                                 <InputLabel sx={{width : '50%', marginRight:"5px", alignSelf: 'center'}}>Regular Price : </InputLabel> 
                                 <RHFTextField name="charges" value={0}  disabled />
                                 
                               </Stack>
                               <Stack direction='row' sx={{width : '50%'}}>
                                 <InputLabel sx={{width : '50%', marginRight:"5px", alignSelf: 'center'}}>Discount Price : </InputLabel> 
                                 <RHFTextField name="discount" value={0}  disabled/>
                               </Stack>
                               
                             </> 
                               }
                        </Stack>
                        <Stack direction="row" justifyContent="space-between"  spacing={2}>  
                             <RHFTextField
                                name='language'
                                label="Language"
                                sx={{ width: '100%' }}
                                select
                              >
                                <MenuItem value='en'>English</MenuItem>
                                <MenuItem value="hi">Hindi</MenuItem>
                                <MenuItem value='enhi'>Bilungual</MenuItem>
                             </RHFTextField>
                            <RHFTextField
                                 id="preview"
                                 name="preview"
                                 label="Preview ( in Pdf)"
                                 onChange={changeHandler1}
                                 onFocus={() => setFocusedFive(true)}
                                 onBlur={() => setFocusedFive(false)}
                                 inputProps={{ accept: "application/pdf" }}
                                 InputProps={{
                                   classes: {
                                     input: "CustomTextField",
                                   },
                                 }}
                                 onClick={() => setPlanner(true)}
                                 type={planner || focusFive ? "file" : "text"}
                               />
                        </Stack>
                       
                        <Stack direction="row"  sx={{ width : '100%'}} spacing={2}>
                            <Stack sx={{ width : '50%'}}>
                                <MuiChipsInput style={{ width : '100%'}}  value={chips} onChange={handleChangeChips} placeholder='Enter Tags' /> 
                            </Stack>
                            <RHFTextField
                                name='isActive'
                                label="Status"
                                sx={{ width: '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </RHFTextField>
                            
                        </Stack>
                        {/* <RHFTextEditor name="keyFeatures" label="Key Features" /> */}
                        <Stack sx={{ width : '100%'}}>
                                <MuiChipsInput style={{ width : '100%'}}  value={keyFeatures} onChange={handleChangeKeyFeatures} placeholder='Enter Key Features' /> 
                            </Stack>
                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Meta Title</InputLabel>
                            <RHFTextField name="metaTitle" label="Meta Title"/>
                        </Stack>
                        <RHFTextEditor name="metaDesc" label="Meta Description" />
                        <RHFTextEditor name="description" label="Description" />
                    </Stack>

                    <Button fullWidth size="large" type="submit" variant="contained"
                        sx={{ bgcolor: theme.palette.primary.mainTheme, width: '80%', marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme, } }}
                        onClick={() => {
                            if (selected.length === 0) setMsg('Required')
                            setVal(true)
                        }}
                    >
                        Submit
                        {/* {showProgress ? <Typography mr={2} sx={{ textAlign: 'center' }}>{submitted ? 'Submitted' : progress < 91 ? `Uploading File..... ${progress}%` : 'Submitting Form'}</Typography> : 'Add'}
                        {showProgress ? submitted ? '' : (<CircularProgress color="secondary" />) : ''} */}
                    </Button>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </Stack>
        </Page >
    </>
    )
}

export default AddEbook