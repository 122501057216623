import React, { useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Divider } from '@mui/material';
// import './ProductProfilePage.css'
import { useParams } from 'react-router-dom';


const CommunityProfile = () => {
    
    const [details, setDetails] = React.useState()

    const { postId } = useParams();
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/community/getCMSPostById/${postId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            // console.log('line91', responseJson)
            setDetails(responseJson?.data);
        }
        fetchUsers()
    }, [])
    // consol.e.log(details)
    return (
        <div className='product_parent'>

            <h2 style={{ marginBottom: '20px' }}>Community Details of - {details?.title}</h2>
            <Card sx={{}}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="300"
                        image={details ? details?.featuredImage : ''}
                        alt="green iguana"
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Title:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.title}
                            </Typography>
                        </div>
                        
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            {`Description:    `}
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <div dangerouslySetInnerHTML={{ __html: details?.desc }}></div>
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Author:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.author?.name}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Language:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.language == 'hi' ? 'Hindi' : details?.language == 'en' ? "English" : 'English/Hindi'}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Tag:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.tags}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Status:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.isActive == true ? 'Active' : "InActive"}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Total Comments:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.totalComments}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Total Likes:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.totalLikes}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Total Views:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.totalViews}
                            </Typography>
                        </div>
                        <Divider />

                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Created At:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.createdAt}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Updated At:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.updatedAt}
                            </Typography>
                        </div>
                        <Divider />
                       
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

export default CommunityProfile; 