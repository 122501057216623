import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// ----------------------------------------------------------------------

export default function AddStoreBanner() {
  const theme = useTheme()
  // const animatedComponents = makeAnimated();
  const animatedComponents = makeAnimated();
  const [selectedValues , setSelectedValues] = useState([])
  const handleChangeValues = (data) => {
    setSelectedValues(data);
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [restrict, setRestrict] = useState('')
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [type, setType] = useState('none');
  const [valueId, setValueId] = useState('');
  const [list, setList] = useState([]);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    bannerType: Yup.string().required('Banner Type required'),
    link: Yup.string()
      .required('Link Banner Required')
      .oneOf(['none', 'product', 'category','link'], 'Invalid Link Value'),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),
    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title: '',
    bannerType: '',
    link: '',
    linkWith: '',
    isActive: '',
  };
  const [file, setFile] = useState()

  const changeHandler = async (event) => {
    let size = event.target.files[0].size / ( 1024);
        if( size > 150){
            setVal(true);
        }else{
            setFile(event.target.files)
            var fileName = document.getElementById('file').value.toLowerCase();
              if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
             else { setRestrict('JPG Or PDF') }
        }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  const handleChangeValue = (data) =>{
    setValueId(data);
  }

  const onSubmit = async (data) => {
   
    if (file) {
      const formData = new FormData();

      setLoading(true)
      
      Object.values(file).forEach(file => {
        formData.append("file", file);
      })
      formData.append('title', data.title);
      
      formData.append('bannerType', data.bannerType);
      formData.append('language', 'en');
      formData.append('isActive', data.isActive);
      formData.append("link", data.link);
      if (data.link !== '' && data.link !== 'none') {
        // formData.append('linkWith', data.valueId);
        formData.append('linkWith' , selectedValues?.value)
      }
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: data => {
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
      };
      setProgress(0);
      axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreBanner`, formData, config).then((response) => {

        if (response.data.msg === 'New Store Banner added successfully.') {
          toast.success(response.data.msg);
          setLoading(false);
          setPdf(false);
          reset();
          window.location.href = "/dashboard/storeBanner";
        }else {
          toast.error(response.data.msg);
          setLoading(false);
        }

      })
        .catch(e => {
          //console.log(e);
        })
    }
  };
  useEffect(() => {
    if (type !== '' && type !== 'none' && type !="link") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/store/${type == "product" ? "getAllStoreProduct" : "getAllStoreProductCategory"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson.data);

      }
      fetchDetails();
    }
    setLoading(false);

  }, [type]);
  
  
  return (<>
    <Stack spacing={3} px={5} >
      <Typography variant='h3'>Add Store Banner</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>


          <RHFTextField name="title" label="Title" />
          <Stack direction="row" spacing={3}>
            <RHFTextField name="file"
              onChange={changeHandler}
              id="file"
              label="File"
              error={!file && val}
              helperText='Image Only (Maximum 100KB allowed)'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}

              onClick={() => setPdf(true)}
              inputProps={{ accept: "image/*" }}
              type={pdf || focus ? "file" : "text"}

            />
            {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
            <RHFTextField name="bannerType" label="Banner Type" select>
              <MenuItem value='APP'>App</MenuItem>
              <MenuItem value="TAB">Tab</MenuItem>
              <MenuItem value="WEB">Web</MenuItem>
            </RHFTextField>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Stack sx={{width : '50%'}}>
                <RHFTextField name="link" label="Link Banner" type='checkbox' select >
                   <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                   <MenuItem value='product' onClick={() => setType('product')}>Product</MenuItem>
                   <MenuItem value='category' onClick={() => setType('category')}>Category</MenuItem>
                   <MenuItem value='link' onClick={() => setType('link')}>Link</MenuItem>
                </RHFTextField>
            </Stack>
            
            { type == 'link' && <RHFTextField name="valueId" label="External Link" type="url" />}
            {(type !== 'none' && type !== '' && type !== 'link') &&
            //  <RHFTextField name="valueId" label={type == "product" ? "Link With Product" : "Link With Category"} select>
            //   {list?.map((item, index) => (

            //     <MenuItem
            //       key={index}
            //       value={item?.id}
            //       onClick={() => setValueId(item?.id)}
            //     >
            //       { item?.title}
                  
            //     </MenuItem>
            //   )

            //   )}
            // </RHFTextField>
              <Stack direction='column' sx={{ width : '50%'}}>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={selectedValues}
                  onChange={handleChangeValues}
                  menuPortalTarget={document.body} 
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isMulti={false}
                                        //  defaultValue={[colourOptions[4], colourOptions[5]]}
                  // isMulti
                  options={list}
                />
              </Stack>
            //  <Stack sx={{ width : "50%"}}>
            //                     <Select
            //                      closeMenuOnSelect={false}
            //                      components={animatedComponents}
            //                      value={valueId}
            //                      onChange={handleChangeValue}
            //                      isMulti={false}
            //                      options={list}
            //                    />
            //   </Stack>
                               
            
            }
          </Stack>


          <Stack direction="row" spacing={3}>
            <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>

          {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
              setMsg('Required')
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
    </Stack>
  </>
  );
}
