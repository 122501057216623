import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------


const flattenObject = (obj) => {
  return `${obj.name} ${obj.email} ${obj.phone}`;
};
const flattenArray = (arr) => {
  return arr.map((item) => `Title - ${item.title.replaceAll(",", "")} Quantity - ${item.quantity}  Code - ${item?.code}`).join('; ');
};
const removeComma = (string) => {
  return string.replaceAll(',', '');
};
function CustomToolbar({ rows, columns, ...gridProps }) {
  const { setQuickFilter, ...otherProps } = gridProps;
  // debugger;
  const apiRef = useGridApiContext();
  // console.log(gridProps)
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    
    const columnIds = columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'user') {
            return flattenObject(row.user)
          }
          return row[columnId]?.toString()?.replaceAll("," , " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;

    // console.log('Exporting all rows:', fullCsvContent);
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'StorePurchaseReport.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function StorePuchaseReport() {
  const theme = useTheme();
  
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  



  
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const [loading , setLoading] = useState(true)
  React.useEffect(()=>{
    setLoading(true);
    const token = localStorage.getItem('token');
    async function fetchOrder(){
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getAllTxn` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`

      }
      });

      const responseJson = await response.json();
       setRows(responseJson.data ?? []);
       setLoading(false);
    }
    fetchOrder();
    

  },[])
 

  const columns = [
    
    {
      field: "orderId",
      headerName: "Order Id",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "couponDetails",
      headerName: "Coupon Details",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div>
          <strong>Code :</strong> {params.value?.code}<br />
          <strong>Type:</strong> {params.value?.type}<br />
          <strong>Value:</strong> {params.value?.value}
        </div>
      ),
      getApplyQuickFilterFn : (value) => {
        return ( params ) => {
          return ( params?.value?.code?.includes(value) ||  params?.value?.type?.includes(value) ||  params?.value?.value?.includes(value))

        }
        // return ( params.value.name.includes(value) )
      }
    },
    {
      field: "orderType",
      headerName: "Order Type",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div >
          <span>{params?.value == 'COD' ? "Cash On delivery" : "Pre Paid"}</span>
        </div>
      ),
    },
    {
      field: "deliveryCharges",
      headerName: "Delivery Charges",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div >
          <span>{params?.value}</span>
        </div>
      ),
    },
  
    
      {
        field: "user",
        headerName: "User Details",
        type: "string",
        width: 250,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderCell: (params) => (
            <div>
              <strong>Name:</strong> {params.value.name}<br />
              <strong>Email:</strong> {params.value.email}<br />
              <strong>Phone:</strong> {params.value.phone}
            </div>
          ),
      },
      {
        field: "txnId",
        headerName: "Transaction Id",
        type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: true,
        
      },
      {
        field: "txnAmount",
        headerName: "Transaction Amount",
        type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        
      },
      
      {
        field: "isPaid",
        headerName: "Paid",
        // type: "object",
        width: 100,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
          <div style={{ color: params.value == true ? 'green' : 'red' }}>
            {params.value ? 'Paid' : 'Not Paid'}
          </div>
        ),
      },
      {
        field: "txnDate",
        headerName: "Transaction Date",
        // type: "object",
        width: 250,
        align: "left",
        headerAlign: "left",
      },
     
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <h2 style={{ margin : '5px'}}>Store Purchase Report</h2>
      <DataGrid
        rows={rows}
        columns={columns}
        // editMode="row"
        // rowHeight={155} 
        autoHeight={true}
        getRowHeight={() => 'auto'} 
        getEstimatedRowHeight={() => 200} 
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        loading={loading}
        pageSizeOptions={[7, 50,100]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
     
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
