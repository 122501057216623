import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";



function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'admin') {
            return `${row.admin.name}(${row.admin.role})`;
          }
          else if (columnId === 'linkWith') {
            return row.linkWith.title
          }
          else if ( columnId === 'serialNumber'){
            return index+1
          }
          return row[columnId]?.toString()?.replaceAll(",","");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'StoreBanner.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function DeepLinkTable() {


  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const[loading , setLoading] = React.useState()
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  React.useEffect(() => {
    const token = localStorage.getItem('token');
    setLoading(true);
    async function fetchOrder() {
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getDynamicLinks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`

        }
      });

      const responseJson = await response.json();
      setRows(responseJson?.data)
      setLoading(false);

    }
    fetchOrder()


  },[])
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));


  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "text",
      headerName: "Text",
      type: "string",
      width: 350,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "link",
      headerName: "Url",
      type: "string",
      width: 100,
      align: 'left',
      headerAlign: "left",
      editable: false,
      renderCell: (params) => (
        <div style={{ cursor : 'pointer'}}onClick={() =>{ navigator.clipboard.writeText(params?.value); toast.success('link Copied')} }>
          <ContentCopyIcon style={{ height: '60%' ,  color: 'blue' }} />
        </div>
      ),
    },
   
    
  ]
  return (
    <Box
      sx={{
        // height: 700,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <h2 style={{ margin : '10px'}}>All Dynamic Links </h2>
      <DataGrid 
        // rowHeight={50} 
        rows={rowsWithSerialNumber}
        columns={columns}
        loading={loading}
        autoHeight={true}
        getRowHeight={() => 'auto'}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
