import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import Page from "../../../components/Page";
import TestSeriesCard from "./components/TestSeriesCard";
import SyncLoader from "react-spinners/SyncLoader";

import './test.css';
import { useTheme } from "@emotion/react";
import getAccess from "../../../utils/getAccess";

const Test = () => {

  const access = getAccess();
  // console.log(access?.access)


  const theme = useTheme();

  const sampleArray = [
    {
      title: "UPSC",
      students: 1245,
      icon: "carbon:rule-test",
    },
    {
      title: "UPSC",
      students: 1245,
      icon: "carbon:rule-test",
    },
    {
      title: "UPSC",
      students: 1245,
      icon: "carbon:rule-test",
    },
  ];

  const navigate = useNavigate();
  let j = 0;
  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  }));

  //Test Series Details
  const [loading, setLoading] = React.useState(true);
  const [users, usersSet] = React.useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getallTestSeriesdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);
  // //console.log(users)
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Test Series">
          <Typography className="page-heading">
            All Test Series
          </Typography>

          <Grid container pl={5} pr={3} spacing={3}>
            {((access?.access.includes('32') || access?.accessFortab == 'readWrite') || access?.access.includes('all')) && <Grid item xs={12} sm={6} md={4}>
              <TestSeriesCard title="notInfo" />
            </Grid>}
            {users?.map((item, i) => {
              return (
                <Grid item key={i} xs={12} sm={6} md={4}>
                  <TestSeriesCard title="Info" data={item} />
                </Grid>
              );
            })}
          </Grid>
        </Page>
      )}
    </>
  );
};

export default Test;
