import React from 'react'

import * as Yup from "yup";
import { useState, useEffect } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom';

import './quiz.css';
import { useTheme } from '@emotion/react';
import MyCkEditor from '../../../components/hook-form/MyCkEditor';

const EditQuestion = (props) => {



const theme=useTheme()

  const { quizId, selectedIdOfQuestion, item } = useParams();

  // //console.log(quizId);
  // //console.log(selectedIdOfQuestion);
  // console.log(item);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  console.log(params.get('item'));
  // const decodedData = JSON.parse(decodeURIComponent(params.get('item')));
  const decodedData = JSON.parse(params.get('item'))
  console.log(decodedData);
  // debugger;

  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  //Getting Quiz Name
  const { quizTitle } = useParams();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    // question_e: Yup.string().required("Title required"),
    // question_h: Yup.string().required("Title required"),
    // option1_e: Yup.string().required("option1 is required"),
    // option2_e: Yup.string().required("option2 is required"),
    // option3_e: Yup.string().required("option3 is required"),
    // option4_e: Yup.string().required("option4 is required"),
    // option1_h: Yup.string().required("option2 is required"),
    // option2_h: Yup.string().required("option2 is required"),
    // option3_h: Yup.string().required("option2 is required"),
    // option4_h: Yup.string().required("option2 is required"),

    // difficulty_level: Yup.string().required("difficulty_level is required"),
    // correctAns: Yup.string().required("correctOption is required"),
    // answer_e: Yup.string().required("answer is required"),
    // answer_h: Yup.string().required("answer is required"),

    // is_active: Yup.boolean().required('Status Required'),
  });



  const [formData, setFormData] = useState({
    question_e: decodedData.question_title[0].e,
    question_h: decodedData.question_title[0].h,
    option1_e: decodedData.option1[0].e,
    option2_e: decodedData.option2[0].e,
    option3_e: decodedData.option3[0].e,
    option4_e: decodedData.option4[0].e,
    option1_h: decodedData.option1[0].h,
    option2_h: decodedData.option2[0].h,
    option3_h: decodedData.option3[0].h,
    option4_h: decodedData.option4[0].h,
    difficulty_level: decodedData.que_level[0].e,
    correctAns: decodedData.correctOption,
    answer_e: decodedData.answer[0].e,
    answer_h: decodedData.answer[0].h,
  });
  //console.log(formData);

  const handleForm = (e) => {
    // //console.log("change");
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const methods = useForm({
    resolver: yupResolver(RegisterSchema),

  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {

    setLoading(true);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      question_title: [
        {
          // e: formData?.question_e,
          // h: formData?.question_h,
          e : dataForDesc1,
          h : dataForDesc2,
        },
      ],
      option1: [
        {
          e: formData?.option1_e,
          h: formData?.option1_h,
        },
      ],
      option2: [
        {
          e: formData?.option2_e,
          h: formData?.option2_h,
        },
      ],
      option3: [
        {
          e: formData?.option3_e,
          h: formData?.option3_h,
        },
      ],
      option4: [
        {
          e: formData?.option4_e,
          h: formData?.option4_h,
        },
      ],
      answer: [
        {
          // e: formData?.answer_e,
          // h: formData?.answer_h,
          e : dataForDesc3,
          h : dataForDesc4,
        },
      ],
      correctOption: formData?.correctAns,
    };

    // setTimeout(async() => {
    const res = await axios.put(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/editQuizQuestion/${selectedIdOfQuestion}`,
      data,
      config
    );
    // }, 2000);



    setTimeout(() => {
      setLoading(false);
      navigate(`/dashboard/quiz/${quizTitle}/${quizId}`);
    }, 600);
  };

  const [questione, setQuestione] = useState(formData.question_e);
  const handlequestione = (content) => {
    setQuestione(content);
    setFormData({
      ...formData,
      'question_e': questione,
    });

    // //console.log(formData);
  };

  const [questionh, setQuestionh] = useState(formData.question_h);
  const handlequestionh = (content) => {
    setQuestionh(content);
    setFormData({
      ...formData,
      'question_h': questionh,
    });

    // //console.log(formData);
  };

  const [answere, setAnswere] = useState(formData.answer_e);
  const handleanswere = (content) => {
    setAnswere(content);
    setFormData({
      ...formData,
      'answer_e': answere,
    });

    // //console.log(formData);
  };

  const [answerh, setAnswerh] = useState(formData.answer_h);
  const [dataForDesc1, setDataForDesc1] = useState(formData.question_e);
  const [dataForDesc2, setDataForDesc2] = useState(formData.question_h);
  const [dataForDesc3, setDataForDesc3] = useState(formData.answer_e);
  const [dataForDesc4, setDataForDesc4] = useState(formData.answer_h);
  const handlanswerh = (content) => {
    setAnswerh(content);
    setFormData({
      ...formData,
      'answer_h': answerh,
    });

    // //console.log(formData);
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['list', 'bullet', 'ordered'],
      [{ 'align': [] }],
      ['blockquote', 'link', 'image', 'formula'],
      ['clean']
    ],
  };


  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Update Question ( {quizTitle} )</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "110%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                English Language
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                हिंदी भाषा
              </Typography>
            </Stack>
            <hr sx={{ background: "lightgray", color: "lightgray" }} />
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
              <div style={{width : '50%'}} className='editor-width'>
                {/* <ReactQuill modules={modules} name="question_e" label="Question Title" value={questione} onChange={handlequestione} /> */}
                <MyCkEditor dataForDesc={dataForDesc1 ? dataForDesc1 : questione} setDataForDesc={setDataForDesc1}/>

              </div>
              {/* <RHFTextField name="question_e" label="Question Title" /> */}
              {/* <RHFTextEditor name="question_e" label="Question Title" value={formData.question_e} onChange={handleForm} /> */}
              {/* <RHFTextField name="question_h" label="हिंदी सवाल शीर्षक" /> */}
              <div style={{width : '50%'}} className='editor-width'>
                {/* <ReactQuill modules={modules} name="question_h" label="हिंदी सवाल शीर्षक" value={questionh} onChange={handlequestionh} /> */}
                <MyCkEditor dataForDesc={dataForDesc2 ? dataForDesc2 : questionh} setDataForDesc={setDataForDesc2}/>
              </div>
              {/* <RHFTextEditor name="question_h" label="हिंदी सवाल शीर्षक" value={formData.question_h} onChange={handleForm}/> */}
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                multiline

                name="option1_e"
                label="Option 1"
                value={formData.option1_e} onChange={handleForm}
              />
              <RHFTextField
                multiline

                name="option2_e"
                label="Option 2"
                value={formData.option2_e} onChange={handleForm}
              />
              <RHFTextField
                multiline

                name="option1_h"
                label="हिंदी विकल्प 1"
                value={formData.option1_h} onChange={handleForm}
              />
              <RHFTextField
                multiline

                name="option2_h"
                label="हिंदी विकल्प 2"
                value={formData.option2_h} onChange={handleForm}
              />
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                multiline

                name="option3_e"
                label="Option 3"
                value={formData.option3_e} onChange={handleForm}
              />
              <RHFTextField
                multiline

                name="option4_e"
                label="Option 4"
                value={formData.option4_e} onChange={handleForm}
              />
              <RHFTextField
                multiline

                name="option3_h"
                label="हिंदी विकल्प 3"
                value={formData.option3_h} onChange={handleForm}
              />
              <RHFTextField
                multiline

                name="option4_h"
                label="हिंदी विकल्प 4"
                value={formData.option4_h} onChange={handleForm}
              />
            </Stack>
            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={3} sx={{ width: "50%" }}>
                <Stack direction="row" spacing={3} py={1}>
                  <RHFTextField name="correctAns" value={formData.correctAns} onChange={handleForm} label="Correct Option" select>
                    <MenuItem value="1">A</MenuItem>
                    <MenuItem value="2">B</MenuItem>
                    <MenuItem value="3">C</MenuItem>
                    <MenuItem value="4">D</MenuItem>
                  </RHFTextField>
                  <RHFTextField
                    name="difficulty_level"
                    label={formData.difficulty_level}
                    select
                    onChange={handleForm}
                  >
                    <MenuItem value="easy_आसान">Easy</MenuItem>
                    <MenuItem value="medium_मध्यम">Medium</MenuItem>
                    <MenuItem value="hard_मुश्किल">Hard</MenuItem>
                  </RHFTextField>
                </Stack>
                {/* <RHFTextEditor name="answer_e" label="Answer" /> */}
                {/* <RHFTextField name="answer_e" label="Answer" /> */}
                {/* <ReactQuill name="answer_e" label="Answer" value={answere} onChange={handleanswere} /> */}
                <Stack>
                  <Typography>Answer</Typography>
                  <MyCkEditor dataForDesc={dataForDesc3 ? dataForDesc3 : answere} setDataForDesc={setDataForDesc3}/>
                </Stack>
                
              </Stack>
              <Stack direction="column" spacing={3} sx={{ width: "50%" }}>
                {/* <RHFTextEditor name="answer_h" label="उत्तर" /> */}
                {/* <RHFTextField name="answer_h" label="उत्तर" multiline rows={4.5} /> */}
                {/* <ReactQuill name="answer_h" label="उत्तर" value={answerh} onChange={handlanswerh} /> */}
                <Typography>उत्तर</Typography>
                <MyCkEditor dataForDesc={dataForDesc4 ? dataForDesc4 : answerh} setDataForDesc={setDataForDesc4}/>
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}

export default EditQuestion