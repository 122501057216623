import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddStoreCategory from './AddStoreCategory';
import StoreCategoryTable from './StoreCategoryTable';
import UpdateCategory from './UpdateCategory';
import getAccess from '../../../utils/getAccess';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StoreCategory = () => {
    const d = getAccess();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem('token');
        async function fetchCategory() {
            const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProductCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`

                }
            });
            const responseJson = await response.json();
            setCategory(responseJson.data);
            setLoading(false);
        }
        fetchCategory();

    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})
    console.log('line139', editData)
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
                    <Tab label="All Store Category" sx={{ width: '100%' }} {...a11yProps(0)} />
                    {/* {(access?.access?.includes('1066') || access?.access.includes('all')) && (<Tab label="Add Store Category" sx={{ width: '100%' }} {...a11yProps(1)} />)}
                     */}
                    { (d?.accessForTab?.includes('readWrite') || d?.access?.includes('all')) && <Tab label="Add Store Category" sx={{ width: '100%' }} {...a11yProps(1)} />}
                    {/* <Tab label="Item Three" sx={{ width: '100%' }} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                
                {!loading && (<StoreCategoryTable data={category} setIsEdit={setIsEdit} setEditData={setEditData} />)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                { (d?.accessForTab?.includes('readWrite') || d?.access?.includes('all')) && <AddStoreCategory />}
                
            </TabPanel>

        </Box >
    );
}

export default StoreCategory;