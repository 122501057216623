import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------

export default function LoginTeacherForm() {
    const theme = useTheme();

    const navigate = useNavigate();
    const [fetchMessage, setFetchMessage] = useState()


    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
        email: '',
        password: '',
        remember: true,
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit
        , formState: { isSubmitting },
    } = methods;

    const [ otp , setOtp] = useState('');
    const [ otpText,setOtpText] = useState(false);
    const onSubmit = async (data) => {
        let host = window.location.hostname;
        try {
            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/TeacherLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify({ ...data, host , serverkey: "SDEMPIRE#$%" })
                body: JSON.stringify({ ...data, host : host , serverkey: "SDEMPIRE#$%" })

            })
            const result = await res.json()

            // alert(result.msg)
            setFetchMessage(result.msg)
            if( result.status == true && result?.msg == 'OTP send successfully'){
                setOtpText(true);
                localStorage.setItem("email", data.email);
            }
            // if (result.msg === 'Teacher Successfully loggedIn') {

            //     localStorage.setItem('role', 'Teacher')
            //     localStorage.setItem("details", result.username);
            //     localStorage.setItem("token", result.data);
            //     localStorage.setItem('id', result.teacher_id)
            //     localStorage.setItem("detailObject", JSON.stringify(result));
            //     localStorage.setItem("isLoggedIn", true);
            //     localStorage.setItem("accessArray", [result.access]);
            //     navigate('/dashboard/app', { replace: true });
            //     window.location.reload()
            // }
        } catch (error) {
            //console.log(error);
        }

    }; 
    const handleOtp = (e) => {
        setOtp(e.target.value);
      }

    const handleVerify = async () => {
        const email = localStorage.getItem("email");
        try {
          const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/verifyTeacher`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ otp, email })
          })
          const result = await res.json();
          setFetchMessage(result.msg == 'Teacher Successfully loggedIn' ? 'Log In Successfully' : result.msg)
           if (result.msg === 'Teacher Successfully loggedIn') {
                localStorage.setItem('role', 'Teacher')
                localStorage.setItem("details", result.username);
                localStorage.setItem("token", result.data);
                localStorage.setItem('id', result.teacher_id)
                localStorage.setItem("detailObject", JSON.stringify(result));
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("accessArray", [result.access]);
                navigate('/dashboard/app', { replace: true });
                window.location.reload()
            }
        } catch (error) {
        }
      };


    return (<>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            { !otpText && <Stack spacing={3}>
                <RHFTextField name="email" label="Email address" />

                <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack> }

            { !otpText && <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
            </Stack>}

            { !otpText && <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}
                sx={{ bgcolor: theme.palette.primary.mainTheme }}>
                Login
            </LoadingButton>}
            {otpText && <Stack spacing={3}>
                            <RHFTextField name='otp' label="Enter Otp" type="number" onChange={(e) => handleOtp(e)} />
                             <LoadingButton fullWidth size="large" variant="contained" onClick={() => handleVerify()}
                               sx={{ bgcolor: theme.palette.primary.mainTheme }}
                             >
                               Submit OTP
                             </LoadingButton>

                        </Stack> }
        </FormProvider>
        <br />
        <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography>
    </>
    );
}
