import {
  Box,
  IconButton,
  Stack,
  Popover,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useTheme } from "@emotion/react";
// import { FormProvider } from 'react-hook-form';
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import CloseIcon from "@mui/icons-material/Close";
import getAccess from "../../../../utils/getAccess";

const BoxDesign = {
  background: "#f4b44b26",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "15px",
  height: "200px",
  // , width: '250px'
};
const BoxInside = {};
const BoxInsideTop = {
  height: "35px",
  background: "#ffffff",
  padding: "5px 13px",
  borderRadius: "12px 12px 0px 0px",
};
const BoxInsideCenter = {
  background: "transparent",
  padding: "5px 13px",
  fontWeight: "500",
};
const BoxInsideCenterInside = {
  background: "rgba(255, 255, 255, 0.4)",
  border: "1px solid #7f11e0bf",
  color: "#7f11e0bf",
  fontWeight: "500",
  fontSize: "13px",
  padding: "8px 10px",
};
const TestSeriesCard = ({ title, data }) => {
  const access = getAccess();
  let createdDate = data?.created_at.split(" ")[0];
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [batchIdForPopover, setBatchIdForPopover] = useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // for apply coin
  const [anchorElForCoin, setAnchorElForCoin] = useState(null);

  const handleClickForCoin = (event) => {
    setAnchorElForCoin(event.currentTarget);
  };

  const handleCloseForCoin = () => {
    setAnchorElForCoin(null);
  };
  const coinDetailsSchema = Yup.object().shape({
    maxCoin: Yup.number()
      .required("Max Coin is Required")
      .typeError("Max Coin must be a number"),
  });
  const defaultValuesForCoin = {
    isCoinApplicable: "",
    maxCoin: 0,
  };

  const openForCoin = Boolean(anchorElForCoin);

  const methods = useForm({
    resolver: yupResolver(coinDetailsSchema),
    defaultValuesForCoin,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const handleSubmitCoinDetails = async (data) => {
    // debugger;
    // console.log(batchIdForPopover);

    const formData = new FormData();

    if (data?.maxCoin) {
      formData.append("isCoinApplicable", "true");
      formData.append("maxAllowedCoins", data?.maxCoin);
    }
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/testSeriesCoins/${batchIdForPopover}`,
        formData,
        config
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          toast.success("Coins applied on this Test Series");
          setTimeout(() => {
            window.location.reload();
          }, 800);
        } else {
          toast.error(response.data.msg);
        }
      });
  };
  const deleteDetails = async (id) => {
    // setLoading(true)
    const token = localStorage.getItem("token");

    await fetch(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/DeleteTestSeries/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // setLoading(false)
    toast.success("Test Series Deleted");

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  //Make Active/InActive
  const makeActive = async () => {
    setLoading(true);
    const formData = new FormData();
    if (data?.is_active) {
      formData.append("is_active", false);
    } else {
      formData.append("is_active", true);
    }
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/UpdateTestSeries/${batchIdForPopover}`,
        formData,
        config
      )
      .then((response) => {
        setLoading(false);

        if (response.data.msg === "updated  the TestSeries SuccessFully") {
          if (response.data.is_active) {
            toast.success("Test Series Activated");
          } else {
            toast.success("Test Series Deactivated");
          }
          setTimeout(() => {
            window.location.reload();
          }, 800);
        } else {
          toast.error(response.data.msg);
        }
      });
  };
  const theme = useTheme();
  return (
    <Box sx={{ ...BoxDesign }}>
      {title == "Info" ? (
        <Stack
          direction="column"
          justifyContent="space-between"
          pb={1}
          sx={{ height: "100%" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...BoxInsideTop }}
          >
            <Typography
              color="#7f11e0bf"
              sx={{ fontSize: "16px", fontWeight: "600" }}
            >
              {data?.testseries_name}
            </Typography>
            {/* <Typography color='#474747' sx={{ fontSize: '12px', fontWeight: '600' }}>Lorem</Typography> */}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ ...BoxInsideCenter }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  ...BoxInsideCenterInside,
                  padding: "8px 14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data?.student?.length}
              </Box>
              <Typography color="#474747" sx={{ fontSize: "14px" }}>
                Students
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" spacing={1}>
              <Box sx={{ borderRadius: "8px", ...BoxInsideCenterInside }}>
                {data?.starting_date}
              </Box>
              <Typography color="#474747" sx={{ fontSize: "14px" }}>
                Test Series Start
              </Typography>
            </Stack>
            {/* <Stack direction="column" alignItems='center' spacing={1} >
                        <Box sx={{ borderRadius: '8px', ...BoxInsideCenterInside }}>

                        </Box>
                        <Typography color='#474747' sx={{ fontSize: '14px' }}>
                            Batch End
                        </Typography>
                    </Stack> */}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...BoxInsideCenter }}
          >
            <Stack direction="column" alignItems="center">
              <Typography color="#7f11e0bf" sx={{ fontSize: "12px" }}>
                {createdDate}
              </Typography>
              <Typography color="#474747" sx={{ fontSize: "14px" }}>
                Date Created
              </Typography>
            </Stack>
            <Typography sx={{ padding: "2px 5px", borderRadius: "8px" }}>
              Status:{" "}
              <span style={{ color: data?.is_active ? "green" : "red" }}>
                {data?.is_active ? "Active" : "In Active"}
              </span>
            </Typography>
            <Box
              onClick={() => {
                setBatchIdForPopover(data?._id);
              }}
            >
              <KeyboardArrowDownIcon
                sx={{
                  color: "#7f11e0bf",
                  background: "rgba(255, 252, 252, 0.72)",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleClick}
                aria-describedby={id}
              />
            </Box>

            {/* POPOVER */}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography
                pl={4}
                pr={4}
                pt={1}
                pb={0.5}
                sx={{
                  color: "gray",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#d3d3d36b",
                  },
                }}
                onClick={() =>
                  navigate(
                    `/dashboard/test/testSeriesDetails/${batchIdForPopover}`
                  )
                }
              >
                View
              </Typography>

              {/* <Typography pl={4} pr={4} pb={1} pt={0.5} sx={{
                            color: 'gray',
                            cursor: 'pointer', '&:hover': {
                                background: '#d3d3d36b'
                            }
                        }}
                            onClick={() => navigate(`/dashboard/batches/updateBatchDetails/`)}>Edit</Typography>*/}
              {(access?.accessForTab == "readWrite" ||
                access?.access.includes("all")) && (
                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboard/test/updateTestSeries/${batchIdForPopover}`
                    )
                  }
                >
                  Edit
                </Typography>
              )}
              {((data?.student?.length == 0 && access?.deleteAccess) ||
              access?.access.includes("all")) &&  (
                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() => deleteDetails(batchIdForPopover)}
                >
                  Delete
                </Typography>
              ) 
             
              }
              { (access?.accessForTab == "readWrite" ||
                access?.access.includes("all")) && (
                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    // color: data?.is_active ? 'red' : 'green',
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() => makeActive()}
                >
                  {data?.is_active ? "Make InActive" : "Make Active"}
                </Typography>) } 

              {/* apply coin */}
              {(access?.accessForTab == "readWrite" ||
                access?.access.includes("all")) && (
                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={handleClickForCoin}
                >
                  Apply Coins
                </Typography>
              )}
              <Popover
                id={id}
                open={openForCoin}
                anchorEl={anchorElForCoin}
                onClose={handleCloseForCoin}
                PaperProps={{
                  style: {
                    background: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                <Stack
                  spacing={3}
                  p={4}
                  m={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 1,
                    }}
                    onClick={handleCloseForCoin} // Add your close logic here
                  >
                    <CloseIcon />
                  </IconButton>

                  <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(handleSubmitCoinDetails)}
                  >
                    <Stack
                      spacing={3}
                      sx={{
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <RHFTextField
                        name="maxCoin"
                        label="Max Coin Applicable"
                      />
                      <Button type="submit" variant="contained" color="primary">
                        Apply Coin
                      </Button>
                    </Stack>
                  </FormProvider>
                </Stack>
              </Popover>
            </Popover>
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: "100%", minHeight: "100%" }}
        >
          <Box
            onClick={() => navigate("/dashboard/addTest")}
            sx={{ cursor: "pointer" }}
          >
            {" "}
            <svg
              width="58"
              height="58"
              viewBox="0 0 78 78"
              fill="#7f11e0bf"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39 0C17.4603 0 0 17.4603 0 39C0 60.5397 17.4603 78 39 78C60.5397 78 78 60.5397 78 39C78 17.4603 60.5397 0 39 0ZM58.5 42.9H42.9V58.5H35.1V42.9H19.5V35.1H35.1V19.5H42.9V35.1H58.5V42.9Z"
                fill="url(#paint0_linear_1_9)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_9"
                  x1="78"
                  y1="69"
                  x2="0"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor={theme.palette.primary.mainTheme} />
                  <stop
                    offset="1"
                    stopColor={theme.palette.primary.mainThemeLight}
                  />
                </linearGradient>
              </defs>
            </svg>
          </Box>
          { (access?.access?.includes('all') ||  access?.access?.includes('32')) && <Typography
            color="#7f11e0bf"
            sx={{ fontSize: "22px", fontWeight: "600" }}
          >
            Add Test Series
          </Typography>}
        </Stack>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export default TestSeriesCard;
