import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  GridRowEditStopReasons,
  gridExpandedSortedRowIdsSelector
} from "@mui/x-data-grid";

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@emotion/react';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import getAccess from "../../../utils/getAccess";
import * as XLSX from 'xlsx'

function CustomToolbar({ rows, columns , exportData }) {

  const apiRef = useGridApiContext();

  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
     
    // console.log(exportData);
    const dataForExcel =  exportData?.map((item) => {
      return {
        // id: item._id ?? "",
        title: item.title ?? "",
        topicNumber : item.topicNumber ?? "" , 
        pdfName: item.fileDetails?.name  ?? "",
        pdfSize : item?.fileDetails?.size ?? "" ,
        pdfUrl : item?.fileDetails?.url ?? "" , 
        admin:`name -${item?.admin.name}, role - ${item.admin.role}`,
        isActive: item.isActive ,
        createdAt: item?.createdAt
      }
    })
    // console.log(dataForExcel);
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'AllTopics.xlsx');
   
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function TopicTable() {
  const theme = useTheme();
  const [rows, setRows] = React.useState([]);
  const { chapterId , title } = useParams();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    
    async function fetchEbooks() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/ebook/getTopicsForAdmin/${chapterId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson  = await fullResponse.json();
      console.log(responseJson.data);
      setRows( responseJson.data);
    }
    
    fetchEbooks();
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedRows, setSelectedRows] = useState([]);


  const navigate = useNavigate()

  
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };





  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  const handleSelectionModelChange = (selection) => {
    // console.log("console" + selection);
    setSelectedRows(selection);
  }


  const access = getAccess();
  



  // for bulk import product
 

  const [progress, setProgress] = useState();

  const columns = [
    {
      field: 'sno',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div>{`${params.value.name} (${params.value.role})`}</div>
        )
      }
    },
    {
      field: "fileDetails",
      headerName: "Topic Url",
      type: "string",
      width: 100,
      align: 'left',
      headerAlign: "left",
      editable: false,
      renderCell: (params) => (
        <div style={{ cursor : 'pointer'}}onClick={() =>{ navigator.clipboard.writeText(params?.value?.url); toast.success('link Copied')} }>
          <ContentCopyIcon style={{ height: '60%' ,  color: 'blue' }} />
        </div>
      ),
    },
      {
        field: 'topicNumber',
        headerName: 'Topic Number',
        width: 140,
        align: 'left',
        headerAlign: 'left',
        
      },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
        field: "isActive",
        headerName: "Status",
        type: "boolean",
        width: 100,
        align: "left",
        editable : true , 
        headerAlign: "left",
        renderCell: (params) => (
          <div style={{ color: params.value == true ? 'green' : 'red' }}>
            {params.value ? 'Active' : 'inActive'}
          </div>
        ),
  
      },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find(item => item.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
        //   <Tooltip title="View Product">
        //   <GridActionsCellItem
        //     icon={<VisibilityOutlinedIcon />}
        //     label="View"
        //     // onClick={handleDeleteClick(id)}
        //     onClick={() => navigate(`/dashboard/topics/${id}/${rowData.title}`)}
        //     color="inherit"
        //   />
        //   </Tooltip>,
          
          // ,
        //   ((access?.deleteAccess || access?.access.includes('all')) ? <Tooltip title="Trash Product">
        //     <GridActionsCellItem
        //   icon={<DeleteForeverIcon style={{ color : 'red'}}/>}
        //   label="Trash"
        //   onClick={handleTrashClick(id)}
        //   // onClick={ (id) => handleOpen(id)}
        //   color="inherit"
        // />

        //   </Tooltip>
        //    : <></>)
          // ((access?.deleteAccess || access?.access.includes('all')) ? <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   // onClick={ (id) => handleOpen(id)}
          //   color="inherit"
          // /> : <></>)
        ];
      }
    }
  ]


  return (<>
  
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >


        <h2 >{`All Topics of ${title}`}</h2>
        
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        autoHeight={true}
        rowModesModel={rowModesModel}
        // onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        // editMode="row"
        // checkboxSelection
        // onRowSelectionModelChange={handleSelectionModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} exportData={rows} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  </>
  );
}
