import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  Stack,
  Typography,
  TextField,
  Button,
  MenuItem,
} from '@mui/material';

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { randomId } from "@mui/x-data-grid-generator";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@emotion/react';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import getAccess from "../utils/getAccess";


const ReportRenderToolbar = ({ setRows, setLoading }) => {
  const theme = useTheme();
  const [type, setType] = React.useState('free');
  const [valueId, setValueId] = React.useState('all');
  const [list, setList] = React.useState();
  const preDate = dayjs().subtract(1, 'day');
  const [streamCategory, setStreamCategory] = React.useState();
  const [starting, setStarting] = React.useState(preDate);
  const [ending, setEnding] = React.useState(dayjs());

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };
  const handleFilter = async (selectedType, valueIdType) => {
    setLoading(true);
    // let endDate = new Date(ending);
    // endDate.setHours(23, 59, 59 ,0);
    // console.log(endDate);
    let token = localStorage.getItem("token");
    const data = {
      type: selectedType == '' ? type : selectedType,
      category: valueIdType == '' ? valueId : valueIdType,
      startAt: selectedType == '' ? starting.format("YYYY-MM-DD") : starting.format("YYYY-MM-DD"),
      endAt: selectedType == '' ? ending.format("YYYY-MM-DD") : ending.format("YYYY-MM-DD"),
      // startAt : starting.$d,
      // endAt : ending.$d,
    }
    // console.log('data',data)
    try {

      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/usersReport`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...data })
      })
      const result = await res.json()
      // setFilterSearch(result.data)
      setRows(result.data);
      setLoading(false);
      // setLoadingForFilter(false)


    } catch (error) {
      // console.log(error);  


    }
    // navigate('/dashboard', { replace: true });
  };
  React.useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchUsers() {
      const fullResponseCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Stream`, {
        method: 'GET',

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }

      });
      const responseJsonCatergory = await fullResponseCategory.json();
      setStreamCategory(responseJsonCatergory.data);
    }
    fetchUsers();
    handleFilter('all', 'all')
  }, []);

  return (
    <>
      <Card sx={{ width: '100%', position: 'relative', padding: '40px', height: '30px', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '40px' }}>
        <Typography sx={{ fontWeight: 'bold' }}>Filters</Typography>

        <TextField name='type' label="Type" select sx={{ height: '50px', width: '150px' }}>
          {/* <MenuItem value="all" onClick={() => setType('all')}>All</MenuItem> */}
          <MenuItem value="free" onClick={() => setType('free')}>Free</MenuItem>
          <MenuItem value="paid" onClick={() => setType('paid')}>Paid</MenuItem>
          {/* <MenuItem value="enhi">Bilungual</MenuItem> */}
        </TextField>
        <TextField name='category' label="Category" select sx={{ height: '50px', width: '150px' }}>
          <MenuItem value="all" onClick={(e) => setValueId('all')}>All</MenuItem>
          {streamCategory?.map((item) => {
            return <MenuItem value={item?.title} onClick={(e) => setValueId(item?.title)}>{item?.title}</MenuItem>

          })}

        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <DatePicker
              label="From"
              value={starting.$d}
              onChange={handleChange}
              // name="starting_date"
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "100%" }} />
              )}
            />
            <DatePicker
              label="To"
              value={ending.$d}
              onChange={handleChangeTwo}
              sx={{ color: "black" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%", color: "black" }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>

        <Button variant='contained' onClick={() => handleFilter('', '')}>Search</Button>
        {/* {loadingForFilter && < SyncLoader color={theme?.palette?.primary?.mainTheme} loading={loadingForFilter} size={10} />} */}
      </Card>

    </>
  );
};
const removeComma = (array) => {
  return array.reduce((acc , curr) => acc + " " + curr , "")
};
function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'serialNumber') {
            return index+1;
          }
          else if (columnId === 'regCategory') {
            return removeComma(row.regCategory)
          }
          return row[columnId];
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'UserReport.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};


export default function UserReport1() {
  const d = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate();
  
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };


  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "enrollId",
      headerName: "Enroll Id",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "userId",
      headerName: "User Id",
      type: "object",
      width: 300,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "phone",
      headerName: "Phone No",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "regCategory",
      headerName: "Requested Category",
      type: "object",
      width: 350,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "regDateTime",
      headerName: "Register At",
      type: "Date",
      width: 200,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "utmCampaign",
      headerName: "Utm Campaign",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
      field: "utmSource",
      headerName: "Utm Source",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
      field: "utmMedium",
      headerName: "Utm Medium",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
      field: "signinType",
      headerName: "Sign Type",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          ( d?.access?.includes('all')  || d?.access?.includes('1115') ||  d?.accessForTab?.includes('read') ||  d?.accessForTab?.includes('readWrite')) ? 
          <GridActionsCellItem
            icon={<VisibilityOutlinedIcon />}
            label="View"
            onClick={() => navigate(`/dashboard/students/studentsProfile/${id}`)}
            color="inherit"
          /> : <></>,
        ];
      },
    },
  ];



  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box mb={2}><h2>All Users Reports </h2></Box>
      <ReportRenderToolbar setLoading={setLoading} setRows={setRows} />
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={rowsWithSerialNumber}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
        loading={loading}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
