import {
    
  IconButton,
  Tooltip
} from "@mui/material";

import * as React from "react";
// import {  useParams } from "react-router-dom";
import "./style.css";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from '@emotion/react';
import { Stack } from '@mui/material'
import { useNavigate , useParams } from "react-router-dom";
import getAccess from "../../../utils/getAccess";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
GridRowModes,
DataGrid,
GridActionsCellItem,
GridRowEditStopReasons,
GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
  

const BatchDpp = () => {
  const access  = getAccess();
  const theme = useTheme();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    //Admin Side Lecture Details
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getAllDppByBatchId/${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // usersSet(responseJson.data);
      setLoading(false);
      setRows(responseJson.data);

      
      
    }

    fetchUsers();

    
  }, []);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete Resource?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteLectureresourceDetails/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    }
  };

  const makeActiveAndInActiveLectureResource = async (id) => {

    const token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/makeActiveAndInActiveLectureResource/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    const responseJson = await response.json();
    if( responseJson?.status){
      toast.success(responseJson.msg)
      setTimeout(() => window.location.reload()
      , 1000)
    }else{
      toast.error(responseJson.msg);
    }
    

  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
    const navigate = useNavigate();
 

  

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
 

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
        field: "title",
        headerName: "Title",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true
      },
      {
        field: "lecture_title",
        headerName: "Lecture",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true
      },
      {
        field: "file",
        headerName: "Pdf",
        type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
            <IconButton
              href={params.value.fileLoc}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'blue' }}
            >
              <PictureAsPdfIcon fontSize="large" style={{ color: 'blue', marginRight: '8px' }} />
            </IconButton>
          ),
        
      },
      
      {
        field: "language",
        headerName: "Language",
        type: "number",
        width: 150,
        align: "left",
        headerAlign: "left",
        renderCell: (params) => (
            <div>{params.value=== 'hi' ? 'Hindi' : 'English'}</div>
          ),
        
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 400,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const rowData = rows.find((item) => item.id === id);
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            (access?.accessForTab?.includes("readWrite") ||
            access?.access?.includes("all") ) ? (
              <Tooltip title="Edit Resource">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() =>
                  navigate(`/dashboard/updateLectureResources/${id}/${'batch'}`)
                }
                color="inherit"
              />
              </Tooltip>
            ) : (
              <></>
            ),
            (access?.deleteAccess == true || access?.access?.includes("all")) ? (
              <Tooltip title="Delete" ><GridActionsCellItem
                icon={<DeleteIcon style={{ color: "red" }} />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                // onClick={ (id) => handleOpen(id)}
  
                color="inherit"
              />
              </Tooltip>
            ) : (
              <></>
            ),
            
            ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title="Change Status">
            <GridActionsCellItem
              icon={ rowData?.is_active ? <ToggleOffIcon style={{ color:'green'}}  /> : <ToggleOnIcon style={{color : 'red'}}/>}
              label="Status"
              onClick={() => { makeActiveAndInActiveLectureResource(id) }}
              color="inherit"
            />
          </Tooltip> : <></>)
          ];
        },
      },
     
     

   
  ];
  return (
    <div >
        { loading == false ? <DataGrid
                    // sx={{ overflow: "auto" }}
                    rowHeight={50}
                    rows={rowsWithSerialNumber}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    // processRowUpdate={processRowUpdate}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 8,
                        },
                      },
                    }}
                    pageSizeOptions={[8, 25, 50]}
                    slots={{
                      //   toolbar: EditToolbar,
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        setRows,
                        setRowModesModel,
                        showQuickFilter: true,
                      },
                    }}
                  />  : <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>}
          <ToastContainer
                        position="bottom-center"
                        autoClose={1000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                   />
    </div>
  )
}

export default BatchDpp