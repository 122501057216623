import { Avatar, Button, Card, Stack, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import io from "socket.io-client";
import socket from './sockets';
import { RHFTextField } from '../../../components/hook-form';
import { useParams } from 'react-router-dom';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StudentAnaytics from './components/StudentAnaytics';
import getAccess from '../../../utils/getAccess';




const CryptoJS = require('crypto-js');




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AppLive = () => {
    const { lectId, lectTitle, ytUrl, lectDesc } = useParams()
    // const decryptedBytes = CryptoJS.AES.decrypt(ytUrl ?? "hh", 'myKey');
    // const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
    // console.log("YT Url", decryptedValue)

    let newLink = '';
    // console.log(ytUrl);
    Array.from(ytUrl).forEach(elem => {

        if (elem !== '@') newLink += elem;
        else newLink += '/';

        // console.log('newLin', newLink)
    });
    // console.log(newLink);
    // const videoId = extractYouTubeVideoId(newLink);
    // const newUrl = `https://www.youtube.com/embed/${videoId}`;
    // console.log(newUrl);

    const detailObject = getAccess()
    const name = detailObject?.username
    const profileIcon = detailObject?.profilePhoto ?? "https://storage-upschindi.s3.ap-south-1.amazonaws.com/data/images/avatar.png"
    // let socket = io.connect('http://localhost:3001', { transports: ['websocket'] })

    const [msg, setMsg] = useState('')
    const [messagelist, setMessageList] = useState([])
    const [nameOfUser, setNameOfUser] = useState()
    const [IconIfUser, setIconIfUser] = useState("")
    const [isLiveFullScreen, setIsLiveFullScreen] = useState(false)


    const joinRoom = () => {
        const roomId = lectId

        socket.emit("create", roomId);

    }
    useEffect(() => {
        joinRoom()
    }, [])
    const [newMessageScroll, setNewMessageScroll] = useState(0)
    const [count, setCount] = useState(0)

    const sendMessage = async (msgg) => {
        // const msgg
        setCount(count + 1)
        const roomId = lectId
        // console.log('line26', msgg) 

        await socket.emit('send-message', msgg, name, roomId, profileIcon)
        setMsg('')
        if (count >= 7) setNewMessageScroll(50);
    }

    const arrivalMessage = async (message, name, userIconUrl) => {
        setMessageList((prev) => [...prev, { message: message, name: name, icon: userIconUrl }])
    }

    useEffect(() => {
        socket.on("receive-message", (message, name, userIconUrl) => {
            // console.log('line32', message)
            arrivalMessage(message, name, userIconUrl)
            setNameOfUser(name)
            setIconIfUser(userIconUrl)
            console.log(message)
        });
    }, [])

    const handleChange = (e) => {
        // console.log(e.target.value)
        if (e.target.value !== '') { setMsg(e.target.value) }

    }
    // console.log(arrivalMessage)

    const [users, usersSet] = useState()
    const [lectDetails, setlectureDetails] = useState()
    //Lecture Resource
    React.useEffect(() => {

        const token = localStorage.getItem("token");
        async function fetchDetails() {





            //Lecture Resource
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureResourceDetails?Lecture_id=${lectId}`, {
                // const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails/${lectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            // console.log(responseJson.data)



            //Lecture Details
            const fullResponseForLecture = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails/${lectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJsonForLecture = await fullResponseForLecture.json();
            setlectureDetails(responseJsonForLecture.data);


        }

        fetchDetails();
    }, []);

    const [value, setValue] = React.useState(0);

    const handleChangeForTabs = (event, newValue) => {
        setValue(newValue);
    };


    const helperString = lectDetails?.description.split(">")[1]
    const lectDescription = helperString?.split("<")[0];
    const player = useRef();

     const onChangeBitrate = (event) => {
         const internalPlayer = player.current?.getInternalPlayer('hls');
         if (internalPlayer) {
        // currentLevel expect to receive an index of the levels array
           internalPlayer.currentLevel = event.target.value;
          }
     }
    //  const videoRef = useRef(null);

     
    // console.log(newLink);
    return (
        <div className='parent' style={{

            height: '100%'
        }}>

            <Stack direction={isLiveFullScreen ? "column" : "row"} gap='20px'>
                <Stack direction="column" sx={{ width: '100%', height: '80vh !important' }}>
                    <div style={{ height: isLiveFullScreen ? "62%" : "100%", width: '100%', padding: '20px' }}>
                        <div style={{ height: '100%', width: '100%', background: 'black', borderRadius: '10px', marginBottom: '10px', overflow: 'hidden' }}>

                            <iframe width={isLiveFullScreen ? "100%" : "780px"} height={isLiveFullScreen ? "400px" : "300px"}
                                src='https://iframe.dacast.com/live/0761d3a4-650c-7d7a-43e3-765833f9d869/161fc293-c848-9b54-7129-1d95c6be5b56'
                                // src={ytUrl}
                            // src={newUrl}
                            >

                            </iframe>
                           {/* <ReactHlsPlayer
                                //  ref={player}
                                src="https://iframe.dacast.com/vod/0761d3a4-650c-7d7a-43e3-765833f9d869/691093f2-7972-41d2-8ee0-3f1562be4a20"
                                 autoPlay={false}
                                 controls={true}
                                 width="100%"
                                height="100%"
                             />, */}
                             {/* <ReactPlayer
                                //  ref={player}
                                src="https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"
                                //  autoPlay={false}
                                 controls={true}
                                 config={{
                                    hls: {
                                      manualFlush: true, // Enable manual quality control
                                    },
                                  }}
                                //  width="100%"
                                // height="100%"
                             />, */}
                             {/* <div data-vjs-player>
                                 <video ref={videoRef} className="video-js" />
                             </div> */}
                            
                        </div>

                    </div>
                   
                    
                    <h5 style={{ height: '5%' }}>
                        {lectDetails?.lecture_title}
                    </h5>
                    <div style={{ height: '50%', width: '100%' }}>

                        <Card sx={{ height: '100%', padding: '10px', display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>

                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChangeForTabs} aria-label="basic tabs example">
                                        <Tab label="Description" {...a11yProps(0)} />
                                        <Tab label="Analytics" {...a11yProps(1)} />

                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    <h4 style={{}}>
                                        {lectDescription}
                                    </h4>
                                    {users?.length !== 0 ? users?.map((item, index) => {
                                        return < div key={index}>

                                            {item?.resourceType == 'pdf' ? <Stack direction="row" alignItems='center' >{item?.title}:<a href={item?.upload_file?.fileLoc}><PictureAsPdfIcon /></a></Stack> : <p>{item?.title}: {item?.upload_file?.fileLoc}</p>}
                                        </div>
                                    }) : 'Nothing'}
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <StudentAnaytics />
                                </CustomTabPanel>

                            </Box>


                        </Card>

                    </div>

                </Stack >
                <Stack direction="column" sx={{ width: isLiveFullScreen ? '100%' : '60%' }}>
                    <Card sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>

                        <div style={{ height: '60px', display: 'flex', justifyContent: 'space-between   ', paddingLeft: '10px ', paddingRight: '10px  ', alignItems: 'center', background: 'rgba(150, 3, 242, 0.10)' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 24C7.075 24 6.71875 23.8563 6.43125 23.5688C6.14375 23.2812 6 22.925 6 22.5V19.5H25.5V6H28.5C28.925 6 29.2812 6.14375 29.5687 6.43125C29.8562 6.71875 30 7.075 30 7.5V30L24 24H7.5ZM0 22.5V1.5C0 1.075 0.14375 0.71875 0.43125 0.43125C0.71875 0.14375 1.075 0 1.5 0H21C21.425 0 21.7812 0.14375 22.0688 0.43125C22.3563 0.71875 22.5 1.075 22.5 1.5V15C22.5 15.425 22.3563 15.7813 22.0688 16.0688C21.7812 16.3563 21.425 16.5 21 16.5H6L0 22.5ZM19.5 13.5V3H3V13.5H19.5Z" fill="#9603F2" fill-opacity="0.75" />
                            </svg>
                            &nbsp; Live Comments
                            <Button onClick={() => setIsLiveFullScreen(!isLiveFullScreen)}>

                                {!isLiveFullScreen ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
                            </Button>
                        </div>

                        <div style={{ overflowY: 'scroll', padding: '20px', height: '60vh', display: 'flex', flexDirection: 'column' }}>
                            {messagelist?.map((item) => {
                                // console.log(item)
                                // console.log
                                return <Stack direction='row' alignItems="center" justifyContent={name == item?.name ? '' : ''} mb={2} sx={{ width: '100%', color: name == item?.name ? 'green' : '', }
                                }>

                                    <Avatar src={IconIfUser} style={{ height: '30px', width: '30px' }} /> &nbsp;
                                    <h5 style={{ textAlign: 'start' }}>{item?.name} : </h5><br /> &nbsp;<span style={{ fontSize: '12px' }}>{item?.message}</span>

                                </ Stack>

                            })}

                        </div>
                        <Stack direction="row" spacing={2} sx={{ position: 'absolute', width: '100%', bottom: '0', padding: '10px' }}> <TextField type='text' value={msg} sx={{ width: '100%' }} onChange={(e) => {
                            handleChange(e)
                        }} />
                            <Button
                                // ref={inputRef}
                                variant='outlined'
                                onClick={() => sendMessage(msg)}
                            // placeholder="Enter your message"
                            >
                                Send
                            </Button>
                            {/* <Button
                                // ref={inputRef}
                                variant='outlined'
                                onClick={() => joinRoom()}
                            // placeholder="Enter your message"
                            >
                                Join
                            </Button> */}
                        </Stack>

                        {/* <h4>AAyein</h4> */}
                    </Card>

                </Stack>



            </Stack >

        </div >
    )
}

export default AppLive