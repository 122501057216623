import {
    Stack, Tooltip
  } from "@mui/material";
  import React from "react";
  import SyncLoader from "react-spinners/SyncLoader";
  import { useNavigate, useParams } from "react-router-dom";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { useTheme } from "@emotion/react";
  import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarDensitySelector,
    useGridApiContext,
    gridExpandedSortedRowIdsSelector
  } from "@mui/x-data-grid";
  import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
  import SaveIcon from "@mui/icons-material/Save";
  import CancelIcon from "@mui/icons-material/Close";
  import getAccess from "../../../utils/getAccess";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {  Typography } from '@mui/material';

const flattenObject = (obj) => {
    return `${obj.name} ${obj.email} ${obj.phone}`;
  };

  function CustomToolbar ({rows , columns }){
    const apiRef = useGridApiContext();
    const exportAllRows = async () => {
       const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
      
      const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
     
      const columnIds = columns.map((column) => column.field);
      const csvHeader = columnIds.join(', ');
      const csvContent = filteredRows
        .map((row) => {
          const rowValues = columnIds.map((columnId) => {
          if (columnId === 'userDetails') {
              return flattenObject(row.user)
            }
            return row[columnId]?.toString()?.replaceAll("," , " ");
          });
          return rowValues.join(', ');
        })
        .join('\n');
      const fullCsvContent = `${csvHeader}\n${csvContent}`;
      const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'LectureReports.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
         
        <GridToolbarDensitySelector />
        <Tooltip title="Export All">
          <IconButton onClick={exportAllRows}>
            <SaveAltIcon style={{color : 'red'}}/>
          </IconButton>
        </Tooltip>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };
  
  const LectureReportTable = ( ) => {
    const [loading, setLoading] = React.useState(false);
    const { lectId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
  
    const access = getAccess();
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    React.useEffect(() => {
      setLoading(true);
      const token = localStorage.getItem("token");
      async function fetchUsers() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureReports?lectureId=${lectId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setLoading(false);
        setRows(responseJson.data);
      }
  
      fetchUsers();
    }, []);
   
    //   const navigate = useNavigate()
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      //   editDataInDb(newRow)
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
  
    
    const columns = [
      {
        field: "sno",
        headerName: "S NO",
        width: 75,
        align: "left",
        headerAlign: "left",
        editable: false,
        renderCell: (params) => <strong>{params.value}</strong>,
      },
  
      {
        field: "title",
        headerName: "Title",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },
      {
        field: "lectureName",
        headerName: "Lecture Name",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },
      
      {
        field: "mentor",
        headerName: "Mentors",
        type: "",
        width: 200,
        align: "left",
        headerAlign: "left",
        renderCell : (params) => (
              <div>
                <strong>Name:</strong> {params?.value?.name}<br />
                <strong>Phone:</strong> {params?.value?.phone}<br />
                <strong>email:</strong> {params?.value?.email}
              </div>
        )
      },
    ];
    return (
      <>
        <Typography variant='h2'>{`Lecture's Report `} </Typography>
          { loading == false ? <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight={true}
          rows={rows}
          loading={loading}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 25, 50]}
          slots={{
            toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
          }}
        //   slots={{
        //     //   toolbar: EditToolbar,
        //     toolbar: GridToolbar,
        //   }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, showQuickFilter: true },
          }}
        /> : <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>}
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    );
  };
  
  export default LectureReportTable;
  