import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, Button, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Page from '../../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddTopic = () => {

    const { chapterId , title } = useParams();
    const[topicFile , setTopicFile ] = useState();
    const [focusFive, setFocusedFive] = React.useState(false);

    const changeHandler =  (event) => {
        // console.log("25" , event.target);
        // console.log(event.target.files[0])
        setTopicFile(event.target.files[0]);
    };

    const [loading, setLoading] = useState(true);
    
    const theme = useTheme();
    const LoginSchema = Yup.object().shape({
        title: Yup.string().required('title is required'),
        isActive: Yup.string().required('Status is required'),
        topicNumber: Yup.number().required('Topic Number is required'),
        
    });

    const defaultValues = {
        title : '',
        description: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    //Add Details'

    const onSubmit = async (data) => {
            // setShowProgress(true)
            setLoading(true)
            if( topicFile){
                const formData = new FormData();
                formData.append('title', data.title);
                formData.append('file' , topicFile); 
                formData.append('chapterId', chapterId);
                formData.append('isActive', data.isActive);
                formData.append('topicNumber' , data.topicNumber);
                let token = localStorage.getItem("token");
                const config = {
                   headers: {
                      'content-type': 'multipart/form-data',
                      Authorization: `Bearer ${token}`,
                   },
            };
            
            axios.post(`${process.env.REACT_APP_LIVE_URL}/ebook/addTopic`, formData , config).then((response) => {
                if (response.data.status) {
                    toast.success(response.data.msg)
                    setTopicFile();
                    reset();
                }else{
                    setLoading(false);
                    toast.error(response.data.msg);
                }
            })
                .catch(e => {
                    //console.log(e);
                })

            }else{
                toast.error('Please Upload topic pdf')
            }
            
            // for( let Pair of formData){
            //     console.log(Pair[0] + " " + Pair[1]);
            // }
            
        

    }
    return (<>
        <Page title='Chapter'>
            <Stack spacing={3} ml={5}>
                <Typography className='page-heading'>{`Add Topic into ${title} `}</Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '80%' }}> 
                        <Stack direction='row' spacing={2}>
                           <RHFTextField name="title" label="Topic Title" />
                           
                        </Stack>
                        <RHFTextField
                                 id="topicFile"
                                 name="Topic File"
                                 label="Topic ( in Pdf)"
                                 onChange={changeHandler}
                                 onFocus={() => setFocusedFive(true)}
                                 onBlur={() => setFocusedFive(false)}
                                 inputProps={{ accept: "application/pdf" }}
                                 InputProps={{
                                   classes: {
                                     input: "CustomTextField",
                                   },
                                 }}
                                //  onClick={() => s(true)}
                                 type= { "file"}
                               />
                        {/* <RHFTextField name="description" label='Description'  id="custom-css-outlined-input" /> */}
                        <Stack direction="row"  sx={{ width : '100%'}} spacing={2}>
                            <RHFTextField name="topicNumber" label="Topic Number"  type="number" id="custom-css-outlined-input" />
                            <RHFTextField
                                name='isActive'
                                label="Status"
                                sx={{ width: '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </RHFTextField>
                        </Stack>
                    </Stack>

                    <Button fullWidth size="large" type="submit" variant="contained"
                        sx={{ bgcolor: theme.palette.primary.mainTheme, width: '80%', marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme, } }}
                        onClick={() => {
                            
                        }}
                    >
                        Submit
                        {/* {showProgress ? <Typography mr={2} sx={{ textAlign: 'center' }}>{submitted ? 'Submitted' : progress < 91 ? `Uploading File..... ${progress}%` : 'Submitting Form'}</Typography> : 'Add'}
                        {showProgress ? submitted ? '' : (<CircularProgress color="secondary" />) : ''} */}
                    </Button>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </Stack>
        </Page >
    </>
    )
}

export default AddTopic