import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridFilterInputSingleSelect
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, TextField, debounce } from "@mui/material";
import getAccess from "../utils/getAccess";
import { useTheme } from "@emotion/react";
import * as XLSX from 'xlsx'

const removeComma = (array) => {
  return array.reduce((acc, curr) => acc + " " + curr, "")
};



function CustomToolbar({ rows, columns, loading, setLoading, searchText, setSearchText, ...gridProps }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row, index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'serialNumber') {
            return index + 1;
          }
          else if (columnId === 'Stream') {
            return removeComma(row.Stream)
          }
          return row[columnId]?.toString().replaceAll(",", " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Users.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  );
};

export default function SpecificUsers() {
  const d = getAccess();
  const [rows, setRows] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rowCountState, setRowCountState] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    let page =  1;
    let pageSize = 1 ;
    // setLoading(true);
    // console.log('97' , searchText);

    async function fetchUsers() {
      setLoading(true);

      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getStudents?page=${page}&pageSize=${pageSize}&searchText=${searchText}`, {
        method: 'GET',
        // signal: controller.signal, 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
      setRows(responseJson.data?.studentList);
      setRowCountState(responseJson.data?.count);
      setLoading(false);
    }
    if( searchText != ""){
      fetchUsers();

    }
    // fetchUsers();
  }, [searchText]);

  




  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };


  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      // editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "enrollId",
      headerName: "Enroll Id",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "userId",
      headerName: "User Id",
      type: "object",
      width: 350,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ?? "NA"}</div>
      ),
    },
    {
      field: "FullName",
      headerName: "Name",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "mobileNumber",
      headerName: "Phone No",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },

    {
      field: "utm_campaign",
      headerName: "Utm Campaign",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "utm_source",
      headerName: "Utm Source",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "utm_medium",
      headerName: "Utm Medium",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "mobileNumberVerified",
      headerName: "isMobileVerified",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value === true ? 'Verified' : 'Not Verified'}
        </div>
      ),
    },
    {
      field: "Stream",
      headerName: "Category",
      type: "object",
      width: 450,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          {params.value.map((title, index) => (
            <div>{title} ,</div>

          ))}
        </div>
      )
    },
    {
      field: "created_at",
      headerName: "Register At",
      type: "Date",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          (d?.accessForTab?.includes('readWrite') || d?.accessForTab?.includes('read') || d?.access?.includes('all')) ?
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon />}
              label="View"
              onClick={() => navigate(`/dashboard/students/studentsProfile/${id}`)}
              color="inherit"
            /> : <></>,
        ];
      },
    },
  ];



  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Stack direction='row' spacing={3} justifyContent='space-between' m={2} mr={10} sx={{ width: '90%', }}>
        <h2>Specific User</h2>
      </Stack>
      <Box m={2}>
        <TextField value={searchText} onChange={handleChange} placeholder="Search User Text ....." />
      </Box>

      <DataGrid
        rows={rowsWithSerialNumber}
        columns={columns}
        editMode="row"
        autoHeight={true}
        loading={loading}
        rowCount={rowCountState}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        // pageSizeOptions={[25, 50, 100]}
        // paginationModel={paginationModel}
        // paginationMode="server"
        // onPaginationModelChange={handlePaginationModelChange}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} loading={loading} setLoading={setLoading} searchText={searchText} setSearchText={setSearchText} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}