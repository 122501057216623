import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { FormHelperText, Modal, TextField } from "@mui/material";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  GridRowEditStopReasons,
  gridExpandedSortedRowIdsSelector
} from "@mui/x-data-grid";

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";
import * as XLSX from 'xlsx';

function CustomToolbar({ rows, columns , exportData }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    // console.log(exportData);
    const dataForExcel =  exportData?.map((item) => {
      return {
        // id: item._id ?? "",
        title: item.title ?? "",
        banner: item.banner ?? "",
        tags: item?.tags?.reduce((acc , curr ) => acc + curr + " ," , "") ?? "",
        admin:`name -${item?.admin.name}, role - ${item.admin.role}`,
        regularPrice: item.regularPrice != "" ? item.regularPrice : "0",
        salePrice: item.salePrice != "" ? item.salePrice : "0",
        language: item.language ,
        isActive: item.isActive ,
        categoriesForExport : item?.categoriesForExport?.reduce((acc , curr) => acc +  curr + " , " , ""),
        createdAt: item?.createdAt
      }
    })
    // console.log(dataForExcel);
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'AllEbook.xlsx');
   
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function EbookTable() {
  const theme = useTheme();
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchEbooks() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/ebook/getEbooks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson  = await fullResponse.json();
      setRows( responseJson.data);
    }
    
    fetchEbooks();
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const handleEditClick = (id , title) => () => {
    window.open(`/dashboard/addChapterToEbook/${id}/${title}`)
  };

  
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  const handleSelectionModelChange = (selection) => {
    // console.log("console" + selection);
    setSelectedRows(selection);
  }
  const access = getAccess();
  // for bulk import product
  const [progress, setProgress] = useState();

  const columns = [
    {
      field: 'sno',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div>{`${params.value.name} (${params.value.role})`}</div>
        )
      }
    },
    // {
    //   field: "url",
    //   headerName: "Url",
    //   type: "string",
    //   width: 100,
    //   align: 'left',
    //   headerAlign: "left",
    //   editable: false,
    //   renderCell: (params) => (
    //     <a href={params.value ? params.value : "NA"} target="_blank">Link</a>
    //   ),
    // },
    {
      field: "banner",
      headerName: "Feature Image",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Image for row ${params.id}`}
          style={{ width: 70, height: 70 }}
        />
      ),
    },
    // {
    //   field: "images",
    //   headerName: "Image",
    //   type: "string",
    //   width: 300,
    //   align: "left",
    //   headerAlign: "left",
    //   renderCell: (params) => (
    //     <div style={{ display: 'flex' }} onClick={() => {
    //       handleOpen();
    //       setModalData(params)
    //     }}>
    //       {params.value.map((imageUrl, index) => (
    //         <img
    //           key={index}
    //           src={imageUrl}
    //           alt={`Image ${index + 1} for row ${params.id}`}
    //           style={{ width: 40, height: 70, marginRight: 5 }}
    //         />
    //       ))}
    //     </div>
    //   ),
    // },
   
    {
      field: "category",
      headerName: "Categories",
      type: "String",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell : (params) => (
        params?.value?.length > 0 ? <Stack spacing={2} direction='column' >
          { params?.value?.map((item) => (
           <span>{item?.title}</span>
        ))}
        </Stack> : <strong>NA</strong>
 
      )
    },
    
    
    {
      field: "regularPrice",
      headerName: "Regular Price",
      type: "object",
      width: 140,
      align: "left",
      // description:access?.accessForTab?.includes('readWrite') ? 'Regular Price is editable' :  " Regular Price",
      headerAlign: "left",
   
      
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      type: "object",
      width: 120,
      align: "left",
      // description:access?.accessForTab?.includes('readWrite') ? 'Sale Price is editable' : "Sale Price ",
      headerAlign: "left",
      

    },
    {
      field: "tags",
      headerName: "Tags",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",

    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value ? 'Active' : 'inActive'}
        </div>
      ),

    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find(item => item.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <Tooltip title="View Chapter">
          <GridActionsCellItem
            icon={<VisibilityOutlinedIcon />}
            label="View"
            // onClick={handleDeleteClick(id)}
            onClick={() => navigate(`/dashboard/chapters/${id}/${rowData?.title}`)}
            color="inherit"
          />
          </Tooltip>,
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Add Chapter">
          <GridActionsCellItem
            icon={<AutoStoriesIcon style={{ color : 'blue'}}/>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id , rowData.title)}
            color="inherit"
          /> 
          </Tooltip>: <></>)
          // ,
        //   ((access?.deleteAccess || access?.access.includes('all')) ? <Tooltip title="Trash Product">
        //     <GridActionsCellItem
        //   icon={<DeleteForeverIcon style={{ color : 'red'}}/>}
        //   label="Trash"
        //   onClick={handleTrashClick(id)}
        //   // onClick={ (id) => handleOpen(id)}
        //   color="inherit"
        // />

        //   </Tooltip>
        //    : <></>)
          // ((access?.deleteAccess || access?.access.includes('all')) ? <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   // onClick={ (id) => handleOpen(id)}
          //   color="inherit"
          // /> : <></>)
        ];
      }
    }
  ]


  return (<>
  
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >


        <h2 >All Ebook</h2>
        
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        autoHeight={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        editMode="row"
        // checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} exportData={rows} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  </>
  );
}
