import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Grid,
    Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import {
    FormProvider,
    RHFTextField,
    RHFCheckbox,
} from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { useTheme } from "@emotion/react";
import { values } from "lodash";

const UpdateStoreFeaturedVideo = ({ editData }) => {



    const theme = useTheme()

    const navigate = useNavigate();
    const [focus, setFocused] = React.useState(false);
    const [resources, setResources] = React.useState(false);
    const [users, usersSet] = React.useState([]);
    const { lectId, batchId, id } = useParams();
    const [loading, setLoading] = useState(false);
    const [fetchMessage, setFetchMessage] = useState();
    const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
    const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));
    const handleChange = (newValue) => {
        setStarting(newValue);
    };
    const handleChangeTwo = (newValue) => {
        setEnding(newValue);
    };

    const LoginSchema = Yup.object().shape({});

    const defaultValues = {};

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    const [file, setFile] = useState();

    const changeHandler = async (event) => {
        setFile(event.target.files[0]);
    };

    //Getting Existing details
    const [lectureDetails, setLectureDetails] = useState();
    const [value, setValue] = useState();
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/store/getProductCategoryByIdAdmin/${id}`,
                // `${process.env.REACT_APP_LIVE_URL}/store/getProductCategoryByIdAdmin/${editData?.id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            setLectureDetails(responseJson?.data);
            //console.log(responseJson.data.starting_date);
            setStarting(dayjs(responseJson?.data?.createdAt));
            // setEnding(dayjs(editData));
            setValue((prev) => ({
                ...prev,
                title: responseJson?.data?.title,
                parentCategory: responseJson.data?.parentCategory?.title,

                isActive: responseJson?.data?.isActive,
            }));

        }
        fetchUsers();
    }, []);
    // //console.log(lectureDetails?.starting_date);
    //console.log(starting);


    let formatDate = (date) => {
        let datehelper = "" + date;
        datehelper = datehelper.split(" ");
        let monthsList = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        let time = datehelper[4];
        let year = datehelper[3];
        let month = `0${(monthsList.indexOf(datehelper[1]) + 1)}`.slice(-2);
        let day = datehelper[2];

        return `${day}-${month}-${year} ${time}`;
    }

    const onSubmit = async (data) => {
        let StartingDate = formatDate(starting.$d);
        let EndingDate = formatDate(ending.$d);

        setLoading(true);
        // //console.log(starting.$d == 'Invalid Date')
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }


        if (!data.title) { formData.append("title", value.title); }



        formData.append("parentCategory", value?.parentCategory);






        if (value.isActive) {
            formData.append('isActive', value.isActive);
        } else {
            formData.append('isActive', lectureDetails?.isActive);
        }


        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios
            .put(`/store/updateProductCategory/${id}`, formData, config)
            .then((response) => {
                // //console.log(response.data);

                if (response.data.msg === "updated the lecture  successfully") {
                    reset();
                    setLoading(false);
                    toast.success("Lecture Details Updated");

                    setTimeout(() => {
                        navigate(`/dashboard/batchDetails/${batchId}`);
                        // window.location.reload();
                    }, 1500);
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    //Getting Category
    const [category, setCategory] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem('token');
        async function fetchCategory() {
            const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProductCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`

                }
            });
            const responseJson = await response.json();
            setCategory(responseJson.data);
            setLoading(false);
        }
        fetchCategory();

    }, []);

    //Getting Batch
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);

            // setLoading(false)
        }
        fetchUsers();
    }, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        // console.log('parentCategory', name, value)
        setValue((prev) => ({ ...prev, [name]: value }));
    };
    const [pdf, setPdf] = useState(false);
    const [val, setVal] = useState(false);
    const [parentCategory, setParentCategory] = useState(false)
    console.log(lectureDetails)
    console.log(value)
    return (
        <Grid direction="row" pl={5}>
            <Typography variant="h2" ml={2}>
                Update Store Feature Video Details
            </Typography>


            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {/* <Stack direction="row" spacing={5} > */}
                <Stack spacing={3} sx={{ width: "70%" }}>


                    <RHFTextField
                        name="title"
                        value={value?.title}
                        onChange={onChange2}
                    />




                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack
                            direction="row"
                            spacing={3}
                            sx={{ width: "100%", justifyContent: "space-between" }}
                        >
                            <DateTimePicker
                                label="Starting Date and Time"
                                value={starting.$d}
                                onChange={handleChange}
                                // name="starting_date"
                                renderInput={(params) => (
                                    <TextField {...params} sx={{ width: "100%" }} />
                                )}
                            />

                        </Stack>
                    </LocalizationProvider>



                    {lectureDetails?.parentCategory?.title !== "" && <Stack direction="row" spacing={3}><RHFTextField name="parentCategory" label={value?.parentCategory} select onChange={onChange2}>

                        <MenuItem value='none' onClick={() => { setParentCategory(false) }}>None</MenuItem>
                        {category?.map((item, index) => { return <MenuItem key={index} value={item.id} onClick={() => { setParentCategory(true) }}>{item.title}</MenuItem> })}

                    </RHFTextField>
                    </Stack>}

                    <Stack spacing={3} direction="row">
                        {lectureDetails?.parentCategory?.title == "" && < RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="File"
                            error={!file && val}
                            helperText='Image Only'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />}

                        {/* <RHFTextField name="link" label={lectureDetails?.link} type="url" /> */}
                    </Stack>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    // loading={loading}
                    sx={{
                        bgcolor: theme.palette.primary.mainTheme,
                        width: "70%",
                        marginTop: "5%",
                        "&:hover": { background: "red" },
                    }}
                >
                    Update
                </LoadingButton>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Grid>
    );
};

export default UpdateStoreFeaturedVideo;