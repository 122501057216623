import {
    Tooltip,
  } from "@mui/material";
  import * as React from "react";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import axios from "axios";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import "./style.css";
  import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn'; 
import ApartmentIcon from '@mui/icons-material/Apartment'; 
import { VisibilityOutlined } from "@mui/icons-material";
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from '@emotion/react';
import { Stack } from '@mui/material'
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReportIcon from '@mui/icons-material/Report';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import getAccess from "../../../utils/getAccess";
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import * as XLSX from 'xlsx';


const BatchLectures = () => {
  const theme = useTheme()
    const access = getAccess();
  const navigate = useNavigate();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectures?BatchId=${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);


 



  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  
  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete Lecture?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteLectureDetails/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    }
  };
  const makeActiveAndInActiveLecture = async (id) => {

    const token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/makeLectureActiveAndInActive/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    const responseJson = await response.json();
    if( responseJson?.status){
      toast.success(responseJson.msg)
      setTimeout(() => window.location.reload()
      , 1000)
    }else{
      toast.error(responseJson.msg);
    }
    

  }
  const handleComment = async ( id ) => {
    
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/lecture/getLectureComments/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if (resJson.status) {
      // toast.success(resJson.msg);
      if( resJson.data?.length > 0 ){
        const workbook = XLSX.utils.book_new();
  
      resJson?.data.forEach((lecture,index) => {
            const lectureName = lecture?.lectureName.substring(0, 21).replace(/[^a-zA-Z ]/g, ""); // Truncate lecture name if necessary
            const comment = lecture?.comment;
            const worksheet = XLSX.utils.json_to_sheet(comment);
            XLSX.utils.book_append_sheet(workbook, worksheet, `${lectureName}${index+1}`);
        });
  
        XLSX.writeFile(workbook, 'LectureComments.xlsx');
        toast.success(resJson.msg);
      }else{
        toast.error('There is no comments for this lecture')
      }
      
    } else {
      toast.error(resJson.msg);
    }
    
  }

  const changeCommentStatus = async ( id ) => {
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/changeLectureComment?lectureId=${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if (resJson.status) {
     toast.success(resJson.msg);
     setTimeout(() => window.location.reload()
      , 1000) 
    } else {
      toast.error(resJson.msg);
    }
    
  }

  
  const[ loading1 , setLoading1] = React.useState(false);

  const handleAttendence = async( commonName ) =>{
    const token = localStorage.getItem("token");
    setLoading1(true);
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/lectureAttendence?commonName=${commonName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      const workbook = XLSX.utils.book_new();
      responseJson?.data.forEach((lecture,index) => {
            const lectureName = lecture?.batchName.substring(0, 18).replace(/[^a-zA-Z ]/g, ""); // Truncate lecture name if necessary
            const timeSpendOnLecture = lecture?.timeSpendOnLecture ;
            // if( timeSpendOnLecture.length > 0 ){
              const worksheet = XLSX.utils.json_to_sheet(timeSpendOnLecture);
              XLSX.utils.book_append_sheet(workbook, worksheet, `${lectureName}${index+1}`);
            // }
            
        });
        if (!workbook.SheetNames || workbook.SheetNames.length === 0) {
          // throw new Error("Workbook is empty");
          toast.error('There is no attendence')
        }else {
          XLSX.writeFile(workbook, 'StudentAttendence.xlsx');
          setLoading1(false);
          toast.success('Attendence Sheet Downloaded')
        }

        
  }
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));




  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      // editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "lecture_title",
      headerName: "Title",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "user_admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true
      renderCell : (params) => {
        return (
          <div>{`${params.value?.FullName} (${params.value?.Role})`}</div>
        )
      }
    },
    {
      field: "teacher",
      headerName: "Teacher",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
         <div>{params.value[0]?.FullName}</div> 
      ),
      getApplyQuickFilterFn : (value) => {
        return ( params ) => {
          return ( params?.value[0]?.FullName?.includes(value) )

        }
        // return ( params.value.name.includes(value) )
      }

    },
    {
      field: "subject",
      headerName: "Subject",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
         <div>{params.value?.title}</div> 
      ),

    },
    {
      field: "lecture_type",
      headerName: "Lecture Type",
      type: "string",
      width: 140,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
         <div>{params.value == 'YT' ? "Youtube" : params.value == 'APP' ?  "App" : 'Two Way Communication'}</div> 
      ),
    },
    {
      field: "LiveOrRecorded",
      headerName: "Type",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "starting_date",
      headerName: "Start Date",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "ending_date",
      headerName: "Ending Date",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
     
    },
    {
      field: "dpp",
      headerName: "DPP",
      type: "string",
      // width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        ( params?.value?.fileLoc != "" ? <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={params?.value?.fileName} ><a
            href={params.value.fileLoc}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileDownloadIcon
              style={{ width: 24, height: 24, marginRight: 5 }}
            />
          </a>
          </Tooltip>
          
            {/* <span>{params.value?.fileName}</span> */}
          
        </div> : <strong>NA</strong> )
      ),
    },
    {
      field: "material",
      headerName: "PDF",
      type: "string",
      // width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        ( params?.value?.fileLoc != "" ? <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={params?.value?.fileName} ><a
            href={params?.value?.fileLoc}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileDownloadIcon
              style={{ width: 24, height: 24, marginRight: 5 }}
            />
          </a>
          </Tooltip>
            {/* <span>{params.value?.fileName}</span> */}
        </div> :  <strong>NA</strong>)
      ),
    },
    {
      field: "resources",
      headerName: "Lecture Resource ",
      type: "",
      width: 300,
      align: "left",
      headerAlign: "left",
      renderCell : (params) => (
        params?.value?.length > 0 ? <Stack spacing={2} direction='column' >
          { params?.value?.map((item) => (
            <a
               href={item?.link?.fileLoc}
               target="_blank"
                rel="noopener noreferrer"
             >
              { `${(item?.resourceType)?.toUpperCase()} -> ${item?.title}`}
            </a>
          
        ))}
        </Stack> : <strong>NA</strong>
 
      )
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 350,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find((item) => item.id === id);
        const [teacherName] = rowData.teacher;
        let date = rowData.starting_date.split(" ")[0];
        let today = new Date();
        let todayDate =
          today.getDate() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getFullYear();
        let check = todayDate.split("-")[0];
        let checkMonth = todayDate.split("-")[1];

        let golive;

        if (check < 10) {
          if (checkMonth > 9) {
            let day = "0" + today.getDate();
            let todayDate =
              day + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
            golive = todayDate == date;
          } else {
            let day = "0" + today.getDate();
            let month = "0" + (today.getMonth() + 1);
            let todayDate = day + "-" + month + "-" + today.getFullYear();
            golive = todayDate == date;
          }
        } else {
          if (checkMonth > 9) {
            let todayDate =
              today.getDate() +
              "-" +
              (today.getMonth() + 1) +
              "-" +
              today.getFullYear();
            golive = todayDate == date;
          } else {
            let month = "0" + (today.getMonth() + 1);
            let todayDate =
              today.getDate() + "-" + month + "-" + today.getFullYear();
            golive = todayDate == date;
          }
        }

        let newLink = "";
        Array.from(rowData?.link).forEach((elem) => {
          if (elem == "/") newLink += "@";
          else newLink += elem;

          // console.log('newLin', newLink)
        });
        let newTitle = "";
        Array.from(rowData?.lecture_title).forEach((elem) => {
          if (elem == "/") newTitle += "@";
          else newTitle += elem;
        });

        // console.log(newLink);
        const helperString = rowData?.description.split(">")[1];
        let lectDescription = helperString?.split("<")[0];
        if (lectDescription == "") {
          lectDescription = "_";
        }
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          rowData?.lecture_type != "YT" && rowData?.LiveOrRecorded == "Live" ? (
            <Tooltip title="Go Live" arrow>
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="Edit"
                className="textPrimary"
                onClick={() =>
                  navigate(
                    `/dashboard/appLive/${rowData?._id}/${newTitle}/${lectDescription}/${newLink}`
                  )
                }
                color="inherit"
              />
            </Tooltip>
          ) : rowData?.lecture_type != "YT" &&
            rowData?.LiveOrRecorded == "Recorded" ? (
            <Tooltip title="View" arrow>
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="Edit"
                className="textPrimary"
                onClick={() =>
                  navigate(
                    `/dashboard/appLive/${rowData?._id}/${newTitle}/${lectDescription}/${newLink}`
                  )
                }
                color="inherit"
              />
            </Tooltip>
          ) : rowData?.lecture_type == "YT" &&
            rowData?.LiveOrRecorded == "Live" ? (
            <Tooltip title="Go Live" arrow>
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="Edit"
                className="textPrimary"
                onClick={() =>
                  navigate(
                    `/dashboard/youtubeLive/${rowData?._id}/${newTitle}/${lectDescription}/${newLink}`
                  )
                }
                color="inherit"
              />
            </Tooltip>
          ) : rowData?.lecture_type == "YT" &&
            rowData?.LiveOrRecorded == "Recorded" ? (
            <Tooltip title="View" arrow>
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() =>
                  navigate(
                    `/dashboard/youtubeLive/${rowData?._id}/${newTitle}/${lectDescription}/${newLink}`
                  )
                }
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? 
          <Tooltip title="Edit Lecture" arrow>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() =>
                navigate(
                  `/dashboard/batchDetails/updateLectureDetails/${Id}/${rowData._id}`
                )
              }
              color="inherit"
            />
            
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite'  || access?.accessForTab == 'read' || access?.access.includes('all')) ?
          <Tooltip title="Lecture Resources" arrow>
            <GridActionsCellItem
              icon={<AssignmentIcon />}
              label="Resources"
              onClick={() =>
                navigate(
                  `/dashboard/lectureResources/${rowData._id}/${Id}`
                )
              }
              color="inherit"
            />
          </Tooltip> : <></>),
          ((access?.deleteAccess == true || access?.access.includes('all')) ?<Tooltip title="Delete Lecture" arrow>
            <GridActionsCellItem
              icon={<DeleteIcon sx={{ color: "red" }} />}
              label="Delete"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
            
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
          <Tooltip title="Change Status">
          <GridActionsCellItem
            icon={ rowData?.isActive ? <ToggleOffIcon style={{ color:'green'}}  /> : <ToggleOnIcon style={{color : 'red'}}/>}
            label="Status"
            onClick={() => { makeActiveAndInActiveLecture(id) }}
            color="inherit"
          />
        </Tooltip> : <></>),
        ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
        <Tooltip title="Lecture Rooms">
        <GridActionsCellItem
          icon={<ApartmentIcon style={{color : 'blue'}}/>}
          label="Lecture Rooms"
          onClick={() => { navigate(`/dashboard/lectureRooms/${id}`) }}
          color="inherit"
        />
      </Tooltip> : <></>),
       ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
       <Tooltip title="Lecture Reports">
       <GridActionsCellItem
         icon={<ReportIcon style={{color : 'red'}}/>}
         label="Lecture Reports"
         onClick={() => { navigate(`/dashboard/lectureReport/${id}`) }}
         color="inherit"
       />
     </Tooltip> : <></>) ,
       ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
       <Tooltip title="Lecture Attendence">
       <GridActionsCellItem
         icon={<CoPresentIcon style={{color : 'blue'}}/>}
         label="Lecture Attendence"
         onClick={() => handleAttendence(rowData.commonName)}
         color="inherit"
       />
     </Tooltip> : <></>) ,
     ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Lecture Comment">
     <GridActionsCellItem
       icon={<CommentIcon style={{ color: 'red' }} />}
       label="Comments"
       onClick={() => handleComment(id)}
       // onClick={() => console.log("line 12"+rowData.is_active)}
       // onClick={ (id) => handleOpen(id)}

       color="inherit"
     />
   </Tooltip> : <></>),
   ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title={rowData?.isCommentAllowed == true ? "Disable Comment" : 'Enable Comment'}>
   <GridActionsCellItem
     icon={<AddCommentIcon style={ rowData?.isCommentAllowed == true ? {color : 'green' } :{ color: 'red' }} />}
     label="Change Status of Lecture Comments"
     onClick={() => changeCommentStatus(id)}
     // onClick={() => console.log("line 12"+rowData.is_active)}
     // onClick={ (id) => handleOpen(id)}

     color="inherit"
   />
 </Tooltip> : <></>),

        ];
      },
    },
  ];
  return (
    <div style={{ height : 'auto'}}>
              { loading == false ? <DataGrid
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 50}
                    loading={loading}
                    rows={rowsWithSerialNumber}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    // processRowUpdate={processRowUpdate}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 8,
                        },
                      },
                    }}
                    autosizeOptions={{
                      columns: ['lecture_title', 'DPP', 'PDF'],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    pageSizeOptions={[8, 25, 50]}
                    slots={{
                      //   toolbar: EditToolbar,
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        setRows,
                        setRowModesModel,
                        showQuickFilter: true,
                      },
                    }}
                  /> : <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>}
                  <ToastContainer
                        position="bottom-center"
                        autoClose={1000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                   />
    </div>
  )
}

export default BatchLectures