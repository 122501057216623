import React from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import {
  FormProvider,
  RHFTextField,
} from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { useTheme } from "@emotion/react";
import { convertYoutubeUrlToEmbed } from "../../../utils/convertYoutubeUrlToEmbed";

const LectureForm = () => {
  const theme = useTheme()

  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  const [focus, setFocused] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [loading, setLoading] = useState(false);
  // const [focusThree, setFocusedThree] = React.useState(false);
  // const [focusTwo, setFocusedTwo] = React.useState(false);

  // const [showStartingDate, setShowStartingDate] = React.useState(false);
  // const [showEndingDate, setShowEndingDate] = React.useState(false);

  const [users, usersSet] = React.useState([]);
  const { Id, batchName } = useParams();
  let newName = ''
  Array.from(batchName).forEach(elem => {

    if (elem !== '_') newName += elem;
    else newName += '/';

  });

  const[socketUrl , setSocketUrl] = useState("");


  const [positive, setPositive] = useState(false);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs());
  const [ending, setEnding] = React.useState(dayjs());
  const [validateYoutubeUrl, setValidateUrl] = useState(false);

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const LoginSchema = Yup.object().shape({
    lecture_title: Yup.string().required("Title is required"),
    // batch_name: Yup.string().required('Batch Name is required'),
    description: Yup.string().required("Description is required"),
    // LiveOrRecorded: Yup.string().required("Type is required"),
    // starting_date: Yup.string().required('Staring date is required'),
    // ending_date: Yup.string().required('Ending Date is required'),
    subject_id: Yup.string().required("Subject  required"),
    teacher:Yup.string().required("Teacher  required"),
    language: Yup.string().required("language  required"),
    lecture_type: Yup.string().required("Lecture Type  required"),
    isActive:Yup.boolean().required(" Status Required")
    
    
    // link: Yup.string().required('Link  required'),
    // materials: Yup.string().required('Resources  required'),
  });

  const defaultValues = {
    lecture_type: "",
    lecture_title: "",
    // batch_name: '',
    description: "",
    // starting_date: '',
    // ending_date: '',
    subject_id: "",
    // LiveOrRecorded: "",
    language: "",
    remember: true,
    link: "",
    isActive : '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();

  function matchYoutubeUrl(url) {
    //console.log("Cameeeeeeee In Here");
    var p = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
    // var p1 = /^https:\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]/;
    // const embedUrl = convertYoutubeUrlToEmbed(url);
    // var matches = embedUrl.match(p1);
    // debugger;
    var matches = url.match(p);
    if (matches) {
      // console.log("heeee");
      setValidateUrl(true);
      // setUtubeurl(embedUrl);
      return true;
    }
    return false;
  }

  function matchM3U8Url(url) {
    //console.log("Cameeeeeeee In Here");
    var p = /\.m3u8/
    var isValid = p.test(url);
    if (isValid) {
      setValidateUrl(true);
      return true;
      // .m3u8
    }
    return false;
  }
  const [utubeurl, setUtubeurl] = useState("");

  const check = () => {
    
    if( youtubeStatus != "APP"){
      var id = matchYoutubeUrl(utubeurl);
     if (id != false) {
      setValidateUrl(true);
      
     } else {
      setValidateUrl(false);
      
     }
    }else{
      // console.log("App")
      // setValidateUrl(true)
      let isM3U8 = matchM3U8Url(utubeurl);
      if( isM3U8){
        setValidateUrl(true);
      }else{
        setValidateUrl(false);
      }

      // setValidateUrl(true)
    }
  }

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };
  const [file2 , setFile2] = useState();

  const changeHandler2 = async(event) => {
    setFile2(event.target.files[0]);
  }
  const[file3 , setFile3] = useState();
  const [val, setVal] = useState(false)
  
  const changeHandler3 = (e) => {
    // console.log(e.target.files);
    setFile3(e.target.files[0])
  }
  const setUrl = async(event) =>{
    // console.log(event.target.value);
    setUtubeurl(event.target.value);
    // console.log(utubeurl);
  }
  let formatDate = (date) => {
    let datehelper = "" + date;
    datehelper = datehelper.split(" ");
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let time = datehelper[4];
    let year = datehelper[3];
    let month = `0${monthsList.indexOf(datehelper[1]) + 1}`.slice(-2);
    let day = datehelper[2];

    return `${day}-${month}-${year} ${time}`;
  };

  const onSubmit = async (data) => {
    // console.log(data);
    let StartingDate = formatDate(starting.$d);
    let EndingDate = formatDate(ending.$d);

    if (!youtubeVideo || (validateYoutubeUrl || youtubeStatus == 'TWOWAY')) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      if(file2){
        formData.append("file2" , file2);
      }
      if(file3){
        formData.append("file3" , file3);
      }
      
      formData.append("lecture_type", data.lecture_type);
      formData.append("lecture_title", data.lecture_title);
      formData.append("description", data.description);
      // formData.append("description", description);
      formData.append("language", data.language);
      if (youtubeVideo) {
        let link = utubeurl;
        if( youtubeStatus != "APP"){
          link = utubeurl ? convertYoutubeUrlToEmbed(utubeurl) : utubeurl;
        }
        // console.log(link);
        formData.append("link",link);
      } else {
        formData.append("link", "");
      }

      if (youtubeVideo) {
        formData.append("LiveOrRecorded",youtubeStatus == 'TWOWAY' ? 'Live' : data.LiveOrRecorded);
      } else {
        formData.append("LiveOrRecorded", "Live");
      }

      formData.append("subject_id", data.subject_id);
      formData.append("batch_id", Id);
      formData.append("starting_date", StartingDate);
      formData.append("ending_date", EndingDate);
      formData.append("teacher" , data.teacher);
      formData.append('isActive' , data.isActive)
      formData.append('socketUrl' , 'https://twoway-backend-prod.sdcampus.com/mediasoup');
      // formData.append("teacher" , "")
      const YtLiveToken = localStorage.getItem("YtLiveToken");

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };


      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/addLecture`,
          formData,
          config
        )
        .then((response) => {
          //console.log(response.data);
          setFetchMessage();

          if (response.data.msg === "added the Lecture details successfully") {
            // setPositive(true)
            setLoading(false);
            toast.success("Added The Lecture Details");
            reset();
            setTimeout(() => {
              navigate(`/dashboard/batchDetails/${Id}`);
            }, 1000);
          } else {
            toast.error(response.data.msg);
            // reset();
            setLoading(false);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
 
  const [liveStatus, setLiveStatus] = useState("");
  const [youtubeStatus, setYoutubeStatus] = useState("");
  const[teacherList , setTeacherList] = useState([]);
  //Getting Batch
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetailsOfBatch/${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);

      // setLoading(false)
    }
    fetchUsers();
    async function fetchTeacher() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setTeacherList(responseJson.data);

      // setLoading(false)
    }
    fetchTeacher();
  }, []);
  // //console.log(users);

  //Youtube Link
  const [youtubeVideo, setYoutubeVideo] = useState(false);

  // //console.log(utubeurl)

  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h2" ml={2}>
        Add Lecture {"  "}({newName})
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}>
        <Typography variant="h6" ml={2} mb={4}>
          Enter the below required details to create a lecture
        </Typography>
      </Stack>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          {/* <Controller
        control={control}
        defaultValue=""
        render={({ field }) => ( */}
        <Stack direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <RHFTextField
            name="lecture_type"
            label="Lecture Type"

            select
          >
            <MenuItem value="YT" onClick={() => { setYoutubeStatus("YT"); setYoutubeVideo(true); }}>YouTube</MenuItem>
            <MenuItem value="APP" onClick={() => { setYoutubeStatus("APP"); setYoutubeVideo(true); }}>App</MenuItem>
            <MenuItem value="TWOWAY" onClick={() => { setYoutubeStatus("TWOWAY"); setYoutubeVideo(true); }}>Two Way Communication</MenuItem>
          </RHFTextField>
          <RHFTextField 
              name="file3"
              onChange={changeHandler3}
              id="file3"
              // label="Lecture Banner"
              error={!file3 && val}
              helperText='Image Only ( Maximum allowed size 100KB)'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
              InputProps={{
                  classes: {
                      input: "CustomTextField"
                  }
              }}
              inputProps={{ accept: "image/*" }}
                            // type={ focus ? "file" : "text"}
              type =  'file'
          />

        </Stack>
          
 

          <RHFTextField name="lecture_title" label="Title "/>
          

          {/* <RHFTextField
            name="description"
            label="Description "
            multiline
            rows={3}
          /> */}
          <RHFTextEditor name="description" label="Description" />
          

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <DateTimePicker
                label="Starting time"
                value={starting.$d}
                onChange={handleChange}
                // name="starting_date"
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
              <DateTimePicker
                label="Ending time"
                value={ending.$d}
                onChange={handleChangeTwo}
                sx={{ color: "black" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%", color: "black" }}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack direction="row" spacing={3}>
            <RHFTextField name="language" label="Language" select>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="hi">Hindi</MenuItem>
              <MenuItem value="enhi">Bilingual</MenuItem>
            </RHFTextField>
            <RHFTextField name="subject_id" label="Subject" select>
              {users?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            
            {
              youtubeStatus != 'TWOWAY' ? ( (youtubeVideo && youtubeStatus == "APP") ? (
                <RHFTextField name="LiveOrRecorded" label="Type" select>
                  <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
                  <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem>
                </RHFTextField>
              ) : (
                <RHFTextField name="LiveOrRecorded" label="Type" select>
                  <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem>
                  <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
                </RHFTextField>
              ) ) :  (
                <></>
              )
            }
          </Stack>
          <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <RHFTextField
              id="assi"
              name="materials"
              label="Resources"
              onChange={changeHandler}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              inputProps={{ accept: "application/pdf" }}
              helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            <RHFTextField
              id="dpp"
              name="dpp"
              label="DPP"
              onChange={changeHandler2}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              inputProps={{ accept: "application/pdf" }}
              helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            
            {youtubeVideo ? (
              ( youtubeStatus != 'TWOWAY') && (<>
                {" "}
                <RHFTextField
                  name="link"
                  label="Link"
                  type="url"
                  // helperText={
                  //   !validateYoutubeUrl ? "Add Valid Youtube Link " : ""
                  // }
                  error={!validateYoutubeUrl}
                  helperText={!validateYoutubeUrl ? "Add Valid Youtube Link" : ""}
                  value={utubeurl}
                  // error={showError ? "" : 'Add Youtube Link'}
                  onChange={setUrl}
                />

              </>)
            ) :
              (<>
              </>)
            }

          </Stack>
          <Stack direction="row" spacing={2}>
                        <RHFTextField name="isActive" label="Status" type='checkbox' select>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                        <RHFTextField name="teacher" label="Teacher" select>
                           {teacherList?.map((item, index) => {
                             return (
                               <MenuItem key={index} value={item._id}>
                                 {item.FullName}
                               </MenuItem>
                             );
                           })}
                         </RHFTextField>

            </Stack>
          
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          onClick={check}
          sx={{
            bgcolor: theme.palette.primary.mainTheme,
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Add
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default LectureForm;
