import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StoreAlertTable from './StoreAlertTable';
import AddStoreAlert from './AddStoreAlert';
import getAccess from '../../../utils/getAccess';
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StoreAlert = () => {
    const d = getAccess();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem('token');
        async function fetchAlert() {
            const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getStoreAlerts`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`

                }
            });
            const responseJson = await response.json();
            console.log(responseJson);
            setAlerts(responseJson.data);
            setLoading(false);
        }
        fetchAlert();

    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%', height: 590 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
                    <Tab label="All Store Alert" sx={{ width: '100%' }} {...a11yProps(0)} />
                    {/* {(access?.access?.includes('1066') || access?.access.includes('all')) && (<Tab label="Add Store Category" sx={{ width: '100%' }} {...a11yProps(1)} />)}
                     */}
                     { (d?.accessForTab?.includes('readWrite') || d?.access?.includes('all')) &&  <Tab label="Add Store Alert" sx={{ width: '100%' }} {...a11yProps(1)} />}
                    {/* <Tab label="Item Three" sx={{ width: '100%' }} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {/* <CategoryTable /> */}
                {/* <h3>Table</h3>
                 */}
                {!loading && (<StoreAlertTable data={alerts} />)}
            </TabPanel>
            <TabPanel value={value} index={1}>
              { (d?.accessForTab?.includes('readWrite') || d?.access?.includes('all')) && <AddStoreAlert /> }
            </TabPanel>

        </Box >
    );
}

export default StoreAlert