import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CampaignIcon from '@mui/icons-material/Campaign';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getAccess from "../../../utils/getAccess";


const flattenArray = (arr) => {
    return arr.map((item, index) => `${index} -> ${(item).toString().replaceAll("," , " ")}`).join(' ');
  };

function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'admin') {
            return `${row.admin.name}(${row.admin.role})`;
          }
          else if (columnId === 'message') {
            return row?.message?.toString().replace(/[\r\n]/gm, '')
            // console.log(row?.message)
          }
          else if (columnId === 'linkWith') {
            return flattenArray(row.linkWith)
          }
          else if ( columnId === 'serialNumber'){
            return index+1
          }
          return row[columnId]?.toString()?.replaceAll(",","");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'PushNotification.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

const NotificationGrid = () => {

  const [loading , setLoading] = React.useState(false);
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const navigate = useNavigate()
  const handleEditClick = (id) => () => {
    navigate(`/dashboard/updateStoreBanner/${id}`)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  React.useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },

    }
    async function fetchNotification(){
        const notifications = await axios.get(`${process.env.REACT_APP_LIVE_URL}/notification/getAllNotification` , config);
        setRows(notifications?.data?.data ?? []);
        // console.log(notifications);
        setLoading(false)
        
    }
    fetchNotification();
    
    

  }, [])

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm('Are you sure you want to delete Notification ?');
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/notification/deleteNotification/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    };
  }

  const handleSendNotification = (id) => async () => {
    // console.log(id);
    const confirmSend  = window.confirm('Are you sure you want to Send Notification ?');
    if (confirmSend ) {
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/notification/sendNotification/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        // setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    };
  }


  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
 


  const columns = [
    {
      field: 'sNo',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "message",
      headerName: "Message",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell : (params) => {
        return (
          <div>{`${params.value.name} (${params.value.role})`}</div>
        )
      }
    },
    // {
    //   field: "route",
    //   headerName: "Notification Type",
    //   type: "string",
    //   width: 200,
    //   align: "left",
    //   headerAlign: "left",
    //   renderCell : (params) => {
    //     return (
    //       <div>{(params?.value?.charAt(0).toUpperCase() + params?.value?.substring(1))?.split(/(?=[A-Z])/).join(" ") ?? ""}</div>
    //     )
    //   }
    // },
    
    // {
    //   field: "icon",
    //   headerName: "Image",
    //   type: "string",
    //   width: 200,
    //   align: "left",
    //   headerAlign: "left",
    //   renderCell: (params) => (
    //     <img
    //       src={params.value}
    //       alt={`Image for row ${params.id}`}
    //       style={{ width: 150, height: 70 }}
    //     />
    //   ),
    // },
 
    {
      field: "link",
      headerName: "User",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "linkWith",
      headerName: "User Type",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <ol>
            {params.value.map((item , index) => {
                return (
                    <li key={index}>{item}</li>
                )
            })}
        </ol>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value ? 'Active' : 'inActive'}
        </div>
      ),

    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
          <Tooltip title="Send Notification">
            <GridActionsCellItem
              icon={<CampaignIcon style={{ color : '#1c6ae8'}} />}
              label="Send Notification"
              className="textPrimary"
              onClick={handleSendNotification(id)}
              color="inherit"
            /> 
          </Tooltip>
          : <></>),
          // ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // /> : <></>),
          ((access?.deleteAccess || access?.access.includes('all')) ? <GridActionsCellItem
            icon={<DeleteIcon style={{color : 'red'}} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          /> : <></>)
        ];
      }
    }
  ]



  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid 
        // rowHeight={50}
        rows={rows}
        columns={columns}
        loading={loading}
        getRowHeight={() => 'auto'}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}

export default NotificationGrid
