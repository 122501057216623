import {
 
  Stack, Tooltip
} from "@mui/material";
import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn'; 
import getAccess from "../../../utils/getAccess";

const LectureResourcesTable1 = ({ setLoadingTable }) => {
  const [loading, setLoading] = React.useState(false);
  const { lectId, batchId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const access = getAccess();
  // table thing
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  React.useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureResource?Lecture_id=${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setLoading(false);
      setLoadingTable(false);
      setRows(responseJson.data);
    }

    fetchUsers();
  }, []);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  //   const navigate = useNavigate()

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete Resource?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteLectureresourceDetails/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    }
  };

  const makeActiveAndInActiveLectureResource = async (id) => {

    const token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/makeActiveAndInActiveLectureResource/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    const responseJson = await response.json();
    if( responseJson?.status){
      toast.success(responseJson.msg)
      setTimeout(() => window.location.reload()
      , 1000)
    }else{
      toast.error(responseJson.msg);
    }
    

  }
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //   editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "resourceType",
      headerName: "Resource Type",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },

    {
      field: "upload_file",
      headerName: "Resource ",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            href={params.value.fileLoc}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileDownloadIcon
              style={{ width: 24, height: 24, marginRight: 5 }}
            />
          </a>
          {params.value?.resourceType === "link" ? (
            <span>{params.value?.fileLoc}</span>
          ) : (
            <span>{params.value?.fileName}</span>
          )}
        </div>
      ),
    },
    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 400,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find((item) => item.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          (access?.accessForTab?.includes("readWrite") ||
          access?.access?.includes("all") ) ? (
            <Tooltip title="Edit Resource">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() =>
                navigate(`/dashboard/updateLectureResources/${id}/${`LectureResource`}`)
              }
              color="inherit"
            />
            </Tooltip>
          ) : (
            <></>
          ),
          (access?.deleteAccess == true || access?.access?.includes("all")) ? (
            <Tooltip title="Delete" ><GridActionsCellItem
              icon={<DeleteIcon style={{ color: "red" }} />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              // onClick={ (id) => handleOpen(id)}

              color="inherit"
            />
            </Tooltip>
          ) : (
            <></>
          ),
          
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
          <Tooltip title="Change Status">
          <GridActionsCellItem
            icon={ rowData?.is_active ? <ToggleOffIcon style={{ color:'green'}}  /> : <ToggleOnIcon style={{color : 'red'}}/>}
            label="Status"
            onClick={() => { makeActiveAndInActiveLectureResource(id) }}
            color="inherit"
          />
        </Tooltip> : <></>)
        ];
      },
    },
  ];
  return (
    <>
        { loading == false ? <DataGrid
        // sx={{ overflow: "auto" }}
        // rowHeight={50}
        // getRowHeight={() => 'auto'}
        rows={rowsWithSerialNumber}
        loading={loading}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8,
            },
          },
        }}
        pageSizeOptions={[8, 25, 50]}
        slots={{
          //   toolbar: EditToolbar,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      /> : <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>}
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default LectureResourcesTable1;
