import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
    Link,
    Stack,
    Typography,
    MenuItem,
    Select,
    InputLabel,
    Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
    FormProvider,
    RHFTextField,
    RHFCheckbox,
} from "../../../components/hook-form";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const UpdateStaff = () => {
    const theme = useTheme();


    const navigate = useNavigate();
    const [users, usersSet] = React.useState([]);
    const { lectId, batchId, teacherId } = useParams();
    const [loading, setLoading] = useState(false);
    const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
    const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));
    const handleChange = (newValue) => {
        setStarting(newValue);
    };
    const handleChangeTwo = (newValue) => {
        setEnding(newValue);
    };

    const LoginSchema = Yup.object().shape({});

    const defaultValues = {};

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    const [file, setFile] = useState();

    const changeHandler = async (event) => {
        setFile(event.target.files[0]);
    };
    const animatedComponents = makeAnimated();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const handleChangeCategories = (data) => {
      setSelectedCategories(data);
    }

    //Old Details
    const [oldValue, setOldValue] = useState();
    const [teacherDetails, setTeacherDetails] = useState();
    const [role, setRole] = useState('')
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/getTeacherDetails/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTeacherDetails(responseJson.data);
            //console.log(teacherDetails)
            settingValues(responseJson.data)
            setRole(responseJson?.data?.TeacherDetails?.Role)
            check(responseJson.data)
        }

        fetchUsers();
    }, []);
    const settingValues = (data) => {
        setRole(data?.TeacherDetails?.Role)
        setOldValue((prev) => ({
            ...prev,
            FullName: data?.TeacherDetails?.FullName,
            email: data?.TeacherDetails?.email,
            // batches: data?.BatchesTeacherIn,
            Role: data?.TeacherDetails?.Role,
            password: data?.TeacherDetails?.password,
            qualification: data?.TeacherDetails?.qualification,
            is_Special: data?.TeacherDetails?.is_Special,
            mobile: data?.TeacherDetails?.mobileNumber,
            deleteAccess: data?.TeacherDetails?.deleteAccess,
            access: data?.TeacherDetails?.access,
            demoVideo : data?.TeacherDetails?.demoVideo,
            category : data?.TeacherDetails?.category,
            categories : data?.TeacherDetails?.categories,

        }));
        setSelectedCategories(data?.TeacherDetails?.categories);
    }
    //console.log(teacherDetails?.TeacherDetails)
    //console.log(oldValue)

    //Submit Function
    const onSubmit = async (data) => {
        //console.log(data)
        
        setLoading(true);
        let obj = { ...oldValue, categories :  selectedCategories?.map((item) => { return item?.value}) , ej_ective: true, batch: assignedBatches }
        // console.log('line197', obj)
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios
            .put(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/updateteacher/${teacherId}`, obj, config)
            .then((response) => {

                if (response.data.msg === "Teacher details updated successfully") {
                    reset();
                    setLoading(false);
                    toast.success("Staff Details Updated");

                    setTimeout(() => {
                        navigate(`/dashboard/staff`);
                        // window.location.reload();
                    }, 1500);
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    //Getting Batch
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);

            // setLoading(false)
        }
        fetchUsers();
    }, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setOldValue((prev) => ({ ...prev, [name]: value }));
    };


    const [batchDetails, setBatchDetails] = useState()
    const [batch, setbatch] = useState([]);
    const [batch_id, setBatchId] = useState()

    const handleChangeBatch = (event) => {
        const {
            target: { value },
        } = event;
        setbatch(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
   
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setBatchDetails(responseJson.data);
        }
        fetchUsers()
    }, [batch_id])
    // console.log('old', oldValue?.batch)

    let helperArray = []
    const [assignedBatches, setAssignedBatches] = useState()
    const check = (tabsDetails) => {
        // console.log(tabsDetails?.BatchesTeacherIn)
        helperArray = tabsDetails?.BatchesTeacherIn?.map((item, index) => item?._id)
        // console.log('helperArray', helperArray)
        setAssignedBatches(helperArray)

        // helperArray = tabsDetails?.BatchesTeacherIn?.map((item, index) => {
        //     if (assignedBatches?.includes(item?.value)) {
        //         console.log(item?.batch_name)
        //         return item?.batch_name;
        //     }

        // })
        // console.log('line218', helperArray)
    }

    // category fetch
    const[categoryList , setCategoryList] = useState([]);

    useEffect(() =>{
        async function fetchCategoryData() {
            let token = localStorage.getItem("token");
            try {
                const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategorailDetails`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                });
                const result = await res.json();
                if (!result.status) {
                    throw new Error(" categories not found");
                }
                setCategoryList(result.data);
                // console.log(result.data);
            } catch (error) {
                // 
            }
    
    
        }
        fetchCategoryData();
    },[])
    const handleChangeAssignBatch = (event) => {
        const {
            target: { value },
        } = event;
        setAssignedBatches(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    // console.log(assignedBatches)
    // console.log(role)
    return (
        <Grid direction="row" pl={5}>
            <Typography variant="h2" m={2}>
                Update Staff Details
            </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {/* <Stack direction="row" spacing={5} > */}
                <Stack spacing={3} sx={{ width: "70%" }}>
                    <Stack direction='row' sx={{width : '100%'}}>
                            <InputLabel sx={{width : '20%', marginRight:"20px", alignSelf: 'center'}}>Full Name : </InputLabel> 
                            <RHFTextField name="FullName"
                                 value={oldValue?.FullName}
                                  onChange={onChange2}
                            />
                    </Stack>
                    <Stack direction='row' sx={{width : '100%'}}>
                            <InputLabel sx={{width : '20%', marginRight:"20px", alignSelf: 'center'}}>Email : </InputLabel> 
                            <RHFTextField name="email"
                               value={oldValue?.email}
                               onChange={onChange2}
                          />
                    </Stack>
                    <Stack direction='row' sx={{width : '100%'}}>
                            <InputLabel sx={{width : '32%', marginRight:"20px", alignSelf: 'center'}}>Mobile Number : </InputLabel> 
                            <RHFTextField name="mobile" type='number'
                              value={oldValue?.mobile}
                              onChange={onChange2}
                             />
                    </Stack>
                    <Stack direction='row' sx={{width : '100%'}}>
                            <InputLabel sx={{width : '20%', marginRight:"20px", alignSelf: 'center'}}>Role : </InputLabel> 
                            <RHFTextField name="Role" label={oldValue?.Role == 'teacher' ? "Teacher" : oldValue?.Role == 'subadmin' ? "Sub-Admin" : "Moderators"} select
                                value={oldValue?.Role}
                                 onChange={onChange2}>
                                        <MenuItem value='teacher' onClick={() => setRole('teacher')}>Teacher</MenuItem>
                                        <MenuItem value="subadmin" onClick={() => setRole('subadmin')}>Sub-Admin</MenuItem>
                                         <MenuItem value="moderator" onClick={() => setRole('moderator')}>Moderators</MenuItem>
                            </RHFTextField>
                    </Stack>
                    
                    
                    

                    { (role == 'teacher' && oldValue?.Role == 'teacher') && 
                     <>
                        <Stack direction='column' spacing={2}>
                            <Stack direction='row' sx={{width : '100%'}}>
                                  <InputLabel sx={{width : '32%', marginRight:"20px", alignSelf: 'center'}}>Demo Video Url : </InputLabel> 
                                  <RHFTextField name="demoVideo" value={oldValue?.demoVideo} onChange={onChange2}  />
                            </Stack>
                            <Stack direction='row' sx={{width : '100%'}}>
                                  <InputLabel sx={{width : '32%', marginRight:"20px", alignSelf: 'center'}}>Qualification : </InputLabel> 
                                  <RHFTextField name="qualification" value={oldValue?.qualification} onChange={onChange2}  />
                            </Stack>
                            {/* <Stack direction='row' sx={{width : '100%'}}>
                                  <InputLabel sx={{width : '32%', marginRight:"20px", alignSelf: 'center'}}>Teacher Category : </InputLabel> 
                                  <RHFTextField name="category" value={oldValue?.category}  select onChange={onChange2}>
                                     {categoryList.map((item) => (
                                          <MenuItem key={item?._id} value={item?._id}>{item?.title}</MenuItem>
                                    ))}
                               </RHFTextField>
                            </Stack> */}
                        </Stack>
                            <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                                <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}> Categories</InputLabel>
                <Stack  sx={{ width : '80%'}}>
                  <ReactSelect
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={selectedCategories}
                    onChange={handleChangeCategories}
                    menuPortalTarget={document.body} 
                    placeholder='Select Categories For Teacher'
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    isMulti
                    options={categoryList}
                  />
                </Stack>
            </Stack>
                </>
                }
                 
                    {(role == 'moderator' || oldValue?.Role == 'moderator') && < Stack direction="row">
                        <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Batch</InputLabel>
                        <Select
                            label=""
                            multiple
                            name='assignedBatches'
                            value={assignedBatches}
                            onChange={handleChangeAssignBatch}

                            sx={{ width: '100%', color: 'black', zIndex: 9 }}
                            MenuProps={MenuProps}
                        >

                            {batchDetails?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item._id}
                                    onClick={() => setBatchId(item?._id)}
                                >
                                    {item?.batch_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>}

                    {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '90px', alignItems: 'center' }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Access Level</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="female" onClick={() => setOldValue((prev) => ({ ...prev, access: 'read' }))} checked={oldValue?.access == 'read'} control={<Radio />} label="Read Only" />
                            <FormControlLabel value="male" onClick={() => setOldValue((prev) => ({ ...prev, access: 'readWrite' }))} checked={oldValue?.access == 'readWrite'} control={<Radio />} label="Read & Write " />
                            <FormControlLabel value="other" onClick={() => setOldValue((prev) => ({ ...prev, deleteAccess: !oldValue?.deleteAccess }))} checked={oldValue?.deleteAccess} control={<Checkbox {...label} />} label="Delete Access" />
                            <FormControlLabel
                                value="disabled"
                                disabled
                                control={<Radio />}
                                label="other"
                            />
                        </RadioGroup>
                    </FormControl>
                    <RHFTextField name='is_Special' label={oldValue?.is_Special ? 'Yes' : 'NO'} select
                        value={oldValue?.is_Special}
                        onChange={onChange2}>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </RHFTextField> */}

                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                        bgcolor: theme.palette.primary.mainTheme,
                        width: "70%",
                        marginTop: "5%",
                        "&:hover": { background: "red" },
                    }}
                >
                    Update
                </LoadingButton>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Grid>
    );
};

export default UpdateStaff;