import React, { useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Divider } from '@mui/material';
import './ProductProfilePage.css'
import { useParams } from 'react-router-dom';

const ProductProfilePage = () => {
    const [details, setDetails] = React.useState()

    const { id } = useParams();

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/store/getStoreProductById/${id}`,
                // `${process.env.REACT_APP_LIVE_URL}/store/getProductCategoryByIdAdmin/${editData?.id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            // console.log('line91', responseJson)
            setDetails(responseJson?.data);
        }
        fetchUsers()
    }, [])
    // consol.e.log(details)
    return (
        <div className='product_parent'>

            <h2 style={{ marginBottom: '20px' }}>Product Detials of {details?.title}</h2>
            <Card sx={{}}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="300"
                        image={details ? details?.featuredImage : ''}
                        alt="green iguana"
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Name:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.title}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Category:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.categories?.map((item) => { return ( <p>{item?.label}</p>)})}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Description:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <div dangerouslySetInnerHTML={{ __html: details?.desc }}></div>
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Key Feature:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {/* {details?.metaDesc ? details?.metaDesc : 'NA'}
                                 */}
                                 <div dangerouslySetInnerHTML={{ __html: details?.keyFeature }}></div>
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Marketing Category:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.marketingCat}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Publication:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.publication ? details?.publication : 'NA'}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Meta Title:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.metaTitle ? details?.metaTitle : 'NA'}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Meta Description:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {/* {details?.metaDesc ? details?.metaDesc : 'NA'}
                                 */}
                                 <div dangerouslySetInnerHTML={{ __html: details?.metaDesc }}></div>
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Delivery Type:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.deliveryType}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Max Allowed Coins:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.maxAllowedCoins}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Max Purchase Quantity:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.maxPurchaseQty}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Language:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.language == 'hi' ? 'Hindi' : details?.language == 'en' ? "English" : 'English/Hindi'}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Product Type:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.productType}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Sale Price:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.salePrice}
                            </Typography>
                        </div>  <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Regular Price:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.regularPrice}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Stock:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.inStock}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Sale Expire Date :
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.saleExpire}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Images:
                        </Typography>
                            <div style={{ overflow: 'scroll', display: 'flex', gap: '20px ' }}>
                                {details?.images?.map((item, index) => <img src={item} style={{ width: 200, height: 200 }} />)}
                            </div>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

export default ProductProfilePage; 