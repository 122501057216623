import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

export default function AddSubject() {


    const theme=useTheme()


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const[val , setVal] = useState(false);
    const[msg , setMsg] = useState("")
    const[focus , setFocused] = useState(false);
    const[restrict , setRestrict] = useState('');
    const [file , setFile] = useState();
    const [pdf , setPdf] = useState(false);
    const [progress, setProgress] = useState();

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        is_active: Yup.boolean().required('Status required'),
    });

    const defaultValues = {
        title: '',
        is_active: ''
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        setLoading(true)
        let token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("title" , data.title);
        formData.append("is_active" , data.is_active);
        if( file){
            formData.append('file' , file);
          }
          const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
      };
        axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addSubject`, formData, config).then((response) => {
            //console.log(response.data);
            if (response.data.msg === 'The Subject Details added successfully') {
              toast.success(response.data.msg);
              setLoading(false);
              setPdf(false);
              reset();
            //   navigate('/dashboard', { replaced: true })
              window.location.href = "/dashboard/subject";
            }else {
              toast.error(response.data.msg);
              setLoading(false);
            }
    
          })
            .catch(e => {
              //console.log(e);
            })
      
    };
    const changeHandler = async (event) => {
    
        //console.log(file)
        let size = event.target.files[0].size / ( 1024 );
            if( size > 100){
                setVal(true);
                setFile(event.target.files[0])
                // setMsg("Maximum banner size 1MB allowed")
            }else{
                setFile(event.target.files[0])
                var fileName = document.getElementById('file').value.toLowerCase();
                  if (fileName.endsWith('.jpg')) { setRestrict('') }
                 else { setRestrict('JPG') }
            }
    
      };

    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Subject</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title" label="Title" />
                    <RHFTextField name="file"
                       onChange={changeHandler}
                        id="file"
                       label="File"
                        // error={!file && val}
                        helperText='Image Only (Maximum 100KB allowed)'
                        onFocus={() => setFocused(true)}
                       onBlur={() => setFocused(false)}
              // inputProps={{ multiple: true }}
                        InputProps={{
                          classes: {
                             input: "CustomTextField"
                          }
                         }}

                        onClick={() => setPdf(true)}
                         inputProps={{ accept: "image/*" }}
                         type={pdf || focus ? "file" : "text"}

                       />

                    <RHFTextField name="is_active" label="Status" type='checkbox' select>
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                    </RHFTextField>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                          setVal(true)
                          setMsg('Required')}}
                        >
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </Stack>
    </>
    );
}
