import * as Yup from "yup";
import { useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, TextField ,Select , InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import Async from "react-select/async";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

export default function StoreReview() {

  const theme = useTheme()
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Review Title is required"),
    rating: Yup.string().required("Rating is required"),
  });

  const defaultValues = {
    rating : 0 ,
    title : "" , 
    user : "" ,
    products : [] ,
    description : '' ,
  };
 
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const[student , setStudent] = useState();
    const handleChangeValue = (data) => {
      // console.log("66" , data);
        setStudent(data);
      }
      const callApi  = async (text) => {
        // console.log(text);
        const token = localStorage.getItem("token");
        const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
        );
        const responseJson = await fullResponse.json();
        return responseJson?.data;
              
        // }
        // fetchDetails();
      }
      const promiseOptions = (inputValue) =>{
         return new Promise((resolve) => {
           setTimeout(() => {
            resolve(callApi(inputValue));
             }, 100);
        })
      };

      const[products , setProducts] = useState('');
    const handleChangeValueForProducts = (data) => {
      // console.log("66" , data);
        setProducts(data);
      }
      const callApiForProducts  = async (text) => {
        // console.log(text);
        const token = localStorage.getItem("token");
        const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProduct?text=${text}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
        );
        const responseJson = await fullResponse.json();
        return responseJson?.data;
              
        // }
        // fetchDetails();
      }
      const promiseOptionsForProducts = (inputValue) =>{
         return new Promise((resolve) => {
           setTimeout(() => {
            resolve(callApiForProducts(inputValue));
             }, 100);
        })
      };

  const onSubmit = async (data) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const reqObject = {
      ...data,
      user :  student?.value ,
      products :  products?.map((item) => item?.value) , 
      title : data?.title , 
      rating : data?.rating , 
      description : data?.description
      // selectedList ,
      // expirationDate: starting?.$d,
    };
    // console.log(selectedList);
    console.log(reqObject)
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/store/addReviewFormAdmin`,
        reqObject,
        config
      )
      .then((response) => {
        if (response.data.status === true) {
          toast.success(response.data.msg);
          
          setLoading(false);
          setProducts([]);
          setStudent('');
          reset();
        } else {
          toast.error(response.data.msg);
          setLoading(false)
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };



  //console.log(starting.$d);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Review </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            {/* <Stack direction='row' spacing={3}> */}
                  <Async 
                   components={animatedComponents}
                   menuPortalTarget={document.body}
                   onChange={handleChangeValue}
                   value= { student}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    loadOptions={promiseOptions}
                    placeholder={'Select The Students'}

                   />
            {/* </Stack> */}
            {/* <Stack direction='row' spacing={3}> */}
                  <Async 
                     components={animatedComponents}
                     menuPortalTarget={document.body}
                     onChange={handleChangeValueForProducts}
                     value= { products}
                     styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                     loadOptions={promiseOptionsForProducts}
                     isMulti 
                     placeholder={'Select The Products'}

                   />
            {/* </Stack> */}
            <Stack direction='row' spacing={3}>
                <RHFTextField name='title' label='Review title' />
            </Stack>
            <Stack direction='row' spacing={3}>
                <RHFTextField name='description' label='Review Description' />
            </Stack>
            <Stack direction='row' spacing={3}>
              <RHFTextField name="rating" label="Rating" select>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
              </RHFTextField>

            </Stack>
            
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              // onClick={() => {
              //   setVal(true);
              //   setMsg("Required");
              // }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
