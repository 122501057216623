import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";



function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'admin') {
            return `${row.admin.FullName}(${row.admin.Role})`;
          }
          else if (columnId === 'category') {
            return row?.category?.title ?? ""
          }
          else if ( columnId === 'sno'){
            return index+1
          }
          return row[columnId]?.toString()?.replaceAll(",","");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'SubCategory.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function SubCategoryGrid() {

  const [rows, setRows] = React.useState([]);
  const [loading , setLoading] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});

  React.useEffect(() => {
    let token = localStorage.getItem('token');
    let config = {
        method : 'GET',
        headers : {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${token}`
            
        }
    }
    setLoading(true);
    async function fetchCategories(){
        const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubCategorialDetails` , config)
        const responseJson = await response.json();
        setRows(responseJson.data ?? []);
        setLoading(false);
    }
    fetchCategories();
  }, [])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const access = getAccess();

  const navigate = useNavigate()

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm('Are you sure you want to delete this Subject?');
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/DeleteSubCategorailDetails/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
    };
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const columns = [
    {
      field: 'sno',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true
      renderCell : (params) => {
        return (
          <div>{`${params.value.FullName} (${params.value.Role})`}</div>
        )
      }
    },
    {
        field: "category",
        headerName: "Parent Category",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true
        renderCell : (params) => {
          return (
            <div>{`${params.value.title}`}</div>
          )
        }
      },
    
   
    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value == true ? 'Active' : 'inActive'}
        </div>
      ),

    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "Date",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => {
        // console.log('value', params)
        return <div>{params.value}</div>
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find((item) => item?.id == id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          (((access?.accessForTab == 'readWrite' && access?.access.includes('107')) || access?.access.includes('all')) ?
          <Tooltip title="Edit ">
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            // onClick={handleEditClick(id)}
            onClick={()=> navigate(`/dashboard/updateSubCategory/${id}`)}
            color="inherit"
          />
          </Tooltip> : <></>),
          ((access?.deleteAccess || access?.access.includes('all')) ? 
          <Tooltip title="Delete">
          <GridActionsCellItem
            icon={<DeleteIcon style={{color : 'red'}}/>}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          /> 
          </Tooltip>: <></>),
        ];
      }
    }
  ]



  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        // rowHeight={50}
        getRowHeight={() => 'auto'}
        // getEstimatedRowHeight={() => 50}
        rows={rows}
        loading={loading}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[15, 30, 50]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true }
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
