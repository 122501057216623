import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
    Stack, Typography, MenuItem,
    InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@emotion/react';
import AsyncSelect from 'react-select/async';


// ----------------------------------------------------------------------

export default function AddMultipleLectureResource() {

    const theme = useTheme();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [msg, setMsg] = useState(false)
    const [val, setVal] = useState(false)
    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        resurce_type: Yup.string().required('Type is required'),
        is_active: Yup.boolean().required('Status Required'),
        language: Yup.string().required('Language is required'),
    });

    const defaultValues = {
        title: '',
        resurce_type: '',
        // file_url: '',
        is_active: '',
        language: '',
    };
    const [file, setFile] = useState()
    //role
    const role = localStorage.getItem('role')

    const changeHandler = async (event) => {
        if (!type) { setFile(event.target.files[0]) }
        else {
            setFile(event.target.value)
        }
        // setFile(event.target.files[0])
        setFileSelected(true)

    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        setLoading(true)
        const formData = new FormData();
        if (!type) {
            formData.append('resurce_type', typeName);
            formData.append('file', file)
        }
        if (type) {
            formData.append('resurce_type', typeName);
            formData.append('link', file)

        }
        formData.append('lectures', "");
        formData.append('lectures', "");
        selectedLectures.map((item) => formData.append('lectures', item?.value));
        formData.append('title', data.title);

        formData.append('is_Verified', true)



        formData.append('language', data.language);
        formData.append('is_active', data.is_active);

        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            },
        };

        axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/postLectureResourceForMultipleBatches`, formData, config).then((response) => {

            if (response.data.status) {
                toast.success('Resources added in given lectures')
                setLoading(false);
                setTimeout(() => {
                    window.location.reload()
                }, 200);
            } else {
                toast.error("Resources not added");
                setLoading(false);
            }

        })
            .catch(e => {
                //console.log(e);
            })

    };
    const [type, setType] = useState(false);
    const [typeName, setTypeName] = useState('');
    const [selectedLectures, setSelectedLectures] = useState([])

  const[lectureList , setLectureList] =  useState([]);

  const handleChangeLecture = (newData) => {
    setSelectedLectures(newData);
  }

  const fetchLecture = async (inputValue) => {
    const token = localStorage.getItem("token");
    const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminSection/fetchLectures?text=${inputValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setLectureList(responseJson.data?.lectures);
      return responseJson.data?.lectures;
  }
  const loadOptions = ( inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchLecture(inputValue));
      }, 1000);
    })
  }
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Multiple Resource</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title" label="Title" />
                    <Stack direction="row" spacing={3}>

                        <Stack direction='row' width="100%">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Select Lectures</InputLabel>
                            <Stack direction='column' sx={{ width: '100%' }}>
                            <AsyncSelect 
                               cacheOptions 
                               closeMenuOnSelect={false}
                               menuPortalTarget={document.body}
                               styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                               isMulti={true}
                               value={selectedLectures}
                               loadOptions={loadOptions}
                               onChange={handleChangeLecture}
                               defaultOptions={lectureList}
                            />                  
                            </Stack>


                        </Stack>


                    </Stack>

                    <RHFTextField name="resurce_type" label="Resource type" select>
                        <MenuItem onClick={() => {
                            setType(true)
                            setTypeName('yt_videos')
                        }} value='yt_videos' defaultValue="yt_videos">Youtube Link</MenuItem>
                        <MenuItem onClick={() => {
                            setType(true)
                            setTypeName('link')
                        }} value='link'>Link</MenuItem>
                        <MenuItem onClick={() => {
                            setType(false)
                            setTypeName('video')
                        }} value="video">Video</MenuItem>
                        <MenuItem onClick={() => {
                            setType(false)
                            setTypeName('pdf')
                        }} value="pdf">PDF</MenuItem>
                        <MenuItem onClick={() => {
                            setType(false)
                            setTypeName('DPP')
                        }} value="DPP">DPP</MenuItem>
                    </RHFTextField>

                    <Stack direction="row" spacing={3}>

                        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
                            <RHFTextField name="file"
                                onChange={changeHandler}
                                sx={{ width: '100%' }}
                                error={!fileSelected && val}
                                label="Resource"
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                inputProps={{ accept: (typeName == 'pdf' || typeName == 'DPP') ? "application/pdf" : "video/*" }}
                                helperText={type ? typeName == 'link' ? "Links Only" : "Youtube Videos link" : (typeName == 'pdf' || typeName == 'DPP') ? "Pdf Files Only" : "Video Files Only"}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}

                                onClick={() => setPdf(true)}
                                type={pdf || focus ? type ? "url" : "file" : "text"}
                            />
                            {!fileSelected && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                        </Stack>
                        <RHFTextField name="language" label="Language" select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                            <MenuItem value="enhi">Bilingual</MenuItem>
                        </RHFTextField>
                        <RHFTextField name="is_active" label="Status" select>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>



                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme, } }}
                        onClick={() => {
                            setMsg('Required')
                            setVal(true)
                        }}>
                        Add
                    </LoadingButton>
                </Stack>
                {/* <Typography variant='h4'>{fetchMessage}</Typography> */}
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
