import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './Draggable.css';
import { useEffect } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';


function DraggableComponent({ modalData, item, handleClose, fetchProduct }) {
    console.log(modalData)
    const [characters, updateCharacters] = useState();
    const [changedList, setChangedList] = useState();
    let helper = []
    let final = []
    useEffect(() => {
        helper = modalData?.map((item, index) => { return { id: `quinn${item}${index}`, thumb: item } })
        updateCharacters(helper)
        console.log(helper)
    }
        , [])
    function handleOnDragEnd(result) {

        if (!result.destination) return;

        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateCharacters(items);
        console.log('items', items)
        final = items?.map((item, index) => { return item?.thumb })
        console.log('final with helper', final)
        setChangedList(final)

    }

    const onSubmit = async (data) => {


        const formData = new FormData();
        // setLoading(true)

        for (let i = 0; i < changedList?.length; i++) {
            formData.append('images', changedList[i]);

        }

        // console.log(formData)

        // formData.append("icon", file);
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                // setProgress(Math.round((100 * data.loaded) / data.total))
            },
        };
        // document.querySelector(".mainDiv").style.display = "block";
        // setProgress(0);
        axios.post(`${process.env.REACT_APP_LIVE_URL}/store/changeImageOrder/${item?.id}`, formData, config).then((response) => {
            handleClose()
            toast.success('Updated')
            // fetchProduct()
            // window.location.reload()
            //console.log(response.data);
            // if (response.data.msg === 'Product Details added') {
            //     setProductId(response?.data?.data?._id)
            //     toast.success(response.data.msg);
            //     setLoading(false);
            //     setPdf(false);
            //     reset();
            //     setValue(value + 1);
            //     setSubmit4(true);
            //     // window.location.href = "/dashboard/storeAllProducts";
            // }

        })
            .catch(e => {
                //console.log(e);
            })

        // } else {

        //     setRestrict('Please Select pdf or jpg')
        // }
    };

    return (
        <div className="App" style={{display:"flex"}} >
            <header className="App-header">
                <h3>Drag images to change the order of the images</h3>
                <DragDropContext onDragEnd={handleOnDragEnd} >
                    <Droppable droppableId="characters" >
                        {(provided) => (
                            <ul className="characters" {...provided.droppableProps} ref={provided.innerRef} style={{overflowY : 'auto', height:"350px" }}>
                                {characters?.map(({ id, thumb }, index) => {
                                    return (
                                        <Draggable key={id} draggableId={id} index={index} >
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                    <div className="characters-thumb">
                                                        <img src={thumb} alt='Thumb' />
                                                    </div>

                                                </li>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                    <Button onClick={onSubmit}>Update</Button>
                </DragDropContext>

            </header>

        </div>
    );
}

export default DraggableComponent;