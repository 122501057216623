import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";


// ----------------------------------------------------------------------

export default function AddScholarshipTest() {
  const theme = useTheme()

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [restrict, setRestrict] = useState('')
//   const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [focus, setFocused] = useState(false);
  const [startingAt, setStartingAt] = useState(dayjs());
  const [registrationEndAt, setRegistrationEndAt] = useState(dayjs());
  const [resultDeclaration, setResultDeclaration] = useState(dayjs());

  
  const [list, setList] = useState([]);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    duration: Yup.string().required('Duration required'),
    // startingAt : Yup.string()

    quizId: Yup.string().required('Quiz is required'),
    isActive: Yup.boolean().required('Status Required'),

    // banner_url: Yup.string().required('Url No. is required'),
    // language: Yup.string().required('Language is required'),
    // isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title: '',
    description:'',
    duration:'',
    
  };
  const [file, setFile] = useState()



  const handleChange1 = (value) => {
    // console.log(`${value}`);
    setStartingAt(value);
  };
  const handleChange2 = (value) => {
    // console.log(`${value}`);
    setRegistrationEndAt(value)
  };
  const handleChange3 = (value) => {
    // console.log(`${value}`);\
    setResultDeclaration(value);
  };
  const changeHandler = async (event) => {
    setFile(event.target.files)
    var fileName = document.getElementById('file').value.toLowerCase();
    if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
    else { setRestrict('JPG Or PDF') }
    //console.log(file)

  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    // //console.log(data)
    // var fileName = document.getElementById('file').value.toLowerCase();

    // if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) {


    // setRestrict('')
    // let myfile = [...file]
    if (file) {
      const formData = new FormData();

      setLoading(true)
      // debugger;
      //console.log(file)
      Object.values(file).forEach(file => {
        formData.append("file", file);
      })
      formData.append('title', data.title);
      formData.append('duration', data.duration);
      formData.append('description', data.description);
      formData.append('quizId', data.quizId);
      formData.append("link", data.link);
      formData.append('startingAt', startingAt?.$d);
      formData.append('resultDeclaration', resultDeclaration?.$d);
      formData.append("registrationEndAt" , registrationEndAt?.$d);
      formData.append('isActive' , data.isActive)
      // formData.append('fileName', file.name);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
      };
      // document.querySelector(".mainDiv").style.display = "block";
      for( let Pair of formData){
        console.log(Pair[0] + " " +Pair[1]);
      }
      setProgress(0);
      axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/createScholarshipTest`, formData, config).then((response) => {
        //console.log(response.data);
        if (response.data.msg === 'New Scholar ship Test added') {
          toast.success(response.data.msg);
          setLoading(false);
          setPdf(false);
          reset();
          // window.location.href = "/dashboard/";
          window.location.reload();
        }

      })
        .catch(e => {
          //console.log(e);
        })
    }
    // } else {

    //     setRestrict('Please Select pdf or jpg')
    // }
  };

  //get batch or get testSeries
  useEffect(() => {
    
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizes?isActive=${true}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson.data);

      }
      fetchDetails();
    
    setLoading(false);
  }, []);
  
  return (<>
    <Stack spacing={3} px={5} >
      <Typography variant='h3'>Add Scholarship Test</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>


          <RHFTextField name="title" label="Title" />
          <Stack direction="row" spacing={3}>
            <RHFTextField name="file"
              onChange={changeHandler}
              id="file"
              label="Banner"
              error={!file && val}
              helperText='Image Only'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}

              onClick={() => setPdf(true)}
              inputProps={{ accept: "image/*" }}
              type={pdf || focus ? "file" : "text"}

            />
            {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
            <RHFTextField
                name="duration"
                label="Duration (in Min)"
                type="number"
              />
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={3}>
                  <DateTimePicker
                    label="Starting At"
                    value={startingAt.$d}
                    onChange={handleChange1}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                  <DateTimePicker
                    label="Registration End At"
                    value={registrationEndAt.$d}
                    onChange={handleChange2}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                            
          </Stack>
          </LocalizationProvider>
          


          <Stack direction="row" spacing={3}>
            <RHFTextField name="quizId" label="Quiz" select>
                            {list?.map((item, i) => { return <MenuItem key={i} value={item._id}>{item.quiz_title}</MenuItem> })}
                        </RHFTextField>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Result Declaration"
                          value={resultDeclaration.$d}
                          onChange={handleChange3}
                    // name="starting_date"
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                        </LocalizationProvider>
          </Stack>
          <RHFTextEditor name="description" label="Description" />
          <Stack direction="row" spacing={3}>
            <RHFTextField name="isActive" label="Status" type='checkbox' select >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>

          {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
              setMsg('Required')
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
    </Stack>
  </>
  );
}
