import * as React from "react";
import Box from "@mui/material/Box";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";
import SourceIcon from '@mui/icons-material/Source';


function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'admin') {
            return `${row.admin.name}(${row.admin.role})`;
          }
          else if (columnId === 'folder') {
            return row.folder.key
          }
          
          return row[columnId];
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'BucketFolder.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function BucketTable() {
  const access = getAccess();
  const navigate = useNavigate();
  const [rows , setRows] = React.useState([
    {
        'id': 1,
       "sNo":"1",
       "folder" : {
         key : "import" ,
         headerName : "InVoice"
       }
    } ,
    {
      'id': 100,
     "sNo":"1",
     "folder" : {
       key : "invoice" ,
       headerName : "InVoice 2"
     }
  } ,
    {
        'id': 2 ,
        "sNo":"2",
        "folder" :{ 
            key : "AIR" ,
            headerName : "All India Radio"
        }
     } ,
     {
        'id': 3 ,
        "sNo":"3",
        "folder" :{ 
            key : "apk" ,
            headerName : "APK"
        }
     } ,
     {
        'id': 4 ,
        "sNo":"4",
        "folder" :{ 
            key : "assets" ,
            headerName : "Assets"
        }
     } ,
     {
        'id': 5 ,
        "sNo":"5",
        "folder" :{ 
            key : "assignments" ,
            headerName : "Assignments"
        }
     } ,
     {
        'id': 6 ,
        "sNo":"6",
        "folder" :{ 
            key : "Attempted" ,
            headerName : "Attempted"
        }
     } ,
     {
        'id': 7 ,
        "sNo":"7",
        "folder" :{ 
            key : "Banner" ,
            headerName : "Banner"
        }
     } ,
     {
        'id': 8 ,
        "sNo":"8",
        "folder" :{ 
            key : "Batches" ,
            headerName : "Batches"
        }
     }  ,
     {
        'id': 9 ,
        "sNo":"9",
        "folder" :{ 
            key : "blog" ,
            headerName : "Blog"
        }
     } ,
     {
        'id': 10 ,
        "sNo":"10",
        "folder" :{ 
            key : "blogDesc" ,
            headerName : "Blog Desccription"
        }
     } ,
     {
        'id': 11 ,
        "sNo":"11",
        "folder" :{ 
            key : "category" ,
            headerName : "Category"
        }
     } ,
     {
        'id': 12 ,
        "sNo":"12",
        "folder" :{ 
            key : "category" ,
            headerName : "Category"
        }
     } ,
     {
        'id': 13 ,
        "sNo":"13",
        "folder" :{ 
            key : "cmsPost" ,
            headerName : "CMS Post"
        }
     } ,
     {
        'id': 14,
        "sNo":"14",
        "folder" :{ 
            key : "CourseOrder" ,
            headerName : "Course Order"
        }
     } ,
     {
        'id': 15,
        "sNo":"15",
        "folder" :{ 
            key : "CourseOrder" ,
            headerName : "Course Order"
        }
     } ,
     {
        'id': 16,
        "sNo":"16",
        "folder" :{ 
            key : "courseOrderInvoice" ,
            headerName : "Course Order Invoice"
        }
     } ,
     {
        'id': 17,
        "sNo":"17",
        "folder" :{ 
            key : "ctaBanner" ,
            headerName : "CTA Banner"
        }
     } ,
     {
        'id': 18,
        "sNo":"18",
        "folder" :{ 
            key : "data" ,
            headerName : "Data"
        }
     } ,
     {
        'id': 19,
        "sNo":"19",
        "folder" :{ 
            key : "fonts" ,
            headerName : "Fonts"
        }
     } ,
     {
        'id': 20,
        "sNo":"20",
        "folder" :{ 
            key : "invoice" ,
            headerName : "Invoice"
        }
     } ,
     {
        'id': 21,
        "sNo":"21",
        "folder" :{ 
            key : "Lecture" ,
            headerName : "Lecture"
        }
     } ,
     {
        'id': 22,
        "sNo":"22",
        "folder" :{ 
            key : "lectureBanner" ,
            headerName : "Letcure Banner"
        }
     } ,
     {
        'id': 23,
        "sNo":"23",
        "folder" :{ 
            key : "lectureResource" ,
            headerName : "Lecture Resource"
        }
     } ,
     {
        'id': 24,
        "sNo":"24",
        "folder" :{ 
            key : "marketing" ,
            headerName : "Marketing"
        }
     } ,
     {
        'id': 25,
        "sNo":"25",
        "folder" :{ 
            key : "NewsClip" ,
            headerName : "News Clip"
        }
     } ,
     {
        'id': 26,
        "sNo":"26",
        "folder" :{ 
            key : "Notes" ,
            headerName : "Notes"
        }
     } ,
     {
        'id': 27,
        "sNo":"27",
        "folder" :{ 
            key : "notification" ,
            headerName : "Notification"
        }
     } ,
     {
        'id': 28,
        "sNo":"28",
        "folder" :{ 
            key : "pdf" ,
            headerName : "PDF"
        }
     } ,
     {
        'id': 29,
        "sNo":"29",
        "folder" :{ 
            key : "PreviousYearQuestionPapers" ,
            headerName : "Previous Year Question Papers"
        }
     } ,

     {
        'id': 30,
        "sNo":"30",
        "folder" :{ 
            key : "productBanner" ,
            headerName : "Product Banner"
        }
     } ,
     {
        'id': 31,
        "sNo":"31",
        "folder" :{ 
            key : "productCategory" ,
            headerName : "Product Category"
        }
     } ,
     {
        'id': 32,
        "sNo":"32",
        "folder" :{ 
            key : "ProductImage" ,
            headerName : "Product Image"
        }
     } ,
     {
        'id': 33,
        "sNo":"33",
        "folder" :{ 
            key : "PYQ" ,
            headerName : "Previous Year Question Papers"
        }
     } ,
     {
        'id': 34,
        "sNo":"34",
        "folder" :{ 
            key : "Quiz" ,
            headerName : "Quiz"
        }
     } ,
     {
        'id': 35,
        "sNo":"35",
        "folder" :{ 
            key : "RecordedVideo" ,
            headerName : "Recorded Video"
        }
     } ,
     {
        'id': 36,
        "sNo":"36",
        "folder" :{ 
            key : "Resources" ,
            headerName : "Resourcess"
        }
     } ,
     {
        'id': 37,
        "sNo":"37",
        "folder" :{ 
            key : "ScholarShipTest" ,
            headerName : "Scholar Ship Test"
        }
     } ,
     {
        'id': 38,
        "sNo":"38",
        "folder" :{ 
            key : "staff" ,
            headerName : "Staff"
        }
     } ,
     {
        'id': 39,
        "sNo":"39",
        "folder" :{ 
            key : "storeBanner" ,
            headerName : "Store Banner"
        }
     } ,
     {
        'id': 40,
        "sNo":"40",
        "folder" :{ 
            key : "storeFeatureVideos" ,
            headerName : "Store Feature Videos"
        }
     } ,
     {
        'id': 41,
        "sNo":"41",
        "folder" :{ 
            key : "storeJSON" ,
            headerName : "Store Json"
        }
     } ,
     {
        'id': 42,
        "sNo":"42",
        "folder" :{ 
            key : "storeProduct" ,
            headerName : "Store Product"
        }
     } ,
     {
        'id': 43,
        "sNo":"43",
        "folder" :{ 
            key : "StoreProduct" ,
            headerName : "Store Product 1"
        }
     } ,
     {
        'id': 44,
        "sNo":"44",
        "folder" :{ 
            key : "stream" ,
            headerName : "Stream"
        }
     } ,
     {
        'id': 45,
        "sNo":"45",
        "folder" :{ 
            key : "subject" ,
            headerName : "Subject"
        }
     } ,
     {
        'id': 46,
        "sNo":"46",
        "folder" :{ 
            key : "TestForTestSeries" ,
            headerName : "Test For Test Series"
        }
     } ,
     {
        'id': 47,
        "sNo":"47",
        "folder" :{ 
            key : "TestSeries" ,
            headerName : "Test Series"
        }
     } ,
]) ;
  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'sNo',
      headerName: 'S NO',
      width: 225,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
    
    {
      field: "folder",
      headerName: "Folder",
      type: "object",
      width: 450,
      align: "left",
      headerAlign: "left",
      renderCell : (params) => {
        return (
          <div>{`${params.value.headerName}`}</div>
        )
      },
      getApplyQuickFilterFn : (value) => {
        return ( params ) => {
          return ( params?.value?.key?.includes(value)  || params?.value?.headerName?.includes(value) )

        }
        // return ( params.value.name.includes(value) )
      }
    },
    
    
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find(item => item.id === id);
        // console.log(rowData?.folder?.key)
        return [
          
          ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all'))  ? <Tooltip title="See Delete Details" arrow>
          <GridActionsCellItem
            icon={<SourceIcon style={{ color: 'red' }} />}
            label="View"
            onClick={() => {console.log(rowData?.folder?.key);navigate(`/dashboard/bucketFolder/${rowData?.folder?.key}`) }}
            color="inherit"
          />
        </Tooltip> : <></>) 
          
          
        ];
      }
    }
    
  ]

  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <h2 style={{ margin : '20px'}}>All Bucket Folder</h2>
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
