import React, { useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Divider } from '@mui/material';
// import './ProductProfilePage.css'
import { useParams } from 'react-router-dom';


const BlogProfile = () => {
    
    const [details, setDetails] = React.useState()

    const { id } = useParams();

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBlogById/${id}`,
                // `${process.env.REACT_APP_LIVE_URL}/store/getProductCategoryByIdAdmin/${editData?.id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            // console.log('line91', responseJson)
            setDetails(responseJson?.data);
        }
        fetchUsers()
    }, [])
    // consol.e.log(details)
    return (
        <div className='product_parent'>

            <h2 style={{ marginBottom: '20px' }}>Blog Details of {details?.title}</h2>
            <Card sx={{}}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="300"
                        image={details ? details?.featuredImage : ''}
                        alt="green iguana"
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Title:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.title}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Excerpt Title:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                               {details?.excerptTitle}
                                {/* <div dangerouslySetInnerHTML={{ __html: details?.desc }}></div> */}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Category:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.category?.title}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            {`Description:    `}
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <div dangerouslySetInnerHTML={{ __html: details?.desc }}></div>
                                {/* <ReactMarkdown>{details?.desc}</ReactMarkdown> */}
                                {/* <Markdown>{details?.desc}</Markdown> */}
                                {/* <ReactMarkdown escapeHtml={false}>{details?.desc}</ReactMarkdown> */}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Meta Title:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.metaTitle}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Meta Description:  
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {/* {details?.marketingCat}
                                 */}
                                 <div dangerouslySetInnerHTML={{ __html: details?.metaDesc }}></div>
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Author:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.author?.FullName}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Language:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.language == 'hi' ? 'Hindi' : details?.language == 'en' ? "English" : 'English/Hindi'}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Status:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.isActive == true ? 'Active' : "InActive"}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Created At:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.createdAt}
                            </Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', alignItems: 'center' }}><Typography variant="h5" mr={2}>
                            Updated At:
                        </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {details?.updatedAt}
                            </Typography>
                        </div>
                        <Divider />
                       
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

export default BlogProfile; 