import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './fourth.css'
// form
import { Controller, useForm  } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem , Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import { MuiChipsInput } from 'mui-chips-input';

// ----------------------------------------------------------------------

export default function AddProductFourth({ setShowForm, setProductId, productId, value, setValue, title, setSubmit4 }) {
    const theme = useTheme()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const [videoFiles , setVideoFiles] = useState([]);
    const [videos , setVideos] = useState([]);
    const[type , setType] = useState("");
    const [ videoUrls , setVideoUrls] = useState([]);
    const handleVideoUrls = (data) => {
        setVideoUrls(data);
    }
    const handleChangeType = (type) => {
        setType(type);
        if( type == 'link'){
            setVideoFiles([]);
            setVideos([])
        }
        if( type == 'Video'){
            setVideoUrls([]);
        
        }
    }
    const handleVideoChange = (event , index ) =>{
        // console.log(event.target.files);
        if (typeof index !== 'undefined') {
            const updatedFiles = [...videoFiles];
            updatedFiles.splice(index, 1);
            setVideoFiles(updatedFiles);
            setVideos(updatedFiles.map(file => URL.createObjectURL(file)));

        }else{
            setVideoFiles(event.target.files);
            const files = Array.from(event.target.files);
           const videoPreviews = files.map((file) => URL.createObjectURL(file));
           setVideos(videoPreviews);
        }
        
    }
    const RegisterSchema = Yup.object().shape({
    });

    const defaultValues = {

    };
    const [file, setFile] = useState()

    const [previewImages, setPreviewImages] = useState()
    const  changeHandler = async (event) => {
        setFile(event.target.files)
        const selectedFIles = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setPreviewImages(selectedFIles)
        

    };
    //console.log(file)
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar

    const onSubmit = async (data) => {
        if (file) {
            const formData = new FormData();

            setLoading(true)
            
            let newArr = [];
            //********* HERE IS THE CHANGE ***********
            for (let i = 0; i < file.length; i++) {
                formData.append('files', file[i]);
            }
            // for (let i = 0; i < videoFiles.length; i++) {
            //     formData.append('videos', videoFiles[i]);
            // }
            // console.log(type , videoFiles , videoUrls)
            if( type === 'Video'){
                // videoFiles?.map((item) => formData.append('videos' , item))
                for (let i = 0; i < videoFiles.length; i++) {
                  formData.append('videos', videoFiles[i]);
                }
                formData.append('videoUrls' , [])
                formData.append('videoType' , 'upload')
            }else if( type == 'link'){
                formData.append('videoType' , 'yt')
                if( videoUrls.length > 1){
                   videoUrls?.map((item) => formData.append('videoUrls' , item))

                }else{
                    formData.append('videoUrls' , videoUrls)
                    formData.append('videoUrls' , "");
                }
                // videoUrls?.map((item) => formData.append('videoUrls'))
            }else{
               formData.append('videoUrls', []);
            }
            formData.append('images', []);
            
            // formData.append('videoUrls' , []);
            // for( let Pair of formData){
            //     console.log(Pair[0] , " -> " , Pair[1])
            // }

            // formData.append("icon", file);
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            // document.querySelector(".mainDiv").style.display = "block";
            setProgress(0);
            axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreProductFourth/${productId}`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.msg === 'Product Details added') {
                    setProductId(response?.data?.data?._id)
                    toast.success(response.data.msg);
                    setLoading(false);
                    setPdf(false);
                    reset();
                    setValue(value + 1);
                    setSubmit4(true);
                    // window.location.href = "/dashboard/storeAllProducts";
                }else{
                    setLoading(false);
                    toast.error(response.data.msg);
                }

            })
                .catch(e => {
                    //console.log(e);
                })
        }
  
    };
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Images for <span style={{ color: 'blue' }}>{title}</span> </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <Stack direction="row" spacing={3}>
                        <h3>Add Images</h3>
                        <RHFTextField name="files"
                            onChange={changeHandler}
                            id="file"
                            label="File"
                            error={!file && val}
                            helperText='Images Only(Maximum size allowed size 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            inputProps={{ multiple: true, accept: "image/*" }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}
                            onClick={() => setPdf(true)}
                            type={pdf || focus ? "file" : "text"}
                        />
                        
                        {file?.length > 0 && previewImages?.map((item, index) => <Stack direction='row'> <img src={item} alt='imglist' style={{ width: 100, height: 100 }} /> </Stack>)}
                    </Stack>
                    <Stack direction='row' spacing={2}>
                           <RHFTextField
                                name='demoVideoType'
                                label="Demo Video Type"

                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem onClick={() => handleChangeType('link')} value='link'>Youtube Link</MenuItem>
                                <MenuItem onClick={() => handleChangeType('Video')} value="Video">Video File</MenuItem>

                            </RHFTextField>
                    </Stack>
                    { type == 'link' && <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                        <h3>Select Product Video Urls</h3>
                        <div style={{ width : '80%'}}>
                            <MuiChipsInput sx={{ width : '100%'}} value={videoUrls} onChange={handleVideoUrls} placeholder='Enter Product Demo Video Urls' />  
                        </div>
                    </Stack>}
                    { type == 'Video'  && <Stack direction='row' spacing={2}>
                        <h3>Add Videos</h3>
                        <div>
                           <label
                             htmlFor="file-input"
                             style={ {
                                 marginRight: '20px',
                                 border: 'none',
                                 backgroundColor: '#944dff',
                                 '&:hover': { backgroundColor: 'red' },
                                 padding: '10px 20px',
                                 borderRadius: '10px',
                                 color: '#fff',
                                 cursor: 'pointer',
                                 transition: 'background 0.2s ease-in-out',
                               }}
                           >
                             Select Videos
                           </label>
                           <input type="file" accept="video/*" multiple id="file-input" onChange={handleVideoChange} style={{ display: 'none' }} />
                        </div>

                         {/* <div style={{ width : '100%' , border : 'solid  #cccccc 1px', padding : '10px' , borderRadius : '8px'}}>
                           <input type="file" accept="video/*" multiple id="file-input" onChange={handleVideoChange}/>
                         </div> */}
                         {videoFiles.length > 0 && <p>{videoFiles.length} file(s) selected</p>}
                          {/* <input type="file" accept="video/*" multiple id="file-input" 
                           /> */}
                        {/* <input type="file" accept="video/*" multiple /> */}
                    </Stack> }
                    { type == 'Video' && <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                     {videos.map((videoSrc, index) => (
                        <Stack direction='column' key={index} style={{ marginRight: '10px' }}>
                           <video src={videoSrc} controls style={{ width: '200px', marginRight: '5px' }} />
                           <Button variant='text' sx={{ marginTop : '10px'}} onClick={() => handleVideoChange(null , index)}>Remove</Button>
                        </Stack>
                       
                     ))}
                    </div> }


                    {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')

                        }}>
                        Next Step
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}

