import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem , Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import ClearIcon from '@mui/icons-material/Clear';
import zIndex from '@mui/material/styles/zIndex';
import { MuiChipsInput } from 'mui-chips-input';

// ----------------------------------------------------------------------

export default function UpdateFourth({ setShowForm, setProductId, productId, value, setValue, title, setSubmit4, editValue, fetchUsers }) {
    const theme = useTheme()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);

    const [images, setImages] = useState([]);
    const[videos , setVideos] = useState([]);
    const[type , setType] = useState("");
    const [ videoUrls , setVideoUrls] = useState([]);
    const handleVideoUrls = (data) => {
        setVideoUrls(data);
    }
    const handleChangeType = (type) => {
        setType(type);
        if( type == 'link'){
            // setVideoFiles([]);
            // setVideos([])
        }
        if( type == 'Video'){
            // setVideoUrls([]);
        
        }
    }

    const RegisterSchema = Yup.object().shape({

    });

    const defaultValues = {

    };
    const [file, setFile] = useState();
    const[videoFiles , setVideoFiles] = useState([]);
    const handleVideoChange = (event , index , videoSrcFile) =>{
        if (typeof index !== 'undefined') {
            let updateFiles =  Array.from(videoFiles);
            let fileUrlMap = updateFiles.map(file => {
                return { file, fileUrl: URL.createObjectURL(file) };
              });
             let answer =  fileUrlMap.filter(item => {
                        // Compare the file objects instead of their URLs
                        return !(item?.file?.name === videoSrcFile?.file?.name && item?.file?.size === videoSrcFile?.file?.size && item?.file?.type === videoSrcFile?.file?.type && item?.file?.lastModified === videoSrcFile?.file?.lastModified);
                      });
             let files= [];
             
             for( let item of answer){
                files.push(item?.file);
             }
            setVideoFiles(files);
            const updateVideos =  [...videos];
            updateVideos.splice(index , 1);
            setVideos(updateVideos.map(item => {
                if( item?.fileUrl?.includes('storeProduct/productGalleryVideo') || item?.fileUrl?.includes('blob')){
                    return { file :  item?.file , fileUrl : item?.fileUrl };
                }else {
                   return { file : item?.file ,  fileUrl : URL.createObjectURL(item?.file)}
                // return file;

                }
            }));
        }else{
            
            setVideoFiles(event.target.files);
            const files = Array.from(event.target.files);
        //    const videoPreviews = files.map((file) => URL.createObjectURL(file));
         const videoPreviews =  files.map(file => {
            return { file : file, fileUrl: URL.createObjectURL(file) };
          });
           setVideos([...videos , ...videoPreviews]);
        }
        
    }

    const [previewImages, setPreviewImages] = useState()
    const changeHandler = async (event) => {
        setFile(event.target.files)
        const selectedFIles = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setPreviewImages(selectedFIles)
    

    };
    //console.log(file)
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar

    const onSubmit = async (data) => {
       
        const formData = new FormData();

        setLoading(true)
        
        let newArr = [];
        //********* HERE IS THE CHANGE ***********
        // console.log(file);
        for (let i = 0; i < file?.length; i++) {
            formData.append('files', file[i]);
        }
        if (images?.length > 1) {
            for (let i = 0; i < images?.length; i++) {
                formData.append('images', images[i]);
            }
        }
        else {
            formData.append('images', images);
            formData.append('images' , "");
        }

        if( type == 'link'){
            formData.append('videoType' , 'yt')
                if( videoUrls.length > 1){
                   videoUrls?.map((item) => formData.append('videoUrls' , item))

                }else{
                    formData.append('videoUrls' , videoUrls)
                    formData.append('videoUrls' , "");

                }

        }else{
            formData.append('videoType' , 'upload')
            for (let i = 0; i < videoFiles?.length; i++) {
                formData.append('videos', videoFiles[i]);
            }
            let videoUrls2 = [];
         videos.filter((item) => {
            if(item?.fileUrl?.includes('storeProduct/productGalleryVideo')){
                // return item?.fileUrl;
                videoUrls2.push(item?.fileUrl)
            }})
            // console.log(videoUrls2)
            if (videoUrls2?.length > 1) {
                for (let i = 0; i < videoUrls2?.length; i++) {
                    if( videoUrls2[i]?.includes('storeProduct/productGalleryVideo')){
                       formData.append('videoUrls', videoUrls2[i]);
                    }
                    // formData.append('videoUrls', videos[i]);
                }
            }
            else {
                formData.append('videoUrls', videoUrls2);
                formData.append('videoUrls' , "");
            }
        }
        // for (let i = 0; i < videoFiles?.length; i++) {
        //     formData.append('videos', videoFiles[i]);
        // }
        // let videoUrls = [];
        //  videos.filter((item) => {
        //     if(item?.fileUrl?.includes('storeProduct/productGalleryVideo')){
        //         // return item?.fileUrl;
        //         videoUrls.push(item?.fileUrl)
        //     }})
        //     // console.log(videoUrls);
        //     // debugger;
        // if (videoUrls?.length > 1) {
        //     for (let i = 0; i < videoUrls?.length; i++) {
        //         if( videoUrls[i]?.includes('storeProduct/productGalleryVideo')){
        //            formData.append('videoUrls', videoUrls[i]);
        //         }
        //         // formData.append('videoUrls', videos[i]);
        //     }
        // }
        // else {
        //     formData.append('videoUrls', videoUrls);
        //     formData.append('videoUrls' , "");
        // }

        // console.log(formData)
        // for( let Pair  of formData){
        //     console.log(Pair[0] + "--->" + Pair[1])
        // }

        // formData.append("icon", file);
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        };
        
        setProgress(0);
        axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreProductFourth/${editValue?.id}`, formData, config).then((response) => {
            //console.log(response.data);
            if (response.data.msg === 'Product Details added') {
                setProductId(response?.data?.data?._id)
                toast.success(response.data.msg);
                setLoading(false);
                setPdf(false);
                reset();
                setValue(value + 1);
                setSubmit4(true);
                fetchUsers();
                // window.location.href = "/dashboard/storeAllProducts";
            }else{
                setLoading(false);
                toast.error(response.data.msg);
            }
        })
            .catch(e => {
                //console.log(e);
            })
       
    };
    const handleImages = (item) => {
        // console.log(item.target.id);
        let imgs = images.filter((img) => img != item.target.id);

        setImages([...imgs]);
    }
    const handleNewImages = (item) => {
        // console.log(file);
        let newFiles = file.filter((img) => img != item);
        setFile([...newFiles]);
        const selectedFIles = [];
        const targetFiles = newFiles;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setPreviewImages(selectedFIles)
    }
    useEffect(() => {
        setImages([...editValue?.images]);
        // let array = editValue?.videos?.map((item) => { return { fileUrl :  item}})
        if( editValue?.videoType === 'upload'){
            setType('Video');
            let array = editValue?.videos?.map((item) => { return { fileUrl :  item}})
            setVideos([...array])
        }else{
            setType('link');
            // setVideos([...editValue?.videos]);
            setVideoUrls([...editValue.videos]);

        }
        // setVideos([...array])
    }, [])
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Update Images for <span style={{ color: 'blue' }}>{title}</span> </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="files"
                            onChange={changeHandler}
                            id="file"
                            label="File"
                            // error={!file && val}
                            helperText='Image Only( Maximum size allowed 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            inputProps={{ multiple: true, accept: "image/*" }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            // inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}


                        />
                       
                        <div style={{ width: '600px', overflowX: 'scroll', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {!file && images?.map((item, index) => {
                                // console.log(item)
                                return <Stack direction='row' sx={{ position: 'relative' }}> <img src={item} alt='imglist' style={{ minWidth: 200, height: 100 }}></img>
                                    <ClearIcon id={item} size='small' sx={{ position: 'absolute', right: '10px', zIndex: 100, cursor: 'pointer', background: 'lightgray', borderRadius: '5px', padding: '4px' }} onClick={handleImages} />
                                </Stack>
                            })}


                            {file?.length > 0 && (<>{previewImages?.map((item, index) => {
                                return <Stack direction='row' sx={{ position: 'relative' }}> <img src={item} alt='imglist' style={{ minWidth: 200, height: 100 }}></img>
                                    <ClearIcon id={item} size='small' sx={{ position: 'absolute', right: '10px', zIndex: 100, cursor: 'pointer', background: 'lightgray', borderRadius: '5px', padding: '4px' }} />
                                </Stack>
                            })}
                                {images?.map((item, index) => {
                                    // console.log(item)
                                    return <Stack direction='row' sx={{ position: 'relative' }}> <img src={item} alt='imglist' style={{ minWidth: 200, height: 100 }} />
                                        <ClearIcon size='small' sx={{ position: 'absolute', right: '10px', zIndex: 100, cursor: 'pointer', background: 'lightgray', borderRadius: '5px', padding: '4px' }} onClick={handleImages} />
                                    </Stack>
                                })}
                            </>
                            )}
                        </div>




                    </Stack>
                    <Stack direction='row' spacing={2}>
                           <RHFTextField
                                name='demoVideoType'
                                label="Demo Video Type"

                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem selected={type === 'link'} onClick={() => handleChangeType('link')} value='link'>Youtube Link</MenuItem>
                                <MenuItem selected={type === 'Video'} onClick={() => handleChangeType('Video')} value="Video">Video File</MenuItem>
                            </RHFTextField>
                    </Stack>
                    { type == 'link' && <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                        <h3>Select Product Video Urls</h3>
                        <div style={{ width : '80%'}}>
                            <MuiChipsInput sx={{ width : '100%'}} value={videoUrls} onChange={handleVideoUrls} placeholder='Enter Product Demo Video Urls' />  
                        </div>
                    </Stack>}
                    { type == 'Video' && <Stack direction='row' spacing={2}>
                        <h3>Add Videos</h3>
                        <div>
                           <label
                             htmlFor="file-input"
                             style={ {
                                 marginRight: '20px',
                                 border: 'none',
                                 backgroundColor: '#944dff',
                                 '&:hover': { backgroundColor: 'red' },
                                 padding: '10px 20px',
                                 borderRadius: '10px',
                                 color: '#fff',
                                 cursor: 'pointer',
                                 transition: 'background 0.2s ease-in-out',
                               }}
                           >
                             Select Videos
                           </label>
                           <input type="file" accept="video/*" multiple id="file-input" onChange={handleVideoChange} style={{ display: 'none' }} />
                        </div>

                         {/* <div style={{ width : '100%' , border : 'solid  #cccccc 1px', padding : '10px' , borderRadius : '8px'}}>
                           <input type="file" accept="video/*" multiple id="file-input" onChange={handleVideoChange}/>
                         </div> */}
                         {videos.length > 0 && <p>{videos.length} file(s) selected</p>}
                          {/* <input type="file" accept="video/*" multiple id="file-input" 
                           /> */}
                        {/* <input type="file" accept="video/*" multiple /> */}
                    </Stack> }
                   { type == 'Video' && <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                     {videos.map((item, index) => (
                        <Stack direction='column' key={index} style={{ marginRight: '10px' }}>
                           <video src={item?.fileUrl} controls style={{ width: '200px', marginRight: '5px' }} />
                           <Button variant='text' sx={{ marginTop : '10px'}} onClick={() => handleVideoChange(null , index , item)}>Remove</Button>
                        </Stack>
                       
                     ))}
                    </div>}

            

                    {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


                    < LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')

                        }}>
                        Next Step
                    </LoadingButton>
                </Stack>
            </FormProvider >
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack >
    </>
    );
}

