import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@emotion/react';
import { func } from 'prop-types';
// ----------------------------------------------------------------------

export default function AddFaqs() {
    const theme = useTheme();
    const [loading, setLoading] = useState(false)
    const RegisterSchema = Yup.object().shape({
        question: Yup.string().required('Question required'),
        answer: Yup.string().required('Answer Required'),
        type: Yup.string().required('Exam Required'),
        isActive :  Yup.boolean().required('Status Required')
    });
    const defaultValues = {
        question : "" ,
        answer : "" ,
        type : '' ,
        isActive : ''
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;
   
    const onSubmit = async (data) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios.post(`${process.env.REACT_APP_LIVE_URL}/webContains/createFAQs`, data, config).then((response) => {
            if (response.data.status) {
                setLoading(false)
                reset()
                toast.success('Added Faqs')

            }else{
                setLoading(false);
                toast.error(response.data.msg)
            }
            // window.location.reload()
        })
            .catch(e => {
                //console.log(e);
            })

    };

    return (<>
        <Stack spacing={3} px={5} >
            {/* <Typography variant='h3'>Add Testimonial</Typography> */}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="question" label="Question" />
                    <RHFTextField name="answer" label="Answer" />
                    <RHFTextField name="type" label="Type" select>
                        <MenuItem value='home'>Home</MenuItem>
                        <MenuItem value='category'>Category</MenuItem>
                        <MenuItem value="batch" >Batch</MenuItem>
                    </RHFTextField>
                    <RHFTextField name="isActive" label="Status" select>
                        <MenuItem value='true'>Active</MenuItem>
                        <MenuItem value='false'>In Active</MenuItem>
                    </RHFTextField>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack>
    </>
    );
}
