import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  GridActionsCell ,
  GridActionsCellItem,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import { useParams } from "react-router-dom";
import getAccess from "../../../utils/getAccess";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button, FormGroup, Modal, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";

// ----------------------------------------------------------------------


const flattenObject = (obj) => {
  return `${obj.name} ${obj.email} ${obj.phone}`;
};
const flattenArray = (arr) => {
  return arr.map((item) => `Title - ${item.title.replaceAll(",", "")} Quantity - ${item.quantity}  Code - ${item?.code}`).join('; ');
};
const removeComma = (string) => {
  return string.replaceAll(',', '');
};
function CustomToolbar({ rows, columns, ...gridProps }) {
  const { setQuickFilter, ...otherProps } = gridProps;
  // debugger;
  const apiRef = useGridApiContext();
  // console.log(gridProps)
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row) => {
        // console.log(row)
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'user') {
            return flattenObject(row.user)
          }
          if( columnId === 'downloadUrl'){
            // console.log(row.downloadUrl)
            return row.downloadUrl
          }
          if( columnId === 'Size'){
            return `${parseFloat(parseFloat(row.Size) / 1024).toFixed(2) } KB` 
          }
          return row[columnId]?.toString()?.replaceAll("," , " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;

    // console.log('Exporting all rows:', fullCsvContent);
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'BucketFiles.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function FolderPage() {
  const access = getAccess();
  const theme = useTheme();
  const { prefix } = useParams();
  const [rows, setRows] = React.useState([]);
  const [loading , setLoading] = useState(true)
  const [rowModesModel, setRowModesModel] = React.useState({});
  const[ openModal , setOpenModel] = React.useState(false);
  const mapPageToNextCursor = React.useRef({});
  const[rowCountState , setRowCountState] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25
  })
  const handlePaginationModelChange = (newPaginationModel) => {
    // We have the cursor, we can allow the page transition.
    // console.log("handle")
    console.log(newPaginationModel);
    if (newPaginationModel.page === 0 || mapPageToNextCursor.current[newPaginationModel.page - 1]) {
      setPaginationModel(newPaginationModel);
      // console.log("handle2")
    }
  };
  React.useEffect(() => {
    if (!loading) {
      // We add nextCursor when available
      mapPageToNextCursor.current[paginationModel.page] = paginationModel.page + 1;
    }
  }, [paginationModel.page, loading]);
  const queryOptions = React.useMemo(
    () => ({
      cursor: mapPageToNextCursor.current[paginationModel.page - 1],
      pageSize: paginationModel.pageSize,
    }),
    [paginationModel],
  );
  // console.log('129' , paginationModel);
  const handleModal = () => {
    setOpenModel(false)
  }
  const[file , setFile] = React.useState();
  const[pdf , setPdf] = React.useState();
  const[ focus , setFocused] = React.useState();

  const onUpload = () => {
    // console.log(file)
    if (file) {
      let  formData = new FormData();

      setLoading(true)
      
      Object.values(file).forEach(file => {
        console.log(file)
        formData.append("file", file);
      })
      formData.append('prefix'  , prefix);
      // console.log('formData' , formData);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        // onUploadProgress: data => {
        //   setProgress(Math.round((100 * data.loaded) / data.total))
        // },
      };
      // console.log('formData' , formData);
      // for( let Pair of formData){
      //   console.log( Pair[0] + " " + Pair[1])
      // }
      // setProgress(0);
      axios.post(`${process.env.REACT_APP_LIVE_URL}/aws/postContent`, formData, config).then((response) => {

        if (response.data.msg === 'Content uploaded on given batch') {
          toast.success(response.data.msg);
          setLoading(false);
          setPdf(false);
          // reset();
          window.location.href = `/dashboard/bucketFolder/${prefix}`;
        }else {
          toast.error(response.data.msg);
          setLoading(false);
        }

      })
        .catch(e => {
          //console.log(e);
        })
    }
  }

  const changeHandler = async (event) => {
    event.preventDefault();
    setFile(event.target.files)
        
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  // const [loading , setLoading] = useState(true)
  React.useEffect(()=>{
    let page = parseInt(queryOptions.cursor) + 1;
    let pageSize = parseInt(queryOptions?.pageSize)
    console.log(page , pageSize);
    setLoading(true);
    const token = localStorage.getItem('token');
    async function fetchOrder(){
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/aws/getAllBucket?prefix=${prefix !== 'import' ? prefix : ""}&pageNumber=${page}&pageSize=${pageSize}` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`
      }
      });
      const responseJson = await response.json();
      // console.log(responseJson.data)
       setRows(responseJson.data?.response ?? []);
       if(rowCountState === 0 ) setRowCountState(responseJson.data?.totalCount);
       setLoading(false);
    }
    fetchOrder();
  },[queryOptions]) ;

  const handleDownload = ( fileUrl) => {
      let link =  document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete File from S3?")
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/aws/deleteContent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

        body : JSON.stringify({ Key :  id  , prefix : prefix})
      });
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    };
  }
 

  const columns = [
    {
      field: "sno",
      headerName: "S.NO.",
      type: "string",
      width: 75,
      align: "left",
      headerAlign: "left",
    },
      {
        field: "Key",
        headerName: "Folder Name",
        width: 550,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "downloadUrl",
        headerName: "Url",
        type: "string",
        width: 100,
        align: 'left',
        headerAlign: "left",
        editable: false,
        renderCell: (params) => (
          <div style={{ cursor : 'pointer'}}onClick={() =>{ navigator.clipboard.writeText(params?.value); toast.success('link Copied')} }>
            <ContentCopyIcon style={{ height: '60%' ,  color: 'blue' }} />
          </div>
        ),
      },
      {
        field: "Size",
        headerName: "Size",
        type: "string",
        width: 100,
        align: 'left',
        headerAlign: "left",
        editable: false,
        renderCell: (params) => (
          <div >
            { `${parseFloat( parseFloat(params?.value) / 1024).toFixed(2)} KB` }
          </div>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const rowData = rows.find(item => item.id === id);
          return [
          //   ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all'))  ? <Tooltip title="See Delete Details" arrow>
          //   <GridActionsCellItem
          //     icon={<ContentCopyIcon style={{ color: 'blue' }} />}
          //     label="View"
          //     onClick={() => { navigator.clipboard.writeText(rowData?.downloadUrl); toast.success('link Copied')}}
          //     color="inherit"
          //   />
          // </Tooltip> : <></>) , 
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Delete">
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: 'red' }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            // onClick={ (id) => handleOpen(id)}
            color="inherit"
          />
        </Tooltip> : <></>),
            
            
          ];
        }
      }
     
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        // display: 'flex',
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <Stack direction='row' style={{ display : 'flex' , justifyContent : 'space-evenly'}}>
        <div>
          <h2 style={{ margin : '10px'}}>All Folder </h2>
        </div>
            {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "25%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} onClick={() => setOpenModel(true)}>Upload Here</Button>}
 
      </Stack>
      {/* <h2 style={{ margin : '5px'}}>All Folder </h2> */}
      <DataGrid
        rows={rows}
        columns={columns}
        // editMode="row"
        // rowHeight={155} 
        autoHeight={true}
        rowCount={rowCountState}
        getRowHeight={() => 'auto'} 
        getEstimatedRowHeight={() => 200} 
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        // initialState={{
        //   pagination: {
        //     paginationModel: {
        //       pageSize: 25,
        //     },
        //   },
        // }}
        loading={loading}
        pageSizeOptions={[25, 50,100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
       <Modal
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormGroup >
                <Typography variant='h4' align='center' mb={2}>Upload Image Or Pdf</Typography>
                <TextField  type="file" onChange={(e) => changeHandler(e)} inputProps={{accept:"image/*, .pdf" } }/>
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => onUpload()}>Upload</Button>
                </Stack>

              </FormGroup>
            </Box>
          </Modal>
     
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
