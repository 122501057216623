import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Modal from '@mui/material/Modal';
import { Typography , Divider } from "@mui/material";
import { useTheme } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";
import SourceIcon from '@mui/icons-material/Source';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

function CustomToolbar ({rows , columns }){
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
     const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row ,index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'admin') {
            return `${row.admin.name}(${row.admin.role})`;
          }
          else if (columnId === 'linkWith') {
            return row.linkWith.title
          }
          else if ( columnId === 'serialNumber'){
            return index+1
          }
          return row[columnId];
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'StoreAlerts.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       
      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{color : 'red'}}/>
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function LogsTable() {
  const access = getAccess();

  const [rows, setRows] = React.useState([]);
  const [details , setDetails] = React.useState({});
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    
    async function fetchLogs() {
        const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getlogs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`

            }
        });
        const responseJson = await response.json();
        setRows(responseJson.data);
        
    }
    fetchLogs();

    
}, []);

  const [rowModesModel, setRowModesModel] = React.useState({});


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  

 




  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'sNo',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    // {
    //   field: "title",
    //   headerName: "Title",
    //   type: "string",
    //   width: 300,
    //   align: "left",
    //   headerAlign: "left",
    //   editable: true
    // },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true
      renderCell : (params) => {
        return (
          <div>{`${params.value.name} (${params.value.role})`}</div>
        )
      }
    },
    {
      field: "event",
      headerName: "Event",
      type: "object",
      width: 350,
      align: "left",
      headerAlign: "left",


    },
    {
      field: "action",
      headerName: "Action",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    
      // renderCell: (params) => (
      //     <div>{params.v ? params.value.title : "NA"}</div>
      //   ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find(item => item.id === id);
        return [
          
          ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all'))  ? <Tooltip title="See Delete Details" arrow>
          <GridActionsCellItem
            icon={<SourceIcon style={{ color: 'red' }} />}
            label="View"
            onClick={() => {setOpen(true);  setDetails(rowData?.data)}}
            color="inherit"
          />
        </Tooltip> : <></>) 
          
          
        ];
      }
    }
    
  ]

  const [open , setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <h2 style={{ margin : '20px'}}>All Logs</h2>
      <DataGrid
        // rowHeight={50}
        autoHeight
        getRowHeight={() => 'auto'}
        // getEstimatedRowHeight={() => 700}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
                   <div style={{ display : 'flex' , justifyContent : 'space-between'}}>
                       <h3>Here is deatils</h3>
                       <IconButton onClick={() => {handleClose() ; }}>
                          <CancelIcon style={{ color : 'red'}}/>
                        </IconButton>
                    </div>
                    
                    <Divider />
                        <div >
                        {Object.keys(details)?.map(key =>
                           <div style={{ display: 'flex', alignContent : 'space-between' }} key={details[key]} >
                          
                             <div >{`${key} ---- > `}</div>
                              <div>  {(details[key])?.toString()}</div>
                          
                            
                               </div>

                            )}
                            {/* <Typography variant="body2" color="text.secondary">
                                {details}
                            </Typography> */}
                        </div>
                        

                        <div style={{ display : 'flex' , marginTop : "30px" , justifyContent : 'space-evenly'}}>
                              <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: theme.palette.primary.mainTheme,
                                    color: "white",
                                    "&:hover": { background: "red" },
                                  }}
                                  onClick={() => handleClose()}
                                >
                                  Cancel
                                </Button>
                               
                            
                        </div>
                        
            
          </Box>
        </Modal>
      </div>
    </Box>
  );
}
