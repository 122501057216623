import { Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fShortenNumber } from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InfoCard from "../../../components/batch/InfoCard";
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from "@emotion/react";
const StudentTestSeries = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [batchInfo, setBatchInfo] = useState();
  const [loading, setLoading] = React.useState(true);
  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  }));

  //Batches
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getallTestSeriesdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //console.log(responseJson.data);
      setBatchInfo(responseJson.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);
  //console.log(batchInfo);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} />
        </Stack>
      ) : (
        <>
          <Typography variant="h3" mb={5} ml={3}>
            Test Series Purchases
          </Typography>
          <Grid container pl={5} pr={3} spacing={4}>
            {batchInfo?.map((item, i) => {
              const {
                _id,
                batch_name,
                student,
                created_at,
                ending_date,
                starting_date,
                is_active
              } = item;
              return (
                <Grid
                  item
                  key={i}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/dashboard/studentTestSeries/studentList/${is_active ? '1' : '0'}/${_id}`)
                  }
                >
                  <InfoCard
                    data={item}
                    title="Info"
                    path=""
                    use="Student Test Series"
                  />
                  {/* <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">

                                <Stack alignItems="center" >
                                    <div className="icon-div-batch-card" > <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M42 30.6667C42 31.0278 41.8701 31.3403 41.6104 31.6042C41.3506 31.8681 41.043 32 40.6875 32C40.332 32 40.0244 31.8681 39.7646 31.6042C39.5049 31.3403 39.375 31.0278 39.375 30.6667V13.125L24.8965 20.5C23.8027 21.0556 22.5039 21.3333 21 21.3333C19.4961 21.3333 18.1973 21.0556 17.1035 20.5L1.59961 12.625C0.533203 12.0694 0 11.4097 0 10.6458C0 9.88194 0.533203 9.23611 1.59961 8.70833L17.1035 0.833333C18.1973 0.277778 19.4961 0 21 0C22.5039 0 23.8027 0.277778 24.8965 0.833333L40.4004 8.70833C41.3301 9.18056 41.8496 9.76389 41.959 10.4583C41.9863 10.4861 42 10.5417 42 10.625V30.6667ZM26.209 23.1667L34.125 19.125V25C34.125 26.1944 32.8467 27.2153 30.29 28.0625C27.7334 28.9097 24.6367 29.3333 21 29.3333C17.3633 29.3333 14.2666 28.9097 11.71 28.0625C9.15332 27.2153 7.875 26.1944 7.875 25V19.125L15.791 23.1667C16.8848 23.7222 18.6211 24 21 24C23.3789 24 25.1152 23.7222 26.209 23.1667Z" fill="url(#paint0_linear_1_18)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1_18" x1="3.15" y1="8.25807" x2="41.7094" y2="28.9292" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#F9A1B4" />
                                                <stop offset="1" stop-color="#DF6480" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    </div>
                                    <Typography sx={{
                                        color: ' #E15B73', fontSize: '1.3rem', fontWeight: '500', marginLeft: '10%', marginRight: '10%', fontSize: '1.3rem', fontWeight: '600', lineHeight: '1', marginTop: '4%'
                                    }}> {batch_name}</Typography>
                                </Stack>
                                <Typography variant='h7' sx={{
                                    color: ' #E15B73'
                                }}> {student.length} Students</Typography>

                            </div>
                            <div className="flip-card-back">
                                <Stack spacing={1} pr={1} pl={1}>
                                    <Typography sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '11px',

                                        textDecoration: ' underline',
                                        color: '#FFFFFF'

                                    }} >Batch Details</Typography>
                                    <Stack direction="row" justifyContent='space-around'>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >Created By :</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >Admin</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent='space-around'>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >Date Created</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >{created_at}</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent='space-around'>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >Batch Starts</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >{starting_date}</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent='space-around'>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >Batch Ends</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: '16px',

                                            color: '#FFFFFF'

                                        }} >{ending_date}</Typography>
                                    </Stack>

                                </Stack>
                            </div>
                        </div>
                    </div> */}
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export default StudentTestSeries;
