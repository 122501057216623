import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import { MuiChipsInput } from 'mui-chips-input'
import MyCkEditor from '../../../components/hook-form/MyCkEditor';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

// ----------------------------------------------------------------------

export default function AddStoreProductFirst({ productId, value, setProductId, setValue, setTitle, setSubmit1 }) {
  const theme = useTheme()
  const animatedComponents = makeAnimated();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleChangeCategories = (data) => {
    setSelectedCategories(data);
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [restrict, setRestrict] = useState('')
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [showForm, setShowForm] = useState({ first: true, second: false, third: false, fourth: false, fifth: false });

  //Chips
  const [chips, setChips] = useState([])

  const handleChangeChips = (newChips) => {
    setChips(newChips)
  }
  const [category, setCategory] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('token');
    async function fetchCategory() {
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProductCategory`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`

        }
      });
      const responseJson = await response.json();
      setCategory(responseJson.data);
      setLoading(false);
    }
    fetchCategory();

  }, []);


  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    code: Yup.string().required('Code required'),
    // desc: Yup.string().required('Description required'),
    // category: Yup.string().required('Category required'),
    badge: Yup.string().required('Badge required'),
    code: Yup.string().required('Code required'),
    language: Yup.string().required('Language is required'),
    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title: '',
    code: '',
    category: '',
    desc: '',
    isActive: '',
  };
  const [file, setFile] = useState()

  const changeHandler = async (event) => {
    let size = event.target.files[0].size / ( 1024 );
        if( size > 100){
            setVal(true);
        }else{
            setFile(event.target.files)
            var fileName = document.getElementById('file').value.toLowerCase();
              if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
             else { setRestrict('JPG Or PDF') }
        }

  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar
  const[dataForDesc , setDataForDesc] = useState("");
  const onSubmit = async (data) => {
    if (file) {
      const formData = new FormData();

      setLoading(true)
      // debugger;
      //console.log(file)
      Object.values(file).forEach(file => {
        formData.append("file", file);
      })
      formData.append('title', data.title);
      // formData.append('category', data.category);
      // formData.append('tags', chips);
      // console.log(selectedCategories)
      if (selectedCategories.length === 1) {
        formData.append('categories', selectedCategories[0]?.id);
        formData.append('categories', "");
      } else {
        selectedCategories.map((item) => formData.append('categories', item?._id))
      }
      chips.map((item) => {
        formData.append("tags", item);
      })
      formData.append('badge', data.badge);
      formData.append('language', data.language);
      formData.append('desc', dataForDesc);
      formData.append('code', data.code);

      formData.append('isActive', data.isActive);

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
      };
      // document.querySelector(".mainDiv").style.display = "block";
      setProgress(0);
      axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreProduct`, formData, config).then((response) => {
        console.log(response.data);
        if (response.data.msg === ' New Product added successfully') {
          setProductId(response?.data?.data?._id)
          toast.success(response.data.msg);
          setLoading(false);
          setPdf(false);
          reset();
          setSubmit1(true);
          // console.log(value);
          setTitle(`${data.title}(${data.code})`);
          setValue(value + 1);

          // window.location.href = "/dashboard/banner";
        }else{
          setLoading(false);
          toast.error(response?.data?.msg)
        }

      })
        .catch(e => {
          //console.log(e);
        })
    }
  };


 
  
  return (<>
    <Stack spacing={3} px={5} >
      <><Typography variant='h3'>Add Store Basic Details</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
          <Stack spacing={3} sx={{ width: '70%' }}>

            <Stack direction='row' spacing={3}>
              <RHFTextField name="title" label="Title" />
              <RHFTextField name="file"
                onChange={changeHandler}
                id="file"
                label="File"
                error={!file && val}
                helperText='Image Only ( Maximum allowed size 100KB)'
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField"
                  }
                }}

                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}

              />
            </Stack>

            {/* <Stack direction='row' spacing={3}> */}
              <MuiChipsInput  value={chips} onChange={handleChangeChips} placeholder='Enter Tags' />
              {/* <RHFTextField style={{ width: '50%' }} name="category" label="Category" select>
                {category?.map((item, index) => { return <MenuItem key={index} value={item?.id}>{item?.title}</MenuItem> })}

              </RHFTextField> */}
            {/* </Stack> */}
            <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}> Categories</InputLabel>
                <Stack  sx={{ width : '80%'}}>
                  <ReactSelect
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={selectedCategories}
                    onChange={handleChangeCategories}
                    menuPortalTarget={document.body} 
                    placeholder='Select Categories For Product'
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    isMulti
                    options={category}
                  />
                </Stack>
                

            </Stack>
            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="badge" label="Badge" /> */}
              <RHFTextField name="badge" label="Badge" type='checkbox' select >
                <MenuItem value={"NEW ARRIVAL"}>NEW ARRIVAL</MenuItem>
                <MenuItem value={"TOP TRENDING"}>TOP TRENDING</MenuItem>
                <MenuItem value={"PRICE DROP"}>PRICE DROP</MenuItem>
                <MenuItem value={"FREEDOM SALE"}>FREEDOM SALE</MenuItem>

              </RHFTextField>
              <RHFTextField name="code" label="Code" />
            </Stack>
            <Typography>Description</Typography>
            <MyCkEditor dataForDesc={dataForDesc} setDataForDesc={setDataForDesc} />
            
            <Stack direction="row" spacing={3}>
              <RHFTextField name="language" label="Language" select>
                <MenuItem value='en'>English</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
                <MenuItem value="enhi">Bilingual</MenuItem>
              </RHFTextField>
              <RHFTextField name="isActive" label="Status" type='checkbox' select >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
              sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
              onClick={() => {
                setVal(true)
                setMsg('Required')

              }}>
              Next Step
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>


      {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
    </Stack>
  </>
  );
}

