import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem , InputLabel} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import RHFTextEditor from "../../../../components/hook-form/RHFTextEditor";

// import "./general.css";
import { useTheme } from '@emotion/react';

import { MuiChipsInput } from 'mui-chips-input'
import MyCkEditor from '../../../../components/hook-form/MyCkEditor';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

// ----------------------------------------------------------------------

export default function UpdateFirst({ productId, value, setProductId, setValue, setTitle, setSubmit1, editValue, onChange2, fetchUsers }) {
    const theme = useTheme();
  const animatedComponents = makeAnimated();
//   console.log(editValue?.categories);
  const [selectedCategories, setSelectedCategories] = useState(editValue?.categories);
  const handleChangeCategories = (data) => {
    setSelectedCategories(data);
  }
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const [showForm, setShowForm] = useState({ first: true, second: false, third: false, fourth: false, fifth: false });

    //Chips
    const [chips, setChips] = useState([]);
    const [dataForDesc, setDataForDesc] = useState(editValue?.desc);

    const handleChangeChips = (newChips) => {
        setChips(newChips)
    }

    const [category, setCategory] = useState([]);

    let badgeArray =  [
        {label : "FREEDOM SALE" , value : "FREEDOM SALE"} , 
        {label : "NEW ARRIVAL" , value : "NEW ARRIVAL"} ,
        {label : "TOP TRENDING" , value : "TOP TRENDING"} ,
        {label : "PRICE DROP" , value : "PRICE DROP"}
    ]

    useEffect(() => {
        setChips(editValue?.tags)
        setDataForDesc(editValue?.desc);
        handleChangeCategories(editValue?.categories)
        // onChangeDesc(editValue?.desc);
        const token = localStorage.getItem('token');
        async function fetchCategory() {
            const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProductCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`

                }
            });
            const responseJson = await response.json();
            setCategory(responseJson.data);
            setLoading(false);
        }
        fetchCategory();

    }, []);


    const RegisterSchema = Yup.object().shape({

    });

    const defaultValues = {

    };
    const [file, setFile] = useState()
    const changeHandler = async (event) => {
        setFile(event.target.files[0])
        var fileName = document.getElementById('file').value.toLowerCase();
        if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
        else { setRestrict('JPG Or PDF') }
        //console.log(file)

    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();

    const onSubmit = async (data) => {
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }

        if (data.title) {
            formData.append("title", data.title);
        } else {
            formData.append("title", editValue?.title);
        }
        formData.append('desc', dataForDesc);
        if (data.language) {
            formData.append("language", data.language);
        } else {
            formData.append("language", editValue?.language);
        }
        chips?.length > 0 && chips?.map((item) => {
            formData.append("tags", item);
        })
        formData.append("marketingCat", 'SA');
        formData.append("slug", editValue?.slug);
        if (selectedCategories.length === 1) {
            formData.append('categories', selectedCategories[0]?.value);
            formData.append('categories', "");
          } else {
            selectedCategories.map((item) => formData.append('categories', item?.value))
          }

        if (data.badge) {
            formData.append("badge", data.badge);
        } else {
            formData.append("badge", editValue?.badge);
        }


        // formData.append("category", editValue?.category);


        if (data.code) {
            formData.append("code", data.code);
        } else {
            formData.append("code", editValue?.code);
        }
        formData.append("isActive", editValue?.isActive);

        // console.log(formData)
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        };
        // document.querySelector(".mainDiv").style.display = "block";
        setProgress(0);
        axios.post(`${process.env.REACT_APP_LIVE_URL}/store/updateStoreProduct/${editValue?.id}`, formData, config).then((response) => {
            // console.log(response.data);
            if (response.data.msg === 'Product Details added') {
                setProductId(response?.data?.data?._id)
                toast.success(response.data.msg);
                setLoading(false);
                setPdf(false);
                reset();
                setSubmit1(true);
                // console.log(value);
                let titleString = `${data.title ? data.title : editValue?.title} `;
                titleString = titleString + `(${data.code ? data.code : editValue.code})`
                // setTitle(`${data.title}(${data.code})`);
                setTitle(titleString);
                setValue(value + 1);
                fetchUsers();
            } else {
                setLoading(false);
                toast.error(response.data.msg);
            }

        })
            .catch(e => {
                //console.log(e);
            })

    };

    return (<>
        <Stack spacing={3} px={5} >
            <><Typography variant='h3'>Update Store Basic Details</Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '70%' }}>

                        <Stack direction='row' spacing={3}>
                            <RHFTextField name="title" value={editValue?.title} type="string" label="Title" onChange={onChange2} />
                            <RHFTextField name="file"
                                onChange={changeHandler}
                                id="file"
                                label="File"
                                // error={!file && val}
                                helperText='Image Only( Maximum Size allowed 100KB)'
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                // inputProps={{ multiple: true }}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}

                                onClick={() => setPdf(true)}
                                inputProps={{ accept: "image/*" }}
                                type={pdf || focus ? "file" : "text"}

                            />
                        </Stack>
                        <RHFTextField name="slug" label="Slug" value={editValue?.slug} onChange={onChange2} />

                        <Stack direction='row' spacing={3}>
                            <MuiChipsInput style={{ width: '100%' }} value={chips ? chips : editValue?.tags} onChange={handleChangeChips} placeholder='Enter Tags' />
                        </Stack>
                        <Stack direction='row' spacing={2} sx={{ width : '100%'}}>
                <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}> Categories</InputLabel>
                <Stack  sx={{ width : '80%'}}>
                  <ReactSelect
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={selectedCategories}
                    onChange={handleChangeCategories}
                    menuPortalTarget={document.body} 
                    placeholder='Select Categories For Product'
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    isMulti
                    options={category}
                  />
                </Stack>
                

            </Stack>

                        <Stack direction="row" spacing={3}>
                            {/* <RHFTextField name='badge' type="string" value={editValue?.badge} label="Badge" onChange={onChange2} />
                             */}
                             <RHFTextField name="badge"  type='checkbox' value={editValue?.badge} onChange={onChange2} select >
                                  { badgeArray?.map((item) => {
                                    //   console.log(editValue?.badge == item?.value)
                                      return (
                                        <MenuItem value={item?.value} selected={editValue?.badge == item?.value}>{item?.label}</MenuItem>

                                      )
                                    //  <MenuItem value={item?.value} selected={editValue?.badge == item?.value}>{item?.label}</MenuItem>

                                  })}
                                  {/* <MenuItem value={"NEW ARRIVAL"}>NEW ARRIVAL</MenuItem>
                                  <MenuItem value={"TOP TRENDING"}>TOP TRENDING</MenuItem>
                                  <MenuItem value={"PRICE DROP"}>PRICE DROP</MenuItem>
                                  <MenuItem value={"FREEDOM SALE"}>FREEDOM SALE</MenuItem> */}
                             </RHFTextField>
                            <RHFTextField name='code' type="string" value={editValue?.code} label="Code" onChange={onChange2} />
                        </Stack>
                        <Typography>Description</Typography>
                        <MyCkEditor dataForDesc={dataForDesc ? dataForDesc : editValue?.desc} setDataForDesc={setDataForDesc} />
                        <Stack direction="row" spacing={3}>
                            <RHFTextField
                                name="language"
                                label={editValue?.language == "en" ? "English" : "Hindi"}
                                select
                            >
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="hi">Hindi</MenuItem>
                            </RHFTextField>
                            <RHFTextField
                                name='isActive'
                                label={editValue?.isActive ? 'Active' : 'Inactive'}
                                sx={{ width: '100%' }}
                                // value={editValue?.isActive}
                                onChange={onChange2}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>

                            </RHFTextField>
                        </Stack>
                        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                            onClick={() => {
                                setVal(true)
                                setMsg('Required')

                            }}>
                            Next Step
                        </LoadingButton>
                    </Stack>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        </Stack>
    </>
    );
}

