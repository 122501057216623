import {Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {  styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from "@emotion/react";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {  VisibilityOutlined } from "@mui/icons-material";
import getAccess from "../../../../utils/getAccess";

const ValidityGrid = () => {
  const d = getAccess();
  const navigate = useNavigate();
  const theme = useTheme();
  const { batchId } =  useParams();
  const [loading, setLoading] = React.useState(true);

  

  //Batches
   //  table thind
   const [rows, setRows] = React.useState([]);
   const [rowModesModel, setRowModesModel] = React.useState({});

   const handleRowEditStop = (params, event) => {
     if (params.reason === GridRowEditStopReasons.rowFocusOut) {
       event.defaultMuiPrevented = true;
     }
   };

   const handleSaveClick = (id) => () => {
     setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
   };
 
  
 
   const handleCancelClick = (id) => () => {
    // console.log('50' , id);
     setRowModesModel({
       ...rowModesModel,
       [id]: { mode: GridRowModes.View, ignoreModifications: true },
     });
 
     const editedRow = rows.find((row) => row.id === id);
     if (editedRow.isNew) {
       setRows(rows.filter((row) => row.id !== id));
     }
   };

   const editDataInDb = async(data) => {
      //  console.log(data);
      const token = localStorage.getItem("token");
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminSection/updateValidity`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body : JSON.stringify({id : data?.id , month : data?.month , salePrice : data?.salePrice , regularPrice : data?.regularPrice , isActive : data?.isActive  })
        }
      );
      const responseJson = await fullResponse.json();
     const updatedRow = { ...data, isNew: false };
    //  console.log('api response' , responseJson);
     if( responseJson.status == true  ){
        setRows(rows.map((row) => (row.id === data.id ? updatedRow : row)));
        toast.success('Validity Update Successfully')
        handleCancelClick(data?.id)();
        // return updatedRow;
     }else{
        toast.error(responseJson.msg);
        handleCancelClick(data?.id)();
     } 
   }
 
   const processRowUpdate = (newRow) => {
     const updatedRow = { ...newRow, isNew: false };
     setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
       editDataInDb(newRow)
    //  console.log("65" , newRow);
    //  return updatedRow;
   };
 
   const handleRowModesModelChange = (newRowModesModel) => {
     setRowModesModel(newRowModesModel);
   };
 
  
   const columns = [
     {
       field: "sno",
       headerName: "S NO",
       width: 75,
       align: "left",
       headerAlign: "left",
       editable: false,
       renderCell: (params) => <strong>{params.value}</strong>,
     },
     {
       field: "month",
       headerName: "Month",
       type: "number",
       width: 200,
       align: "left",
       headerAlign: "left",
       editable: true,
     },
     {
       field: "salePrice",
       headerName: "Sale Price",
       type: "number",
       width: 200,
       align: "left",
       headerAlign: "left",
       editable: true,
     },
     {
       field: "regularPrice",
       headerName: "Regular Price",
       type: "number",
       width: 150,
       align: "left",
       headerAlign: "left",
       editable: true,
     },
     {
       field: "isActive",
       headerName: "Status",
       type: "boolean",
       width: 100,
       align: "left",
       headerAlign: "left",
       editable: true,
       renderCell: (params) => (
         <div style={{ color: params.value == true ? "green" : "red" }}>
           {params.value ? "Active" : "inActive"}
         </div>
       ),
     },
 
     {
       field: "actions",
       type: "actions",
       headerName: "Actions",
       width: 100,
       cellClassName: "actions",
       getActions: ({ id }) => {
         const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
         const rowData = rows.find((item) => item.id === id);
         if (isInEditMode) {
           return [
             <GridActionsCellItem
               icon={<SaveIcon />}
               label="Save"
               sx={{
                 color: "primary.main",
               }}
               onClick={handleSaveClick(id)}
             />,
             <GridActionsCellItem
               icon={<CancelIcon />}
               label="Cancel"
               className="textPrimary"
               onClick={handleCancelClick(id)}
               color="inherit"
             />,
           ];
         }
 
         return [
          ( d?.access?.includes('all') ||  d?.accessForTab?.includes('read') ||  d?.accessForTab?.includes('readWrite')) ? 
           <GridActionsCellItem
             icon={<VisibilityOutlined />}
             label="Edit"
             className="textPrimary"
             // onClick={handleEditClick(id)}
             onClick={() => console.log('New Grid')}
             color="inherit"
           /> : <></>,
 
           //     <GridActionsCellItem
           //     icon={<DeleteIcon style={{ color : 'red'}}/>}
           //     label="Delete"
           //     onClick={handleDeleteClick(id)}
           //     // onClick={ (id) => handleOpen(id)}
 
           //     color="inherit"
           //   />
         ];
       },
     },
   ];
  
   useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchValidity() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminSection/getValidityOfBatch/${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson?.data);
      setLoading(false);
    }
    fetchValidity();
  }, []);
  //console.log(batchInfo);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} />
        </Stack>
      ) : (
        <>
          <Typography variant="h3" mb={5} ml={3}>
            Validity Table of Batches
          </Typography>
          <DataGrid
            // sx={{ overflow: "auto" }}
            rowHeight={50}
            rows={rows}
            loading={loading}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[15, 25, 50]}
            slots={{
              //   toolbar: EditToolbar,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, showQuickFilter: true },
            }}
          />
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </>
  );
};

export default ValidityGrid;
