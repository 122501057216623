



import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    // Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
    Input,
    TextField,
    MenuItem,
} from '@mui/material';
// components
import Page from '../../../components/Page';

import Scrollbar from '../../../components/Scrollbar';

import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../user';
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import axios from 'axios';
import LinkIcon from '@mui/icons-material/Link';
import { ExportCSV } from '../../../utils/ExportCSV';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: '', label: 'S.No', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    // { id: 'category', label: 'Category', alignRight: false },
    { id: 'channel_url', label: 'Channel', alignRight: false },
    // { id: 'language', label: 'Language', alignRight: false },
    // { id: 'created_at', label: 'Date', alignRight: false },
    { id: 'is_active', label: 'Status', alignRight: false },


];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Telegramtable() {

    const theme = useTheme();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');
    const [selectedId, setSelectedId] = useState();

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate()
    const [users, usersSet] = useState()
    const [loading, setLoading] = useState(true)



    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/TelegramDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            setLoading(false)
            // if (responseJson.msg === 'not an admin') {
            //     localStorage.removeItem("isLoggedIn")
            //     localStorage.removeItem("details")
            //     navigate('/')
            // };
        }

        fetchUsers();
    }, []);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users?.map((n) => n.title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;
    let helperArray= [];
    const filteredUsers = applySortFilter(users ? users : ['no'], getComparator(order, orderBy), filterName);
    filteredUsers.map((item, index) => helperArray.push({
        SNo:index+1,
        title : item?.title,
        channelUrl : item?.channel_url,
        // language: item?.language,
        isActive : item?.is_active,

    }))
    // console.log(filteredUsers)
    const isUserNotFound = filteredUsers.length === 0 || filteredUsers[0] == 'no';
    const [isEdit, setIsEdit] = useState(false)
    const [updatedValues, setUpdatedValues] = useState({})
    //console.log(updatedValues)
    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '60vh' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>) :
            (<Page title="Telegram">
                <Container sx={{ position: 'absolute', minWidth: '100%' }}>


                    <Card>
                        <Stack pr={3} direction='row' justifyContent='space-between' alignItems='center' >
                            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                            <ExportCSV csvData={helperArray} fileName='Telegram Data' />

                        </Stack>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={users?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers?.length > 0 && filteredUsers[0] !== 'no' && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const { id, title, _id, category, language, created_at, is_active, channel_url } = row;
                                            const isItemSelected = selected.indexOf(title) !== -1;
                                            {/* //console.log('activ statues' + is_active) */ }
                                            const onChange = (e, row, _id) => {
                                                const { name, value } = e.target

                                                setUpdatedValues((prev) => {
                                                    return { ...prev, [name]: value }
                                                })


                                            }
                                            const handleUpdate = async () => {
                                                setLoading(true)
                                                const token = localStorage.getItem("token");
                                                const authToken = token;




                                                const config = {
                                                    headers: {
                                                        'content-type': 'application/json',
                                                        Authorization: `Bearer ${authToken}`,
                                                    },
                                                };
                                                const res = await axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updatedTelegramDetails/${_id}`, updatedValues, config);

                                                toast.success('Updated')
                                                setLoading(false)
                                                setTimeout(() => window.location.reload()
                                                    , 800)
                                            }
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                                                    <TableCell  >
                                                        <Typography variant='subtitle2' >{index + 1 + rowsPerPage * page}</Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" pl={2}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                                                            <Typography variant="subtitle2" noWrap>
                                                                {isEdit && selectedId === _id ? (<Input
                                                                    onChange={(e) => onChange(e, row, _id)}
                                                                    // value={value}
                                                                    name='title'
                                                                    placeholder={title}



                                                                />) : <span>{title}</span>}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left"><a href={channel_url} style={{ color: 'black' }}>
                                                        <LinkIcon

                                                            sx={{
                                                                cursor: 'pointer', '&:hover': {
                                                                    color: 'red'
                                                                }
                                                            }} />
                                                    </a></TableCell>


                                                    <TableCell align="left">{isEdit && selectedId === _id ? (<TextField
                                                        onChange={(e) => onChange(e, row)}
                                                        // value={value}
                                                        label={is_active ? 'Active' : 'Inactive'}
                                                        name='is_active'
                                                        placeholder={is_active ? 'Active' : 'Inactive'}
                                                        size="small"
                                                        select
                                                        sx={{ width: '100px' }}>
                                                        <MenuItem value={true}>Active</MenuItem>
                                                        <MenuItem value={false}>Inactive</MenuItem>
                                                    </TextField>) : <span style={{ background: is_active ? 'green' : 'red', padding: '5px', borderRadius: '10px', color: 'white', fontWeight: '500' }}>{is_active ? 'Active' : 'Inactive'}</span>}</TableCell>
                                                    {isEdit && selectedId === _id ? (< TableCell align="left">
                                                        <Button variant='outlined' onClick={handleUpdate}>Update</Button>
                                                    </TableCell>) : null}


                                                    <TableCell align="right" onClick={() => setSelectedId(_id)}>
                                                        <UserMoreMenu id={_id} req='DeleteTelegramDetails' isEdit={isEdit} setIsEdit={setIsEdit} setLoading={setLoading} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </Card>
                </Container>
            </Page>)}
    </>
    );
}


























