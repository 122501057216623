import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import MyCkEditor from '../../../components/hook-form/MyCkEditor';

// ----------------------------------------------------------------------

export default function AddAnnouncement() {
  const theme = useTheme()
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false)
  const [restrict, setRestrict] = useState('')

  const [pdf, setPdf] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [type, setType] = useState('none');
  const [valueId, setValueId] = useState('');
  const [list, setList] = useState([]);
  const [ dataForDesc , setDataForDesc ] = useState('');

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    // description: Yup.string().required('Description required'),
    link: Yup.string()
      .required('Link Banner Required')
      .oneOf(['none', 'batch', 'testSeries'], 'Invalid Link Value'),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),

    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title: '',
    description: '',
    link: '',
    linkWith: '',
    isActive: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar
  const handleValueChange = (value) => {
    setValueId(value);
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },

    };
    let reqObject = {
      title: data.title,
      // description: data.description,
      description :  dataForDesc ,
      link: data.link,
      linkWith: (data.link !== '' && data.link !== 'none') ? valueId?._id : "",
      isActive: data.isActive
    }
    setProgress(0);
    axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/createAnnouncement`, reqObject, config).then((response) => {
      //console.log(response.data);
      if (response.data.msg === 'New Announcement created successfully') {
        toast.success(response.data.msg);
        setLoading(false);
        setPdf(false);
        reset();
        window.location.href = "/dashboard/announcements";
      } else {
        // toast.er(ror('Announcement not created')
        toast.error(response.data.msg);
        setLoading(false);
      }

    })
      .catch(e => {
        //console.log(e);
      })


  };

  //get batch or get testSeries
  useEffect(() => {
    if (type !== '' && type !== 'none') {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/${type == "batch" ? "getBatches" : "getallTestSeriesdetails"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
        // let data = responseJson.data.filter((item) => item.is_active == true)
        // setList(data);

      }
      fetchDetails();
    }
    setLoading(false);

  }, [type]);

  return (<>
    <Stack spacing={3} px={5} >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>


          <RHFTextField name="title" label="Title" />
          <Stack direction="row" spacing={3} >
            <Stack sx={{ width: (type !== 'none' && type !== '') ? "50%" : "100%" }}>
              <RHFTextField name="link" label="Link Announcement" type='checkbox' select >
                <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                <MenuItem value='batch' onClick={() => setType('batch')}>Batch</MenuItem>
                <MenuItem value='testSeries' onClick={() => setType('testSeries')}>Test Series</MenuItem>
              </RHFTextField>

            </Stack>

            {(type !== 'none' && type !== '') &&
              <Stack sx={{   width: '50%' }}>
                <Select
                  inputId="valueId"
                  options={list}
                  isMulti={false}
                  value={valueId}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  onChange={handleValueChange}
                />
              </Stack>

            }
          </Stack>
          {/* <RHFTextField name="description" label="Description" />
           */}
           <Stack sx={{ marginTop : '20px'}}>
              <MyCkEditor dataForDesc={dataForDesc} setDataForDesc={setDataForDesc} />
           </Stack>
          
          <Stack direction="row" spacing={3} >

            <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
              setMsg('Required')
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
    </Stack>
  </>
  );
}
