
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm ,  Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
// components

import { FormProvider, RHFTextField , } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import MyCkEditor from '../../../components/hook-form/MyCkEditor'

// ----------------------------------------------------------------------

export default function EditCommunity() {
    const { postId } = useParams();
    const theme=useTheme()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const[community , setCommunity] = useState();
    const [dataForDesc , setDataForDesc] = useState();
    const [ values , setValues] = useState({});
    const[type, setType] = useState(values?.link || 'none');
    const[ showImage , setShowImage] = useState(false);
    const [imageName , setImageName] = useState("")

    const RegisterSchema = Yup.object().shape({});

    const defaultValues = {
        title: '',
        tags: '',
        desc:'',
        language:'',
        isActive: '',
    };
    const [file, setFile] = useState()

    const changeHandler = async (event) => {
        
            setFile(event.target.files)
            var fileName = document.getElementById('file').value.toLowerCase();
              if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
             else { setRestrict('JPG Or PDF') }
    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    useEffect(() => {
        
        const token = localStorage.getItem("token");
    async function fetchDetails() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/community/getCMSPostById/${postId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          setCommunity(responseJson.data);
          checkDetails(responseJson.data);
          
    }
    fetchDetails();
    
    setLoading(false);
    
}, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        // console.log( "line97"+name + " "+value);
        setValues(prev => ({ ...prev, [name]: value }))
    }
    const onSubmit = async (data) => {
        
            const formData = new FormData();

            setLoading(true);
            if (file) {
                Object.values(file).forEach(file => {
                        formData.append("file", file);
                    })

            }
            formData.append('title', values?.title);
            formData.append('desc', dataForDesc);
            formData.append('tags', values?.tags);
            // formData.append('language', 'en');
            formData.append('isActive', values?.isActive); 
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };

            setProgress(0);
            axios.put(`${process.env.REACT_APP_LIVE_URL}/community/updateCMSPost/${postId}`, formData, config).then((response) => {
                if (response.data.status == false) {
                    toast.error(response.data.msg);
                    setLoading(false);
                    
                }else{
                    setLoading(false);
                    toast.success("Community Updated")
                    setPdf(false);
                    reset();
                    window.location.href = "/dashboard/community";
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        
    };

    const handleDelete = () =>{
        setShowImage(false)
        setFile("");
    }

    const checkDetails = (community) => {
        setValues( prev => ({
            ...prev,
            title : community?.title,
            tags : community?.tags,
            featuredImage :community?.featuredImage,
            language: community?.language,
            isActive: community?.isActive,
            

        }));
        setDataForDesc(community?.desc);
        if( community?.featuredImage){
            setShowImage(true);
        }
    }
    
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Update Community - {values?.title}</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title"  value={values?.title} onChange={onChange2}/>
                    <Stack direction="row" spacing={2}>
                        { showImage ? 
                        <Stack direction='column' sx={{ width: '100%', position: 'relative' }} alignItems="center" spacing={1} >
                        <IconButton sx={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => handleDelete()}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                        <img src={values?.featuredImage} alt="FeaturedImage" style={{ height: '100px' , width:"200px"}}/>

                    </Stack>
                        :<RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="Select image"
                            error={!file && val}
                            helperText='Image Only ( Maximum allowed size 1MB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />}
                        <Stack direction='row' sx={{width : '100%', alignItems: "center"}}>
                                    <InputLabel sx={{width : '30%',marginLeft:"5px", marginRight:"1px", alignItems:'center'}}>Tag : </InputLabel> 
                                    <RHFTextField name="tags" value={values?.tags} label={values?.tags == "news" ? "News" : values?.tags == "blog" ? "Blog" : "Notification"} onChange={onChange2} select>
                                        <MenuItem value='news'>News</MenuItem>
                                          <MenuItem value="blog">Blog</MenuItem>
                                          <MenuItem value="notification">Notification</MenuItem>
                                       </RHFTextField>
                                  </Stack>
                        
                    </Stack>
                    <Stack direction='column'>
                     <Typography>Description</Typography>
                      <MyCkEditor dataForDesc={dataForDesc} setDataForDesc={setDataForDesc} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="language" label="Language" value={values?.language} onChange={onChange2} select>
                           <MenuItem value='en'>English</MenuItem>
                           <MenuItem value="hi">Hindi</MenuItem>
                           <MenuItem value="enhi">Bilingual</MenuItem>
                         </RHFTextField>
                        <RHFTextField name="isActive"  label={values?.isActive ? "Active" : "Inactive"} value={values?.isActive} onChange={onChange2} select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}>
                        Update Community
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack>
    </>
    );
}
