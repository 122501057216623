import { Box, Grid, Pagination, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Page from '../../../components/Page'
import BatchCard from './Components/BatchCard'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import "./students.css"
import Table from './Components/Table';
import StudentCard from './Components/StudentCard';
import { useParams } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@emotion/react';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


//Header For Table
const sampleAssignmentHeader = [
    { NO: 'NO' },
    { NO: 'Assignment' },
    { NO: 'Total Point' },
    { NO: 'Point' },
    { NO: 'BatchPoint' }
]
const feeBillingHeader = [
    { NO: 'NO' },
    { NO: 'Course' },
    { NO: ' Order Id' },
    { NO: 'Billing Details' },
    { NO: 'Amount' }
]
const websiteBillingHeader = [
    { NO: 'NO' },
    { NO: 'Course' },
    { NO: ' Transaction Id' },
    { NO: 'Ease PayId' },
    { NO: 'Transaction Date' },
    { NO: 'Amount' }
]
const storeBillingHeader = [
    { NO: 'NO' },
    { NO: 'Product' },
    { NO: ' Transaction Id' },
    { NO: 'Ease PayId' },
    { NO: 'Transaction Date' },
    { NO: 'Amount' }
]
const QuizHeader = [
    { NO: 'NO' },
    { NO: 'Quiz Name' },
    // { NO: 'Test Name' },
    { NO: 'Score' },
    { NO: 'Attempted On' },

]
const wishListHeader = [
    { NO: 'NO' },
    { NO: 'Product Name' },
    // { NO: 'Test Name' },
    // { NO: 'Score' },
    // { NO: 'Attempted On' },

]
const cartHeader = [
    { NO: 'NO' },
    { NO: 'Product Name' },
    // { NO: 'Test Name' },
    { NO: 'Quantity' },
    // { NO: 'Attempted On' },

]
const TestSeriesHeader = [
    { NO: 'NO' },
    { NO: 'Test Series Name' },
    { NO: 'Test Name' },
    { NO: 'Score' },
    { NO: 'Attempted On' },

]
const TimerHeader = [
    { NO: 'NO' },
    { NO: 'Timer Title' },
    // { NO: 'Test Name' },
    { NO: 'Time Duration' },
    { NO: 'Created On' },

]
const sampleAssignment = [
    {
        sno: 1,
        assignment: 'Assignments-1',
        total: 20,
        point: 10,
        batchPoint: '20/20'
    },
    {
        sno: 2,
        assignment: 'Assignments-2',
        total: 20,
        point: 10,
        batchPoint: '20/20'
    },
    {
        sno: 3,
        assignment: 'Assignments-3',
        total: 20,
        point: 10,
        batchPoint: '20/20'
    },
    {
        sno: 4,
        assignment: 'Assignments-4',
        total: 20,
        point: 10,
        batchPoint: '20/20'
    },
]
const FeeBilling = [
    {
        sno: 1,
        assignment: 'SD Campus',
        total: '11-11-11',
        point: 'SC00234',
        batchPoint: '$500'
    },
    {
        sno: 2,
        assignment: 'English',
        total: '12-11-2022',
        point: 'SC00234',
        batchPoint: '$500'
    },

]



const StudentProfile = () => {

    const theme = useTheme();
    const iconStyle = {
        background: theme.palette.primary.mainThemeLight,
        padding: '5px',
        color: theme.palette.primary.mainTheme,
        color: 'purple',
        borderRadius: '50%',
        cursor: 'pointer'
    }
    //States
    const [value, setValue] = useState(0)


    //States
    const [disableName, setDisableName] = useState('left');



    //States
    const [slider, setslider] = useState(0);


    //Id
    const { data } = useParams()



    //Tabs 
    const [valueForTabs, setValueForTabs] = React.useState(0);
    const[quizs , setQuizs] = React.useState();


    const handleChange = (event, newValue) => {
        setValueForTabs(newValue);
    };


    //Fetching Data
    const [users, usersSet] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/studentDetail/${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setLoading(false)
            usersSet(responseJson.data);
        }

        fetchUsers();
    }, []);




    //Scroll Logic
    const handleScroll = (direction) => {

        if (users?.batchesTakenByUser.length - 3 == slider) {
            setDisableName('right')

        }
        if (slider == 0) {
            setDisableName('left')

        }
        if (direction == 'left' && slider > 0) {
            setDisableName('none')
            setslider(slider - 1);
            setValue(value + 251)
        }
        else if (direction == 'right' && slider < users?.batchesTakenByUser.length - 3) {
            setDisableName('none')
            setslider(slider + 1);
            setValue(value - 251)
        }

    }

    //Test Series
    //Getting Details
    const [loading, setLoading] = useState(true)
    const [testSeries, setTestSeries] = useState()
    const [test, setTest] = useState();
    const[webSitePayments , setWebSitePayments] = useState();
    const[storePayments , setStorePayments] = useState();
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getTestSeriesPurchased/${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTestSeries(responseJson.data);
            const fullResponseTest = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAttemptedTestAdminPanelSide?userId=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJsonTest = await fullResponseTest.json();
            setTest(responseJsonTest);
            const fullResponseQuiz = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAttemptedQuizsByUserId?id=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJsonQuiz = await fullResponseQuiz.json();
            setQuizs(responseJsonQuiz?.data ?? []);
            // website paymnets for user
            const fullResponseWebsite = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getWebSitePaymentsByUserId?id=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJsonWebsite = await fullResponseWebsite.json();
            // store Payments for user
            setWebSitePayments(responseJsonWebsite?.data ?? []);
            const fullResponseStore = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getStorePaymentsByUserId?id=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJsonStore = await fullResponseStore.json();
            setStorePayments(responseJsonStore?.data ?? []);
            
            setLoading(false)
            if (responseJson.msg === 'not an admin') {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("details")
            };
        }

        fetchUsers();
    }, []);


    //Timer
    //Getting Details

    const [timer, setTimer] = useState()
    // const [test, setTest] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getTimerByStudentId/${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTimer(responseJson.data);

            setLoading(false)
            if (responseJson.msg === 'not an admin') {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("details")
            };
        }

        fetchUsers();
    }, []);
    //console.log(timer)

    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }} > <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack >) : (<Page title='Student Profile'>
            <Grid container spacing={1} sx={{ height: '70vh' }}>
                <Grid item xs={12} sm={12} md={8} sx={{ height: '860px', overflow: 'auto' }}>
                    <Stack sx={{ height: '100%' }}>
                        <Box>
                            <Stack direction="row" justifyContent="space-between" pl={1} pr={2}>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                    Purchased Course
                                </Typography>

                            </Stack>
                            <Box sx={{ minWidth: '100%', minHeight: '130px' }}>
                                <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{ minWidth: '100%', minHeight: '130px' }}>
                                    <ArrowBackIosOutlinedIcon sx={{ ...iconStyle, color: disableName == 'left' ? 'lightgray' : theme.palette.primary.mainTheme }} onClick={() => handleScroll('left')} />
                                    <Stack direction="row" spacing={3} pl={1} pr={2} pb={1} mt={1} className="BatchScroll" sx={{ minWidth: '95%' }}>
                                        {users?.batchesTakenByUser.map((item, i) => {
                                            return <BatchCard value={value} batch={item} />
                                        })}
                                        {users?.batchesTakenByUser.length == 0 && <BatchCard value={value} batch={null} title="No Batch" />}
                                    </Stack>
                                    <ArrowForwardIosOutlinedIcon sx={{ ...iconStyle, color: disableName == 'right' ? 'lightgray' : theme.palette.primary.mainTheme }} onClick={() => handleScroll('right')} />
                                </Stack>
                                {/* <Stack direction="row" justifyContent="space-between" pl={1} pr={2}>
                                    <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                        Purchased Test Series
                                    </Typography>
                                </Stack> */}
                                {/* <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{ minWidth: '100%', minHeight: '130px' }}>
                                    <ArrowBackIosOutlinedIcon sx={{ ...iconStyle, color: disableName == 'left' ? 'lightgray' : theme.palette.primary.mainTheme }} onClick={() => handleScroll('left')} />
                                    <Stack direction="row" spacing={3} pl={1} pr={2} pb={1} mt={1} className="BatchScroll" sx={{ minWidth: '95%' }}>
                                        {testSeries?.map((item, i) => {
                                            return <BatchCard value={value} batch={item} title={item?.testseries_id?.testseries_name} />
                                        })}
                                        {testSeries?.length == 0 && <BatchCard value={value} batch={null} title="No Test Series" />}
                                    </Stack>
                                    <ArrowForwardIosOutlinedIcon sx={{ ...iconStyle, color: disableName == 'right' ? 'lightgray' : theme.palette.primary.mainTheme }} onClick={() => handleScroll('right')} />
                                </Stack> */}
                                {/* <Stack direction="row" justifyContent="space-between" pl={1} pr={2}>
                                    <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                        Purchased Quiz
                                    </Typography>
                                </Stack> */}
                                {/* <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{ minWidth: '100%', minHeight: '130px' }}>
                                    <ArrowBackIosOutlinedIcon sx={{ ...iconStyle, color: disableName == 'left' ? 'lightgray' :  theme.palette.primary.mainTheme }} onClick={() => handleScroll('left')} />
                                    <Stack direction="row" spacing={3} pl={1} pr={2} pb={1} mt={1} className="BatchScroll" sx={{ minWidth: '95%' }}>
                                        <BatchCard value={value} batch={null} title="Purchased Quiz" /> */}
                                {/* {testSeries?.map((item, i) => {
                                            return <BatchCard value={value} batch={item} title={item?.testseries_id?.testseries_name} />
                                        })}
                                        {testSeries?.length == 0 && <BatchCard value={value} batch={null} title="No Test Series" />} */}
                                {/* </Stack>
                                    <ArrowForwardIosOutlinedIcon sx={{ ...iconStyle, color: disableName == 'right' ? 'lightgray' :  theme.palette.primary.mainTheme }} onClick={() => handleScroll('right')} />
                                </Stack> */}


                            </Box>
                        </Box>

                        {/* Tabs */}

                        <Box sx={{ width: '100%', marginTop : "13px"}}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueForTabs} onChange={handleChange} textColor="red"
                                    variant="fullWidth"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                >
                                    {/* <Tab label="Test Attemted" {...a11yProps(0)} /> */}
                                    <Tab label="Test Attempted" {...a11yProps(0)} />
                                    <Tab label="WebSite payments" {...a11yProps(1)} />
                                    <Tab label="Store Payments" {...a11yProps(1)} />
                                    <Tab label="App Payments" {...a11yProps(3)} />
                                    <Tab label="Timer" {...a11yProps(4)} />
                                    <Tab label="WishList" {...a11yProps(5)} />
                                    <Tab label="Cart" {...a11yProps(6)} />
                                </Tabs>
                            </Box>
                            {/* <TabPanel value={valueForTabs} index={0}>

                                <Box>
                                    <Typography pl={1} variant='h6' sx={{ fontWeight: '600' }}>Test Attemted</Typography>
                                    <Table sampleAssignment={sampleAssignment} sampleAssignmentHeader={TestSeriesHeader} dataArrayForTest={test?.data} use='Test' />
                                </Box>
                            </TabPanel> */}
                            <TabPanel value={valueForTabs} index={0}>
                                <Box>
                                    <Typography mt={1} pl={1} variant='h6' sx={{ fontWeight: '600' }}> Test Attempted</Typography>
                                    <Table sampleAssignment={sampleAssignment} sampleAssignmentHeader={QuizHeader} dataArray={quizs} use='Quiz' tableName='Quiz'/>
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueForTabs} index={1}>
                                <Box>
                                    <Typography pl={1} variant='h6' sx={{ fontWeight: '600' }}>WebSite Payments</Typography>
                                    <Table sampleAssignment={sampleAssignment} sampleAssignmentHeader={websiteBillingHeader} dataArray={webSitePayments} use="WebSitePayment" />
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueForTabs} index={2}>
                                <Box>
                                    <Typography pl={1} variant='h6' sx={{ fontWeight: '600' }}>Store Payments</Typography>
                                    <Table sampleAssignment={sampleAssignment} sampleAssignmentHeader={storeBillingHeader} dataArray={storePayments} use="StorePayment"/>
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueForTabs} index={3}>

                                <Box>
                                    <Typography mt={1} pl={1} variant='h6' sx={{ fontWeight: '600' }}>App Payments</Typography>
                                    <Table sampleAssignment={FeeBilling} sampleAssignmentHeader={feeBillingHeader} dataArray={users?.allTransactions} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueForTabs} index={4}>

                                <Box>
                                    <Typography mt={1} pl={1} variant='h6' sx={{ fontWeight: '600' }}>Timer</Typography>
                                    <Table sampleAssignment={FeeBilling} sampleAssignmentHeader={TimerHeader} dataArray={timer} use="Timer" />
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueForTabs} index={5}>

                                <Box>
                                    <Typography mt={1} pl={1} variant='h6' sx={{ fontWeight: '600' }}>Wishlist</Typography>
                                    <Table sampleAssignment={sampleAssignment} sampleAssignmentHeader={wishListHeader} dataArray={users?.wishLists} use="WishList" tableName="WishList"/>
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueForTabs} index={6}>

                                <Box>
                                    <Typography mt={1} pl={1} variant='h6' sx={{ fontWeight: '600' }}>Cart</Typography>
                                    <Table sampleAssignment={sampleAssignment} sampleAssignmentHeader={cartHeader} dataArray={users?.storeCarts} use="StoreCart" tableName="StoreCart"/>
                                </Box>
                            </TabPanel>

                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={4} sx={{ height: '110vh' }}>
                    <StudentCard role='Student' detail={users?.userDetails} />
                </Grid>
            </Grid>

        </Page>)
        }</>
    )
}

export default StudentProfile