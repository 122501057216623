import * as Yup from 'yup';
import { useState, useEffect } from 'react';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------

export default function AddPreviousYearQuestionPapers() {

    const theme =useTheme()

    const[ categorialDetails , setCategorialDetails] = useState([]);
    const [subCategorialDetails, setSubCategorialDetails] = useState([]);
    const[selectCategory, setSelectCategory] = useState("");
    const [loading, setLoading] = useState(true)



    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);


    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        // file_url: Yup.string().required('Url  is required'),
        language: Yup.string().required('Language is required'),
        category: Yup.string().required('Category Required'),
        subCategory: Yup.string().required('Sub Category required'),
        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        subject: '',
        // file_url: '',
        language: '',
        notes_type: '',
        is_active: '',
        created_At:'',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    const [file, setFile] = useState()


    const changeHandler = async (event) => {
        setFile(event.target.files[0])


    };
    const onSubmit = async (data) => {
        if (file?.size) {
            setLoading(true)
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', data.title);
            formData.append('category', data.category);
            formData.append('subCategory', data.subCategory);
            formData.append('language', data.language);
            formData.append('notes_type', data.notes_type);
            formData.append('is_active', data.is_active);
            formData.append('created_At', data.created_At);
            // for( const pair of formData){
            //     console.log(pair[0] + " " + pair[1]);
            // }
            // formData.append('is_active', data.is_active);
            // formData.append('fileName', file.name);
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addPreviousYearQuestionPapersDetails`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.msg === 'added Previous Year Question Paper details successfully') {
                    toast.success(response.data.msg)
                    setFile(null)
                    setShowBanner(false)
                    setFocused(false)
                    setVal(false)
                    setMsg('')
                    setLoading(false)
                    reset()
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        }



    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchCategory() {

            const resCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategorailDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const resultCategory = await resCategory.json();
            setCategorialDetails(resultCategory.data);
            setLoading(false);
            
        }
        

        fetchCategory();
    }, []);


    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchSubCategory(){
            const resSubCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubCategorialDetailsByCategoryId/${selectCategory}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const resultSubCategory = await resSubCategory.json();
            setSubCategorialDetails(resultSubCategory.data);
            
            
            setLoading(false);
        }
        fetchSubCategory();
    } ,[selectCategory])
    const [focus, setFocused] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    //console.log(showBanner)
    //console.log(file)
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Previous Year Question Papers</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>

                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="title" label="Title" />
                        {/* <RHFTextField name="subject" label="Subject" select>
                            {subCategorialDetails?.map((sub) => {
                                return <MenuItem value={sub.title}>{sub.title}</MenuItem>
                            })}
                        </RHFTextField> */}
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="category" label="category" select>
                            {categorialDetails?.map((category) => {
                                return <MenuItem key={category._id} value={category._id} onClick={(e) => setSelectCategory(e.target.dataset.value)}>{category.title}</MenuItem>
                            })}
                        </RHFTextField>
                        <RHFTextField name="subCategory" label="Sub Category" select>
                            {subCategorialDetails?.map((sub) => {
                                return <MenuItem value={sub._id}>{sub.title}</MenuItem>
                            })}
                        </RHFTextField>

                    </Stack>

                    <Stack direction="row" spacing={3}>

                        <RHFTextField name="language" label="Language" select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                            <MenuItem value="enhi">Bilingual</MenuItem>
                        </RHFTextField>
                        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>

                            <RHFTextField name="file_url" label="PDF"
                                error={!file && val}
                                onChange={changeHandler}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}

                                onClick={() => setShowBanner(true)}
                                type={showBanner || focus ? "file" : "text"}
                                helperText="Pdf Files Only"
                                inputProps={{ accept: "application/pdf" }}
                            />
                            {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}

                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={3}>

                        {/* <RHFTextField name="notes_type" label="Type" select >
                            <MenuItem value='shorts'>Shorts</MenuItem>
                            <MenuItem value='NCERT'>NCERT</MenuItem>
                        </RHFTextField> */}
                        <RHFTextField name="is_active" label="Status" select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
