import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridToolbar,
    GridActionsCellItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarDensitySelector,
    useGridApiContext,
    gridExpandedSortedRowIdsSelector,
    GridRowEditStopReasons
  } from "@mui/x-data-grid";
  import IconButton from '@mui/material/IconButton';
  import Tooltip from '@mui/material/Tooltip';
  import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

function CustomToolbar ({rows , columns }){
    const apiRef = useGridApiContext();
    const exportAllRows = async () => {
       const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
      const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
      const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
      const csvHeader = columnIds.join(', ');
      const csvContent = filteredRows
        .map((row ,index) => {
          const rowValues = columnIds.map((columnId) => {
            if (columnId === 'admin') {
              return `${row.admin.name}(${row.admin.role})`;
            }
            else if (columnId === 'teacher') {
              return row.teacher[0]?.FullName
            }
            else if (columnId === 'batch') {
              return row.batch?.batch_name
            }
            else if (columnId === 'language') {
              return row.language == 'hi' ? "Hindi" : row.language ==  "en" ? "English" : "Hindi/English"
            }
            else if (columnId === 'lecture_type') {
              return row.lecture_type == 'YT' ? "Youtube" : row.lecture_type ==  "APP" ? "App" : "Two Way"
            }
            else if ( columnId === 'sno'){
              return index+1
            }
            return row[columnId]?.toString()?.replaceAll(",","");
          });
          return rowValues.join(', ');
        })
        .join('\n');
      const fullCsvContent = `${csvHeader}\n${csvContent}`;
      const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'teachersSchedule.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
         
        <GridToolbarDensitySelector />
        <Tooltip title="Export All">
          <IconButton onClick={exportAllRows}>
            <SaveAltIcon style={{color : 'red'}}/>
          </IconButton>
        </Tooltip>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };
  


export default function TodayScheduleGrid() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getMySchedularTeacherSide`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const access = getAccess();

  const columns = [
    {
      field: "sno",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      // editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "lecture_title",
      headerName: "Lecture Name",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "batch",
      headerName: "Batch",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <span>{params.value?.batch_name}</span>,

      // editable: true,
    },
    {
      field: "lecture_type",
      headerName: " Platfrom Type",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      // editable: true,
      renderCell: (params) => (
        <div>
          {params.value == "YT"
            ? "Youtube"
            : params.value == "APP"
            ? "App"
            : params.value == "TWOWAY"
            ? "Two Way Communication"
            : "" }
        </div>
      ),
      
    },
    {
      field: "LiveOrRecorded",
      headerName: "Lecture Type",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "teacher",
      headerName: "Teacher Assigned",
      type: "object",
      width: 190,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value[0]?.FullName ?? "NA"}</div>
      ),
      getApplyQuickFilterFn : (value) => {
        return ( params ) => {
          return ( params?.value[0]?.FullName?.includes(value) )
        }
      }
    },

    {
      field: "language",
      headerName: "Language",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div >
          {params.value == 'hi' ? "Hindi" : params.value ==  "en" ? "English" : "Hindi/English"}
        </div>
      ),
    },
    {
      field: "starting_date",
      headerName: "Starting Date & Time",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
  ];
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Typography variant='h6' pl={2} pt={2} sx={{ textAlign: 'center' }}>{access?.role == 'teacher' ? 'My Schedule' : "Today's Schedule"}</Typography>

      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        editMode="row"
        autoHeight={true}
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50]}
        // slots={{
        //   //   toolbar: EditToolbar,
        //   toolbar: GridToolbar,
        // }}
        slots={{
            toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
          }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
