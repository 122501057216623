import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import '../students.css'
import { useTheme } from '@emotion/react'
import StorePaymentTable from './StorePaymentTable'

const Table = (props) => {
    const { sampleAssignment, sampleAssignmentHeader, dataArray, dataArrayForTest, use , tableName} = props
    const theme = useTheme()
    // //console.log(dataArrayForTest)
    // console.log(dataArray);
    return (
        <Container >
            <Box mt={1} pt={2} sx={{ height: '100%', minHeight: '100px', background: theme.palette.primary.mainThemeLight, borderRadius: '10px', boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.25)' }}>
               { use != "StorePayment" && <Stack direction="row" mb={0.5} sx={{ overflowX: "scroll" }}>
                    {sampleAssignmentHeader.map((item, i) => {
                        return <Typography key={i} fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '600', minWidth: '100px', maxWidth: '180px' }}>{item.NO}</Typography>
                    })}

                </Stack>}
                <Divider color="black" />
                {(use == 'WishList' && tableName=="WishList")&& <Box className='tableScroll' >
                    {dataArray?.products?.length > 0 ? dataArray?.products.map((item, i) => {
                        // let Tdate = item?.transactionDate.split(" ")[0]

                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700', minWidth: '100px', maxWidth: '180px' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.title ? item?.title : '-'}</Typography>
                        </Stack>
                            <Divider />

                        </>
                    }) : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}
                {(use == 'StoreCart' && tableName=="StoreCart")&& <Box className='tableScroll' >
                    {dataArray?.products?.length > 0 ? dataArray?.products.map((item, i) => {
                        // let Tdate = item?.transactionDate.split(" ")[0]

                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700', minWidth: '100px', maxWidth: '180px' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.productId?.title ? item?.productId?.title : '-'}</Typography>
                            <Typography fontSize="small" sx={{ marginLeft: '12px', fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.quantity ? item?.quantity : '-'}</Typography>
                          
                        </Stack>
                            <Divider />

                        </>
                    }) : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}
                {(use == 'Quiz' && tableName=="Quiz")&& <Box className='tableScroll' >
                    {dataArray?.length > 0 ? dataArray?.map((item, i) => {
                        // let Tdate = item?.transactionDate.split(" ")[0]

                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700', minWidth: '100px', maxWidth: '180px' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.quizName ? item?.quizName : '-'}</Typography>
                            <Typography fontSize="small" sx={{ marginLeft: '12px', fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.score ? item?.score : '-'}</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.attempted ? item?.attempted : '-'}</Typography>
                            {/* <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>₹{item?.amount}</Typography> */}
                        </Stack>
                            <Divider />

                        </>
                    }) : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}
                {use == 'WebSitePayment' && <Box className='tableScroll' >
                    {dataArray?.length > 0 ? dataArray?.map((item, i) => {
                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700', minWidth: '100px', maxWidth: '180px' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.course ? item?.course : '-'}</Typography>
                            <Typography fontSize="small" sx={{ marginLeft: '12px', fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.txnId ? item?.txnId : '-'}</Typography>
                            <Typography fontSize="small" sx={{ marginLeft: '12px', fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.easePayId ? item?.easePayId : '-'}</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.txnDate ? item?.txnDate :  '-'}</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>₹{item?.txnAmount}</Typography>
                        </Stack>
                            <Divider />

                        </>
                    }) : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}
                {use == 'StorePayment' &&  
                <Box className='tableScroll' >
                    {dataArray?.length > 0 ? 
                     <StorePaymentTable data={dataArray}/>

                     : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}

                {(use != 'Timer' && use !="StorePayment" && use !="WebSitePayment" && use !="Test" && tableName != 'Quiz' && tableName !="StoreCart" && tableName !="WishList" ) && <Box className='tableScroll' >
                    {dataArray?.length > 0 ? dataArray?.map((item, i) => {
                        let Tdate = item?.transactionDate.split(" ")[0]

                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700', minWidth: '100px', maxWidth: '180px' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.batch_name ? item?.batch_name : '-'}</Typography>
                            <Typography fontSize="small" sx={{ marginLeft: '12px', fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.userOrederId ? item?.userOrederId : '-'}</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>{item?.transactionDate ? Tdate : '-'}</Typography>
                            <Typography fontSize="small" sx={{ fontSize: '12px', flex: '1', textAlign: 'center', minWidth: '100px', maxWidth: '180px' }}>₹{item?.amount}</Typography>
                        </Stack>
                            <Divider />

                        </>
                    }) : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}
                
                {use == 'Test' && <Box className='tableScroll' >
                    {dataArrayForTest?.length > 0 && dataArrayForTest?.map((item, i) => {

                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', minWidth: '200px', maxWidth: '200px' }}>{item?.testSeries_id?.testseries_name ? item?.testSeries_id?.testseries_name : '-'}</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', }}>{item?.test_id ? item?.test_id?.Test_title : '-'}</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', }}>{item?.Score ? item?.Score : '-'}</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', }}>{item?.created_at}</Typography>
                        </Stack>
                            <Divider />

                        </>
                    })}

                </Box>}
                {use == 'Timer' && <Box className='tableScroll' >
                    {dataArray?.length > 0 ? dataArray?.map((item, i) => {
                        {/* let Tdate = item?.created_at.split(" ")[0] */ }
                        return <><Stack key={i} direction="row" mt={2} >
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', fontWeight: '700' }}>{i + 1}-</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', }}>{item?.TimerTitle}</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center' }}>{item?.timerDuration}</Typography>
                            <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center' }}>{item?.created_at.split(" ")[0]}</Typography>
                            {/* <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', }}>{item?.test_id ? item?.test_id?.Test_title : '-'}</Typography> */}
                            {/* <Typography fontSize="small" sx={{ flex: '1', textAlign: 'center', }}>{item?.Score ? item?.Score : '-'}</Typography> */}
                        </Stack>
                            <Divider />

                        </>
                    }) : <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '60px' }}> No Data</Stack>}

                </Box>}
            </Box>
        </Container>
    )
}

export default Table