import * as React from "react";
import {
  DataGrid,
  GridToolbar 
} from "@mui/x-data-grid";

const StorePaymentTable = ({ data }) => {
  const [rows, setRows] = React.useState(data);
  const columns = [
    {
      field: "sno",
      headerName: "S.NO",
      type: "object",
      width: 75,
      align: "left",
      headerAlign: "left",
    },
    
    {
      field: "products",
      headerName: "Products",
      type: "object",
      width: 300,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          {params.value.map((detail, index) => (
            <div key={index}>
              <strong>Title:</strong> {detail?.productId?.title}<br />
              <strong>Quantity:</strong> {detail?.quantity}<br />
              {/* <strong>Code:</strong> {detail.code} */}
            </div>
          ))}
        </div>
      ),
      
    },
      {
        field: "txnId",
        headerName: "Transaction Id",
        type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: true,
        
      },
      {
        field: "easePayId",
        headerName: "ease PayId",
        type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: true,
        
      },
    //   easePayId
      {
        field: "txnAmount",
        headerName: "Amount",
        type: "object",
        width: 100,
        align: "left",
        headerAlign: "left",
        
      },
      
      
      {
        field: "txnDate",
        headerName: "Transaction Date",
        // type: "object",
        width: 250,
        align: "left",
        headerAlign: "left",
      },
      
      
]

  

  return (
   
      <DataGrid
        rows={rows}
        autoHeight
        columns={columns}
        getRowHeight={() => 'auto'} 
        getEstimatedRowHeight={() => 200} 
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        // loading={loading}
        pageSizeOptions={[7, 25,50]}
        slots={{
        //   toolbar: EditToolbar,
           toolbar: GridToolbar 
        }}
        slotProps={{
          toolbar: { setRows, showQuickFilter: true,}
        }}
      />
    
  );
}


export default StorePaymentTable;
