import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
  Box,
  Divider,
  Grid,
} from "@mui/material";
// components
import Page from "../../../components/Page";
// mock
// importusers ? from '../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './quiz.css';
// ------------------------------------------------

export default function StudentResults() {
  const [questionSelected, setQuestionSelected] = useState(false);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  //Getting Id and Name Of quiz
  const { quizId, quizTitle, studentId } = useParams();

  // Quiz Data of Student
  const [analysis, setAnalysis] = useState();
  const [difficulty, setDifficulty] = useState();
  const [myScore, setMyScore] = useState();
  const [summary, setSummary] = useState();
  const [toperScore, setToperScore] = useState();
  const [totalMarks, setTotalMarks] = useState();

  const [quizD, setQuizD] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizDetails/${quizId}`,
        {
          // const fullResponse = await fetch(`http://localhost:5000/api/v1/adminPanel/getQuizDetails/${quizId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setQuizD(responseJson.data);
    }

    fetchUsers();
  }, []);
  const [selectedIdOfQuestion, setSelectedIdOfQuestion] = useState("");

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizResultByQuizIdAndStudentId/${quizId}/${studentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      if (responseJson.data == null) {
        console.log(
          "Result",
          responseJson.data,
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizResultByQuizIdAndStudentId/${quizId}/${studentId}`
        );
        usersSet([]);
      } else {
        // console.log(responseJson.data);
        // console.log(responseJson.data.accuracy);

        usersSet(responseJson.data.response);
        setSelectedIdOfQuestion(responseJson.data.response[0]?.ans_id);
        // console.log(responseJson.data.analysis);
        setAnalysis(responseJson.data.accuracy);
        setDifficulty(responseJson.data.difficulty);
        setMyScore(responseJson.data.myScore);
        setSummary(responseJson.data.summary);
        setToperScore(responseJson.data.toperScore);
        setTotalMarks(responseJson.data.totalMarks);

      }

      setLoading(false);
    }

    fetchUsers();
    setLoading(false);

  }, []);


  // console.log(totalMarks);

  const [questionTitle, setQuestionTitles] = useState();

  const handleChangeTitle = (e) => {
    const { name, value } = e.target;

    setQuestionTitles((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const [language, setLanguage] = useState(false);
  const [height, setHeight] = useState();
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "70vh" }}
        >
          {" "}
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Quiz Questions">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                {quizTitle} Questions
              </Typography>
            </Stack>


            {
              // setTimeout(()=>{
              <div className="accordian">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="inside_accordian"
                  >
                    <Typography>Quiz Summary</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* 1st Row */}
                    <Stack direction="row" justifyContent='space-between'>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8	", borderRadius: "15px" }}>
                        <span className="small_box">
                          Total No. of Questions
                        </span>
                        <span>
                          {summary !== undefined ? (summary.noOfQues) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          Attempted Questions
                        </span>
                        <span>
                          {summary !== undefined ? (summary.Attempted) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          Correct Answered
                        </span>
                        <span>
                          {summary !== undefined ? (summary.correctAns) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          Skipped Questions
                        </span>
                        <span>
                          {summary !== undefined ? (summary.skipped) : (<></>)}
                        </span>
                      </Stack>
                    </Stack>

                    {/* 2nd Row */}
                    <Stack direction="row" justifyContent='space-between'>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8	", borderRadius: "15px" }}>
                        <span className="small_box">
                          Accuracy (%)
                        </span>
                        <span>
                          {analysis !== undefined ? (analysis.percentage) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8	", borderRadius: "15px" }}>
                        <span className="small_box">
                          Accuracy
                        </span>
                        <span>
                          {analysis !== undefined ? (analysis.number) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          My Score (%)
                        </span>
                        <span>
                          {myScore !== undefined ? (myScore.percentage) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          My Score
                        </span>
                        <span>
                          {myScore !== undefined ? (myScore.number) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          Topper Score (%)
                        </span>
                        <span>
                          {toperScore !== undefined ? (toperScore.percentage) : (<></>)}
                        </span>
                      </Stack>
                      <Stack direction="column" alignItems="center" sx={{ padding: '10px', margin: '10px', boxShadow: " 0px 0px 17px #F6D8D8", borderRadius: "15px" }}>
                        <span>
                          Topper Score
                        </span>
                        <span>
                          {toperScore !== undefined ? (toperScore.number) : (<></>)}
                        </span>
                      </Stack>

                    </Stack>

                  </AccordionDetails>
                </Accordion>
              </div>
              // },4000)
            }


            <Stack
              direction="row"
              mb={2}
              sx={{
                boxShadow: " 0px 0px 17px #BFBFBF",
                borderRadius: "15px",
                height: "100%",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  flex: 0.5,
                  height: "100%",
                  bgcolor: "#FB5259",
                  borderRadius: "15px 0px 0px 15px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  p={2}
                  sx={{ width: "100%" }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <svg
                      width="32"
                      height="28"
                      viewBox="0 0 32 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 16.8H3.55556V11.2H0V16.8ZM0 28H3.55556V22.4H0V28ZM0 5.6H3.55556V0H0V5.6ZM7.11111 16.8H32V11.2H7.11111V16.8ZM7.11111 28H32V22.4H7.11111V28ZM7.11111 0V5.6H32V0H7.11111Z"
                        fill="white"
                      />
                    </svg>
                    <Typography color="#FFFFFF" sx={{ fontSize: "18px" }}>
                      {users?.length} Questions
                    </Typography>
                  </Stack>

                  {/* <Typography>{deleteDisplay}</Typography> */}
                </Stack>
                <Stack
                  direction="column"
                  sx={{
                    width: "100%",
                    maxHeight: "150vh",
                    overflowY: "scroll",
                    borderBottomLeftRadius: "15px",
                  }}
                >
                  {users?.length > 0 ? (
                    users?.map((item, index) => {
                      // console.log(item);
                      // console.log(selectedIdOfQuestion);

                      // console.log(selectedIdOfQuestion == item?._id);
                      return (
                        <div
                          onClick={() => setSelectedIdOfQuestion(item?.ans_id)}
                        // onMouseEnter={() => }
                        >
                          <Stack
                            key={index}
                            p={2}
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                              width: "100%",
                              minHeight: "100px",
                              bgcolor:
                                selectedIdOfQuestion == item?.ans_id
                                  ? "#fff1f3"
                                  : "white",
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => setQuestionSelected(true)}
                          >
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              sx={{ flex: 1 }}
                            >
                              <Typography
                                p={0.4}
                                sx={{
                                  fontWeight: "700",
                                  color: "white",
                                  background: "#FB5259",
                                  borderRadius: "50%",
                                  textAlign: "center",
                                }}
                              >
                                Q-{index + 1}
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (language
                                      ? item?.question_title?.h
                                      : item?.question_title?.e
                                    )
                                      .replace(/<[^>]+>/g, "")
                                      .substring(0, 30) + " ...",
                                }}
                              ></div>
                            </Stack>
                          </Stack>
                        </div>
                      );
                    })
                  ) : (
                    <Stack
                      p={2}
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        width: "100%",
                        minHeight: "100px",
                        bgcolor: "white",
                        cursor: "pointer",
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        No Questions
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{ flex: 1, height: "100vh", bgcolor: "#FFFFFF", borderRadius: "0px 15px 15px 0px" }}
              >
                <Stack
                  direction="row"
                  p={1.5}
                  alignItems="center"
                  sx={{
                    width: "100%",
                    borderBottom: 1,
                    borderColor: "lightgray",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      px={2}
                      sx={{
                        color: "#FB5259",
                        background: "#fff1f3",
                        borderRadius: "25px",
                        cursor: "pointer",
                      }}
                    >
                      {quizD?.quiz_duration} min
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      px={2}
                      sx={{
                        color: "#FB5259",
                        background: "#fff1f3",
                        borderRadius: "25px",
                        cursor: "pointer",
                      }}
                    >
                      Score : {totalMarks}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      sx={{
                        color: "#FB5259",
                        background: "#fff1f3",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setLanguage(!language)}
                    >
                      {language ? "अ" : "A"}
                    </Typography>
                  </Stack>
                </Stack>
                {users?.length > 0 &&
                  users?.map((item, index) => {
                    return (
                      <>
                        {selectedIdOfQuestion == item?.ans_id && (
                          <>
                            {isEdit ? (
                              <TextField
                                type="text"
                                name="question"
                                label="Enter Title"
                                variant="standard"
                                sx={{ width: "50%", alignSelf: "center" }}
                              />
                            ) : (
                              <Typography
                                py={2}
                                px={5}
                                variant=" h6"
                                sx={{
                                  textAlign: "start",
                                  justifyContent: "center",
                                  wordBreak: "break-word",
                                }}
                              >

                                <div
                                  style={{
                                    display: "inline-block",
                                    width: "90%",
                                    marginBottom: "10px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: language
                                      ? item?.question_title?.h
                                      : item?.question_title?.e,
                                  }}
                                ></div>
                                {/* {language
                                  ? item?.question_title?.h
                                  : item?.question_title?.e}{" "} */}
                              </Typography>
                            )}

                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={item?.myAnswer}
                                name="radio-buttons-group"
                              >
                                <Grid
                                  container
                                  spacing={3}
                                  px={5}
                                  sx={{ width: "100%" }}
                                >
                                  <Grid item xs={4} sm={3} md={6}>
                                    <Card
                                      sx={{
                                        height: "100px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor:
                                          item?.myAnswer == "1"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "#D2FFD6"
                                              : "rgba(251, 82, 89, 0.1)"
                                            : item?.correctOption == "1"
                                              ? "#D2FFD6"
                                              : "white",
                                        border:
                                          item?.myAnswer == "1"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "3px solid #57D163"
                                              : "3px solid #FB5259"
                                            : item?.correctOption == "1"
                                              ? "3px solid #57D163"
                                              : "white",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="1"
                                        name="option1"
                                        control={<Radio />}
                                        label={
                                          language
                                            ? item?.option1?.h
                                            : item?.option1?.e
                                        }
                                      />
                                    </Card>
                                  </Grid>
                                  <Grid item xs={4} sm={3} md={6}>
                                    <Card
                                      sx={{
                                        height: "100px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor:
                                          item?.myAnswer == "2"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "#D2FFD6"
                                              : "rgba(251, 82, 89, 0.1)"
                                            : item?.correctOption == "2"
                                              ? "#D2FFD6"
                                              : "white",
                                        border:
                                          item?.myAnswer == "2"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "3px solid #57D163"
                                              : "3px solid #FB5259"
                                            : item?.correctOption == "2"
                                              ? "3px solid #57D163"
                                              : "white",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="2"
                                        name="option2"
                                        control={<Radio />}
                                        label={
                                          language
                                            ? item?.option2?.h
                                            : item?.option2?.e
                                        }
                                      />
                                    </Card>
                                  </Grid>
                                  <Grid item xs={4} sm={3} md={6}>
                                    <Card
                                      sx={{
                                        height: "100px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor:
                                          item?.myAnswer == "3"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "#D2FFD6"
                                              : "rgba(251, 82, 89, 0.1)"
                                            : item?.correctOption == "3"
                                              ? "#D2FFD6"
                                              : "white",
                                        border:
                                          item?.myAnswer == "3"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "3px solid #57D163"
                                              : "3px solid #FB5259"
                                            : item?.correctOption == "3"
                                              ? "3px solid #57D163"
                                              : "white",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="3"
                                        name="option3"
                                        control={<Radio />}
                                        label={
                                          language
                                            ? item?.option3?.h
                                            : item?.option3?.e
                                        }
                                      />
                                    </Card>
                                  </Grid>
                                  <Grid item xs={4} sm={3} md={6}>
                                    <Card
                                      sx={{
                                        height: "100px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor:
                                          item?.myAnswer == "4"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "#57D163"
                                              : "red"
                                            : item?.correctOption == "4"
                                              ? "#57D163"
                                              : "white",
                                        border:
                                          item?.myAnswer == "4"
                                            ? item?.myAnswer ==
                                              item?.correctOption
                                              ? "3px solid #57D163"
                                              : "3px solid #FB5259"
                                            : item?.correctOption == "4"
                                              ? "3px solid #57D163"
                                              : "white",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="4"
                                        name="option4"
                                        control={<Radio />}
                                        label={
                                          language
                                            ? item?.option4?.h
                                            : item?.option4?.e
                                        }
                                      />
                                    </Card>
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            </FormControl>
                            <Stack direction="row"
                              alignItems="start"
                              p={5}
                              justifyContent="space-between">
                              {isEdit ? (
                                <TextField
                                  type="text"
                                  name="answer"
                                  label="Enter Answer"
                                  onChange={handleChangeTitle}
                                  variant="standard"
                                  sx={{ width: "50%", alignSelf: "center" }}
                                />
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                    width: "100%",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {" "}
                                  {language ? "व्याख्या" : "Explanation"} :-
                                  <br></br>
                                  <div
                                    sx={{
                                      fontSize: "20px",
                                      textTransform: "capitalize",
                                      textAlign: "start",
                                      width: "100%",
                                      wordBreak: "break-all",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: (language
                                        ? item?.answer?.h
                                        : item?.answer?.e).replace(/<[^>]+>/g, "")
                                        .substring(0, 500) + " ...",
                                    }}
                                  >
                                    { }
                                  </div>
                                </Typography>
                              )}
                              {/* <Stack
                              direction="row"
                              alignItems="center"
                              px={5}
                              justifyContent="space-between"
                            >
                              <Typography
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "20px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Correct Answer:-{" "}
                                <span style={{ color: "#4CAF50" }}>
                                  {item?.correctOption}
                                </span>
                              </Typography>
                              
                              </Stack> */}
                            </Stack>
                            <ToastContainer
                              position="bottom-center"
                              autoClose={1000}
                              hideProgressBar
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                              theme="light"
                            />
                          </>
                        )}
                      </>
                    );
                  })}
              </Stack>
            </Stack>
          </Container>
        </Page>
      )}
    </>
  );
}
