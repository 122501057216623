import React from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  InputLabel,
  Grid,

} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { useTheme } from "@emotion/react";
import { convertYoutubeUrlToEmbed } from "../../../utils/convertYoutubeUrlToEmbed";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,

    },
  },
};


const CenterLectureForm = () => {
  const theme = useTheme()
  const animatedComponents = makeAnimated();

  const [focus, setFocused] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [users, usersSet] = React.useState([]);

  const [batches, setBatches] = React.useState([]);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs());
  const [ending, setEnding] = React.useState(dayjs());

  const handleChange = (newValue) => {
    // console.log("69"+newValue?.$d);
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const LoginSchema = Yup.object().shape({
    lecture_title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    subject_id: Yup.string().required("Subject  required"),
    teacher: Yup.string().required("Teacher  required"),
    language: Yup.string().required("language  required"),
    lecture_type: Yup.string().required("Lecture Type  required"),
    isActive: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    lecture_type: "",
    lecture_title: "",
    // batch_name: '',
    description: "",
    // starting_date: '',
    // ending_date: '',
    subject_id: "",
    // LiveOrRecorded: "",
    language: "",
    remember: true,
    link: "",
    isActive :"" ,
    socketUrl : "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();

  function matchYoutubeUrl(url) {
    //console.log("Cameeeeeeee In Here");
    var p = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
    // var p = /^https:\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]/;
    var matches = url.match(p);
    if (matches) {
      // console.log("heeee");
      setValidateUrl(true);
      return matches[1];
    }
    return false;
  }
  function matchM3U8Url(url) {
    //console.log("Cameeeeeeee In Here");
    var p = /\.m3u8/
    var isValid = p.test(url);
    if (isValid) {
      setValidateUrl(true);
      return true;
      // .m3u8
    }
    return false;
  }
  const [utubeurl, setUtubeurl] = useState("");
  const[socketUrl , setSocketUrl] = useState("");
  const [validateYoutubeUrl, setValidateUrl] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);

  const check = () => {

    if (youtubeStatus != "APP") {
      var id = matchYoutubeUrl(utubeurl);
      if (id != false) {
        setValidateUrl(true);

      } else {
        setValidateUrl(false);

      }
    } else {
      // console.log("App")
      // setValidateUrl(true)
      let isM3U8 = matchM3U8Url(utubeurl);
      if (isM3U8) {
        setValidateUrl(true);
      } else {
        setValidateUrl(false);
      }
    }
  }

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };
  const [file1, setFile1] = useState();
  const changeHandler1 = async (event) => {
    setFile1(event.target.files[0]);
  };
  const[file3 , setFile3] = useState();
  
  const changeHandler3 = (e) => {
    // console.log(e.target.files);
    setFile3(e.target.files[0])
  }
  let formatDate = (date) => {
    let datehelper = "" + date;
    datehelper = datehelper.split(" ");
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let time = datehelper[4];
    let year = datehelper[3];
    let month = `0${monthsList.indexOf(datehelper[1]) + 1}`.slice(-2);
    let day = datehelper[2];

    return `${day}-${month}-${year} ${time}`;
  };

  const onSubmit = async (data) => {
    // //console.log(data);
    // console.log(starting?.$d);
    // console.log(ending?.$d);
    // console.log('hello')
    
    let StartingDate = formatDate(starting.$d);
    let EndingDate = formatDate(ending.$d);
    // let StartingDate = dayjs(starting).format('DD-MM-YYYY HH:mm:ss');
    // let EndingDate = dayjs(ending).format('DD-MM-YYYY HH:mm:ss');
    // console.log(StartingDate) ;
    // console.log(EndingDate);

    if (!youtubeVideo || (validateYoutubeUrl || youtubeStatus == 'TWOWAY')) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      if (file1) {
        formData.append('file1', file1);
      }
      formData.append('file3' , file3);
      formData.append("lecture_type", data.lecture_type);
      formData.append("lecture_title", data.lecture_title);
      formData.append("description", data.description);
      formData.append("language", data.language);
      if (youtubeVideo) {
        let link = utubeurl;
        if (youtubeStatus != "APP") {
          link = utubeurl ? convertYoutubeUrlToEmbed(utubeurl) : utubeurl;
        }
        formData.append("link", link);
      } else {
        formData.append("link", "");
      }
      // debugger;
      
      if (youtubeVideo) {
        formData.append("LiveOrRecorded",youtubeStatus == 'TWOWAY' ? 'Live' : data.LiveOrRecorded);
      } else {
        formData.append("LiveOrRecorded", "Live");
      }

      formData.append("subject_id", data.subject_id);
      // console.log(selectedBatches);
      if (selectedBatches.length === 1) {
        formData.append('batches', selectedBatches[0]?._id);
        formData.append('batches', "");
      } else {
        selectedBatches.map((item) => formData.append('batches', item?._id))
      }
      formData.append("teacher", data.teacher);
      formData.append("starting_date", StartingDate);
      formData.append("ending_date", EndingDate);
      formData.append('isActive' , data.isActive);
      formData.append('socketUrl' , 'https://twoway-backend-prod.sdcampus.com/mediasoup');
      const YtLiveToken = localStorage.getItem("YtLiveToken");

      // for( let batch of selectedBatches){
      //   console.log(batch);
      // }
      // console.log('formData' , formData)
      // for(let Pair of formData){
      //   console.log(Pair[0] + "----> " + Pair[1])
      // }

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/addCenterLecture`,
          formData,
          config
        )
        .then((response) => {
          setFetchMessage();

          if (response.data.msg === "Lectures is added for all given batches") {
            setLoading(false);
            toast.success("Added The Lecture Details in all given batches");
            setTimeout(() => {
              window.location.reload();
            }, 200);
          } else {
            toast.error(response.data.msg);
            setLoading(false);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  const [liveStatus, setLiveStatus] = useState("");
  const [youtubeStatus, setYoutubeStatus] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  //Getting Batch
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);

      // setLoading(false)
    }
    fetchUsers();
    async function fetchBatches() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatches`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // let batchData = responseJson?.data?.filter((item) => item.is_active == true);
      // console.log("11"+batchData.length)
      setBatches(responseJson?.data);
    }
    fetchBatches();

    async function fetchTeacher() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setTeacherList(responseJson.data);

      // setLoading(false)
    }
    fetchTeacher();
  }, []);
  
  
  // //console.log(users);

  //Youtube Link
  const [youtubeVideo, setYoutubeVideo] = useState(false);
  const [val, setVal] = useState(false)
  const [msg, setMsg] = useState('');

  // //console.log(utubeurl)

  const handleChangeBatches = (data) => {
    setSelectedBatches(data);
  }
  
  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h2" ml={2}>
        Add Lecture for Batches
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}>
        <Typography variant="h6" ml={2} mb={4}>
          Enter the below required details to create a lecture
        </Typography>
      </Stack>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: "70%" }}>
          <Stack direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}>
              <RHFTextField
                name="lecture_type"
                label="Lecture Type"
                select
              >
                <MenuItem value="YT" onClick={() => { setYoutubeStatus("YT"); setYoutubeVideo(true); }}>YouTube</MenuItem>
                <MenuItem value="APP" onClick={() => { setYoutubeStatus("APP"); setYoutubeVideo(true); }}>App</MenuItem>
                <MenuItem value="TWOWAY" onClick={() => { setYoutubeStatus("TWOWAY"); setYoutubeVideo(true); }}>Two Way Communication</MenuItem>
              </RHFTextField>
               <RHFTextField 
                    name="file3"
                    onChange={changeHandler3}
                    id="file3"
                    label="Lecture Banner"
                    error={!file3 && val}
                    helperText='Image Only ( Maximum allowed size 100KB)'
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
                     InputProps={{
                        classes: {
                            input: "CustomTextField"
                        }
                    }}
                    inputProps={{ accept: "image/*" }}
                    // type={ focus ? "file" : "text"}
                    type =  'file'
                />

          </Stack>
          
       <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
                <RHFTextField name="lecture_title" label="Title " />
            </Stack>
            <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Select Batches</InputLabel>
                            <Stack direction='column' sx={{ width: '100%' }}>
                               
                
                                   <Select
                                         closeMenuOnSelect={false}
                                         components={animatedComponents}
                                         value={selectedBatches}
                                         onChange={handleChangeBatches}
                                         menuPortalTarget={document.body} 
                                         styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        //  defaultValue={[colourOptions[4], colourOptions[5]]}
                                         isMulti
                                         options={batches}
                                       />

              {selectedBatches?.length == 0 && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
            </Stack>
          </Stack>
          <RHFTextEditor name="description" label="Description" />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <DateTimePicker
                label="Starting time"
                value={starting.$d}
                onChange={handleChange}
                // name="starting_date"
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
              <DateTimePicker
                label="Ending time"
                value={ending.$d}
                onChange={handleChangeTwo}
                sx={{ color: "black" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%", color: "black" }}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack direction="row" spacing={3}>
            <RHFTextField name="language" label="Language" select>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="hi">Hindi</MenuItem>
              <MenuItem value="enhi">Bilingual</MenuItem>
            </RHFTextField>
            <RHFTextField name="subject_id" label="Subject" select>
              {users?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            {
              youtubeStatus != 'TWOWAY' ? ( (youtubeVideo && youtubeStatus == "APP") ? (
                <RHFTextField name="LiveOrRecorded" label="Type" select>
                  <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
                  <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem>
                </RHFTextField>
              ) : (
                <RHFTextField name="LiveOrRecorded" label="Type" select>
                  <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem>
                  <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
                </RHFTextField>
              ) ) :  (
                <></>
                // <RHFTextField name="LiveOrRecorded" label="Type" select>
                //   {/* <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem> */}
                //   <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
                // </RHFTextField>
              )
            }
            
          </Stack>
          <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <RHFTextField
              id="assi"
              name="materials"
              label="Resources"
              onChange={changeHandler}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              inputProps={{ accept: "application/pdf" }}
              helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            <RHFTextField
              id="dpp"
              name="dpp"
              label="DPP"
              onChange={changeHandler1}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              inputProps={{ accept: "application/pdf" }}
              helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            { youtubeVideo  ? 
            (( youtubeStatus != 'TWOWAY') && (
              <>
                {" "}
                <RHFTextField
                  name="link"
                  label="Link"
                  type="url"
                  error={!validateYoutubeUrl}
                  helperText={!validateYoutubeUrl ? "Add Valid Youtube Link" : ""}
                  value={utubeurl}
                  // error={!validateYoutubeUrl}
                  onChange={(e) => setUtubeurl(e.target.value)}
                />

              </>
            ) )  : (
              <></>
            )  
            }

          </Stack>
          <Stack direction="row" spacing={2}>
            <RHFTextField name="isActive" label="Status" type='checkbox' select>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
            <RHFTextField name="teacher" label="Teacher" select>
              {teacherList?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {item.FullName}
                  </MenuItem>
                );
              })}
            </RHFTextField>

          </Stack>
          {/* <RHFTextField name="teacher" label="Teacher" select>
            {teacherList?.map((item, index) => {
              return (
                <MenuItem key={index} value={item._id}>
                  {item.FullName}
                </MenuItem>
              );
            })}
          </RHFTextField> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          onClick={check}
          sx={{
            bgcolor: theme.palette.primary.mainTheme,
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Add
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default CenterLectureForm;
