import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useTheme } from '@emotion/react';
import MyCkEditor from '../../../components/hook-form/MyCkEditor';
// ----------------------------------------------------------------------

export default function AddCenterAnnouncements() {
  const theme = useTheme()
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false)
  const [pdf, setPdf] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [batches, setBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const[dataForDesc , setDataForDesc] = useState('')

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required('Title required'),
    // description: Yup.string().required('Description required'),
    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title: '',
    description: '',

    isActive: '',
  };
  const handleChangeBatches = (data) => {
    setSelectedBatches(data);

  }
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },

    };
    let reqObject = {
      title: data.title,
      // description: data.description,
      description : dataForDesc , 
      link: 'batch',
      batches: selectedBatches?.map((item) => item?._id),
      isActive: data.isActive
    }
    setProgress(0);
    axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/createAnnouncementForBatches`, reqObject, config).then((response) => {
      //console.log(response.data);
      if (response.data.msg === 'New Announcement created successfully for batches') {
        toast.success(response.data.msg);
        setLoading(false);
        setPdf(false);
        reset();
        // window.location.href = "/dashboard/announcements";
        window.location.reload()
      } else {
        toast.error(`SomeThing went wrong`);
        setLoading(false);
      }

    })
      .catch(e => {
        //console.log(e);
      })
  };

  //get batch or get testSeries
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchBatches() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatches`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setBatches(responseJson?.data);
    }
    fetchBatches();
    setLoading(false);

  }, []);

  return (<>
    <Stack spacing={3} px={5} >
      <Typography variant='h3'>Add Announcement For Batches</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>
          <RHFTextField name="title" label="Title" />
          <Stack direction="row">
            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Select Batches</InputLabel>
            <Stack direction='column' sx={{ width: '100%' , marginBottom : '20px' }}>
              
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={selectedBatches}
                onChange={handleChangeBatches}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                //  defaultValue={[colourOptions[4], colourOptions[5]]}
                isMulti
                options={batches}
              />

              {selectedBatches.length == 0 && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
            </Stack>
          </Stack>

          {/* <RHFTextField name="description" label="Description" />
           */}
            <MyCkEditor dataForDesc={dataForDesc} setDataForDesc={setDataForDesc} />
          <Stack direction="row" spacing={3} >

            <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>


          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
              setMsg('Required')
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Stack>
  </>
  );
}
