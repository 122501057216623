import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
    Stack,
    Typography,
    MenuItem,
    Grid,
    InputLabel
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
    FormProvider,
    RHFTextField,
} from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const UpdateSubCategory = () => {
    const theme = useTheme()
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const LoginSchema = Yup.object().shape({});
    const defaultValues = {};
    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;


    const animatedComponents = makeAnimated();
    const [categories , setCategories] = useState([]);
    const [selectedCategory , setSelectedCategory] =  useState([]);
    const handleChangeCategory =  (newValue ) => {
        setSelectedCategory(newValue);
    }
    const [value, setValue] = useState();
    useEffect(() => {
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
        // const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubCategoryForAdmin/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();

            const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin` , config)
            const categoryResponse = await response.json();
            setCategories(categoryResponse?.data ?? []);
            setLoading(false);
            let curCategory =  categoryResponse?.data?.find((item) => item?._id === responseJson?.data?.category?._id)
            setSelectedCategory(curCategory);

            setValue((prev) => ({
                ...prev,
                title: responseJson?.data?.title,
                slug :  responseJson?.data?.slug , 
                category : responseJson?.data?.category , 
                is_active: responseJson?.data?.is_active,

            }));
            

        }
        fetchUsers();
    }, []);

   


    const onSubmit = async (data) => {
        setLoading(true);
        let reqObject = {
            title :  value.title ,
            is_active :  value.is_active ,
            category : selectedCategory?.value ,
            slug : value?.slug ,             
        }
        
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios
            .put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateSubCategorialDetails/${id}`, reqObject, config)
            .then((response) => {
                if (response.data.status == true) {
                    reset();
                    setLoading(false);
                    toast.success(response.data.msg);

                    setTimeout(() => {
                        navigate(`/dashboard/subCategory`);
                        // window.location.reload();
                    }, 1500);
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }
            })
            .catch((e) => {
                //console.log(e);
            });
    };

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setValue((prev) => ({ ...prev, [name]: value }));
    };
    // console.log(value)
    return (
        <Grid direction="row" pl={5}>
            <Typography variant="h2" m={2}>
                Update  SubCategory  - {value?.title}
            </Typography>


            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} sx={{ width: "70%" }}>

                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Title : </InputLabel>
                            <RHFTextField name="title" value={value?.title} onChange={onChange2}/>
                        </Stack>
                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Slug : </InputLabel>
                            <RHFTextField name="slug" value={value?.slug} onChange={onChange2}/>

                        
                        </Stack>
                        <ReactSelect
                                         closeMenuOnSelect={false}
                                         components={animatedComponents}
                                         value={selectedCategory}
                                         onChange={handleChangeCategory}
                                         placeholder='Please Select Parent Category'
                                         menuPortalTarget={document.body} 
                                         styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                                        //  defaultValue={[colourOptions[4], colourOptions[5]]}
                                         isMulti={false}
                                         options={categories}
                                       />
                   
                  
                 

                    <Stack spacing={3} direction="row">
                        
                        <RHFTextField
                            name='is_active'
                            label={value?.is_active == true  ? 'Active' : 'Inactive'}
                            sx={{ width: '100%' }}
                            select
                            type="checkbox"
                            onChange={onChange2}
                        >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>

                        </RHFTextField>
                    </Stack>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                        bgcolor: theme.palette.primary.mainTheme,
                        width: "70%",
                        marginTop: "5%",
                        "&:hover": { background: "red" },
                    }}
                >
                    Update
                </LoadingButton>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Grid>
    );
};

export default UpdateSubCategory;