import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';


// ----------------------------------------------------------------------

export default function AddYoutube() {


    const theme=useTheme()


    const navigate = useNavigate();
    const [fetchMessage, setFetchMessage] = useState()
    const [positive, setPositive] = useState(false);
    const [users, usersSet] = useState([]);
    const [loading, setLoading] = useState(false);
    const[type, setType] = useState('none');
    const[valueId , setValueId] = useState('');
    const[list , setList] = useState([]);

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        // lastName: Yup.string().required('Last name required'),
        // category: Yup.string().required('Category is required'),
        video_url: Yup.string().required('Url No. is required'),
        language: Yup.string().required('Language is required'),
        link: Yup.string()
          .required('Link Banner Required')
          .oneOf(['none', 'batch', 'testSeries', 'category'], 'Invalid Link Value'),
        valueId: Yup.string().when('link', {
          is: (link) => link !== 'none' && link !== '',
          then: Yup.string().required('Link With Required'),
          otherwise: Yup.string().notRequired(),
        }),
        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        // category: '',
        video_url: '',
        language: '',
        link:"",
        linkWith:'',
        is_active: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        setLoading(true)
        let token = localStorage.getItem("token");
        //console.log(token);
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RpbmdAZ21haWwuY29tIiwiaWF0IjoxNjYzOTQzMTc5LCJleHAiOjE2NjM5NDQ5Nzl9.ix9ZXfVEqHrH20-bnMxHQD9c4Z0Z3plfkJWu3YiisKY'
        try {

            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addYouTubeDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({...data , link : type , linkWith : valueId})
            })
            const result = await res.json()
            toast.success(result.msg)
            setFetchMessage(result.msg)
            if (result.msg === 'added youtube details successfully') {
                setLoading(false)
                reset()
                // navigate('/dashboard', { replaced: true })
            }
        } catch (error) {
            //console.log(error);
        }
        // navigate('/dashboard', { replace: true });
    };
    useEffect(() => {
        if( type !== '' && type !=='none'){
            const token = localStorage.getItem("token");
        async function fetchDetails() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/${
                  type == "batch" ? "getBatchesDetails" : type == "testSeries" ? "getallTestSeriesdetails" : "getCategory"
                }`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const responseJson = await fullResponse.json();
              setList(responseJson.data);
              
        }
        fetchDetails();
        }
        setLoading(false);
        
    }, [type]);
    //CategoryDetails
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     async function fetchUsers() {
    //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`
    //             },

    //         });
    //         const responseJson = await fullResponse.json();
    //         usersSet(responseJson.data);
    //         // if (responseJson.msg === 'not an admin') {
    //         //     localStorage.removeItem("isLoggedIn")
    //         //     localStorage.removeItem("details")
    //         //     navigate('/')
    //         // };
    //     }

    //     fetchUsers();
    // }, []);

    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Youtube</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>


                    <RHFTextField name="title" label="Title" />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="video_url" label="Video URL" type='url' />
                        <RHFTextField name="language" label="Language" select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="link" label="Link Video" type='checkbox' select >
                            <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                            <MenuItem value='batch' onClick={() => setType('batch')}>Batch</MenuItem>
                            <MenuItem value='testSeries' onClick={() => setType('testSeries')}>Test Series</MenuItem>
                            <MenuItem value='category' onClick={() => setType('category')}>Category</MenuItem>
                        </RHFTextField>
                        { (type !== 'none' && type !=='') && <RHFTextField name="valueId" label={ type == "batch" ? "Link With Batch" : type=='testSeries' ? "Link With Test Series" : "Link With Catgeory"} select>
                        {list?.map((item, index) => (
                                    
                                        <MenuItem
                                          key={index}
                                          value={item?._id}
                                          onClick={() => setValueId(item?._id)}
                                        >
                                          {type == "batch"
                                            ? item?.batch_name : 
                                            type=="testSeries" ? item?.testseries_name : item?.title}
                                        </MenuItem>
                                      )
                 
                        )}
                            </RHFTextField>}
                    </Stack>


                    <Stack direction="row" spacing={3}>
                        {/* <RHFTextField name="category" label="Category" select>
                            {users.map((item) => { return <MenuItem value={item.title}>{item.title}</MenuItem> })}



                        </RHFTextField> */}
                        <RHFTextField name="is_active" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor:theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </Stack>
    </>
    );
}
