import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";




// import "./general.css";
import { useTheme } from '@emotion/react';
import RHFTextEditor from '../../../../components/hook-form/RHFTextEditor';

// ----------------------------------------------------------------------

export default function AddProductThird({ setShowForm, setProductId, productId , value , setValue , title , setSubmit3}) {
    const theme = useTheme()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);

    const [saleExpireDate , setSaleExpireDate] = useState(dayjs());
    const handleChangeEndDate = ( newValue) =>{
        setSaleExpireDate(newValue);
    }
    

    const RegisterSchema = Yup.object().shape({
        regularPrice: Yup.string().required('Regular Price required'),
        // salePrice: Yup.string().required('Sale Price required'),
        salePrice: Yup.string()
           .required('Sale Price required')
            .test('is-less-than-regular', 'Sale Price must be less than Regular Price', function (salePrice) {
             const regularPrice = this.resolve(Yup.ref('regularPrice'));
             return parseFloat(salePrice) < parseFloat(regularPrice);
        }),

        // url: Yup.string()
        //     .required('Link Banner Required'),

        // valueId: Yup.string().when('link', {
        //   is: (link) => link !== 'none' && link !== '',
        //   then: Yup.string().required('Link With Required'),
        //   otherwise: Yup.string().notRequired(),
        // }),

        // category: Yup.string().required('Category is required'),
        // banner_url: Yup.string().required('Url No. is required'),
        // language: Yup.string().required('Language is required'),

        isCoinApplicable: Yup.boolean().required('Is Coin Applicable Required'),
    });

    const defaultValues = {
        // title: '',
        // BannerType: '',
        // category: '',
        // banner_url: '',
        // language: '',

        // videoType: '',
        // linkWith: '',
        // url: '',
        // isActive: '',
    };
    const [coinApplicable, setIsCoinApplicable] = useState(false)
    //console.log(file)
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar
    // console.log('Sale Expires ' , saleExpireDate)
    const onSubmit = async (data) => {
        // const formData = new FormData();
        setLoading(true)
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        };
        // document.querySelector(".mainDiv").style.display = "block";
        let saleExpire = saleExpireDate.$d ;
        // console.log("Sale Expire Time" , saleExpire)
        setProgress(0);
        axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreProductThird/${productId}`, {...data , saleExpire}, config).then((response) => {
            //console.log(response.data);
            if (response.data.msg === 'Product Details added') {
                setProductId(response?.data?.data?._id)
                toast.success(response.data.msg);
                setLoading(false);
                setPdf(false);
                reset();
                setValue(value+1);
                setSubmit3(true)
                // window.location.href = "/dashboard/banner";
            }else{
                toast.error(response.data.msg);
                setLoading(false);
            }

        })
            .catch(e => {
                //console.log(e);
            })

    };

    const [products, setProducts] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchVideos() {
            const response = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/store/getAllStoreProduct`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await response.json();
            // console.log(responseJson.data);
            setProducts(responseJson.data);
            setLoading(false);
        }
        fetchVideos();
    }, []);


    return (<>
        <Stack spacing={3} px={5} >
            {/* <Typography variant='h3'>Add Store Product 3</Typography> */}
            <Typography variant='h3'>Please Fill <span style={{ color : 'blue'}}>{title}</span>  Product details</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>

                  <Stack direction='row' spacing={3}>
                    <RHFTextField name="regularPrice" type='number' label="Regular Price" />
                    <RHFTextField name="salePrice" type='number' label="Sale Price" />
                  </Stack>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <DateTimePicker
                label="Sale Expires time"
                value={saleExpireDate.$d}
                onChange={handleChangeEndDate}
                // name="starting_date"
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
             
            </Stack>
                  </LocalizationProvider>

                  <RHFTextField name="metaTitle" type='string' label="Meta Title" />
                  <RHFTextEditor name="metaDesc" label="Meta Description" />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isCoinApplicable" label="Coin Applicable" type='checkbox' select >
                            <MenuItem value={true} onClick={() => setIsCoinApplicable(true)}>Yes</MenuItem>
                            <MenuItem value={false} onClick={() => setIsCoinApplicable(false)}>No</MenuItem>
                        </RHFTextField>
                        {coinApplicable && <RHFTextField name="maxAllowedCoins" type='number' label="Max Allowed Coins" />}
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')

                        }}>
                        Next Step
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}

