import * as Yup from 'yup';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css'
import { User } from '../../../context/context';
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------







export default function AddRecordedVideo({ title }) {

    const theme =useTheme()

    const navigate = useNavigate();
    const { percentage, setDownloadPercentage, showBackgroundPercentage, setShowBackgroundPercentage } = React.useContext(User)

    const [subject, setSubject] = useState()
    const [loading, setLoading] = useState(true)

    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [batch_id, setBatchId] = useState()
    const [uploadSize, setUploadSize] = useState({
        completed: 0,
        totalSize: 0
    })
    const [showProgress, setShowProgress] = useState(false)


    const [progress, setProgress] = React.useState(0);
    const [submitted, setSubmitted] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [val, setVal] = React.useState(false);
    const { lectId, batchId } = useParams()

    // //console.log(lectId)
    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        language: Yup.string().required('Language is required'),
        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        is_active: '',
        language: '',
    };




    const [file, setFile] = useState()


    const changeHandler = async (event) => {
        if (!type) { setFile(event.target.files[0]) }
        else {
            setFile(event.target.value)
        }


    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;


    const onSubmit = async (data) => {
        if (file?.size) {

            const formData = new FormData();
            formData.append("file", file);
            formData.append('title', data.title);
            if (data.batch_id) {
                formData.append('batch_id', data.batch_id);
            } else {
                formData.append('batch_id', batchId);
            }
            if (data.lecture_id) {
                formData.append('lecture_id', data.lecture_id);
            } else {
                formData.append('lecture_id', lectId);
            }


            formData.append('language', data.language);
            formData.append('is_active', data.is_active);
            const token = localStorage.getItem("token");

            setLoading(true)
            setShowProgress(true)
            setShowBackgroundPercentage(true)
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,

                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total)
                    //console.log(`${loaded / 1000000}kb of ${total / 1000000}kb | ${percent}%`);
                    setUploadSize(prev => ({
                        completed: Math.floor((loaded / 1000000)),
                        totalSize: Math.floor((total / 1000000))
                    }))

                    if (percent < 90) {
                        setDownloadPercentage(percent)
                        // setProgress(percent)
                    }

                },
                maxBodyLength: Infinity,
                maxContentLength: Infinity,
            };
            // axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addRecordedVideo`, formData, config).then((response) => {
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addRecordedVideo`, formData, config).then((response) => {
                setDownloadPercentage(100)
                setProgress(100)
                setLoading(false)
                setVal(false)
                setMsg('')
                reset()
                toast.success(response.data.msg)
                setPdf(false)
                setSubmitted(true)
                // setDownloadPercentage('Uploaded')
                setTimeout(() => {

                    setShowBackgroundPercentage(false)
                    navigate('/dashboard/recorded')
                    window.location.reload()
                }, 1000)


            })
                .catch(e => {
                    //console.log(e);
                })
        }
    };
    const [users, usersSet] = useState([]);

    //Setting resource type
    const [type, setType] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Resources`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const resSubject = await res.json();
            setSubject(resSubject.data);
            setLoading(false)
        }

        fetchUsers();
    }, []);


    //Lecture list
    const [lectureDetails, setLectureDetails] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails?BatchId=${batch_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setLectureDetails(responseJson.data);
        }
        fetchUsers()
    }, [batch_id])



    //Lecture list
    const [batchDetails, setBatchDetails] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setBatchDetails(responseJson.data);
        }
        fetchUsers()
    }, [batch_id])


    //console.log(progress + 'Progress')
    return (<>
        <Stack spacing={3} px={5} >
            {/* <Typography variant='h3'>Add Recorded Video</Typography> */}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title" label="Title" />
                    {/* <RHFTextField name="language" label="Language" /> */}

                    {title && <RHFTextField name="batch_id" label="Batch" select>
                        {batchDetails?.map((item, index) => <MenuItem key={index} value={item?._id} onClick={() => setBatchId(item?._id)}>{item?.batch_name}</MenuItem>)}

                    </RHFTextField>}
                    {title && <RHFTextField name="lecture_id" label="Lecture" select>
                        {lectureDetails?.map((item, index) => <MenuItem key={index} value={item?._id}>{item?.lecture_title}</MenuItem>)}

                    </RHFTextField>}

                    <Stack direction="row" spacing={3}>
                        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>

                            <RHFTextField name="file"
                                onChange={changeHandler}
                                error={!file && val}
                                label="Video"
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                onClick={() => setPdf(true)}
                                type={pdf || focus ? "file" : "text"}
                                // inputProps={{ multiple: true }}
                                inputProps={{ accept: "video/*" }}
                                helperText="Video Files Only"
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField",

                                    }
                                }}
                            />
                            {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                        </Stack>
                        <RHFTextField name="language" label="Language" select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                            <MenuItem value="enhi">Bilingual</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <RHFTextField name="is_active" label="Status" select>
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>In active</MenuItem>
                    </RHFTextField>




                    {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                    // onClick={navigate('/dashboard/recorded')}
                    >
                        Add
                    </LoadingButton> */}
                    <Button fullWidth size="large" type="submit" variant="contained"
                        disabled={showBackgroundPercentage}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}
                    // onClick={navigate('/dashboard/recorded')}
                    >
                        {/* {showProgress ? <LinearProgressWithLabel value={progress} submitted={submitted} /> : 'Add'} */}
                        {showProgress ? <Typography mr={2} sx={{ textAlign: 'center' }}>{submitted ? 'Submitted' : percentage < 91 ? `Uploading File..... ${percentage}%` : 'Submitting Form'}</Typography> : 'Add'}
                        {showProgress ? submitted ? '' : (<CircularProgress color="secondary" />) : ''}
                    </Button>
                    {/* {showProgress && <LinearProgressWithLabel value={progress} submitted={submitted} />} */}
                    {/* {showProgress && <Typography>{uploadSize.completed}MB/{uploadSize.totalSize}MB</Typography>} */}

                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack >
    </>
    );
}
