import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar 
} from "@mui/x-data-grid";
import {
  randomId,
} from "@mui/x-data-grid-generator";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import getAccess from "../../../utils/getAccess";




function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function ScholarshipTestTable() {
  const access = getAccess();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  React.useEffect(()=>{
    const token = localStorage.getItem('token');
    async function fetchTests(){
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getScholarshipTests` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`
      }
      });
      const responseJson = await response.json();
       setRows(responseJson.data);
    }
    fetchTests()

  },[])
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    navigate(`/dashboard/editScholarshipTest/${id}`);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick =  (id) => async() => {
    // console.log(id);
    const confirmDelete = window.confirm('Are you sure you want to delete this Scholarship Test?');
    if( confirmDelete){
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteScholarshipTest/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if( resJson.status){
      toast.success(resJson.msg);
      setRows(rows.filter((row) => row.id !== id));
    }else{
      toast.error(resJson.msg);
    }
    // toast.success(resJson);
   };
    }
    

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber =  rows?.map((row, index) => ({ ...row, serialNumber: index + 1 }));

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
    //   editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
  
    {
        field: "title",
        headerName: "Title",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true
      },
      {
        field: "banner",
        headerName: "Banner",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
          <img
            src={params.value}
            alt={`Image for row ${params.id}`}
            style={{ width: 150, height: 70 }}
          />
        ),
      },
      
      {
        field: "quizId",
        headerName: "Quiz Name",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
            <div>{params.value.title ? params.value.title : "NA"}</div>
          ),
      },
      {
        field: "duration",
        headerName: "Duration(in Min)",
        type: "number",
        width: 100,
        align: "left",
        headerAlign: "left",
      },
      
      {
        field: "startingAt",
        headerName: "Starting Date",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        // renderCell: (params) => (
        //     <div>{params.value?.title ? params.value?.title : "NA"}</div>
        //   ),
      },
      {
        field: "registrationEndAt",
        headerName: "Registration Last Date",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        // renderCell: (params) => (
        //     <div>{params.value?.title ? params.value?.title : "NA"}</div>
        //   ),
      },
      {
        field: "resultDeclaration",
        headerName: "Result Declaration Date",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        // renderCell: (params) => (
        //     <div>{params.value?.title ? params.value?.title : "NA"}</div>
        //   ),
      },
      {
        field: "isActive",
        headerName: "Status",
        type: "boolean",
        width: 100,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderCell: (params) => (
          <div style={{ color: params.value === true ? 'green' : 'red' }}>
            {params.value ? 'Active' : 'inActive'}
          </div>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const rowData = rows.find(item => item.id === id);
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main"
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }
  
          return [
            ( ( access?.access?.includes('all') || ( access?.access?.includes('151'))) ? <GridActionsCellItem
              icon={<VisibilityOutlined />}
              label="View"
              className="textPrimary"
              onClick={() => navigate(`/dashboard/scholarshipTestUser/${rowData?.title}/${id}`)}
              color="inherit"
            /> : <></>),
            ( ( access?.access?.includes('all') || ( access?.access?.includes('151') && access?.accessForTab?.includes('readWrite') ) ) ? <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            /> : <></>),
            ( ( access?.access?.includes('all') || ( access?.access?.includes('151') && access?.deleteAccess == true) ) ? <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            /> : <></>)
          ];
        }
      }
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        rows={rowsWithSerialNumber}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25,50]}
        slots={{
        //   toolbar: EditToolbar,
           toolbar: GridToolbar 
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
