import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, InputLabel, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, } from '../../../components/hook-form';


import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useParams , useLocation } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Page from '../../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssignMentorToLectureRoom = () => {
    const location = useLocation();
    // console.log(location)
    const animatedComponents = makeAnimated(); 
    const[ teachers , setTeachers] = useState([]);
    const [ loading , setLoading] = useState(false);
    const[selectedMentors , setSelectedMentors] =  useState([]);
    const handleChangeFaqs = (data) => {
        // console.log(data);
        setSelectedMentors(data);
      }
    const navigate = useNavigate()
    const { from , id } = useParams();
    const theme = useTheme();
    const LoginSchema = Yup.object().shape({
    });

    const defaultValues = {
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [ lectureId , setLectureId] = useState();
    useEffect(() => {
        setSelectedMentors(location?.state?.mentor)
        // console.log(location?.state?.lectureId)
        setLectureId(location?.state?.lectureId)
    } , [location])



    //Assign Teachers
    const onSubmit = async (data) => {
        setLoading(true);
        let token = localStorage.getItem("token");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
        let obj = {
            mentors: selectedMentors?.map((item) => { return item?.value}),
            roomId : id ,
            lectureId : lectureId ,
            // type : from
        }
        //console.log(obj)
        axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/assignMentors`, obj, config).then((response) => {
            if (response.data.status) {
                reset()
                toast.success("Mentor assigned in given lecture") ;
                setLoading(false);
                setTimeout(() => {
                    navigate(`/dashboard/lectureRooms/${lectureId}`)
                }, 1000)
            }
        })
            .catch(e => {
                //console.log(e);
            })
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchTeacher() {
            const fullResponse = await fetch(
              `${process.env.REACT_APP_LIVE_URL}/adminTeacher/getAllTeacherList`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const responseJson = await fullResponse.json();
            setTeachers(responseJson.data);
      
            // setLoading(false)
          }
          fetchTeacher();
    }, []);
    return (<>
        <Page title='FAQS'>
            <Stack spacing={3} ml={5} sx={{ width: '100%' }}>
                <Typography variant='h2'>{`Add Mentor`} </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack direction="column" sx={{ width: '90%' }}>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={selectedMentors}
                            onChange={handleChangeFaqs}
                            placeholder={`Please Select Required Faqs`}
                            menuPortalTarget={document.body} 
                            styles={{  
                                
                                control: (provided) => ({
                                   ...provided,
                                  width: '60%' // Set width to 100% for full width
                                }) ,
                                menuPortal: base => ({ ...base, width : '60%' , zIndex: 9999 }) }}
                        //  defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={teachers}
                        />
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" 
                        // loading={isSubmitting}
                        loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, width: '40%', marginTop: '2%', '&:hover': { background: theme.palette.primary.mainTheme, } }}
                    >
                        Add
                    </LoadingButton>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Stack>
        </Page>
    </>
    )
}

export default AssignMentorToLectureRoom