import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import RHFTextEditor from '../../../../components/hook-form/RHFTextEditor';

// ----------------------------------------------------------------------

export default function AddProductSecond({ setShowForm, setProductId, productId, value, setValue, title, setSubmit2 }) {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const RegisterSchema = Yup.object().shape({
        productType: Yup.string().required('Product Type is required'),
        inStock: Yup.string().required('Stock Size is required'),
        maxPurchaseQty: Yup.string()
            .required('Max Purchase required')
            .test('is-less-than-regular', 'Max Purchase Qty must be less than inStock', function (maxPurchaseQty) {
                const inStock = this.resolve(Yup.ref('inStock'));
                return parseFloat(maxPurchaseQty) < parseFloat(inStock);
            }),
        // maxPurchaseQty: Yup.string().required('Max Purchase Quantity is required'),
        deliveryType: Yup.string().required('Delivery Typeis required'),
        
    });

    const defaultValues = {
        productType: '',
        maxPurchaseQty: '',
        deliveryType: '',
        keyFeature: '',
      
    };
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const [file, setFile] = useState()

  const changeHandler = async (event) => {
    let size = event.target.files[0].size / ( 1024 * 1024);
        if( size > 10){
            setVal(true);
        }else{
            setFile(event.target.files)
            var fileName = document.getElementById('file').value.toLowerCase();
              if (fileName.endsWith('.pdf') ) { setRestrict('') }
             else { setRestrict('PDF') }
        }

  };

    //console.log(file)
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar
    const onSubmit = async (data) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        };
        const formData = new FormData();
        if( file){
            Object.values(file).forEach(file => {
                formData.append("file", file);
              })
        }
        formData.append('marketingCat' , data.marketingCat)
        formData.append('inStock' , data.inStock)
        formData.append('keyFeature' , data.keyFeature)
        formData.append('deliveryType' , data.deliveryType)
        formData.append('productType' , data.productType)
        formData.append('publication' , data.publication)
        formData.append('maxPurchaseQty' , data.maxPurchaseQty)


        // for( let Pair of formData){
        //     console.log(Pair[0] + " -> "+ Pair[1])
        // }


        // console.log(data);
        setProgress(0);
        axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreProductSecond/${productId}`, formData, config).then((response) => {
            // console.log(response.data);
            if (response.data.msg === 'Product Details added') {
                setProductId(response?.data?.data?._id)
                toast.success(response.data.msg);
                setLoading(false);
                reset();
                setValue(value + 1);
                setSubmit2(true);
                // window.location.href = "/dashboard/banner";
            }else{
                toast.error(response.data.msg);
                setLoading(false);
            }

        })
            .catch(e => {
                //console.log(e);
            })

       
    };

 
   
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Please Fill <span style={{ color: 'blue' }}>{title}</span> Product details</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>

                    <Stack direction="row" spacing={3}>

                        <RHFTextField name="productType" label="Product Type" select>
                            <MenuItem value='simple'>Simple</MenuItem>

                            {/* <MenuItem value="grouped">Grouped</MenuItem> */}
                            {/* <MenuItem value="variable">Variable</MenuItem> */}
                        </RHFTextField>
                        <RHFTextField name="inStock" type='text' label="Stock" />
                        <RHFTextField name="publication" type='text' label="publication" />

                    </Stack>

                    <Stack direction='row' spacing={3}>
                        <RHFTextField name="maxPurchaseQty" type='text' label="Max Purchase Quantity" />
                        <RHFTextField name="deliveryType" label="Delivery Type" select>
                            {/* <MenuItem value='cod'>COD</MenuItem> */}
                            <MenuItem value="online">Online</MenuItem>
                            {/* <MenuItem value="both">Both</MenuItem> */}
                        </RHFTextField>
                        <RHFTextField name="marketingCat" label="Marketing Category" select>
                            <MenuItem value='POPULAR'>Popular Products</MenuItem>
                            <MenuItem value="BOOK">Book Section</MenuItem>
                            <MenuItem value="SA">Stationary Accessories</MenuItem>
                            <MenuItem value="HDC">High Demanding Combos</MenuItem>
                            <MenuItem value="HDP">High Demanding Products</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <Stack direction='row' spacing={3} sx={{ display : 'flex' , alignItems :'center'}}>
                        <h3 style={{ width : '30%'}}> Select Preview Pdf</h3>
                        <RHFTextField name="file"
                          onChange={changeHandler}
                          id="file"
                          label="File"
                          error={!file && val}
                          helperText='Image Only ( Maximum allowed size 10MB)'
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)}
                          InputProps={{
                            classes: {
                              input: "CustomTextField"
                            }
                          }}

                          onClick={() => setPdf(true)}
                          inputProps={{ accept: "pdf/*" }}
                          type={pdf || focus ? "file" : "text"}

                        />
                    </Stack>
                    <RHFTextEditor name="keyFeature" label="Key Feature" />


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        
                        
                        >
                        Next
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}

