import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import "./general.css";
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

export default function AddBanner() {
    const theme = useTheme()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const [type, setType] = useState('none');
    const [valueId, setValueId] = useState('');
    const [list, setList] = useState([]);

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        BannerType: Yup.string().required('Banner Type required'),
        link: Yup.string()
            .required('Link Banner Required')
            .oneOf(['none', 'batch', 'testSeries', 'category', 'link', 'scholarship'], 'Invalid Link Value'),
        valueId: Yup.string().when('link', {
            is: (link) => link !== 'none' && link !== '',
            then: Yup.string().required('Link With Required'),
            otherwise: Yup.string().notRequired(),
        }),



        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        BannerType: '',
        // category: '',
        // banner_url: '',
        // language: '',

        link: '',
        linkWith: '',
        is_active: '',
    };
    const [file, setFile] = useState()




    const changeHandler = async (event) => {

        let size = event.target.files[0].size / (1024);
        if (size > 150) {
            setVal(true);
        } else {
            setFile(event.target.files)
            var fileName = document.getElementById('file').value.toLowerCase();
            if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
            else { setRestrict('JPG Or PDF') }
        }

    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar

    const onSubmit = async (data) => {

        if (file) {
            const formData = new FormData();

            setLoading(true)
            // debugger;
            //console.log(file)
            Object.values(file).forEach(file => {
                formData.append("file", file);
            })
            formData.append('title', data.title);
            // formData.append('category', data.category);
            formData.append('category', 'Banner');
            // formData.append('subject', data.subject);
            formData.append('BannerType', data.BannerType);
            formData.append('language', 'en');
            formData.append('is_active', data.is_active);
            formData.append("link", data.link);
            if (data.link !== '' && data.link !== 'none') {
                formData.append('linkWith', data.valueId);
            }

            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            // document.querySelector(".mainDiv").style.display = "block";
            setProgress(0);
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addBannerDetails`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.msg === 'Banner Details added successfully') {
                    toast.success(response.data.msg);
                    setLoading(false);
                    setPdf(false);
                    reset();
                    window.location.href = "/dashboard/banner";
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }

            })
                .catch(e => {
                    //console.log(e);
                })
        }

    };

    //get batch or get testSeries
    useEffect(() => {
        if (type !== '' && type !== 'none') {
            const token = localStorage.getItem("token");
            async function fetchDetails() {
                const fullResponse = await fetch(
                    `${process.env.REACT_APP_LIVE_URL}/adminPanel/${type == "batch" ? "getBatches" : type == "testSeries" ? "getallTestSeriesdetails" : type == "scholarship" ? "getScholarshipTests" : "getCategory"
                    }`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const responseJson = await fullResponse.json();
                let data = [];
                if (type == 'scholarship') {
                    data = responseJson?.data
                } else {
                    data = responseJson?.data
                }

                setList(data);
                //   setList(responseJson.data);

            }
            fetchDetails();
        }
        setLoading(false);

    }, [type]);

    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Banner</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>


                    <RHFTextField name="title" label="Title" />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="File"
                            error={!file && val}
                            helperText='Image Only ( Maximum allowed size 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />
                        {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
                        <RHFTextField name="BannerType" label="Banner Type" select>
                            <MenuItem value='APP'>App</MenuItem>
                            <MenuItem value="TAB">Tab</MenuItem>
                            <MenuItem value="WEB">Web</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="link" label="Link Banner" type='checkbox' select >
                            <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                            <MenuItem value='batch' onClick={() => setType('batch')}>Batch</MenuItem>
                            <MenuItem value='testSeries' onClick={() => setType('testSeries')}>Test Series</MenuItem>
                            <MenuItem value='category' onClick={() => setType('category')}>Category</MenuItem>
                            <MenuItem value='link' onClick={() => setType('link')}>Link</MenuItem>
                            <MenuItem value='scholarship' onClick={() => setType('scholarship')}>Scholarship</MenuItem>
                        </RHFTextField>
                        {(type == 'link') && <RHFTextField name="valueId" label="External Link" type="url" />}
                        {(type !== 'none' && type !== '' && type != 'link') && <RHFTextField name="valueId" label={type == "batch" ? "Link With Batch" : type == 'testSeries' ? "Link With Test Series" : type == 'scholarship' ? "Link With Scholarship Test" : "Link With Catgeory"} select>
                            {list?.map((item, index) => (

                                <MenuItem
                                    key={index}
                                    value={item?._id}
                                    onClick={() => setValueId(item?._id)}
                                >
                                    {type == "batch"
                                        ? item?.batch_name :
                                        type == "testSeries" ? item?.testseries_name : item?.title}
                                </MenuItem>
                            )

                            )}
                        </RHFTextField>}
                    </Stack>


                    <Stack direction="row" spacing={3}>


                        <RHFTextField name="is_active" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>




                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
