import React , { useState , useEffect} from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormProvider , RHFTextField } from '../../../components/hook-form' ;
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import Select from 'react-select';
import Async from "react-select/async";
import makeAnimated from 'react-select/animated';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from "@mui/lab";
import { Typography , Stack , Button , MenuItem } from '@mui/material';
const AssignStudent = ({ batchId , setOpen}) => {
    const theme = useTheme();
    // console.log(batchId)
    const animatedComponents = makeAnimated();
    const [loading, setLoading] = useState(false);
    const registerSchema = Yup.object().shape({
    //   batchId: Yup.string().required('BatchId Required'),
      transactionId: Yup.string().required('Transaction ID Required'),
      amount: Yup.string().required('Amount Required')
    });
    const defaultValues = {
       batchId : "",
       transactionId : "",
       amount : "",
       userIdArr : []
    };
    const methods = useForm({
      resolver: yupResolver(registerSchema),
      defaultValues,
    });
    const {
      handleSubmit,
      reset,
      formState: { isSubmitting },
    } = methods;

    const [list , setList] = useState([]);
    useEffect(() => {
            const token = localStorage.getItem("token");
        async function fetchDetails() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminSection/getValidityofBatch/${batchId}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const responseJson = await fullResponse.json();
              setList(responseJson?.data);
        }
        fetchDetails();
        setLoading(false);
        
    }, []);

    const[student , setStudent] = useState('');
    const handleChangeValue = (data) => {
        setStudent(data);
      }
      

      const callApi  = async (text) => {
        // console.log(text);
        const token = localStorage.getItem("token");
        const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
        );
        const responseJson = await fullResponse.json();
        return responseJson?.data;
              
        // }
        // fetchDetails();
      }
      const promiseOptions = (inputValue) =>{
         return new Promise((resolve) => {
           setTimeout(() => {
            resolve(callApi(inputValue));
             }, 100);
        })
      };
      // console.log(student)
    const onSubmit = async (data) => {
      // console.log(data)
      const token = localStorage.getItem("token");
    const authToken = token;
     setLoading(true);
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // console.log('110', data)

    const reqData = {
      "userIdArr": [student?.value],
      "batchId": batchId,
      "transactionId":data.transactionId,
      "amount":data.amount,
      "validityId" :  data.validity , 
    }
    // console.log('reqData' , reqData);
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/batchEnroll`,
        // data,
        reqData,
        config
      )
      .then((response) => {
        // console.log(response.data.msg);
        if(response.data.status){
          toast.success(response.data.msg, { autoClose: 3000 });
          setLoading(false);
          setOpen(false);
        }else{
          // console.log("Erorr")
          toast.error(response.data.msg);
          setLoading(false)
        }
        
      })
      .catch((e) => {
        toast.error(e, { autoClose: false });
        // setOpen()
        //console.log(e);
      });
    }
  
  //  console.log(`${JSON.stringify(student)}`);
    return (
      <>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" align="center" mb={2}>
            Assign Students
          </Typography>
          <Stack direction="column" spacing={3}>
                {/* <Select 
                  name='Validity'
                  closeMenuOnSelect={false}
                  components={animatedComponents} 
                  menuPortalTarget={document.body}
                  onChange={handleChangeValidity} 
                  placeholder='Please Select Validty Plan'
                  value={validity} 
                  styles={{ menuPortal :  base => ({ ...base , zIndex : 9999})}} 
                  options={list} 
                  isMulti={false}
                /> */}
               {/* <Select
                name="Student"
                closeMenuOnSelect={false}
               components={animatedComponents}
                value={student}
                onChange={handleChangeValue}
                onInputChange={callApi}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            
                                        //  defaultValue={[colourOptions[4], colourOptions[5]]}
                isMulti={false}
                options={list}
                /> */}
                <Async 
                   
                components={animatedComponents}
                menuPortalTarget={document.body}
                onChange={handleChangeValue}
                value= { student}
                 styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                 loadOptions={promiseOptions}
                />
                <RHFTextField name="transactionId" label="Transaction Id" />
                <RHFTextField name="amount" label="Amount" />
                <RHFTextField
                                name='validity'
                                label="Select Validity Plan"
                                sx={{ width: '100%' }}
                                select
                >
                   {list?.map((item) => (
                      <MenuItem  value={item?.value}>{item?.label }</MenuItem>

                  )) }
                </RHFTextField>
               
          </Stack>
         
          <Stack
            direction="row"
            spacing={2}
            m={3}
            align="center"
            justifyContent="space-evenly"
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.primary.mainTheme,
                color: "white",
                "&:hover": { background: "red" },
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
           
            <LoadingButton size="large" type="submit" variant="contained" disabled={student == ''} loading={loading}
              sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme } }}>
              Assign Student
            </LoadingButton>
          </Stack>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </FormProvider>
      </>
    );
}

export default AssignStudent