import React, { useState } from 'react'
import { FormProvider, RHFTextField } from '../../../components/hook-form'
import { MenuItem, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import Select from 'react-select';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';
import makeAnimated from 'react-select/animated';
import { toast , ToastContainer } from 'react-toastify';


const AddDeepLink = () => {
    const theme = useTheme();
  const animatedComponents = makeAnimated();

    const[type , setType] = useState('');
    const[list , setList] = useState([]);
    const[value , setValue ] = useState([]);
    const[loading , setLoading] = useState(false);


    const [file, setFile] = React.useState()
  const [restrict, setRestrict] = React.useState('')
  const [focus, setFocused] = React.useState(false);
  const [pdf, setPdf] = React.useState(false)
  const [msg, setMsg] = React.useState('');
  const [val, setVal] = React.useState(false);
  const[message , setMessage] = React.useState('');

    const handleValue = (newValue) =>{
        setValue(newValue);
    }

    React.useEffect(() => {
        // setLoading(true);
        setValue([]);
        setList([])
        async function fetchList(){
          let endUrl = type == 'batch' ? `adminPanel/getBatches` 
                       : type == 'quiz'  ? 'adminPanel/getQuizes' : 
                       type == 'scholarship'  ? 'adminPanel/getScholarshipTests' : 
                       type == 'storeCategory'  ? 'store/getAllStoreProductCategory':
                       type == 'store'  ? 'store/getAllStoreProduct' : 'community/getCMSPosts'
          const token = localStorage.getItem('token')
          let config = {
            headers : {
              'Content-Type' : "application/json",
              authorization : `Bearer ${token}`
            }
          }
          const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/${endUrl}` , config);
          let data = response?.data?.data?.filter((item) =>  (item?.is_active == true || item?.isActive == true ))
          // setList(response?.data?.data ?? []);
          // console.log(data);
          setList(data);
    
        }
        if( type != ""  && type != 'all'){
          fetchList();
        }
    
      }, [type])

    const NotificationSchema = Yup.object().shape({
        type : Yup.string().required('Type is Required'),
        // specific : Yup.string().required("Specific Type Required"),
        // message : Yup.string().required('Message is Required'),
         utmSource :  Yup.string().required(' Utm Source Required'),
         utmCampaign :  Yup.string().required(' Utm Campaign Required'),
        utmMedium : Yup.string().required('Utm Medium is required'),
        utmTerm : Yup.string().required('Utm Term is required'),
        utmContent : Yup.string().required('Utm Content is required'),
        socialTitle : Yup.string().required('Social Title is required'),
        socialDescription : Yup.string().required('Social Description is required'),
      })
    
      const defaultValues = {
        title : "",
        message : "",
    
      }
    
      const methods = useForm({
        resolver : yupResolver(NotificationSchema),
        defaultValues
      })
    
      const changeHandler = async (event) => {
        let size = event?.target?.files[0]?.size / ( 1024);
            if( size > 100){
                setVal(true);
            }else{
                setFile(event?.target?.files[0])
                var fileName = document.getElementById('file').value.toLowerCase();
                  if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
                 else { setRestrict('JPG Or PDF') }
            }
      };
      const {
        handleSubmit , reset ,
        formState : { isSubmitting }
      } = methods;


    const onSubmit = async(data) => {
        setLoading(true)
       let formData = new FormData();
       if( file){
         formData.append('file' , file);
       }
       formData.append('utmSource' , data?.utmSource)
       formData.append('utmContent' , data?.utmContent)
       formData.append('utmMedium' , data?.utmMedium)
       formData.append('utmCampaign' , data?.utmCampaign)
       formData.append('utmTerm' , data?.utmTerm)
       formData.append('socialTitle' , data?.socialTitle)
       formData.append('socialDescription' , data?.socialDescription)
       if( type == 'batch'){
          formData.append('route', 'batchbyid')
       }else if ( type == 'quiz'){
         formData.append('route' , 'dailyQuizbyid')
       }else if ( type == 'community'){
         formData.append('route' , 'feedById')
       }
       else if ( type == 'scholarship'){
          formData.append('route' , 'scholarshipTestById')

       }else if ( type == 'store'){
          formData.append('route' , 'productByID')
       }else if ( type == 'storeCategory'){
          formData.append('route' , 'storeCatagorybyid')
       }
       formData.append('rootId' ,value?.value)
      formData.append('childId' , null)
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
       const response =  await axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/createDeepLink` , formData , config);
       console.log(response.data)
       if( response?.data?.status == true){
        setLoading(false);
        toast.success(response.data.msg);
        window.location.href = '/dashboard/dynamicLinks'
       }else{
        setLoading(false);
        toast.error(response.data.msg)
       }
    }
  return (
    <Stack spacing={3} px={5}>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <h2 style={{ margin :  '10px'}}> Add Link</h2>
    <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={2}>
            <Stack sx={{ width : type != "" ? '50%' : "100%"}}>
              <RHFTextField name="type" label="Select Type" select>
                <MenuItem value="batch" onClick={() => setType("batch")}>Batch</MenuItem>
                <MenuItem value="quiz" onClick={() => setType("quiz")}>Quiz</MenuItem> 
                <MenuItem value="scholarship" onClick={() => setType("scholarship")}> Scholarship</MenuItem>
                <MenuItem value="community" onClick={() => setType("community")}>Community</MenuItem>
                <MenuItem value="store" onClick={() => setType("store")}>Store</MenuItem>
                <MenuItem value="storeCategory" onClick={() => setType("storeCategory")}>Store Category</MenuItem>
              </RHFTextField>
            </Stack>
         
          { (type != "" && type != "all" ) &&
          (<Stack sx={{ width :  '50%'}}>
            <Select
            isMulti={false}
            options={list}
            components={animatedComponents}
            placeholder={`Please Select ${type == 'batch' ? 'Batch' : type == 'quiz' ? 'Quiz' : type == 'scholarship' ? 'Scholarship Test' : 'Community Post'}`}
            closeMenuOnSelect={false}
            value={value}
            onChange={handleValue}
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
          </Stack> ) 
           
          }
        </Stack>
        <Stack direction='row' spacing={2}>
           <RHFTextField name="utmSource" placeholder='Enter Utm Source'/>
           <RHFTextField name="utmMedium" placeholder='Enter Utm medium'/>
        </Stack>
        <Stack direction='row' spacing={2}>
           <RHFTextField name="utmTerm" placeholder='Enter Utm Term'/>
           <RHFTextField name="utmContent" placeholder='Enter Utm Content'/>
        </Stack>
        <Stack direction='row' spacing={2}>
             <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="File"
                  // error={!file && val}
                  helperText='Image Only (Maximum 100KB allowed)'
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField"
                    }
                 }}

                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}

              />
           <RHFTextField name="utmCampaign" placeholder='Enter Utm Campaign'/>
        </Stack>
        <Stack direction='row' spacing={2}>
           <RHFTextField name="socialTitle" placeholder='Enter Social Title'/>
           <RHFTextField name="socialDescription" placeholder='Enter Social Description'/>
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
            //   setVal(true)
            //   setMsg('Required')
            }}
            >
            Add
          </LoadingButton>
      </Stack>
    </FormProvider>
    <ToastContainer
        position="bottom-center"
        autoClose={1000}
        deProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
      
    </Stack>
  )
}

export default AddDeepLink