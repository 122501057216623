import * as Yup from 'yup';
import {  useState , useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import "./general.css";
import { useTheme } from '@emotion/react';
import RHFTextEditor from '../../../../components/hook-form/RHFTextEditor';

// ----------------------------------------------------------------------

export default function UpdateThird({ setShowForm, setProductId, productId, value, setValue, title, setSubmit3, editValue, onChange2, fetchUsers }) {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const RegisterSchema = Yup.object().shape({

    });
    const [saleExpireDate , setSaleExpireDate] = useState(dayjs(editValue?.saleExpire));
    const handleChangeEndDate = ( newValue) =>{
        setSaleExpireDate(newValue);
    }
    const defaultValues = {};
    const [coinApplicable, setIsCoinApplicable] = useState(false)
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar
    const[ desc , setDesc] = useState(editValue?.metaDesc);
    const onChange3 = (value) =>{
        setDesc(value)
    }
    // useEffect(() => {
    //     setSaleExpireDate(dayjs(editValue?.saleExpire))
    // } , [])
    const onSubmit = async (data) => {
       
        const details = {
            isCoinApplicable: data?.isCoinApplicable ? data?.isCoinApplicable : editValue?.isCoinApplicable,
            maxAllowedCoins: editValue?.maxAllowedCoins,
            regularPrice: editValue?.regularPrice,
            salePrice: editValue?.salePrice,
            metaTitle : editValue?.metaTitle,
            metaDesc : desc,
            saleExpire :  saleExpireDate.$d
        }

        setLoading(true)

        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        };
        setProgress(0);
        axios.post(`${process.env.REACT_APP_LIVE_URL}/store/addStoreProductThird/${editValue?.id}`, details, config).then((response) => {

            if (response.data.msg === 'Product Details added') {
                setProductId(response?.data?.data?._id)
                toast.success(response.data.msg);
                setLoading(false);
                setPdf(false);
                reset();
                setValue(value + 1);
                setSubmit3(true)
                fetchUsers();
                // window.location.href = "/dashboard/banner";
            }else{
                toast.error(response.data.msg);
                setLoading(false);
            }

        })
            .catch(e => {
                //console.log(e);
            })
    };
    // console.log(editValue?.metaTitle);
    // console.log(editValue?.metaDesc);
    return (<>
        <Stack spacing={3} px={5} >
            {/* <Typography variant='h3'>Add Store Product 3</Typography> */}
            <Typography variant='h3'>Update <span style={{ color: 'blue' }}>{title}</span>  Product details</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>

                    <Stack direction='row' spacing={3}>
                        <RHFTextField name="regularPrice" type='text' value={editValue?.regularPrice} onChange={onChange2} label="Regular Price" />
                        <RHFTextField name="salePrice" type='text' value={editValue?.salePrice} onChange={onChange2} label="Sale Price" />
                    </Stack>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <DateTimePicker
                label="Sale Expires time"
                value={saleExpireDate.$d}
                onChange={handleChangeEndDate}
                // name="starting_date"
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
             
            </Stack>
                  </LocalizationProvider>
                    <RHFTextField name="metaTitle" value={editValue?.metaTitle} type='string' label="Meta Title" onChange={onChange2}/>
                   <RHFTextEditor name="metaDesc" value={desc}  label="Meta Description" onChange={onChange3}/>

                    <Stack direction="row" spacing={3}>

                        <RHFTextField name="isCoinApplicable" label={editValue?.isCoinApplicable ? 'Coin Applicable (Yes)' : 'Coin Applicable (No)'} type='checkbox' select >
                            <MenuItem value={true} onClick={() => setIsCoinApplicable(true)}>Yes</MenuItem>
                            <MenuItem value={false} onClick={() => setIsCoinApplicable(false)}>No</MenuItem>
                        </RHFTextField>
                        {coinApplicable && <RHFTextField name="maxAllowedCoins" value={editValue?.maxAllowedCoins} onChange={onChange2} type='text' label="Max Allowed Coins" />}
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')

                        }}>
                        Next Step
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}

