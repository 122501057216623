import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem, Select, InputLabel, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, } from '../../../components/hook-form';


import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Page from '../../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';









const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const AssignTeacherToBatch = () => {

    const [allteachers, teacherSet] = useState([]);
    const [teacher, setteacher] = React.useState([]);
    const navigate = useNavigate()
    const { batchId } = useParams()
    const theme = useTheme();
    const LoginSchema = Yup.object().shape({
    });

    const defaultValues = {
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;




    //Assign Teachers
    const onSubmit = async (data) => {
        // //console.log(data);



        let token = localStorage.getItem("token");

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
        let obj = {
            teacher: teacher,
            batch_id: batchId

        }
        //console.log(obj)
        axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addTeacherToBatch`, obj, config).then((response) => {
            if (response.data.msg == 'updated the batches details successfully') {
                reset()
                toast.success("Batch Details Updated")
                setTimeout(() => {
                    navigate('/dashboard/batches')
                }, 1000)
            }
        })
            .catch(e => {
                //console.log(e);
            })
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            teacherSet(responseJson.data);
        }

        fetchUsers();
    }, []);




    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setteacher(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (<>
        <Page title='Batch'>
            <Stack spacing={3} ml={5}>
                <Typography variant='h2'>Add Teacher To Batch </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >




                    <Stack direction="row" sx={{ width: '40%' }}>
                        <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Teachers</InputLabel>
                        <Select
                            // label    ="Name"
                            // id="demo-multiple-name"
                            // labelId="test-select-label"
                            label=""
                            multiple
                            name='teacher'
                            value={teacher}
                            onChange={handleChange}

                            sx={{ width: '100%', color: 'black', zIndex: 9 }}
                            MenuProps={MenuProps}
                        >

                            {allteachers?.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name._id}
                                    style={getStyles(name, teacher, theme)}
                                >
                                    {name.FullName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>



                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, width: '40%', marginTop: '2%', '&:hover': { background: theme.palette.primary.mainTheme, } }}
                    >
                        Add
                    </LoadingButton>
                </FormProvider>
                {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize', width: '40%' }}>{fetchMessage}</Typography> */}
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Stack>
        </Page>
    </>
    )
}

export default AssignTeacherToBatch