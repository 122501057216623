
import * as React from "react";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar 
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


export default function StoreUser() {
  
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();
  const[loading , setLoading] = React.useState(false)
  const [rowModesModel, setRowModesModel] = React.useState({});
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true)
    async function fetchUsers() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/store/getStoreUser`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
      const responseJson = await fullResponse.json();
      setRows(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
    
  }, []);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };


  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

    

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber =  rows?.map((row, index) => ({ ...row, serialNumber: index + 1 }));

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
  
      {
        field: "userId",
        headerName: "User Id",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
       
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        
      },
      {
        field: "email",
        headerName: "Email",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        
      },
      {
        field: "mobileNumber",
        headerName: "phone",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
      },

      {
        field: "utm_campaign",
        headerName: "Utm Campaign",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "utm_source",
        headerName: "Utm Source",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "utm_medium",
        headerName: "Utm Medium",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "createdAt",
        headerName: "Created At",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main"
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }
  
          return [
            <GridActionsCellItem
            icon={<VisibilityOutlinedIcon />}
            label="View"
            onClick={() => navigate(`/dashboard/students/studentsProfile/${id}`)}
            color="inherit"
          />,
            
          ];
        }
      }
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <h3 style={{ marginBottom:"6px"}}>All Store Users</h3>
      <DataGrid
        rows={rowsWithSerialNumber}
        columns={columns}
        editMode="row"
        getRowHeight={() => 'auto'}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25,50]}
        slots={{
        //   toolbar: EditToolbar,
           toolbar: GridToolbar 
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
