import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar 
} from "@mui/x-data-grid";
import AddCommentIcon from '@mui/icons-material/AddComment';
import {
  Tooltip,
} from "@mui/material";

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

const CommunityTable =() => {
  const navigate = useNavigate();
  const access = getAccess();
  // console.log(access);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [loading , setLoading] = React.useState(true);
  React.useEffect(()=>{
    const token = localStorage.getItem('token');
    async function fetchCommunity(){
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/community/getCMSPosts` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`

      }
      });
      const responseJson = await response.json();
       setRows(responseJson.data ?? []);
       setLoading(false);
    }
    fetchCommunity()
  },[])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const changeCommentStatus = async ( id ) => {
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/community/changePostComment?postId=${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if (resJson.status) {
     toast.success(resJson.msg);
     setTimeout(() => window.location.reload()
      , 1000) 
    } else {
      toast.error(resJson.msg);
    }
    
  }

  const handleDeleteClick =  (id) => async() => {
    // console.log(id);
    const confirmDelete = window.confirm('Are you sure you want to delete this Community?');
    if( confirmDelete){
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/community/deleteCMSPost/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if( resJson.status){
      toast.success(resJson.msg);
      setRows(rows.filter((row) => row.id !== id));
    }else{
      toast.error(resJson.msg);
    }
    // toast.success(resJson);
   };
    }
    

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber =  rows?.map((row, index) => ({ ...row, serialNumber: index + 1 }));

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
    {
        field: "title",
        headerName: "Title",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "featuredImage",
        headerName: "Feauted Image",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        renderCell: (params) => (
            <img
              src={params.value}
              alt={`Image for row ${params.id}`}
              style={{ width: 150, height: 70 }}
            />
          ),
      },
      {
        field: "author",
        headerName: "Author",
        type: "object",
        width: 200,
        align: "left",
        headerAlign: "left",
        renderCell : (params) => (
          <div>{params.value?.FullName}</div>
        )
        
      },
      {
        field: "language",
        headerName: "Language",
        // type: "object",
        width: 100,
        align: "left",
        headerAlign: "left",
        renderCell : (params) => (
          <div>{params.value == "hi" ? "Hindi" : params.value == 'en' ? "English" : "Both"}</div>
        )
        
      },
      {
        field: "isActive",
        headerName: "Status",
        type: "boolean",
        width: 100,
        align: "left",
        headerAlign: "left",
        renderCell: (params) => (
            <div style={{ color: params.value == true ? 'green' : 'red' }}>
              {params.value ? 'Active' : 'inActive'}
            </div>
          ),
      
      },
      {
        field: "createdAt",
        headerName: "Created At",
        type: "Date",
        width: 170,
        align: "left",
        headerAlign: "left",
        // editable: true,
        
      },
      {
        field: "updatedAt",
        headerName: "Updated At",
        type: "Date",
        width: 170,
        align: "left",
        headerAlign: "left",
        // editable: true,
        
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const rowData = rows.find((item) => item.id === id);
          
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main"
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }
  
          return [
            (((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' ) || access?.access.includes('all') ) ? <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              className="textPrimary"
              onClick={() => navigate(`/dashboard/communityProfile/${id}`)}
              color="inherit"
            /> : <></>),
            ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title={rowData?.isCommentAllowed == true ? "Disable Comment" : 'Enable Comment'}>
            <GridActionsCellItem
              icon={<AddCommentIcon style={ rowData?.isCommentAllowed == true ? {color : 'green' } :{ color: 'red' }} />}
              label="Change Status of Lecture Post"
              onClick={() => changeCommentStatus(id)}
              // onClick={() => console.log("line 12"+rowData.is_active)}
              // onClick={ (id) => handleOpen(id)}
         
              color="inherit"
            />
          </Tooltip> : <></>),
            (((access?.accessForTab == 'readWrite' && access?.access?.includes('131') ) || access?.access.includes('all')) ? <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => navigate(`/dashboard/community/${id}`)}
              color="inherit"
            /> : <></>),
            (((access?.deleteAccess == true && access?.access?.includes('131') )|| access?.access.includes('all')) ? <GridActionsCellItem
              icon={<DeleteIcon sx={{color :"red"}}/>}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            /> : <></>)
          ];
        }
      }
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        rows={rowsWithSerialNumber}
        columns={columns}
        autoHeight
        loading={loading}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25,50]}
        slots={{
        //   toolbar: EditToolbar,
           toolbar: GridToolbar 
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
export default CommunityTable
