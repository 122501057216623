import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, MenuItem, TextField, Stack, InputLabel , Card , Button } from "@mui/material";

import StoreOrdersTable from "./StoreOrdersTable";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import getAccess from "../../../utils/getAccess";
import { blue } from "@mui/material/colors";
import Select from 'react-select';

const style = {
  py: 3,
  boxShadow: 0,
  textAlign: "center",
  color: "#7f11e0bf",
  bgcolor: "#7f11e026",
  width: "100px",
  minHeight: "20px",
  borderRadius: "10px",
  '&:hover': {
    transform: "scale(1.1)",
    bgcolor: "#7f11e0bf",
    color : "white"
  },
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StoreOrder = () => {
  // const access = getAccess();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [details, setDetails] = React.useState({});
  const [type, setType] = React.useState({value : 'all' , label : "All"});
  const preDate = dayjs().subtract(1, "day");

  const [starting, setStarting] = React.useState(preDate);
  const [ending, setEnding] = React.useState(dayjs());
  const typeOptions = [
    { value : 'all' , label : 'All'},
    { value : 'store' , label : 'Store'},
    { value : 'publication' , label : 'Publication'},

  ]
  const [ filterObject , setFilterObject] =  React.useState({ platform : 'all' , startDate : starting , endDate : ending})

  const handleChangeOne = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeType = (newData) => {
    setType(newData)
  }
  

  const  fetchSummary = async() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_LIVE_URL}/store/getOrdersSummary?platform=${type?.value}&startDate=${starting}&endDate=${ending}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await response.json();
    setDetails(responseJson?.data ?? {});
    setLoading(false);
  }
  React.useEffect(() => {    
    fetchSummary();
  }, []);
  
  // console.log(details);
  const handleFilter = () => {
    // fetchSummary()
    setFilterObject({
      platform : type?.value ,
      startDate : starting ,
      endDate : ending 
    })
    
  }
  return (
    <Box sx={{ width: "100%" }}>
      <h2 style={{ marginBottom: "6px" }}>All Store Orders</h2>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={style}>{`Un FulFill (${details?.UnFulFillable})`}</Box>
        <Box sx={style}>{`New Orders (${details?.newOrder})`} </Box>
        <Box sx={style}>{`Packed Orders (${details?.packed})`}</Box>
        <Box
          sx={style}
        >{`Ready To Ship Orders (${details?.readyToShipped})`}</Box>
        <Box sx={style}>{`Cancelled (${details?.cancelled})`}</Box>
        <Box sx={style}>{`All Ordered (${details?.allOrders})`} </Box>
        <Box sx={style}>{`Delivered Orders(${details?.delivered})`}</Box>
        <Box sx={style}>{`Return Orders (${details?.returnOrders})`}</Box>
      </Box>
      
      <Card sx={{ width: '100%', position: 'relative', padding: '40px', height: '30px', marginTop: '20px',marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '40px' }}>
        <h3>Filters</h3>
        <Box sx={{width :  "200px" ,}}>
          <Select  
             menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={handleChangeType}
            placeholder={'Order Type'} options={typeOptions} 
          />
        </Box>
        <Box >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              value={starting.$d}
              onChange={handleChangeOne}
              // name="starting_date"
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "100%" }} />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To"
              value={ending.$d}
              onChange={handleChangeTwo}
              sx={{ color: "black" }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "100%", color: "black" }} />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box>
            <Button variant="contained" onClick={handleFilter}>Apply</Button>
        </Box>
      </Card>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ width: "100%" }}
        >
          <Tab label="UnFulFillable" sx={{ width: "9%" }} {...a11yProps(0)} />
          <Tab label="New" sx={{ width: "9%" }} {...a11yProps(1)} />
          <Tab label="Packed" sx={{ width: "9%" }} {...a11yProps(2)} />
          <Tab label="Ready To Ship" sx={{ width: "9%" }} {...a11yProps(3)} />
          <Tab label="Cancelled" sx={{ width: "9%" }} {...a11yProps(4)} />
          <Tab label="All Orders" sx={{ width: "9%" }} {...a11yProps(5)} />
          <Tab label="Delivered " sx={{ width: "9%" }} {...a11yProps(6)} />
          <Tab label="Return " sx={{ width: "9%" }} {...a11yProps(7)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StoreOrdersTable
          data={data}
          type="UnFulFillable"
          paid="paid"
          isLoading={loading}
          orderMethod="recentOrders"
          filterObject={filterObject}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StoreOrdersTable
          data={data}
          type="new"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}

        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StoreOrdersTable
          data={data}
          type="packed"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StoreOrdersTable
          data={data}
          type="readyToShipped"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}

        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StoreOrdersTable
          data={data}
          type="cancelled"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}

        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <StoreOrdersTable
          data={data}
          type="all"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}

        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <StoreOrdersTable
          data={data}
          type="delivered"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}

        />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <StoreOrdersTable
          data={data}
          type="return"
          paid=""
          isLoading={loading}
          orderMethod=""
          filterObject={filterObject}

        />
      </TabPanel>
    </Box>
  );
};

export default StoreOrder;
