import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import "./general.css";
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

export default function AddBanner() {
      const theme=useTheme()
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    const[type, setType] = useState('home');
    const[valueId , setValueId] = useState('');
    const[list , setList] = useState([]);

    const RegisterSchema = Yup.object().shape({
        // renderLink: Yup.string().required('Render Link required'),
        link: Yup.string()
          .required('Link CTA Banner Required')
          .oneOf(['home', 'category','examCategory', 'exam','examDetails' , 'externalLink'], 'Invalid Link Value'),
        valueId: Yup.string().when('link', {
          is: (link) => link !== 'home' && link !== 'exam',
          then: Yup.string().required('Link With Required'),
          otherwise: Yup.string().notRequired(),
        }),
        routingLink : Yup.string().url(),
        isActive: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        // renderLink: '',
        link:'',
        linkWith:'',
        isActive: '',
    };
    const [file, setFile] = useState()



   
    const changeHandler = async (event) => {
      
        let size = event.target.files[0].size / ( 1024 );
        if( size > 100){
            setVal(true);
        }else{
            setFile(event.target.files[0])
            var fileName = document.getElementById('file').value.toLowerCase();
              if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
             else { setRestrict('JPG Or PDF') }
        }
        
    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar

    const onSubmit = async (data) => {
       
        if (file) {
            const formData = new FormData();

            setLoading(true)
            // debugger;
            //console.log(file)
            formData.append("file", file);
            formData.append("link" , data.link);
            if( data.link !== 'home' && data.link !== 'exam'){      
                formData.append('linkWith' , data.valueId);
            }
            formData.append('routingLink' , data.routingLink)
            formData.append('isActive' , data.isActive)
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            // document.querySelector(".mainDiv").style.display = "block";
            setProgress(0);
            axios.post(`${process.env.REACT_APP_LIVE_URL}/webContains/createCTABanner`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.status) {
                    toast.success(response.data.msg);
                    setLoading(false);
                    setPdf(false);
                    reset();
                    window.location.href = "/dashboard/ctaBanner";
                }else{
                    toast.error(response.data.msg);
                    setLoading(false);
                }

            })
                .catch(e => {
                    //console.log(e);
                })
        }else{
            toast.error('Please select icon')
        }
        
    };

     //get batch or get testSeries
    useEffect(() => {
        if( type !== 'home' && type !=='exam'){
            const token = localStorage.getItem("token");
        async function fetchDetails() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/${
                   type === 'examDetails' ? "adminPanel/getAllBlog" : type === 'examCategory' ? "webContains/blogCategory" : "adminPanel/getCategory"  
                //    type == 'examDetails' : 
                }`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const responseJson = await fullResponse.json();
              let data = [];
              if( type == 'examDetails'){
                data =  responseJson?.data.filter((item) => item.isActive == true) 
              }
              else if (type == 'examCategory') {
                data = responseJson?.data
              }else {
                data = responseJson?.data?.filter((item) => item?.is_active == true)
              }
              
              setList(data);
            //   setList(responseJson.data);
              
        }
        fetchDetails();
        }
        setLoading(false);
        
    }, [type]);
 
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add CTA Banner</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="File"
                            error={!file && val}
                            helperText='Image Only ( Maximum allowed size 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />                      
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="link" label="Link CTA Banner" type='checkbox' select >
                            <MenuItem value='home' onClick={() => setType('home')}>Home</MenuItem>
                            <MenuItem value='category' onClick={() => setType('category')}>Category Page</MenuItem>
                            <MenuItem value='exam' onClick={() => setType('exam')}>Exam Page</MenuItem>
                            <MenuItem value='examCategory' onClick={() => setType('examCategory')}>Exam Category Page</MenuItem>
                            <MenuItem value='examDetails' onClick={() => setType('examDetails')}>Specfic Exam Deatils Page</MenuItem> 
                            {/* <MenuItem value='externalLink' onClick={() => setType('externalLink')}>External Link</MenuItem>  */}

                        </RHFTextField>
                        { (type !== 'home' && type !=='exam' && type !== 'externalLink') && <RHFTextField name="valueId" label={ type == "category" ? "Link With Category" : type=='examDetails' ? "Link With Specfic Exam" : type=='examCategory' ? "Link With Exam Category" : ''} select>
                            {list?.map((item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item?._id}
                                          onClick={() => setValueId(item?._id)}
                                        >
                                            { item?.title}
                                        </MenuItem>
                                      )
                 
                            )}
                            </RHFTextField>}
                            {/* { type  === 'externalLink' && <RHFTextField name='valueId' label="Enter The link"/>} */}
                    </Stack>
                    <RHFTextField name='routingLink' label="Enter Url" />
                    <Stack direction="row" spacing={3}>
                        {/* <RHFTextField name="renderLink" label="Render Link" /> */}
                        <RHFTextField name="isActive" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
