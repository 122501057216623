import { Avatar, Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { border, Stack } from '@mui/system'
import React, { useState } from 'react'
import Page from '../../../components/Page'
import { CameraAlt, Call, Email, Edit } from '@mui/icons-material';
import Iconify from '../../../components/Iconify';
import Modal from '@mui/material/Modal';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// For Dialog Box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CSVLink } from 'react-csv'; // for CSV file download
import { set } from 'lodash';
import { useTheme } from '@emotion/react';
import getAccess from '../../../utils/getAccess';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
};

const Profile = () => {


  const theme = useTheme()


  // Code for handling Dialog box
  const [edit, setEdit] = useState(false);
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Student Data"
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
        data,
        config
      )
      .then((response) => {
      })
      .catch((e) => {
        //console.log(e);
      });

  }
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById('name').value
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        // //console.log(response);
        if (response.data.status == false) {
          setErrorOtp("Invalid Otp");
        }
        else {
          setEdit(true);
          setErrorOtp("Successfully Verified");
          handleClose();
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  }

  //Password
  const [showPassword, setShowPassword] = useState(false);

  //Loading
  const [loading, setLoading] = useState(false);

  const Container = {


    // background: linear - gradient(170.85deg, #EA4444 - 174.82 %, rgba(234, 68, 68, 0) 86.95 %);
    background: 'linear-gradient(to bottom,#7f11e026 , rgba(234, 68, 68, 0))',

    // background: 'rgba(255, 137, 158, 0.12)', borderRadius: '10px',
    position: 'relative'

  }
  const BoxStyle = {
    position: 'relative',
    // background: 'red',
    width: 'max-content',
    height: '100%',


  }
  const IconStyle = {
    position: 'absolute',
    right: '8px',
    bottom: '12px',
    bgcolor: '#e5e5e5',
    borderRadius: '50%',
    padding: '3px',
    cursor: 'pointer'

  }
  const TypographyStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontWeight: '600'
  }
  // const name = localStorage.getItem('details')
  const [image, setImage] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target
    setImage(value)
  }
  const details = getAccess();
  const [number, setNumber] = useState(details?.mobileNo);
  //console.log(details);
  const role = details?.role;
  const [file, setFile] = useState();
  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };

  const navigate = useNavigate()

  //Update Passowrd
  const handleUpdate = async () => {
    //console.log('Hellllo');
    setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const formData = new FormData();
    // formData.append("file", file);
    // formData.append("mobileNumber",number);
    // //console.log(formData);
    const data = {
      "mobileNumber": number,
      "name": "SD Campus",
      "subjectIdsArr": ["63d18ccc0e7f5bc9451bff93"],
      "qualification": "B.Tech"
    }
    axios
      .put(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/updateProfile`, data, config)
      .then((response) => {
        //console.log(response.data);

        if (response.data.status) {
          //console.log("inside");
          setLoading(false);
          toast.success("Updated Successfully !");
          const dataa = {
            "email_phoneNumber": details.mobileNo,
            "message": "Logout my account"
          }
          const handleLogout = () => {

            setTimeout(() => navigate('/'), 1000)
            setTimeout(() => {
              localStorage.removeItem("token")
              localStorage.removeItem("isLoggedIn")
              localStorage.removeItem("details")
              localStorage.removeItem("detailObject")
              localStorage.removeItem("role")
              localStorage.removeItem("id")
              localStorage.removeItem("idOfAdmin")
              localStorage.removeItem("accessArray")
            }, 800)


          }

          handleLogout();
        } else {
          setLoading(false)
          toast.error(response.data.msg);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };


  return (
    <Page title='Profile'>
      <Box p={5} sx={{ height: '70vh', ...Container }}>
        <Stack spacing={3}>
          <Box sx={{ ...BoxStyle }}>
            <Avatar
              alt="Aemy Sharp"
              src={details?.profilePhoto}
              sx={{ width: 144, height: 144, border: '0.5px solid black' }}
            />

            {
              edit ? (
                <>
                  <input
                    style={{
                      display: "none"
                    }}

                    id="choose-file"
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    onChange={changeHandler}

                  />
                  <label htmlFor="choose-file">
                    <CameraAlt sx={{ ...IconStyle }} />
                  </label>
                </>
              ) : (
                <></>
              )
            }


          </Box>
          <Stack >
            <Typography variant='h5' ml={2}>{details.username}</Typography>
            <Typography ml={2} mb={5} sx={{ color: 'gray', fontSize: '19px' }}>{role} {' '},{' '}SD Campus </Typography>
          </Stack>
          <Stack direction="row" spacing={5} sx={{ position: 'relative' }}>
            {
              edit ? (
                <>
                  <label htmlFor="choose-file">
                    Mobile Number
                  </label>
                  <input type="number" style={{ padding: "10px" }} placeholder={details?.mobileNo} onChange={(e) => { setNumber(e.target.value); }}></input>
                </>
              ) :
                (
                  <>
                    <Typography variant='h7' ml={2} sx={{ ...TypographyStyle }}><Call />{' '} {details?.mobileNo}</Typography>
                  </>
                )
            }
            <Typography variant='h7' ml={2} sx={{ ...TypographyStyle }}><Email />{' '}{role === 'admin' ? details.name : details.email}</Typography>
            {(role == 'admin' && !edit) ? (
              <Edit fontSize='large'
                onClick={() => { handleClickOpen(); sendOtp(); }}
                sx={{
                  position: 'absolute', right: '10%', padding: '5px', borderRadius: '50%', cursor: 'pointer', transition: 'all 0.3s', '&:hover': {
                    bgcolor: '#e5e5e5',

                  }
                }} />
            ) : (
              <Button variant="contained"
                sx={{
                  width: "max-content",
                  alignSelf: "center",
                  background: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            )
            }
          </Stack>

        </Stack>
        {/* Modal */}
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Dialog open={open} onClose={handleClose}>

          <DialogTitle>OTP Varification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              OTP is send successfully to your regisered e-mail.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="OTP Number"
              type="number"
              fullWidth
              variant="standard"
            />
            <DialogContentText sx={{ color: "red", fontSize: '12px', padding: "3px" }}>
              {errorOTP}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={verifyOtp}>Verify</Button>
          </DialogActions>

        </Dialog>
      </Box>
    </Page>
  )
}

export default Profile