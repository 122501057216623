import React , { useState , useEffect} from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormProvider , RHFTextField } from '../../../components/hook-form' ;
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import Async from "react-select/async";
import makeAnimated from 'react-select/animated';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from "@mui/lab";
import { Typography , Stack , Button, TextField  } from '@mui/material';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AssignStudentForEbook = ({ ebookId , setOpen}) => {
    const theme = useTheme();
    const animatedComponents = makeAnimated();
    const [loading, setLoading] = useState(false);
  const [starting, setStarting] = React.useState(dayjs());
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

    const registerSchema = Yup.object().shape({
    //   batchId: Yup.string().required('BatchId Required'),
      transactionId: Yup.string().required('Transaction ID Required'),
      amount: Yup.string().required('Amount Required')
    });
    const defaultValues = {
       ebookId : "",
       transactionId : "",
       amount : "",
       userIdArr : []
    };
    const methods = useForm({
      resolver: yupResolver(registerSchema),
      defaultValues,
    });
    const {
      handleSubmit,
      reset,
      formState: { isSubmitting },
    } = methods;

    const[student , setStudent] = useState('');
    const handleChangeValue = (data) => {
    //   console.log("66" , data);
        setStudent(data);
      }
      const callApi  = async (text) => {
        // console.log(text);
        const token = localStorage.getItem("token");
        const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
        );
        const responseJson = await fullResponse.json();
        return responseJson?.data;
              
        // }
        // fetchDetails();
      }
      const promiseOptions = (inputValue) =>{
         return new Promise((resolve) => {
           setTimeout(() => {
            resolve(callApi(inputValue));
             }, 100);
        })
      };
      // console.log(student)
    const onSubmit = async (data) => {
      // console.log(data)
      const token = localStorage.getItem("token");
    const authToken = token;
     setLoading(true);
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const reqData = {
      "studentId": student?.value,
      "ebookId": ebookId,
      "transactionId":data.transactionId,
      "amount":data.amount,
      "expireDate" : starting
    }
    // console.log('reqData' , reqData);
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/ebook/ebookAssign`,
        // data,
        reqData,
        config
      )
      .then((response) => {
        //console.log(response);
        if(response.data.status){
          toast.success(response.data.msg, { autoClose: 3000 });
          setLoading(false);
          setOpen(false);
        }else{
          // console.log("Erorr")
          toast.error(response.data.msg);
          setLoading(false)
        }
        
      })
      .catch((e) => {
        toast.error(e, { autoClose: false });
        // setOpen()
        //console.log(e);
      });
    }
  
  //  console.log(`${JSON.stringify(student)}`);
    return (
      <>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" align="center" mb={2}>
            Assign Students
          </Typography>
          <Stack direction="column" spacing={3}>
                <Async 
                   components={animatedComponents}
                   menuPortalTarget={document.body}
                   onChange={handleChangeValue}
                   value= { student}
                   styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                   loadOptions={promiseOptions}
                />
                <RHFTextField name="transactionId" label="Transaction Id" />
                <RHFTextField name="amount" label="Amount" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <Stack
                         direction="row"
                         spacing={3}
                         sx={{ width: "100%", justifyContent: "space-between" }}
                    >
                       <DatePicker
                          label="Expire Date"
                         value={starting.$d}
                         onChange={handleChange}
                         renderInput={(params) => (
                           <TextField {...params} sx={{ width: "100%" }} />
                         )}
                        />
             
                      </Stack>
                </LocalizationProvider>

          </Stack>
         
          <Stack
            direction="row"
            spacing={2}
            m={3}
            align="center"
            justifyContent="space-evenly"
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.primary.mainTheme,
                color: "white",
                "&:hover": { background: "red" },
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
           
            <LoadingButton size="large" type="submit" variant="contained" disabled={student == ''} loading={loading}
              sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme } }}>
              Assign Student
            </LoadingButton>
          </Stack>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </FormProvider>
      </>
    );
}

export default AssignStudentForEbook