import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    // Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
} from '@mui/material';
// components
import Page from '../../../components/Page';

import Scrollbar from '../../../components/Scrollbar';

import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../user';
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: '', label: 'S.No', alignRight: false },
    { id: 'StudentName', label: 'Name', alignRight: false },
    { id: 'mobileNumber', label: 'Phone No.', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'username', label: 'Username', alignRight: false },


];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.StudentName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Resources() {

    const theme = useTheme();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate()
    const [users, usersSet] = useState()
    const [loading, setLoading] = useState(true)
    //Getting Details
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            setLoading(false)
            if (responseJson.msg === 'not an admin') {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("details")
                navigate('/')
            };
        }

        fetchUsers();
    }, []);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

    const filteredUsers = applySortFilter(users ? users : ['no'], getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>) :
            (<Page title="Resources">
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography variant="h3" gutterBottom>
                            All Resouces
                        </Typography>
                        {/* <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: theme.palette.primary.mainTheme, '&:hover': { background: 'red' } }} >Add Student</Button> */}
                    </Stack>

                    <Card>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={users?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const { id, StudentName, email, username, mobileNumber, avatarUrl, userId } = row;
                                            const isItemSelected = selected.indexOf(StudentName) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, StudentName)} />
                                                    </TableCell>
                                                    <TableCell >
                                                        {/* <Typography variant="subtitle2" noWrap>
                                                            {index + 1}
                                                        </Typography> */}

                                                    </TableCell>

                                                    <TableCell component="th" scope="row" pl={2}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {/* <Avatar alt={StudentName} src={avatarUrl} sx={{ width: 28, height: 28 }} /> */}
                                                            <Typography variant="subtitle2" noWrap>
                                                                {StudentName}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{mobileNumber}</TableCell>
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="left">{username}</TableCell>


                                                    <TableCell align="right">
                                                        <UserMoreMenu id={userId} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>
            </Page>)}
    </>
    );
}
