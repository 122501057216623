import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, TextField, MenuItem, Select, InputLabel, Button, IconButton, Box, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Page from '../../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { MuiChipsInput } from 'mui-chips-input'
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
const AddChapter = () => {

    const { ebookId , title } = useParams();
   
    
    const [loading, setLoading] = useState(true);
    
    const theme = useTheme();
    const LoginSchema = Yup.object().shape({
        title: Yup.string().required('title is required'),
        isActive: Yup.string().required('Status is required'),
        paid: Yup.string().required('Paid Status is required'),
        description : Yup.string().required('Description is required')
        
    });

    const defaultValues = {
        title : '',
        description: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    //Add Details'

    const onSubmit = async (data) => {
            // setShowProgress(true)
            setLoading(true)

            // const formData = new FormData();
            // formData.append('title', data.title);
            // formData.append('description' , data.description); 
            // formData.append('ebookId', ebookId);
            // formData.append('isActive', data.isActive);
            // formData.append('paid', data.paid);
            // formData.append('chapterNumber' , data.chapterNumber);
            // for( let Pair of formData){
            //     console.log(Pair[0] + " " + Pair[1]);
            // }
            let token = localStorage.getItem("token");
            const config = {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            let obj = {
                title : data.title ,
                description : data.description ,
                isActive : data.isActive ,
                paid : data.paid ,
                ebookId : ebookId ,
                chapterNumber : data.chapterNumber , 
            }
            axios.post(`${process.env.REACT_APP_LIVE_URL}/ebook/addChapter`, obj , config).then((response) => {
                if (response.data.status) {
                    // setProgress(100)
                    toast.success(response.data.msg)
                    // setSubmitted(true)
                    // setTimeout(() => {
                    //     setLoading(false)
                    //     navigate('/dashboard/ebooks')

                    // }, 1000)
                    reset()
                }else{
                    setLoading(false);
                    toast.error(response.data.msg);
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        

    }
    return (<>
        <Page title='Chapter'>
            <Stack spacing={3} ml={5}>
                <Typography className='page-heading'>{`Add Chapter into ${title} `}</Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '80%' }}> 
                        <Stack direction='row' spacing={2}>
                           <RHFTextField name="title" label="Ebook Title" />
                           
                        </Stack>
                        <RHFTextField name="description" label='Description'  id="custom-css-outlined-input" />
                        <Stack direction="row"  sx={{ width : '100%'}} spacing={2}>
                            <RHFTextField name="chapterNumber" label="Chapter Number"  type="number" id="custom-css-outlined-input" />

                            <RHFTextField
                                name='paid'
                                label="Paid Status"
                                sx={{ width: '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true}>Paid</MenuItem>
                                <MenuItem value={false}>Free</MenuItem>
                            </RHFTextField>
                            <RHFTextField
                                name='isActive'
                                label="Status"
                                sx={{ width: '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </RHFTextField>
                            
                        </Stack>
                      
                        
                    </Stack>

                    <Button fullWidth size="large" type="submit" variant="contained"
                        sx={{ bgcolor: theme.palette.primary.mainTheme, width: '80%', marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme, } }}
                        onClick={() => {
                            
                        }}
                    >
                        Submit
                        {/* {showProgress ? <Typography mr={2} sx={{ textAlign: 'center' }}>{submitted ? 'Submitted' : progress < 91 ? `Uploading File..... ${progress}%` : 'Submitting Form'}</Typography> : 'Add'}
                        {showProgress ? submitted ? '' : (<CircularProgress color="secondary" />) : ''} */}
                    </Button>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </Stack>
        </Page >
    </>
    )
}

export default AddChapter