import {
    Stack, Tooltip
  } from "@mui/material";
  import React from "react";
  import SyncLoader from "react-spinners/SyncLoader";
  import { useNavigate, useParams } from "react-router-dom";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { useTheme } from "@emotion/react";
  import {
    GridRowModes,
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
  } from "@mui/x-data-grid";
  import EditIcon from "@mui/icons-material/Edit";
  import SaveIcon from "@mui/icons-material/Save";
  import CancelIcon from "@mui/icons-material/Close";
  import getAccess from "../../../utils/getAccess";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {  Typography } from '@mui/material';

  
  const LectureRoomTable = ( ) => {
    const [loading, setLoading] = React.useState(false);
    const { lectId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
  
    const access = getAccess();
    // table thing
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    React.useEffect(() => {
      setLoading(true);
      const token = localStorage.getItem("token");
      async function fetchUsers() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureRoom?lectureId=${lectId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setLoading(false);
        // setLoadingTable(false);
        setRows(responseJson.data);
      }
  
      fetchUsers();
    }, []);
    const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
    //   const navigate = useNavigate()
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const makeActiveAndInActiveLectureResource = async (id) => {
  
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/makeActiveAndInActiveLectureResource/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
  
      });
      const responseJson = await response.json();
      if( responseJson?.status){
        toast.success(responseJson.msg)
        setTimeout(() => window.location.reload()
        , 1000)
      }else{
        toast.error(responseJson.msg);
      }
      
  
    }

    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      //   editDataInDb(newRow)
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
  
    const rowsWithSerialNumber = rows?.map((row, index) => ({
      ...row,
      serialNumber: index + 1,
    }));
    const columns = [
      {
        field: "sno",
        headerName: "S NO",
        width: 75,
        align: "left",
        headerAlign: "left",
        editable: false,
        renderCell: (params) => <strong>{params.value}</strong>,
      },
  
      {
        field: "roomName",
        headerName: "Room Name",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: true,
      },
      {
        field: "lectureName",
        headerName: "Lecture Name",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },
      {
        field: "batchName",
        headerName: "Batch Name",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },
      {
        field: "mentor",
        headerName: "Mentors",
        type: "",
        width: 200,
        align: "left",
        headerAlign: "left",
        renderCell : (params) => (
          params?.value?.length > 0 ? <Stack spacing={2} direction='column' >
            { params?.value?.map((item) => (
              <strong>{item?.mentorName}</strong>
          ))}
          </Stack> : <strong>NA</strong>
   
        )
      },
      
      {
        field: "students",
        headerName: "Student Count",
        type: "",
        width: 120,
        align: "left",
        headerAlign: "left",
        renderCell : (params) => (
           <strong>{params?.value?.length}</strong>
   
        )
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 400,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const rowData = rows.find((item) => item.id === id);
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Assign Mentor">
        <GridActionsCellItem
          icon={<LiveHelpIcon style={{ color: 'red' }} />}
          label="AssignMentor"
          onClick={() => { navigate(`/dashboard/assignMentor/${id}` , { state : { mentor : rowData?.mentor , lectureId : rowData?.lectureId}}) }}
          // onClick={() => console.log("line 12" , rowData)}
          // onClick={ (id) => handleOpen(id)}
          color="inherit"
        />
      </Tooltip> : <></>),
          ];
        },
      },
    ];
    return (
      <>
        <Typography variant='h2'>{`Lecture's Room`} </Typography>
          { loading == false ? <DataGrid
          // sx={{ overflow: "auto" }}
          // rowHeight={50}
          getRowHeight={() => 'auto'}
          autoHeight={true}
          rows={rowsWithSerialNumber}
          loading={loading}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 25, 50]}
          slots={{
            //   toolbar: EditToolbar,
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, showQuickFilter: true },
          }}
        /> : <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>}
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    );
  };
  
  export default LectureRoomTable;
  