import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack , Typography, MenuItem , Button} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

export default function CreateValidity({batchId , handleCloseModalForValidity}) {
    const theme = useTheme()
    const [loading, setLoading] = useState(false);
    const RegisterSchema = Yup.object().shape({
        regularPrice: Yup.number("Regular Price should be number").min(0 , 'Regular Price should be equal or greater than 0').required('Regular Price Required'),
        salePrice: Yup.number("Sale Price should be number").min(0 , 'Sale Price should be equal or greater than 0' ).required('Sale Price Required'),
        month : Yup.number('Month should be number').positive('Month should be positve').required('Month is Required'),
        isActive: Yup.boolean().required('Status required'),
    });

    const defaultValues = {
        salePrice: 0,
        regularPrice: 0,
        isActive: true ,
        month :  0
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    

   

    const onSubmit = async (data) => {
        setLoading(true)
        let token = localStorage.getItem("token");
        // console.log(data);

        try {

            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/createValidity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({...data , batch : batchId})
            })
            const result = await res.json();
            setLoading(false)
            if (result.status === true ) {
               toast.success(result.msg)
                reset();
            }else{
                toast.error(result.msg);
                
            }
        } catch (error) {
            //console.log(error);
        }

    };

    return (<>
        <Stack spacing={3} px={6} py={2} >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '100%' }}>
                    <Stack direction='row' spacing={3}>
                        <RHFTextField name="regularPrice" label="Regular Price" type='number' />
                        <RHFTextField name="salePrice" label="sale Price" type='number' />
                    </Stack>
                    <RHFTextField name="month" label="Month" type='number' />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isActive" label="Status" type='checkbox' select>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <Stack direction='row' spacing={3} width='100%' sx={{ display :'flex' , justifyContent : 'center'}}>
                      <Button onClick={() => handleCloseModalForValidity()} sx={{ width: "30%"  , color : 'white' ,  bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Close 
                      </Button>
                      <LoadingButton  size="large" type="submit" variant="contained" loading={loading}
                          sx={{ width:"30%" , bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                           Add
                      </LoadingButton>
                    </Stack>
                    
                </Stack>
            </FormProvider >
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack >
    </>
    );
}
