import React, { useEffect } from 'react'
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RHFTextEditor from '../../../components/hook-form/RHFTextEditor';
import { useTheme } from '@emotion/react';


const UpdateTest = () => {
    const theme = useTheme();

    const navigate = useNavigate();
    const [type, setType] = React.useState('');
    const [val, setVal] = useState(false)


    const [focus, setFocused] = React.useState(false);
    const [resources, setResources] = React.useState(false);
    const [users, usersSet] = React.useState([]);
    const { testId, testSeriesId } = useParams()
    const [loading, setLoading] = useState(false);
    const [fetchMessage, setFetchMessage] = useState()
    const [starting, setStarting] = React.useState(dayjs('2022-01-01T21:11:54'));
    const [ending, setEnding] = React.useState(dayjs('2022-01-01T21:11:54'));
    const handleChange = (newValue) => {
        setStarting(newValue);
    };
    const handleChangeTwo = (newValue) => {
        setEnding(newValue);
    };


    const LoginSchema = Yup.object().shape({

    });

    const defaultValues = {

    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [file, setFile] = useState()
    //Date

    const [showStartingDate, setShowStartingDate] = React.useState(false);





    //Getting Existing details
    const [lectureDetails, setLectureDetails] = useState()
    const [value, setValue] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getTestDetailsPerticular/${testId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();

            setLectureDetails(responseJson.data);
            setValue(prev => ({
                ...prev,
                Test_title: responseJson.data?.Test_title,
                duration: responseJson.data?.duration,
                instructions: responseJson.data?.instructions,
                No_of_question: responseJson.data?.No_of_questions,
                totalMarks: responseJson.data?.totalMarks,
                negativemarking: responseJson.data?.negativemarking,

                Test_code: responseJson.data?.Test_code,


            }))
            setStarting(dayjs(responseJson.data.starting_date))
            // setEnding(dayjs(responseJson.data.ending_date))

        }
        fetchUsers()
    }, [])
    //console.log(lectureDetails)


    const onSubmit = async (data) => {
        setLoading(true)
        const formData = new FormData();
        if (file) { formData.append('questionPaper', file); }
        if (answerTemplate) { formData.append('answerTemplate', answerTemplate); }
        // if (data.negativemarking) { formData.append(' negativemarking', data.negativemarking); }
        // else {
        //     formData.append(' negativemarking', data.negativemarking);
        // }
        formData.append('negativemarking', value?.negativemarking);
        formData.append('Test_title', value?.Test_title);
        formData.append('Test_code', value?.Test_code);
        formData.append('instructions', value?.instructions);
        formData.append('No_of_question', value?.No_of_question);
        formData.append('duration', value?.duration);
        formData.append('starting_date', value?.starting_date);
        formData.append('totalmarks', value?.totalMarks);
        formData.append('TestSeriesId', testSeriesId);


        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            },
        };
        //console.log(value?.starting_date);
        // //console.log(formData);
        axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateTheTestForTestSeries/${testId}`, formData, config).then((response) => {
            //console.log(response.data);


            if (response.data.msg === 'updated  the Test for TestSeries') {
                reset()
                setLoading(false)
                toast.success("Test Details Updated")

                setTimeout(() => {
                    navigate(`/dashboard/test/testSeriesDetails/${testSeriesId}`)
                    // window.location.reload()
                }, 1500)
            } else {
                toast.error(response.data.msg)
            }
        })
            .catch(e => {
                //console.log(e);
            })

    }


    //Getting Batch
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);

            // setLoading(false)


        }
        fetchUsers();
    }, []);
    //console.log(users)




    //Setting Old Values
    const onChange2 = (e) => {
        const { name, value } = e.target;
        setValue(prev => ({ ...prev, [name]: value }))

    }



    //Banner

    const [msg, setMsg] = useState('')
    const [focusThree, setFocusedThree] = React.useState(false);
    const [showBanner, setShowBanner] = React.useState(false);
    const changeHandler = async (event) => {
        setFile(event.target.files[0])
    };

    //Answer Template
    const [focusTwo, setFocusedTwo] = React.useState(false);
    const [showBannerTwo, setShowBannerTwo] = React.useState(false);
    const [answerTemplate, setAnswerTemplate] = useState()
    const changeHandlerTwo = async (event) => {
        setAnswerTemplate(event.target.files[0])
    };

    // temp
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        //console.log(selectedDate);
    };

    return (
        <Grid direction="row" pl={5}>


            <Typography variant='h2' ml={2}>Update Test Details</Typography>
            <Typography variant='h6' ml={2} mb={4}>Enter the below required details to update a Test</Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                {/* <Stack direction="row" spacing={5} > */}
                <Stack spacing={3} sx={{ width: '70%' }}>


                    <Stack>
                        <h4>Title</h4>
                        <RHFTextField name="Test_title" value={value?.Test_title} onChange={onChange2} />
                    </Stack>

                    <Stack>
                        <h4>Code</h4>
                        <RHFTextField name="Test_code" value={value?.Test_code} onChange={onChange2} />
                    </Stack>


                    <Stack direction='row' spacing={3}>

                        {/* <RHFTextField
                    name='starting_date'
                    type='date'
                    // label={lectureDetails?.starting_date}
                    onChange={handleDateChange}
                    ></RHFTextField> */}
                        {/* <h4>Starting Data</h4>   */}
                        {/* <p>{lectureDetails?.starting_date}</p> */}

                        {/* <RHFTextField name="starting_date"
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label={lectureDetails?.starting_date}
                                onClick={() => setShowStartingDate(true)}
                                // type={showStartingDate || focus ? "date" : "text"} 
                                type='text'
                                /> */}

                        {/* <DatePicker selected={selectedDate} onChange={handleDateChange} />
                        {selectedDate && <p>Selected Date: {selectedDate.toDateString()}</p>} */}

                        <RHFTextField
                            name='question_paper_type'
                            label={lectureDetails?.question_paper_type}

                            sx={{ width: '100%' }}
                            select
                        >
                            <MenuItem onClick={() => setType(true)} value='objective'>Objective</MenuItem>
                            <MenuItem onClick={() => setType(false)} value="subjective">Subjective</MenuItem>

                        </RHFTextField>
                    </Stack>
                    <Stack direction='row' spacing={3}>
                        <Stack direction="column" sx={{ width: '100%' }}>

                            <RHFTextField
                                name="questionPaper"

                                onChange={changeHandler}
                                onFocus={() => setFocusedThree(true)}
                                onBlur={() => setFocusedThree(false)}

                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label="Question Paper"
                                onClick={() => setShowBanner(true)}
                                type={showBanner || focusThree ? "file" : "text"}
                            />
                            {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                            {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>} */}
                        </Stack>
                        <Stack direction="column" sx={{ width: '100%' }}>
                            <RHFTextField
                                name="answerTemplate"


                                onFocus={() => setFocusedTwo(true)}
                                onBlur={() => setFocusedTwo(false)}

                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label="Answer Template"
                                onClick={() => setShowBannerTwo(true)}
                                type={showBannerTwo || focusTwo ? "file" : "text"}
                            />
                            {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                            {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>} */}
                        </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>

                        <Stack direction='row' sx={{ width: '100%' }}>
                            <h4>Total Marks</h4>
                            <RHFTextField name="totalMarks" value={value?.totalMarks} onChange={onChange2} type='number' />
                        </Stack>

                        <RHFTextField
                            name='negativemarking'
                            // value={value?.negativemarking ? 'Yes' : 'False'}

                            label={value?.negativemarking ? 'Yes' : 'NO'}
                            value={value?.negativemarking}
                            onChange={onChange2}
                            sx={{ width: '100%' }}
                            select
                            type="checkbox"
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>

                        </RHFTextField>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>

                        <Stack>
                            <h4>No Of Question</h4>
                            <RHFTextField name="No_of_question" value={value?.No_of_question} onChange={onChange2} type='number' />
                        </Stack>

                        <Stack>
                            <h4>Duration</h4>
                            <RHFTextField name="duration" label="" value={value?.duration} onChange={onChange2} type='number' />
                        </Stack>

                        <Stack>
                            <h4>Instruction</h4>
                            <RHFTextField name="instructions" value={value?.instructions} onChange={onChange2} />
                        </Stack>

                    </Stack>




                </Stack>



                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                    sx={{ bgcolor: theme.palette.primary.mainTheme, width: '70%', marginTop: '5%', '&:hover': { background: theme.palette.primary.mainTheme } }}
                >
                    Update
                </LoadingButton>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}


        </Grid >
    )
}

export default UpdateTest